import axios from 'axios';
import { useEffect } from 'react';
import { logout } from '../redux/reducers/auth';
import { useDispatch } from 'react-redux';

const API2 = axios.create({
    baseURL: 'https://wcf6p1rxl9.execute-api.us-west-1.amazonaws.com/prod',
    headers: {
        'content-type':'application/json'
    }
});

const AxiosInterceptor2 = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {

        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {
            if(error.response?.status==401){
                dispatch(logout("Inicia sesión nuevamente"));
            }
            return Promise.reject(error);
        }


        const interceptor = API2.interceptors.response.use(resInterceptor, errInterceptor);

        return () => API2.interceptors.response.eject(interceptor);

    }, [])
    return children;
}

export default API2;
export { AxiosInterceptor2 };
