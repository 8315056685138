import { PencilIcon } from "@heroicons/react/20/solid";
import { TrashIcon } from "@heroicons/react/24/outline";
import { PlusIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { classNames } from "../../constants/functions";
import PlusFolder from "../vectors/PlusFolder";
import AssessmentNewSimpleObjective from "./AssessmentNewSimpleObjective";

const AssessmentNewParentObjective = ({
  id,
  title,
  description,
  selectNextObjectiveToDelete,
  selectNextObjectiveToEdit,
  active,
  descendants,
  newNextChildObjective,
  selectNextParentObjectiveToDelete,
  selectNextParentObjectiveToEdit
}) => {

  const selectNextObjectiveToDeleteWrapper = (childId) => {
    selectNextObjectiveToDelete(id, childId);
  }
  if (!active) {
    return <></>;
  }
  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames(
        "relative bg-white p-6 flex shadow rounded-lg border"
      )}
    >
      <div className="flex-1 flex flex-col">
        <div className="flex text-sm font-medium justify-end">
          <div className="ml-4 border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              className="text-green-600 hover:text-green-800 flex items-center"
              onClick={(e) => newNextChildObjective(id)}
            >
              <PlusIcon className="h-5 w-5 mr-1" />
              Objetivo Secundario
            </button>
          </div>
          <div className="ml-4 border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              className="text-green-600 hover:text-green-800 flex items-center"
              onClick={(e) => selectNextParentObjectiveToEdit(id, title)}
            >
              <PencilIcon className="h-5 w-5 mr-1" />
              Editar Objetivo
            </button>
          </div>
          <div className="ml-4 border-l border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              onClick={(e) => selectNextParentObjectiveToDelete(id)}
              className="text-red-600 hover:text-red-800 flex items-center"
            >
              <TrashIcon className="h-5 w-5 mr-1" />
              Eliminar Objetivo
            </button>
          </div>
        </div>
        <h3 className="text-lg font-medium focus:outline-none">{title}</h3>
        <h3 className="mt-4 text focus:outline-none text-indigo-600">
          {descendants.length} objetivos secundarios:
        </h3>
        <div className="mt-3 flex flex-col space-y-4">
          {descendants.length > 0 ? (
            descendants.map((objective) => (
              <AssessmentNewSimpleObjective
                key={`key-new-child-objective-${objective.objetivo_proximo_id}`}
                id={objective.objetivo_proximo_id}
                active={objective.is_active}
                status={objective.estatus_objetivo_id}
                title={objective.titulo}
                description={objective.descripcion}
                dueDate={new Date(objective.fecha_entrega+"T00:00:00")}
                selectNextObjectiveToDelete={selectNextObjectiveToDeleteWrapper}
                selectNextObjectiveToEdit={selectNextObjectiveToEdit}
              />
            ))
          ) : (
            <div className="text-center mt-3">
              <PlusFolder />
              <h3 className="mt-2 text-sm font-medium text-gray-900">
                Sin objetivos secundarios
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Da click en el botón "Objetivo secundario" y crea un nuevo
                objetivo secundario.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

AssessmentNewParentObjective.propTypes = {
  id: PropTypes.number,
  descendants: PropTypes.array,
  title: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  selectNextParentObjectiveToDelete: PropTypes.func,
  selectNextParentObjectiveToEdit: PropTypes.func,
  selectNextObjectiveToDelete: PropTypes.func,
  selectNextObjectiveToEdit: PropTypes.func,
  newNextChildObjective: PropTypes.func,
};

AssessmentNewParentObjective.defaultProps = {
  id: 1,
  active: true,
  descendants: [],
  title: "Default title",
  description: "Default description",
  selectNextParentObjectiveToDelete: () => {},
  selectNextParentObjectiveToEdit: () => {},
  selectNextObjectiveToDelete: () => {},
  selectNextObjectiveToEdit: () => {},
  newNextChildObjective: () => {},
};

export default AssessmentNewParentObjective;
