import React from "react";
import { UsersIcon, UserGroupIcon } from "@heroicons/react/24/solid";
import face from "./../../assets/img/faces/avatar.png";

const OrganizationCard = ({ user, className, color }) => {
  const countTotal = (node, count = 0) => {
    if (!node.tieneColaboradores) {
      return 1;
    } else {
      for (const colab of node.colaboradores) {
        count += countTotal(colab);
      }
    }
    return count + 1;
  };

  return (
    <>
      <div
        id={`emp-${user.numero_empleado}`}
        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow w-96 m-auto flex- flex-col overflow-hidden"
      >
        <div className="flex w-full items-center justify-between space-x-6 p-6">
          <div className="flex-1 ">
            <h3 className="text-left text-sm font-medium text-gray-900">
              {user.nombre_completo}
            </h3>
            <h3 className="mt-1 text-left text-xs font-medium text-gray-600">
              {user.region}
            </h3>
            <p className="mt-1 text-left text-xs text-gray-500">
              {user.puesto}
            </p>
            <p className="mt-1 text-left text-xs text-gray-500">
              {user.area}
            </p>
          </div>
          <div
            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-300 bg-cover bg-center"
            style={{backgroundImage: `url(${user?.imagen_perfil ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${user?.imagen_perfil}`: face})`}}
          />
        </div>
        {user.tieneColaboradores && <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="flex w-0 flex-1">
              <div
                className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                <UsersIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{user.colaboradores.length} Directos</span>
              </div>
            </div>
            <div className="-ml-px flex w-0 flex-1">
              <div
                className="relative inline-flex w-0 flex-1 items-center justify-center rounded-br-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
              >
                <UserGroupIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
                <span className="ml-3">{countTotal(user) - 1} Totales</span>
              </div>
            </div>
          </div>
        </div>}
      </div>
    </>
  );
};

OrganizationCard.defaultProps = {
  className: "",
  color: "",
  user: {},
};

export default OrganizationCard;
