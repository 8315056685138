import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundPercentage, roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

const COLORSOBJFILL = ["#16a34a66", "#dc262666"];
const COLORSOBJSTROKE = ["#16a34a", "#dc2626"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);


  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center pt-2 items-center space-x-6 flex-wrap">
      {
        payload.map((entry, index) => {
          const { payload } = entry;
          return (
          <li key={`item-${index}`} className="flex items-center">
            <div className="h-4 w-4 mr-2" style={{backgroundColor: payload.fill, border: 1, borderStyle: "solid", borderColor: payload.fill}}></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        )})
      }
    </ul>
  );
}

const GlobalObjectives = ({ period, isBoss, region, area }) => {
  const {user, token} = useSelector(state => state.authentication);
  const [global, setGlobal] = useState({});
  const [loadingGlobal, setLoadingGlobal] = useState(true);

  useEffect(() => {
    if (period) {
      fetchGlobalObjectivess()
    }
  }, [period, region, area]);

  const fetchGlobalObjectivess = useCallback(()=>{
    let params = { year: period };
      if (region != 0) {
        params.region_id = region;
      }
      if (area != "0") {
        params.area = area;
      }
      setLoadingGlobal(true);
      const headers = { Authorization: token }
      API.get(`/objectives/results/counts`, { params, headers })
        .then(({ status, data }) => {
          setLoadingGlobal(false);
          if (status === 200) {
            setGlobal(data);
          } else {
            setGlobal({});
          }
        })
        .catch((error) => {
          setLoadingGlobal(false);
          setGlobal({});
        });
  },[period, region, area, token])

  const getObjectivesDataAchieved = () => {
    let cumplidos = 0;
    let noCumplidos = 0;
    let total = Number(global['total']);
    let alcanzados = Number(global['alcanzados']);
    let porcentajeAlcanzados = alcanzados/total;
    cumplidos = roundPercentage(porcentajeAlcanzados);
    noCumplidos = roundPercentage(1 - porcentajeAlcanzados);
    let result = [
      { name: "Alcanzados", value: cumplidos },
      { name: "No alcanzados", value: noCumplidos },
    ];
    return result;
  };

  const getObjectivesDataCompleted = () => {
    let cumplidos = 0;
    let noCumplidos = 0;
    let total = Number(global['total']);
    let completados = Number(global['completados']);
    let porcentajeCompletados = completados/total;
    cumplidos = roundPercentage(porcentajeCompletados);
    noCumplidos = roundPercentage(1 - porcentajeCompletados);
    let result = [
      { name: "Cumplidos", value: cumplidos },
      { name: "No Cumplidos", value: noCumplidos },
    ];
    return result;
  };

  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-2">
        <dt className="truncate text-sm font-medium text-gray-500">
          Objetivos totales
        </dt>
        {
          loadingGlobal
          ? <InfinityLoader legend={false} className="h-14 w-14"/>
          : <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {global.total}
          </dd>
        }
      </div>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-2">
        <dt className="truncate text-sm font-medium text-gray-500">
          Objetivos alcanzados
        </dt>
        {
          loadingGlobal
          ? <InfinityLoader legend={false} className="h-14 w-14"/>
          : <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {global.alcanzados}
          </dd>
        }
      </div>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-2">
        <dt className="truncate text-sm font-medium text-gray-500">
          Objetivos por alcanzar
        </dt>
        {
          loadingGlobal
          ? <InfinityLoader legend={false} className="h-14 w-14"/>
          : <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {global.no_alcanzados}
          </dd>
        }
      </div>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-3 h-96">
        <dt className="truncate text-sm font-medium text-gray-500">
          Porcentaje de objetivos alcanzados
        </dt>
        {loadingGlobal ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="h-full">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  nameKey="name"
                  dataKey="value"
                  data={getObjectivesDataAchieved()}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="80%"
                  fill="#E31B22"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {getObjectivesDataAchieved().map((entry, index) => (
                    <Cell
                      key={`key-objective-smart-estatus-cell-${index}`}
                      fill={COLORSOBJFILL[index]}
                      stroke={COLORSOBJSTROKE[index]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
              </PieChart>
            </ResponsiveContainer>
          </dd>
        )}
      </div>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-3 h-96">
        <dt className="truncate text-sm font-medium text-gray-500">
          Porcentaje de objetivos completados por colaborador
        </dt>
        {loadingGlobal ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="h-full">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  nameKey="name"
                  dataKey="value"
                  data={getObjectivesDataCompleted()}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="80%"
                  fill="#E31B22"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {getObjectivesDataCompleted().map((entry, index) => (
                    <Cell
                      key={`key-objective-ventas-estatus-cell-${index}`}
                      fill={COLORSOBJFILL[index]}
                      stroke={COLORSOBJSTROKE[index]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
              </PieChart>
            </ResponsiveContainer>
          </dd>
        )}
      </div>
    </>
  );
};

GlobalObjectives.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number,
  area: PropTypes.string,
};

GlobalObjectives.defaultProps = {
  period: null,
  isBoss: true,
  region: 0,
  area: "0",
};

export default GlobalObjectives;
