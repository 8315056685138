import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import HomeObjective from "../../components/cards/HomeObjective";
import HomeAssessment from "../../components/cards/HomeAssessment";
import face from "./../../assets/img/faces/avatar.png";
import PlusFolder from "../../components/vectors/PlusFolder";
import HomeProgress from "../../components/cards/HomeProgress";
import { Link, useNavigate, useParams } from "react-router-dom";
import { ChevronRightIcon, HomeIcon } from "@heroicons/react/24/solid";
import { checkForRound } from "../../utils/math_utils";
import ProfileEmployeeAssessment from "../../components/cards/ProfileEmployeeAssessment";
import { getDateLabel } from "../../utils/date_utils";
import HomeProject from "../../components/cards/HomeProject";

export default function EmployeeProfile() {
  const { user, token } = useSelector((state) => state.authentication);
  const [period, setPeriod] = useState(null);
  const pathParams = useParams();
  const [objectives, setObjectives] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [employee, setEmployee] = useState(null);
  const [lastProgress, setLastProgress] = useState([]);
  const [projects, setProjects] = useState([]);
  const [salesObjectives, setSalesObjectives] = useState({});
  const [errorTitle, setErrorTitle] = useState("Error");
  const [error, setError] = useState("Error");
  let notificationRef = useRef();
  let navigate = useNavigate();

  useEffect(() => {
    if(assessments.length > 0){
      let assessmentsToUpdate = assessments.filter(a => a.revision_terminada && a.estatus_cierre_id==2 && a.estatus_evaluacion_id==2);
      if(assessmentsToUpdate.length > 0){
        navigate(`/t3b/assessments/${assessmentsToUpdate[0].evaluacion_usuario_id}/confirm_feedback`)
      }
    }
  }, [assessments]);

  useEffect(() => {
    const params = { last: 1 };
    const options = { params, headers: { Authorization: token } };
    API.get("/periods", options)
      .then(({ data, status }) => {
        if (status === 200) {
          setPeriod(data);
          fetchObjectives(pathParams.employeeId, data.periodo_id, setObjectives);
          fetchProjects(pathParams.employeeId, data.periodo_id, setProjects);
          fetchAssessments(pathParams.employeeId, data.periodo_id, setAssessments, setSalesObjectives);
        }
      })
      .catch((error) => {
        setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
      });
    const optionsTwo = { params: { users: pathParams.employeeId }, headers: { Authorization: token } };
    API.get("/usersearch", optionsTwo)
      .then(({ data, status }) => {
        if (status === 200) {
          setEmployee(data[0]);
        }
      })
      .catch((error) => {
        setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
      });
  }, []);

  useEffect(() => {
    const fetchLastProgress = (users) => {
      const params = { users };
      const options = { params, headers: { Authorization: token } };
      API.get("/lastprogresses", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setLastProgress(data);
          } else {
            setLastProgress([]);
          }
        })
        .catch((error) => {
          setLastProgress([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };

    if (employee) {
      let usersProgress = [];
      usersProgress.push(employee.numero_empleado);
      fetchLastProgress(usersProgress.join());
    }
  }, [employee]);

  const fetchObjectives = useCallback(
    (users, period, setter) => {
      const params = { users, period };
      const options = { params, headers: { Authorization: token } };
      return API.get("/objectives", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setter(data);
          }
        })
        .catch((error) => {
          setter([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    },
    [token]
  );

  const fetchProjects = useCallback(
    (users, period, setter) => {
      const params = { projects: true, current_next: users, period };
      const options = { params, headers: { Authorization: token } };
      return API.get("/objectives", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setter(data);
          }
        })
        .catch((error) => {
          setter([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información de los proyectos"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información de los proyectos"
            );
          }
          notificationRef.current?.showNotification();
        });
    },
    [token]
  );

  const fetchAssessments = useCallback(
    (users, period, setter, setterSales) => {
      const params = { users, period };
      const options = { params, headers: { Authorization: token } };
      return API.get("/assessments", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setter(data);
            let userAssessments = [];
            for(let i=0; i < data.length; i++){
              let assess = data[i];
              if(assess.tipo_objetivos == 3 && assess.estatus_evaluacion_id!=3){
                userAssessments.push(assess.evaluacion_usuario_id);
              }
            }
            if(userAssessments.length > 0){
              fetchSalesObjectiveAnswers(userAssessments.join(), setterSales);
            }
          }
        })
        .catch((error) => {
          setter([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    },
    [token]
  );

  const fetchSalesObjectiveAnswers = useCallback(
    (assessments, setter) => {
      let params = {
        assessments: assessments
      }
      const options = { params, headers: { Authorization: token } };
      return API.get(`/assessments/salesobjectivesusers`, options)
        .then(({ data, status }) => {
          let newData = {};
          if (status === 200) {
            newData = data;
          }
          setter(newData);
        })
        .catch((error) => {
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
          return setter({});
        });
    },
    [token]
  );

  const loadObjectivesAgain = () => {
    fetchObjectives(pathParams.employeeId, period.periodo_id, setObjectives);
    fetchProjects(pathParams.employeeId, period.periodo_id, setProjects);
  }

  return (
    <>
      <div className="min-h-full">
        <main className="py-8">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <h1 className="sr-only">Perfil de usuario</h1>
            <nav className="flex mb-4" aria-label="Breadcrumb">
              <ol className="flex items-center space-x-4">
                <li>
                  <div>
                    <Link
                      to="/t3b/home"
                      className="text-gray-400 hover:text-gray-500"
                    >
                      <HomeIcon
                        className="h-5 w-5 flex-shrink-0"
                        aria-hidden="true"
                      />
                      <span className="sr-only">Home</span>
                    </Link>
                  </div>
                </li>
                <li>
                  <div className="flex items-center">
                    <ChevronRightIcon
                      className="h-5 w-5 flex-shrink-0 text-gray-400"
                      aria-hidden="true"
                    />
                    <span className="ml-4 text-sm font-medium text-gray-700">
                      {employee?.nombre_completo}
                    </span>
                  </div>
                </li>
              </ol>
            </nav>
            <div className="space-y-4 lg:space-y-8">
              <section aria-labelledby="profile-overview-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <h2 className="sr-only" id="profile-overview-title">
                    Perfil de usuario
                  </h2>
                  <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                          <img
                            className="mx-auto h-20 w-20 rounded-full object-cover"
                            src={
                              employee?.imagen_perfil
                                ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${employee.imagen_perfil}`
                                : face
                            }
                            alt=""
                          />
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left justify-center flex flex-col">
                          <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                            {employee?.nombre_completo}
                          </p>
                          <p className="text-sm font-medium text-red-600">
                            {employee?.puesto}
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0">
                        {objectives.filter(o=>o.estatus_periodo_id==1).length > 0 &&
                          <div>
                            <p className="text-xs font-medium text-gray-900">{objectives.filter(o=>o.estatus_objetivo_id==4 && o.estatus_periodo_id==1).length} de {objectives.filter(o=>o.estatus_periodo_id==1).length} objetivos validados</p>
                            <div className="mt-1" aria-hidden="true">
                              <div className="overflow-hidden rounded-full bg-gray-200">
                                <div className="h-2 rounded-full bg-green-600" style={{ width: `${objectives.filter(o=>o.estatus_objetivo_id==4).length/objectives.filter(o=>o.estatus_periodo_id==1).length*100}%` }} />
                              </div>
                            </div>
                          </div>
                        }
                        {/* <a
                          href="/#"
                          className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                        >
                          Ver progreso
                        </a> */}
                      </div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 bg-gray-50 px-6 py-5 text-center text-sm font-medium">
                    <nav
                      className="flex flex-col items-center justify-center"
                      aria-label="Progress"
                    >
                      <p className="text-xl font-light">
                        <span className="font-bold uppercase">{period?.titulo}</span>
                      </p>
                      <p className="text font-light">
                      <span className="font-bold text-gray-500">Del {getDateLabel(period?.fecha_inicio+"T00:00:00")} al {getDateLabel(period?.fecha_fin+"T00:00:00")}</span>
                      </p>
                    </nav>
                  </div>
                </div>
              </section>
              {objectives.filter(o => o.estatus_periodo_id==2).length > 0 && <section aria-labelledby="objetivos">
                <h2 className="text-lg font-medium text-gray-500">
                  Objetivos {new Date(period?.fecha_fin).getFullYear() + 1}
                </h2>
                <div className="mt-3 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow">
                  <h2 className="sr-only" id="objetivos">
                    Objetivos
                  </h2>
                  {objectives.filter(o => o.estatus_periodo_id==2).map((objective) => (
                    <HomeObjective
                      key={`key-objetive-row-${objective.objetivo_id}`}
                      id={objective.objetivo_id}
                      status={objective.estatus_objetivo_id}
                      title={objective.titulo}
                      description={objective.descripcion}
                      periodStatus={objective.estatus_periodo_id}
                      dueDate={objective.fecha_entrega+"T00:00:00"}
                      startDate={period.fecha_inicio}
                      self={user?.numero_empleado==objective.numero_empleado}
                      superior={user?.colaboradores.includes(objective.numero_empleado)}
                      endSelfAssessment={assessments.filter(a=>a.estatus_evaluacion_id==4 || (a.estatus_evaluacion_id==2 && a.autoevaluacion_terminada)).length > 0}
                      endSuperiorAssessment={assessments.filter(a=>a.estatus_evaluacion_id==4 || (a.estatus_evaluacion_id==2 && a.evaluacion_terminada)).length > 0}
                      loadObjectivesAgain={loadObjectivesAgain}
                      objectiveValidated={objective.validado}
                    />
                  ))}
                </div>
              </section>
              }
              <section aria-labelledby="objetivos">
                <h2 className="text-lg font-medium text-gray-500">
                  Objetivos del periodo
                </h2>
                {
                  objectives.filter(o => o.estatus_periodo_id==1).length > 0
                  ? <div className="mt-3 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow">
                    <h2 className="sr-only" id="objetivos">
                      Objetivos
                    </h2>
                    {objectives.filter(o => o.estatus_periodo_id==1).map((objective) => (
                      <HomeObjective
                        key={`key-objetive-row-${objective.objetivo_id}`}
                        id={objective.objetivo_id}
                        status={objective.estatus_objetivo_id}
                        title={objective.titulo}
                        description={objective.descripcion}
                        periodStatus={objective.estatus_periodo_id}
                        dueDate={objective.fecha_entrega+"T00:00:00"}
                        startDate={period.fecha_inicio}
                        self={user?.numero_empleado==objective.numero_empleado}
                        superior={user?.colaboradores.includes(objective.numero_empleado)}
                        endSelfAssessment={assessments.filter(a=>a.estatus_evaluacion_id==4 || (a.estatus_evaluacion_id==2 && a.autoevaluacion_terminada)).length > 0}
                        endSuperiorAssessment={assessments.filter(a=>a.estatus_evaluacion_id==4 || (a.estatus_evaluacion_id==2 && a.evaluacion_terminada)).length > 0}
                        loadObjectivesAgain={loadObjectivesAgain}
                        objectiveValidated={objective.validado}
                      />
                    ))}
                  </div>
                  : salesObjectives[pathParams.employeeId]?.length  > 0
                    ? <div className="m-0 overflow-x-auto my-6">
                      <table className="min-w-full divide-y divide-gray-300 border mb-2">
                        <thead className="bg-gray-50">
                          <tr className="divide-x divide-gray-200">
                            <th scope="col" className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 print:text-xs">
                              Objetivo
                            </th>
                            <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                              Esperado
                            </th>
                            <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                              Real
                            </th>
                            <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                              Resultado
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {salesObjectives[pathParams.employeeId]?.map((sale) => (
                            <tr key={`key-sale-objective-${sale.objetivo_venta_id}`} className="divide-x divide-gray-200">
                              <td className="p-2 text-sm font-medium text-gray-900 print:text-xs">
                                {sale.titulo}
                              </td>
                              <td className="p-2 text-sm text-gray-900 text-center print:text-xs">{checkForRound(sale.valor_esperado)}</td>
                              <td className="p-2 text-sm text-gray-900 text-center print:text-xs">{checkForRound(sale.valor_real)}</td>
                              <td className={`whitespace-nowrap p-2 text-sm text-center font-medium print:text-xs ${sale.cumplido==='Cumplido' ? 'text-green-600' : sale.cumplido==='No Aplica' ? 'text-yellow-500' : 'text-red-600'}`}>{sale.cumplido}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    : <div className="text-center mt-3">
                      <PlusFolder />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">Sin objetivos</h3>
                      <p className="mt-1 text-sm text-gray-500">Espera por la carga de objetivos en el próximo periodo de evaluación.</p>
                    </div>
                }
              </section>
              {projects.filter(o => o.estatus_periodo_id == 2).length > 0 && <section aria-labelledby="proyectos">
                <h2 className="text-lg font-medium text-gray-500">
                  Proyectos {new Date(period?.fecha_fin).getFullYear() + 1}
                </h2>
                <div className="mt-3 divide-y divide-gray-200 overflow-hidden rounded-lg">
                  <h2 className="sr-only" id="proyectos">
                  Proyectos
                  </h2>
                  {projects.filter(o => o.estatus_periodo_id == 2).map((project) => (
                    <HomeProject
                      key={`key-next-project-${project.proyecto_id}`}
                      id={project.proyecto_id}
                      status={project.estatus}
                      title={project.titulo}
                      valor={project.valor}
                      logrado={project.logrado}
                      semestre={project.semestre}
                      next={true}
                      krs={project.krs}
                    />
                  ))}
                </div>
              </section>}
              {projects.filter(o => o.estatus_periodo_id == 1).length > 0 && <section aria-labelledby="objetivos">
                <h2 className="text-lg font-medium text-gray-500">
                  Proyectos {period?.titulo}
                </h2>
                <div className="mt-3 divide-y divide-gray-200 overflow-hidden rounded-lg">
                  <h2 className="sr-only" id="objetivos">
                    Objetivos
                  </h2>
                  {projects.filter(o => o.estatus_periodo_id == 1).map((project) => (
                    <HomeProject
                      key={`key-next-project-${project.proyecto_id}`}
                      id={project.proyecto_id}
                      status={project.estatus}
                      title={project.titulo}
                      valor={project.valor}
                      logrado={project.logrado}
                      semestre={project.semestre}
                      next={false}
                      krs={project.krs}
                    />
                  ))}
                </div>
              </section>}
              {assessments.length > 0 && (
                <section aria-labelledby="evaluaciones" className="mb-8">
                  <h2 className="sr-only" id="evaluaciones">
                    Evaluación del periodo
                  </h2>
                  <div className="space-y-3">
                    {assessments.map((assessment) => (
                      <ProfileEmployeeAssessment
                        key={`key-assessment-row-${assessment.evaluacion_usuario_id}`}
                        id={assessment.evaluacion_usuario_id}
                        status={assessment.estatus_evaluacion_id}
                        title={assessment.titulo}
                        startDate={new Date(assessment.fecha_inicio+"T00:00:00")}
                        endDate={new Date(assessment.fecha_fin+"T23:59:59")}
                        autoevaluacion={assessment.autoevaluacion_terminada}
                        evaluacion={assessment.evaluacion_terminada}
                        self={user?.numero_empleado==assessment.numero_empleado}
                        superior={user?.colaboradores.includes(assessment.numero_empleado)}
                        feedback={assessment.revision_agendada}
                        feedbackEnded={assessment.revision_terminada}
                        feedbackDate={new Date(assessment.revision_fecha_timezone)}
                      />
                    ))}
                  </div>
                </section>
              )}
            </div>
          </div>
        </main>
      </div>
    </>
  );
}