import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { useSelector } from 'react-redux'
import API from '../../services/API'
import { base64StringToBlob } from "blob-util";
import { useState } from 'react'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function AdminReportsActions({ periodo_nombre, periodo, area, region, job, zona, distrito, formato }) {
  const {user,token} = useSelector(state => state.authentication);
  const [loadingReport, setLoadingReport] = useState(false);

  const downloadFileIntegral = (tipo) => {
    let params = {
      periodo,
      area,
      region,
      job,
      zona,
      distrito,
      formato,
      tipo
    };
    const headers = { Authorization: token };
    setLoadingReport(true);
    API.get("/results/files/others", {
      params,
      headers,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingReport(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${tipo}_${periodo_nombre}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReport(false);
      });
  };

  return (
    <Menu as="div" className="w-full relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          {loadingReport ? "Cargando reporte" : "Otros reportes"}
          {loadingReport ? <ArrowDownTrayIcon className="h-5 w-5 mr-1 animate-bounce"/> : <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => downloadFileIntegral("GLOBAL")}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left block px-4 py-2 text-sm'
                  )}
                >
                  Promedio global
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => downloadFileIntegral("ACCIONES")}
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left block px-4 py-2 text-sm'
                  )}
                >
                  Acciones de capacitación
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => downloadFileIntegral("OBJETIVOS")}
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left block px-4 py-2 text-sm'
                  )}
                >
                  Objetivos
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => downloadFileIntegral("COMPETENCIAS")}
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left block px-4 py-2 text-sm'
                  )}
                >
                  Competencias
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => downloadFileIntegral("CULTURA")}
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left block px-4 py-2 text-sm'
                  )}
                >
                  Cultura
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => downloadFileIntegral("VENTAS")}
                  href="#"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left block px-4 py-2 text-sm'
                  )}
                >
                  Objetivos de ventas
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
