import { useEffect, useState } from "react";
import { CalendarDaysIcon, CalendarIcon, ChevronLeftIcon, XMarkIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
} from "@heroicons/react/20/solid";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import API from "../../services/API";
import { useSelector } from "react-redux";
import { useRef } from "react";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import { useCallback } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/entry.nostyle";
import Notification from "../../components/notifications/Notification";
import { PlusIcon } from "@heroicons/react/24/solid";
import InfinityLoader from "../../components/loaders/InfinityLoader";

export default function Periods() {
  const { user, token } = useSelector(state => state.authentication);
  const [periods, setPeriods] = useState([]);
  const newPeriodRef = useRef();
  const notificationRef = useRef();
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [error, setError] = useState("Ocurrió un error");
  const [title, setTitle] = useState("");
  const [dateRange, setDateRange] = useState([new Date(),new Date()]);

  useEffect(() => {
    fetchPeriods();
  }, []);

  const fetchPeriods = useCallback(()=>{
    setLoadingInfo(true);
    API.get("/periods", { headers: {Authorization: token} })
      .then(({ data, status }) => {
        setLoadingInfo(false);
        if (status === 200) {
          setPeriods(data);
        }
      })
      .catch((error) => {
        setLoadingInfo(false);
        setErrorTitle("Error al buscar");
        setError("Ocurrió un error al buscar los periodos");
        return notificationRef.current.showNotification();
      });
  },[token])

  const postPeriod = () => {
    if(title===""){
      setError("Ingresa un título");
      return notificationRef.current.showNotification();
    }
    setLoadingSave(true);
    const [fecha_inicio, fecha_fin] = dateRange;
    let body = {
        titulo: title,
        fecha_inicio,
        fecha_fin
    }
    API.post('/periods', body)
    .then( result => {
      setLoadingSave(false);
      newPeriodRef.current.hideModal();
      fetchPeriods();
    })
    .catch( error => {
        setLoadingSave(false);
    })
  }

  const onSelectDate = (date) => {
    setDateRange(date);
  };

  let superAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;

  return (
    <div className="min-w-0 w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="flex flex-col justify-start pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  Periodos de evaluación
                </h1>
                <p className="mt-4 text-sm text-gray-700">
                  Crea nuevos periodos de evaluación, asigna y consulta
                  evaluaciones en cada periodo.
                </p>
              </div>
              <div className="sm:flex-auto mt-4">

              </div>
            </div>
            {superAdministrador && <div className="flex justify-start sm:justify-end mb-4">
              <button
                type="button"
                className="inline-flex items-center rounded-md border border-transparent bg-green-200 px-6 py-3 text-base font-medium text-green-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                onClick={e =>  newPeriodRef.current.showModal()}
              >
                <PlusIcon className="h-5 w-5 mr-1" />
                Nuevo periodo
              </button>
            </div>}
          </div>
          {
            loadingInfo
            ? <>
              <InfinityLoader />
            </>
            : <div className="flex flex-col">
              <div className="-mx-4 overflow-hidden sm:-mx-0 lg:-mx-0  bg-white shadow sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                  {periods.map((period) => (
                    <li key={`key-periodo-${period.periodo_id}`}>
                      <Link
                        to={`/t3b/admin/periods/${period.periodo_id}`}
                        className="block hover:bg-gray-50"
                      >
                        <div className="flex items-center px-4 py-4 sm:px-6">
                          <div className="flex min-w-0 flex-1 items-center">
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <p className="truncate text-sm font-medium text-indigo-600">
                                  {period.titulo}
                                </p>
                                <p className="hidden md:flex mt-2 items-center text-sm text-gray-500">
                                  <CalendarDaysIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">
                                    Del {period.fecha_inicio} al {period.fecha_fin}
                                  </span>
                                </p>
                              </div>
                              <div className="block">
                                <div>
                                  <p className="text-sm text-gray-900 font-medium">
                                    Proceso COV: {period.cov_abierto ? <span className="text-yellow-500">Abierto</span> : <span className="text-red-500">Cerrado</span>}
                                  </p>
                                  {period.estatus == 1 ? (
                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                      <ExclamationCircleIcon
                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400"
                                        aria-hidden="true"
                                      />
                                      Periodo abierto
                                    </p>
                                  ) : (
                                    <p className="mt-2 flex items-center text-sm text-gray-500">
                                      <CheckCircleIcon
                                        className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400"
                                        aria-hidden="true"
                                      />
                                      Periodo cerrado
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          }
        </div>
      </main>
      <BigModalTemplate ref={newPeriodRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Nuevo periodo de evaluación</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newPeriodRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              TÍTULO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Título del periodo..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHAS DEL PERIODO
            </label>
            <div className="">
            <DateRangePicker
              onChange={onSelectDate}
              value={dateRange}
              locale="es"
              calendarIcon={
                <CalendarIcon className="h-5 w-5 text-black" />
              }
              clearIcon={null}
              prevLabel={
                <ChevronLeftIcon className="h-5 w-5 text-white" />
              }
              nextLabel={
                <ChevronRightIcon className="h-5 w-5 text-white" />
              }
            />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newPeriodRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingSave}
                onClick={postPeriod}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {
                  loadingSave ? "Creando periodo" : "Crear"
                }
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
