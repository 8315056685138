import { CalendarDaysIcon, PencilIcon } from "@heroicons/react/20/solid";
import { TrashIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { classNames } from "../../constants/functions";
import { months_short_es } from "../../utils/date_utils";

const FeedbackSimpleObjective = ({
  id,
  dueDate,
  title,
  description,
  selectNextObjectiveToDelete,
  selectNextObjectiveToEdit,
  active,
  changeObjectiveFeedbackStatus,
  feedbackStatus
}) => {
  if (!active) {
    return <></>;
  }
  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames(
        "relative bg-white p-6 flex shadow rounded-lg border"
      )}
    >
      <div className="flex-1 flex flex-col">
        <div className="w-full text-right">
          <button
            type="button"
            onClick={e => changeObjectiveFeedbackStatus(id, 2)}
            className={`relative inline-flex items-center rounded-l-md border px-2 py-2 text-sm font-medium focus:z-10 focus:border-green-500 focus:outline-none focus:ring-1 focus:ring-green-500 ${feedbackStatus==2 ? "bg-green-200 border-green-600 text-green-600" : "bg-white hover:bg-gray-50 border-gray-300 text-gray-500"}`}
          >
            <CheckIcon className="h-5 w-5" aria-hidden="true" />
          </button>
          <button
            type="button"
            onClick={e => changeObjectiveFeedbackStatus(id, 3)}
            className={`relative -ml-px inline-flex items-center rounded-r-md border px-2 py-2 text-sm font-medium focus:z-10 focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 ${feedbackStatus==3 ? "bg-red-200 border-red-600 text-red-600" : "bg-white hover:bg-gray-50 border-gray-300 text-gray-500"}`}
          >
            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <h3 className="text-lg font-medium focus:outline-none">{title}</h3>
        <p className="my-1 flex items-center text-sm font-medium text-gray-600">
          <CalendarDaysIcon className="h-5 w-5 mr-1" />
          Fecha límite de cumplimiento: {dueDate.getDate()} de{" "}
          {months_short_es[dueDate.getMonth()]}. {dueDate.getFullYear()}
        </p>
        <p className="mt-1 text-sm text-gray-500 flex-1">{description}</p>
        <div className="flex text-sm font-medium mt-4 justify-end">
          <div className="ml-4 border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              className="text-green-600 hover:text-green-800 flex items-center"
              onClick={(e) =>
                selectNextObjectiveToEdit(id, title, description, dueDate)
              }
            >
              <PencilIcon className="h-5 w-5 mr-1" />
              Editar Objetivo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

FeedbackSimpleObjective.propTypes = {
  id: PropTypes.number,
  feedbackStatus: PropTypes.number,
  dueDate: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  selectNextObjectiveToDelete: PropTypes.func,
  selectNextObjectiveToEdit: PropTypes.func,
  changeObjectiveFeedbackStatus: PropTypes.func,
};

FeedbackSimpleObjective.defaultProps = {
  id: 1,
  feedbackStatus: 1,
  active: true,
  dueDate: new Date(),
  title: "Default title",
  description: "Default description",
  selectNextObjectiveToDelete: () => {},
  selectNextObjectiveToEdit: () => {},
  changeObjectiveFeedbackStatus: () => {},
};

export default FeedbackSimpleObjective;
