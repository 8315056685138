import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useEffect, useState, useRef } from "react";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../services/API";
import { getDateLabel } from "../../utils/date_utils";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import CommonModalTemplate from "../../components/modals/CommonModalTemplate";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import axios from "axios";

export default function CompleteObjective() {
  let pathParams = useParams();
  let navigate = useNavigate();
  let { user, token } = useSelector((state) => state.authentication);
  const [objective, setObjective] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadingObjective, setLoadingObjective] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [progress, setProgress] = useState("");
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [boss, setBoss] = useState({});
  const endObjectiveRef = useRef();
  const notificationRef = useRef();

  useEffect(() => {
    const fetchObjectiveInfo = () => {
      API.get(`/objectives/${pathParams.objectiveId}`, {
        headers: { Authorization: token },
      })
        .then((result) => {
          setLoadingObjective(false);
          if (result.status == 200) {
            setObjective(result.data);
          } else {
            setObjective(null);
            setErrorTitle("Error");
            setError("Error al buscar la información del objetivo");
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          setErrorTitle("Error");
          setLoadingObjective(false);
          setObjective(null);
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    const fetchBoss = (users) => {
      const options = { headers: { Authorization: token } };
      API.get("/users/"+user.jefe_inmediato_id, options)
        .then(({ data, status }) => {
          if (status === 200) {
            setBoss(data);
          } else {
            setBoss({});
          }
        })
        .catch((error) => {
          setBoss({});
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    fetchObjectiveInfo();
    fetchBoss();
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const saveProgress = async (progr_res) => {
    if(progress==""){
      setError(
        "Ingresa el progreso"
        );
      return notificationRef.current.showNotification();
    }
    setLoadingSave(true);
    let body = {
      descripcion: progress,
      resultado_objetivo_id: progr_res,
      numero_empleado: user.numero_empleado,
      fecha_captura: new Date(),
      evidencias: [],
    };
    let evidencias = [];
    for (let i = 0; i < files.length; i++) {
      let item = files[i];
      let file = {
        last_modified_date: item.lastModifiedDate,
        name: item.name,
        size: item.size,
        type: item.type
      };
      evidencias.push(file);
    }
    body.evidencias = evidencias;
    API.post(`/objectives/${objective.objetivo_id}/newprogressv2`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        if (result.status == 201) {
          if(progr_res==3){
            sendEmailEnd();
          } else {
            sendEmail();
          }

          let data = result.data;
          let promises = [];
          for (let i = 0; i < data.length; i++) {
            const element = data[i];
            let item = files[i];
            let promise = axios.put(element.presigned_url, item, {
              headers: { 'Content-Type': element.type },
            })
            promises.push(promise);
          }

          if(promises.length > 0){
            Promise.all(promises).then(function(values) {
              setLoadingSave(false);
              let navigateRoute = "/t3b/home";
              if (objective.numero_empleado != user.numero_empleado) {
                navigateRoute = `/t3b/employees/${objective.numero_empleado}`;
              }
              navigate(navigateRoute, { replace: true });
            });
          } else {
            setLoadingSave(false);
            let navigateRoute = "/t3b/home";
            if (objective.numero_empleado != user.numero_empleado) {
              navigateRoute = `/t3b/employees/${objective.numero_empleado}`;
            }
            navigate(navigateRoute, { replace: true });
          }
        } else {
          setObjective(null);
          setError("Error al registrar la información del objetivo");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingSave(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al registrar la información del objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al registrar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const onAddFiles = (e) => {
    if(!e.target.files[0]){
      setError(
        "Debes subir un archivo"
      );
      return notificationRef.current.showNotification();
    } else {
      let sizeError = false;
      for (let i = 0; i < e.target.files.length; i++) {
        if(e.target.files[i].size > 10000000){
          sizeError = true;
          break;
        }
      }
      if(sizeError){
        setError(
          "El archivo no debe superar los 10MB"
        );
        return notificationRef.current.showNotification();
      }
      let a = Array.prototype.slice.call(e.target.files);
      setFiles(a);
    };
  };

  const removeFile =(index) => {
    let newFiles = [...files];
    newFiles.splice(index,1);
    setFiles(newFiles);
  }

  const sendEmail = () => {
    let evidencias = [];
    let evidencia = "";
    for (let i = 0; i < files.length; i++) {
      let item = files[i];
      evidencias.push(item.name);
    }
    if(evidencias.length > 0){
      evidencia = evidencias.join();
    }

    let body = {
      templateId: "d-fd9838e63897412d88bfc244012556df",
      groupId: 17817,
      personalizations: [
        {
          from: {
            email: "notificaciones@evaluaciondedesempeno3b.com",
            name: "Evaluación de desempeño",
          },
          to: [
            {
              email: boss?.email,
              name: boss?.nombre_completo,
            },
          ],
          dynamicTemplateData: {
            receive: boss?.nombre_completo,
            colaborador: `El colaborador ${user?.nombre} ha`,
            objetivo: objective?.titulo,
            avance: progress,
            evidencia: evidencia,
          },
        },
        {
          from: {
            email: "notificaciones@evaluaciondedesempeno3b.com",
            name: "Evaluación de desempeño",
          },
          to: [
            {
              email: user?.email,
              name: user?.nombre_completo,
            },
          ],
          dynamicTemplateData: {
            receive: user?.nombre_completo,
            colaborador: "Se ha",
            objetivo: objective?.titulo,
            avance: progress,
            evidencia: evidencia,
          },
        },
      ],
    };
    API.post("/integra/email/template", body, {
      headers: { Authorization: token },
    })
      .then((response) => {
        if (response) {
          const { status } = response;
          if (status != 200) {
            setError(
              "Ocurrió un error al notificar al jefe inmediato de la completitud de su objetivo"
            );
            notificationRef.current.showNotification();
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al notificar al jefe inmediato de la completitud de su objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al notificar al jefe inmediato de la completitud de su objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const sendEmailEnd = () => {
    let body = {
      templateId: "d-5300f44a427344ca92b3b66947916f28",
      groupId: 17817,
      personalizations: [
        {
          from: {
            email: "notificaciones@evaluaciondedesempeno3b.com",
            name: "Evaluación de desempeño",
          },
          to: [
            {
              email: boss?.email,
              name: boss?.nombre_completo,
            },
          ],
          dynamicTemplateData: {
            receive: boss?.nombre_completo,
            colaborador: user?.nombre,
            objetivo: objective?.titulo
          },
        },
      ],
    };
    API.post("/integra/email/template", body, {
      headers: { Authorization: token },
    })
      .then((response) => {
        if (response) {
          const { status } = response;
          if (status != 200) {
            setError(
              "Ocurrió un error al notificar al jefe inmediato de la completitud de su objetivo"
            );
            notificationRef.current.showNotification();
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al notificar al jefe inmediato de la completitud de su objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al notificar al jefe inmediato de la completitud de su objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const saveAnShowEnd = () => {
    if(progress==""){
      setError(
        "Ingresa avances o evidencias"
        );
      return notificationRef.current.showNotification();
    }
    endObjectiveRef.current.showModal();
  }

  return (
    <div className="min-h-full">
      <main className="py-8 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:max-w-7xl">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/t3b/home"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-4 text-sm font-medium text-gray-700">
                    Alcanzar objetivo
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          <div className="mt-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Detalles del objetivo
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Agrega un comentario referente a las acciones realizadas para
                alcanzar el objetivo.
              </p>
              <p className="mt-1 text-sm text-gray-500 font-bold">
                Recuerda que tu jefe inmediato debe validarlo para que cuente como objetivo alcanzado.
              </p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Objetivo
                  </dt>
                  {loadingObjective ? (
                    <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                  ) : (
                    <dd className="mt-1 text-gray-900 font-semibold sm:col-span-2 sm:mt-0">
                      {objective?.titulo}
                    </dd>
                  )}
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Entregable tangible
                  </dt>
                  {loadingObjective ? (
                    <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                  ) : (
                    <dd className="mt-1 text-gray-900 text-sm sm:col-span-2 sm:mt-0">
                      {objective?.descripcion}
                    </dd>
                  )}
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha límite de cumplimiento
                  </dt>
                  {loadingObjective ? (
                    <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                  ) : (
                    <dd className="mt-1 text-gray-900 text-sm sm:col-span-2 sm:mt-0">
                      {getDateLabel(objective ? objective?.fecha_entrega+"T00:00:00" : new Date())}
                    </dd>
                  )}
                </div>
              </dl>
            </div>
          </div>
          <div className="space-y-8 mt-4">
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Avances
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        value={progress}
                        onChange={(e) => setProgress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="sm:col-span-6">
              <label
                htmlFor="about"
                className="block text-sm font-medium text-gray-700"
              >
                Evidencia
              </label>
              <div className="mt-1 flex flex-col items-center justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6 bg-white">
                <div className="space-y-1 text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 48 48"
                    aria-hidden="true"
                  >
                    <path
                      d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <div className="flex text-sm text-gray-600 justify-center">
                    <label
                      htmlFor="file-upload"
                      className="text-center relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                    >
                      <span className="text-center">
                        Subir uno o más archivos
                      </span>
                      <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        multiple="multiple"
                        className="sr-only"
                        onChange={onAddFiles}
                      />
                    </label>
                  </div>
                  <p className="text-xs text-gray-500">
                    XLSX, DOCX, PPTX, PNG, JPG, PDF, MP4, GIF hasta 10MB
                  </p>
                </div>
                {files.length > 0 && (
                  <ul
                    role="list"
                    className="divide-y divide-gray-200 rounded-md border border-gray-200 w-full mt-6"
                  >
                    {files.map((f, fIndex) => (
                      <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                        <div className="flex w-0 flex-1 items-center">
                          <PaperClipIcon
                            className="h-5 w-5 flex-shrink-0 text-gray-400"
                            aria-hidden="true"
                          />
                          <span className="ml-2 w-0 flex-1 truncate">
                            {f.name}
                          </span>
                        </div>
                        <div className="ml-4 flex-shrink-0">
                          <button className="font-medium text-red-600 hover:text-red-500" onClick={e => removeFile(fIndex)}>
                            Eliminar
                          </button>
                        </div>
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={(e) => navigate(-1)}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  onClick={saveAnShowEnd}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Guardar
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <CommonModalTemplate ref={endObjectiveRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Alcanzar objetivo</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro que deseas alcanzar el objetivo? Una vez enviado no podrás editarlo
              </p>
              <p className="text-sm text-gray-500 font-bold">
                Recuerda que tu jefe inmediato debe validarlo para que cuente como objetivo alcanzado.
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingSave}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={e => saveProgress(3)}
          >
            {loadingSave ? "Cargando progreso" : "Sí"}
          </button>
          {!loadingSave && <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-red-300 bg-red-600 text-white px-4 py-2 text-base font-medium  shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            disabled={loadingSave}
            onClick={() => endObjectiveRef.current.hideModal()}
          >
            {loadingSave ? "Cargando progreso" : "No"}
          </button>}
        </div>
      </CommonModalTemplate>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
