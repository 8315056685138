import { Link } from "react-router-dom";
import banner from "./../../assets/img/brand/BANNER_COV.jpg";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";

const HomeCovs = () => {
  return (
    <>
      <h2 className="sr-only" id="covs">
        Carga de objetivos de venta
      </h2>
      <div className="relative bg-white shadow rounded-lg">
        <div className="relative mx-auto px-4 py-6 sm:px-6 lg:px-8 flex flex-wrap items-center justify-between">
          <div className="">
            <h2 className="text-lg font-semibold text-red-500">
              Periodo abierto
            </h2>
            <p className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
              Carga de objetivos de venta
            </p>
            <p className="text-lg text-gray-500">
              Realiza la carga de objetivos de venta de tus colaboradores.
            </p>
          </div>
          <div className="mt-4">
            <div className="inline-flex rounded-md shadow">
              <Link
                to="/t3b/covs"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-base font-medium text-white hover:bg-red-700"
              >
                Realizar carga
                <ArrowTopRightOnSquareIcon
                  className="-mr-1 ml-3 h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

HomeCovs.propTypes = { };

HomeCovs.defaultProps = { };

export default HomeCovs;
