import { useCallback, useEffect, useRef, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import API from "../../services/API";
import face from "./../../assets/img/faces/avatar.png";
import { roundTwoDecimals } from "../../utils/math_utils";
import { getDateLabel } from "../../utils/date_utils";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  PieChart,
  Pie,
  Cell,
  BarChart,
  XAxis,
  YAxis,
  Bar,
} from "recharts";

const COLORSOBJFILL = ["#16a34a66", "#dc262666"];
const COLORSOBJSTROKE = ["#16a34a", "#dc2626"];

const initialFilters = [{ id: "region", name: "Región", options: [] }];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);


  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center pt-2 items-center space-x-6 flex-wrap">
      {
        payload.map((entry, index) => {
          const { payload } = entry;
          return (
          <li key={`item-${index}`} className="flex items-center">
            <div className="h-4 w-4 mr-2" style={{backgroundColor: payload.fill, border: 1, borderStyle: "solid", borderColor: payload.fill}}></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        )})
      }
    </ul>
  );
}

const initialDate = new Date();
let fiveDaysDate = new Date();
fiveDaysDate.setDate(initialDate.getDate() + 4);
let firstDayCurrentMonth = new Date();
firstDayCurrentMonth.setDate(1);

export default function Results() {
  const { user, token } = useSelector((state) => state.authentication);
  const [loadingReportData, setLoadingReportData] = useState(true);
  const [reportData, setReportData] = useState(null);
  const [period, setPeriod] = useState(null);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();
  const { showNotification } = useOutletContext();
  const [selfProfile, setselfProfile] = useState(true);

  useEffect(() => {
    if (user) {
      const params = { last: 1 };
      const options = { params, headers: { Authorization: token } };
      API.get("/periods", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setPeriod(data);
            fetchReportsEdd(data);
          }
        })
        .catch((error) => {
          showNotification(error.status, error.message, "danger");
        });
    }
  }, [user]);

  const fetchReportsEdd = useCallback(
    (period, users = []) => {
      setLoadingReportData(true);
      let params = {
        foo: true,
      };
      if (users.length > 0) {
        params.users = users.join();
      }
      API.get(
        `/edd/resultados/${user?.numero_empleado}/periodo/${period?.periodo_id}`,
        {
          headers: { Authorization: token },
          params,
        }
      )
        .then((result) => {
          const { status, data } = result;
          setLoadingReportData(false);
          if (status === 200) {
            setReportData(data);
          } else if (status === 204) {
            setReportData(null);
          } else {
            setReportData(null);
            setError("Error al buscar los reportes de evaluación");
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          setLoadingReportData(false);
          setReportData(null);
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los reportes de evaluación"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los reportes de evaluación"
            );
          }
          notificationRef.current.showNotification();
        });
    },
    []
  );

  const getResultObjetivos = (reporte) => {
    let label = "";
    if(selfProfile){
      if (reporte?.objetivos > 0) {
        label = roundTwoDecimals((reporte?.validados / reporte?.objetivos) * 100);
        label = label + "%";
      } else if (reporte?.objetivos_ventas > 0) {
        label = roundTwoDecimals((reporte?.cumplidos / reporte?.objetivos_ventas) * 100);
        label = label + "%";
      } else {
        label = "No Aplica";
      }
    } else {
      let objetivos = 0;
      let objetivosVentas = 0;
      let validados = 0;
      let cumplidos = 0;
      for(let i = 0; i < reporte.length; i++){
        objetivos += Number(reporte[i].objetivos)
        objetivosVentas += Number(reporte[i].objetivos_ventas)
        validados += Number(reporte[i].validados)
        cumplidos += Number(reporte[i].cumplidos)
      }
      if (objetivos > 0) {
        label = roundTwoDecimals((validados / objetivos) * 100);
        label = label + "%";
      } else if (objetivosVentas > 0) {
        label = roundTwoDecimals((cumplidos / objetivosVentas) * 100);
        label = label + "%";
      } else {
        label = "No Aplica";
      }
    }
    return label;
  };

  const getPieChartObjectivesData = (r) => {
    let data = [
      { name: "Cumplidos", value: 0 },
      { name: "No cumplidos", value: 0 },
    ];

    if(selfProfile){
      if (r) {
        data[0].value += r?.validados;
        data[1].value += r?.no_validados;
      }
      if(data[0].value == 0 && data[1].value == 0){
        data[0].value += r?.cumplidos;
        data[1].value += r?.no_cumplidos;
      }
    } else {
      let no_validados = 0;
      let no_cumplidos = 0;
      let validados = 0;
      let cumplidos = 0;
      for(let i = 0; i < r.length; i++){
        no_validados += Number(r[i].no_validados)
        no_cumplidos += Number(r[i].no_cumplidos)
        validados += Number(r[i].validados)
        cumplidos += Number(r[i].cumplidos)
      }
      data[0].value += validados;
      data[1].value += no_validados;
      if(data[0].value == 0 && data[1].value == 0){
        data[0].value += cumplidos;
        data[1].value += no_cumplidos;
      }
    }
    return data;
  };

  const getCompetences = (r) => {
    let data = [];
    if(selfProfile){
      r?.secciones_valores.forEach((s) => {
        s.apartados_valores.forEach((a) => {
          if (a.evaluacion_valor_seccion) {
            let apartado = {
              name: a.apartado_titulo,
              Cumple: a.cumple_evaluacion,
              Insatisfactorio: a.insatisfactorio_evaluacion,
              Excede: a.excede_evaluacion,
            };
            data.push(apartado);
          } else if(a.cumple_evaluacion > 0 || a.insatisfactorio_evaluacion > 0 || a.excede_evaluacion > 0) {
            let apartado = {
              name: a.seccion_titulo,
              Cumple: a.cumple_evaluacion,
              Insatisfactorio: a.insatisfactorio_evaluacion,
            };
            data.push(apartado);
          }
        });
      });
    } else {
      r.forEach((f) => {
        f?.secciones_valores.forEach((s) => {
          s.apartados_valores.forEach((a) => {
            if (a.evaluacion_valor_seccion) {
              let itemIdx = data.findIndex(d => d.name == a.apartado_titulo);
              if(itemIdx < 0){
                let apartado = {
                  name: a.apartado_titulo,
                  Cumple: a.cumple_evaluacion,
                  Insatisfactorio: a.insatisfactorio_evaluacion,
                  Excede: a.excede_evaluacion,
                };
                data.push(apartado);
              } else {
                data[itemIdx].Cumple += a.cumple_evaluacion;
                data[itemIdx].Insatisfactorio += a.insatisfactorio_evaluacion;
                data[itemIdx].Excede += a.excede_evaluacion;
              }
            } else if(a.cumple_evaluacion > 0 || a.insatisfactorio_evaluacion > 0 || a.excede_evaluacion > 0) {
              let itemIdx = data.findIndex(d => d.name == a.apartado_titulo);
              if(itemIdx < 0){
                let apartado = {
                  name: a.apartado_titulo,
                  Cumple: a.cumple_evaluacion,
                  Insatisfactorio: a.insatisfactorio_evaluacion,
                };
                data.push(apartado);
              } else {
                data[itemIdx].Cumple += a.cumple_evaluacion;
                data[itemIdx].Insatisfactorio += a.insatisfactorio_evaluacion;
              }
            }
          });
        });
      });
    }
    return data;
  };

  const getMaxCompetences = (r) => {
    let max = 0;
    if(selfProfile){
      r?.secciones_valores.forEach((s) => {
        s.apartados_valores.forEach((a) => {
          if (a.evaluacion_valor_seccion) {
            if(a.cumple_evaluacion > max){
              max = a.cumple_evaluacion;
            }
            if(a.excede_evaluacion > max){
              max = a.excede_evaluacion;
            }
            if(a.insatisfactorio_evaluacion > max){
              max = a.insatisfactorio_evaluacion;
            }
          }
        });
      });
    }  else {
      let data = [];
      r.forEach((f) => {
        f?.secciones_valores.forEach((s) => {
          s.apartados_valores.forEach((a) => {
            if (a.evaluacion_valor_seccion) {
              let itemIdx = data.findIndex(d => d.name == a.apartado_titulo);
              if(itemIdx < 0){
                let apartado = {
                  name: a.apartado_titulo,
                  Cumple: a.cumple_evaluacion,
                  Insatisfactorio: a.insatisfactorio_evaluacion,
                  Excede: a.excede_evaluacion,
                };
                data.push(apartado);
              } else {
                data[itemIdx].Cumple += a.cumple_evaluacion;
                data[itemIdx].Insatisfactorio += a.insatisfactorio_evaluacion;
                data[itemIdx].Excede += a.excede_evaluacion;
              }
            } else if(a.cumple_evaluacion > 0 || a.insatisfactorio_evaluacion > 0 || a.excede_evaluacion > 0) {
              let itemIdx = data.findIndex(d => d.name == a.apartado_titulo);
              if(itemIdx < 0){
                let apartado = {
                  name: a.apartado_titulo,
                  Cumple: a.cumple_evaluacion,
                  Insatisfactorio: a.insatisfactorio_evaluacion,
                };
                data.push(apartado);
              } else {
                data[itemIdx].Cumple += a.cumple_evaluacion;
                data[itemIdx].Insatisfactorio += a.insatisfactorio_evaluacion;
              }
            }
          });
        });
      });
      data.forEach((a) => {
        if(a.Cumple > max){
          max = a.Cumple;
        }
        if(a.Excede > max){
          max = a.Excede;
        }
        if(a.Insatisfactorio > max){
          max = a.Insatisfactorio;
        }
      });
    }
    return max;
  };

  const fetchResults = (value) => {
    if(value == true && selfProfile == false){
      setselfProfile(true);
      fetchReportsEdd(period);
    }
    if(value == false && selfProfile == true){
      setselfProfile(false);
      fetchReportsEdd(period, user?.colaboradores);
    }
  }

  return (
    <>
      <div className="min-w-full  min-h-full overflow-y-auto">
        <main className="pt-8 pb-16 px-8 md:px-16">
          <div className="">
            <div>
              <section aria-labelledby="profile-overview-title">
                <div className="overflow-hidden rounded-lg bg-white shadow">
                  <h2 className="sr-only" id="profile-overview-title">
                    Perfil de usuario
                  </h2>
                  <div className="bg-white p-6">
                    <div className="sm:flex sm:items-center sm:justify-between">
                      <div className="sm:flex sm:space-x-5">
                        <div className="flex-shrink-0">
                          <img
                            className="mx-auto h-16 w-16 rounded-full object-cover"
                            src={
                              user?.imagen_perfil
                                ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${user?.imagen_perfil}`
                                : face
                            }
                            alt=""
                          />
                        </div>
                        <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                          <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                            {user?.nombre_propio}
                          </p>
                          <p className="text-sm font-medium text-red-600">
                            {user?.puesto}
                          </p>
                        </div>
                      </div>
                      <div className="mt-5 flex justify-center sm:mt-0 space-x-4"></div>
                    </div>
                  </div>
                  <div className="border-t border-gray-200 bg-gray-50 px-6 py-5 text-center text-sm font-medium">
                    <nav
                      className="flex flex-col items-center justify-center"
                      aria-label="Progress"
                    >
                      <p className="text-xl font-light">
                        <span className="font-bold uppercase">
                          {period?.titulo}
                        </span>
                      </p>
                      <p className="text font-light">
                        <span className="font-bold text-gray-500">
                          Del {getDateLabel(period?.fecha_inicio+"T00:00:00")} al{" "}
                          {getDateLabel(period?.fecha_fin+"T00:00:00")}
                        </span>
                      </p>
                    </nav>
                  </div>
                </div>
              </section>
              {user?.colaboradores.length > 0 && (
                <section className="flex justify-center my-4">
                  <button
                    className={`px-4 py-1 font-medium text-sm rounded-l border border-red-500  ${
                      selfProfile
                        ? "bg-red-500 text-white"
                        : " bg-transparent text-red-500"
                    }`}
                    onClick={(e) => fetchResults(true)}
                  >
                    Evaluado
                  </button>
                  <button
                    className={`px-4 py-1 font-medium text-sm rounded-r border border-red-500  ${
                      !selfProfile
                        ? "bg-red-500 text-white"
                        : " bg-transparent text-red-500"
                    }`}
                    onClick={(e) => fetchResults(false)}
                  >
                    Evaluador
                  </button>
                </section>
              )}
              {
                loadingReportData ? (
                  <div className="w-full h-full flex items-center justify-center bg-white rounded-lg">
                    <video
                      className="w-96 h-96 flex flex-1"
                      alt={"Tiendas 3B"}
                      muted
                      loop
                      autoPlay
                    >
                      <source
                        src={
                          "https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"
                        }
                        type="video/mp4"
                      />
                      Sin soporte de video MP4
                    </video>
                  </div>
                ):
                  reportData
                  ? (
                    <>
                      <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-3">
                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">
                            Objetivos cumplidos
                          </dt>
                          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                            {getResultObjetivos(selfProfile ? reportData?.calificacion : reportData?.calificaciones)}
                          </dd>
                        </div>
                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">
                            {selfProfile ? "Calificación" : "Promedio"}
                          </dt>
                          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                            {roundTwoDecimals(reportData?.global)}
                          </dd>
                        </div>
                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6">
                          <dt className="truncate text-sm font-medium text-gray-500">
                            Planes de acción
                          </dt>
                          <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
                            {reportData?.acciones}
                          </dd>
                        </div>
                      </dl>
                      <div className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-2">
                      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 h-96">
                          <dt className="truncate text-sm font-medium text-gray-500">
                            Cumplimiento de objetivos
                          </dt>
                          <ResponsiveContainer width="100%" height="100%">
                            <PieChart width="100%" height="100%">
                              <Pie
                                nameKey="name"
                                dataKey="value"
                                data={getPieChartObjectivesData(
                                  selfProfile ? reportData?.calificacion : reportData?.calificaciones
                                )}
                                cx="50%"
                                cy="50%"
                                innerRadius="50%"
                                outerRadius="80%"
                                fill="#E31B22"
                                labelLine={false}
                                label={renderCustomizedLabel}
                              >
                                {getPieChartObjectivesData(
                                  selfProfile ? reportData?.calificacion : reportData?.calificaciones
                                ).map((entry, index) => (
                                  <Cell
                                    key={`estatus-cell-${index}`}
                                    fill={COLORSOBJFILL[index]}
                                    stroke={COLORSOBJSTROKE[index]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                        <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 h-96">
                          <dt className="truncate text-sm font-medium text-gray-500">
                            Competencias
                          </dt>
                          <ResponsiveContainer width="100%" height="100%">
                            <BarChart
                              margin={{top:15}}
                              data={getCompetences(selfProfile ? reportData?.calificacion : reportData?.calificaciones)}
                            >
                              <Tooltip />
                              <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
                              <XAxis dataKey="name" />
                              <YAxis type="number" domain={[0, getMaxCompetences(selfProfile ? reportData?.calificacion : reportData?.calificaciones)]} />
                              <Bar dataKey="Cumple" stackId="a" fill="#16a34a66" stroke="#16a34a" >
                              </Bar>
                              <Bar
                                dataKey="Insatisfactorio"
                                stackId="a"
                                fill="#dc262666"
                                stroke="#dc2626"
                              />
                              <Bar dataKey="Excede" stackId="a" fill="#2563eb66" stroke="#2563eb"/>
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </div>
                    </>
                  )
                  : (
                    <>
                      <div className="text-center py-8">
                        <svg
                          className="mx-auto h-24 w-24 text-gray-400"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="true"
                        >
                          <path
                            vectorEffect="non-scaling-stroke"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                          />
                        </svg>
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          Sin resultados
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          No hay resultados en el periodo.
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          Finaliza tu proceso de evaluación para obtener los resultados.
                        </p>
                      </div>
                    </>
                  )
              }
            </div>
          </div>
        </main>
      </div>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </>
  );
}
