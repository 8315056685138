import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  useNavigate,
  useSearchParams
} from "react-router-dom";
import { refreshUser } from '../../redux/reducers/auth';

const TokenLogin = () => {
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const videoRef = useRef();
  const [searchParams] = useSearchParams();

  useEffect(()=> {
    const intsession = searchParams.get('intsession');
    if(intsession && intsession.length > 0){
        dispatch(refreshUser({ token: intsession })).then(()=>{
          navigate('/t3b/home');
        });
    } else {
        navigate('/');
    }
  }, []);

  return (
    <div className='bg-token-login'>
      <video className="bg-video" alt={"Tiendas 3B"} loop muted autoPlay ref={videoRef}>
        <source src={'https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4'} type="video/mp4" />
        Sin soporte de video MP4
      </video>
    </div>
  );
}

export default TokenLogin;