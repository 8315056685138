import { CalendarDaysIcon, PencilIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { classNames } from '../../constants/functions';
import { months_short_es } from '../../utils/date_utils';

const AssessmentNewProject = ({id, title, status, krs, dueDate, value, active, selectNextProjectToDelete, selectNextProjectToEdit}) => {
  if(!active){
    return <></>;
  }
  return (
    <div
      key={`key-next-project-${id}`}
      className={classNames("relative bg-white p-6 flex shadow rounded-lg border")}
    >
      <div className="flex-1 flex flex-col">
        <h3 className="text-2xl font-medium focus:outline-none">
          {title}
        </h3>
        <p className="mt-1 text-sm text-gray-500 flex-1">
          VALOR EN PORCENTAJE: {value}%
        </p>
        <p className="my-1 flex items-center text-sm font-medium text-gray-600">
          <CalendarDaysIcon  className='h-5 w-5 mr-1'/>
          Fecha límite de cumplimiento: Semestre {dueDate}
        </p>
        <h3 className="text-lg text-gray-700 font-medium focus:outline-none">
          Resultados clave:
        </h3>
        <ul className="list-disc pl-4 text-gray-500">
            {(krs.map((kr)=>(
                <li>{kr.titulo}</li>
            )))}
        </ul>
        <div className="flex text-sm font-medium mt-4 justify-end">
          <div className="ml-4 border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              className="text-gray-700 hover:text-gray-500 flex items-center"
              onClick={e => selectNextProjectToEdit(id, title, value, dueDate, krs)}
            >
              <PencilIcon className='h-5 w-5 mr-1'/>
              Editar Proyecto
            </button>
          </div>
          <div className="ml-4 border-l border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              onClick={e => selectNextProjectToDelete(id)}
              className="text-red-600 hover:text-red-800 flex items-center"
            >
              <TrashIcon className='h-5 w-5 mr-1'/>
              Eliminar Proyecto
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

AssessmentNewProject.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  status: PropTypes.number,
  dueDate: PropTypes.number,
  value: PropTypes.number,
  active: PropTypes.bool,
  krs: PropTypes.array,
  selectNextProjectToDelete: PropTypes.func,
  selectNextProjectToEdit: PropTypes.func
};

AssessmentNewProject.defaultProps = {
  id: 1,
  active: true,
  dueDate: 1,
  title: "Default title",
  value: 1,
  status: 1,
  krs: [],
  selectNextProjectToDelete: () => {},
  selectNextProjectToEdit: () => {}
};

export default AssessmentNewProject;