import PropTypes from "prop-types";
import { useRef } from "react";
import ObjectiveProgressModal from "../modals/ObjectiveProgressModal";
import face from "./../../assets/img/faces/avatar.png";

const HomeProgress = ({ id, image, name, docDate, title, objective }) => {
  const progressRef = useRef();

  const watchProgress = () => {
    progressRef.current.showModal(objective);
  };

  return (
    <li key={`key-last-progress-card-${id}`} className="py-4">
      <div className="flex space-x-3">
        <img
          className="h-6 w-6 rounded-full object-cover"
          src={
            image
              ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${image}`
              : face
          }
          alt=""
        />
        <div className="flex-1 space-y-1">
          <div className="flex items-center justify-between">
            <h3 className="text-sm font-medium">{name}</h3>
            <p className="text-sm text-gray-500">{docDate}</p>
          </div>
          <p className="text-sm text-gray-500">
            <button
              className="text-sm font-medium text-blue-600 hover:text-blue-800 hover:underline"
              as="span"
              onClick={watchProgress}
            >
              Registró un nuevo avance
            </button>{" "}
            en el objetivo{" "}
            <span className="text-sm font-medium text-gray-600">
              {title}.
            </span>
          </p>
        </div>
      </div>
      <ObjectiveProgressModal ref={progressRef} />
    </li>
  );
};

HomeProgress.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  docDate: PropTypes.string,
  title: PropTypes.string,
  objective: PropTypes.number,
};

HomeProgress.defaultProps = {
  id: 1,
  objective: 1,
  image: "Default image",
  name: "Default name",
  docDate: "2023-01-01",
  title: "Default title",
};

export default HomeProgress;
