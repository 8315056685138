import PropTypes from "prop-types";
import { useRef } from "react";
import { classNames } from "../../constants/functions";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, EyeIcon } from "@heroicons/react/24/solid";

const AssessmentProject = ({
  id,
  status,
  title,
  hasActions,
  self,
  superior,
  krs,
  valor,
  logrado,
  semestre,
  callback,
}) => {
  const getObjectiveStatusClassName = (status_id) => {
    let className = "";
    if (status_id != 2) {
      className = "bg-red-100 text-red-800";
    } else {
      className = "bg-blue-100 text-blue-800";
    }
    return className;
  };

  const getObjectiveStatusLabel = (status_id) => {
    let className = "";
    if (status_id != 2) {
      className = "No alcanzado";
    } else {
      className = "Alcanzado";
    }
    return className;
  };

  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames(
        "relative bg-white p-6 flex shadow rounded-lg border"
      )}
    >
      <div className="flex-1 flex flex-col">
        <div className="flex justify-end">
          <span
            className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${getObjectiveStatusClassName(
              status
            )}`}
          >
            {getObjectiveStatusLabel(status)}
          </span>
        </div>
        <h3 className="text-lg font-medium focus:outline-none">{title}</h3>
        <div className="flex space-x-4">
          <p className="font-regular text-red-500 text-sm">
            <span className="font-medium underline">Valor:</span>&nbsp;
            {valor}%
          </p>
          <p className="font-regular text-red-500 text-sm">
            <span className="font-medium underline">Logrado:</span>&nbsp;
            {logrado}%
          </p>
        </div>
        <p className="font-regular text-gray-600 text-sm underline mb-1">
          Fecha límite de cumplimiento: {semestre}
          {semestre == 1 ? "er" : "do"} Semestre del 2024
        </p>
        <p className="text-left font-bold text-xs text-gray-900 underline">
          RESULTADOS CLAVE
        </p>
        <ul className="list-disc pl-4 text-xs text-gray-900">
          {krs.map((kr) => (
            <li>{kr.titulo}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

AssessmentProject.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  title: PropTypes.string,
  hasActions: PropTypes.bool,
  self: PropTypes.bool,
  superior: PropTypes.bool,
  callback: PropTypes.func,
  krs: PropTypes.array,
  valor: PropTypes.number,
  logrado: PropTypes.number,
  semestre: PropTypes.number,
};

AssessmentProject.defaultProps = {
  id: 1,
  status: 1,
  title: "Default title",
  hasActions: true,
  self: false,
  superior: false,
  callback: null,
  krs: [],
  valor: 1,
  logrado: 1,
  semestre: 1,
};

export default AssessmentProject;
