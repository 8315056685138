import { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import { MagnifyingGlassIcon, UsersIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
} from '@heroicons/react/20/solid'
import Notification from "../../components/notifications/Notification";
import { useSelector } from 'react-redux';
import { useRef } from 'react';
import API from '../../services/API';
import PlusFolder from '../../components/vectors/PlusFolder';
import face from "./../../assets/img/faces/avatar.png";
import { Link } from 'react-router-dom';
import InfinityLoader from '../../components/loaders/InfinityLoader';

const sortOptions = [
  { name: 'Nombre', href: '#', current: true },
  { name: 'Posición', href: '#', current: false },
  { name: 'Área', href: '#', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Objectives() {
  const {user, token} = useSelector(state=>state.authentication);
  const [open, setOpen] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [users, setUsers] = useState([]);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [search, setSearch] = useState("");
  const notificationRef = useRef();

  const searchUsers = (e) => {
    e.preventDefault();
    const headers = { Authorization: token }
    let params = {
      search: search,
      active: true
    }

    setLoadingUsers(true);
    API.get('/usersearch', {params, headers})
    .then(({ data })=>{
      setLoadingUsers(false);
      setUsers(data);
    })
    .catch((err)=>{
      setLoadingUsers(false);
      setUsers([]);
    })
  }

  return (
    <div className="flex-1 max-w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">Filtros</h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div className="flex sm:flex md:flex lg:flex flex-col sm:flex-col md:flex-row lg:flex-row items-center sm:items-center md:items-center lg:items-center justify-start sm:justify-start md:justify-between lg:justify-between pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">Objetivos</h1>
                <p className="mt-4 text-sm text-gray-700">
                  Busca usuarios y revisa los objetivos que han cumplido durante el año
                </p>
              </div>
            </div>

            <form onSubmit={searchUsers}>
              <label htmlFor="usersearch" className="block text-sm font-medium text-gray-700">
                Buscar usuarios
              </label>
              <div className="mt-1 flex rounded-md shadow-sm mb-4">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="usersearch"
                    id="usersearch"
                    value={search}
                    onChange={e => setSearch(e.target.value)}
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Número de empleado, nombre o apellidos"
                  />
                </div>
                <button
                  type="button"
                  onClick={searchUsers}
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  <span>Buscar</span>
                </button>
              </div>
            </form>

            {/* Filters */}
            <section aria-labelledby="filter-heading" className='-my-2 -mx-4 flex-1 sm:-mx-6 lg:-mx-8'>
              <h2 id="filter-heading" className="sr-only">
                Filtros
              </h2>

              <div className="">
                <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        Ordenar por
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {sortOptions.map((option) => (
                            <Menu.Item key={option.name}>
                              {({ active }) => (
                                <a
                                  href={option.href}
                                  className={classNames(
                                    option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  {option.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </section>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                {
                  loadingUsers
                  ? <>
                    <InfinityLoader />
                  </>
                  : <>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Nombre
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Posición
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Región
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Objetivos</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {users.map((person) => (
                            <tr key={`key-row-employee-${person.numero_empleado}`}>
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full object-cover"
                                      src={person?.imagen_perfil ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${person?.imagen_perfil}` : face}
                                      alt=""
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div className="font-medium text-gray-900">
                                      {person.nombre_completo}
                                    </div>
                                    <div className="text-gray-500">{person.email}</div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="text-gray-900">{person.puesto}</div>
                                <div className="text-gray-500">{person.area}</div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.region}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <Link
                                  to={`/t3b/admin/objectives/${person.numero_empleado}`}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Ver objetivos<span className="sr-only">de {person.nombre_completo}</span>
                                </Link>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {users.length < 1 && <div className="text-center mt-3">
                        <PlusFolder />
                        <h3 className="mt-2 text-sm font-medium text-gray-900">No hay usuarios con los filtros seleccionados</h3>
                        <p className="mt-1 text-sm text-gray-500">Utiliza el campo de búsqueda para buscar usuarios.</p>
                      </div>}
                  </>
                }
              </div>
            </div>
          </div>
        </div>
      </main>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
