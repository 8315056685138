import {
  ClipboardDocumentCheckIcon,
  DocumentDuplicateIcon,
  ExclamationTriangleIcon,
  PencilSquareIcon,
  PlusIcon,
  TrashIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import Notification from "../../components/notifications/Notification";
import API from "../../services/API";
import BigModalTemplate from "../../components/modals/BigModalTemplate";

export default function Formats() {
  const [loading, setLoading] = useState(true);
  const [actives, setActives] = useState(true);
  const [loadingCreate, setLoadingCreate] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [forms, setForms] = useState([]);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [name, setName] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [instructions, setInstructions] = useState("");
  const [duplicate, setDuplicate] = useState(0);
  const [duplicateTitle, setDuplicateTitle] = useState("");
  const [periodicidad, setPeriodicidad] = useState(30);
  const [openCreate, setOpenCreate] = useState(false);
  const notificationRef = useRef();
  const duplicateModalRef = useRef();
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.authentication);

  useEffect(() => {
    fetchForms();
  }, []);

  const fetchForms = useCallback(()=>{
    setErrorTitle("Error al buscar");
    API.get("/forms", { headers: { Authorization: token } })
      .then((reponse) => {
        setLoading(false);
        let { status, data } = reponse;
        if (status == 200) {
          setForms(data);
        } else if (status == 204) {
          setForms([]);
        } else {
          setForms([]);
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los formatos"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los formatos"
          );
        }
        notificationRef.current.showNotification();
      });
  },[token, notificationRef])

  const createForm = () => {
    setLoadingCreate(true);
    const body = {
      titulo: name,
      descripcion: descripcion,
      instrucciones: "",
      tipo_objetivos: 1,
      tipo_plan: 1,
    };
    setErrorTitle("Error al crear");
    API.post("/forms", body, {
      headers: { Authorization: token },
    })
      .then((reponse) => {
        setLoadingCreate(false);
        let { status, data } = reponse;
        if (status == 200) {
          setName("");
          setDescripcion("");
          setPeriodicidad(30);
          navigate(`/t3b/admin/forms/${data.formato_id}`);
        } else {
          setError(`Ocurrió un error con código de status ${status}`);
        }
      })
      .catch((error) => {
        setLoadingCreate(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al crear el formato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al crear el formato"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const selectDuplicateForm = (form, tit) => {
    setDuplicate(form);
    setDuplicateTitle(tit);
    duplicateModalRef.current.showModal();
  }
  const duplicateForm = () => {
    setLoadingDuplicate(true);
    setErrorTitle("Error al crear");
    let body = {
      titulo: `(Duplicado) ${duplicateTitle.slice(37)}`
    }
    API.post(`/forms/${duplicate}/duplicate`, body, {
      headers: { Authorization: token },
    })
      .then((reponse) => {
        duplicateModalRef.current.hideModal();
        setLoadingDuplicate(false);
        let { status } = reponse;
        if (status == 200) {
          fetchForms();
        } else {
          setError(`Ocurrió un error con código de status ${status}`);
        }
      })
      .catch((error) => {
        duplicateModalRef.current.hideModal();
        setLoadingDuplicate(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al duplicar el formato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al duplicar el formato"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  return (
    <>
      <div className="flex-1 max-w-full">
        <main className="py-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="px-4 sm:px-0">
              <div className="flex flex-wrap items-start justify-between sm:flex-nowrap mb-6">
                <h2 className="text-3xl font-medium text-gray-900">Formatos</h2>
                <div className="flex gap-3">
                  <button
                    type="button"
                    onClick={(e) => setActives(!actives)}
                    className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-900 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 space-x-2"
                  >
                    {actives ? (
                      <TrashIcon className="h-5 w-5" />
                    ) : (
                      <ClipboardDocumentCheckIcon className="h-5 w-5" />
                    )}
                    <span>{actives ? "Papelera" : "Activos"}</span>
                  </button>
                  <button
                    type="button"
                    onClick={(e) => setOpenCreate(true)}
                    className="relative inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 space-x-2"
                  >
                    <PlusIcon className="h-5 w-5" />
                    <span>Nuevo</span>
                  </button>
                </div>
              </div>

              {loading ? (
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-3"
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8].map((item) => (
                    <li
                      key={`key-placeholder-${item}`}
                      className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow border overflow-hidden"
                    >
                      <div className="relative h-20 w-full bg-gradient-to-r from-gray-100">
                        <span className="absolute right-2 top-2 flex-shrink-0 rounded-full bg-gray-200 px-2 py-0.5 text-xs font-medium h-4 w-16 animate-pulse"></span>
                      </div>
                      <div className="flex w-full items-center justify-between space-x-6 p-6">
                        <div className="flex-1 truncate">
                          <div className="flex items-center space-x-3">
                            <h3 className="truncate text-sm font-medium bg-gray-200 h-4 w-full rounded-full animate-pulse"></h3>
                          </div>
                          <p className="mt-2 truncate text-sm bg-gray-200 h-4 w-full rounded-full animate-pulse"></p>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              ) : forms.length < 1 ? (
                <div className="text-center">
                  <svg
                    className="mx-auto h-24 w-24 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    Sin formatos
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Inicia creando un nuevo formato de entrevista.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      onClick={(e) => setOpenCreate(true)}
                      className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Nuevo
                    </button>
                  </div>
                </div>
              ) : (
                <ul
                  role="list"
                  className="grid grid-cols-1 gap-6 sm:grid-cols-3 lg:grid-cols-3"
                >
                  {forms
                    .filter((f) => f.is_active == actives)
                    .map((item, index) => (
                      <li
                        key={`key-format-${item.formato_id}`}
                        className="col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow flex flex-col"
                      >
                        <div className="flex w-full flex-1 items-center justify-between space-x-6 p-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="whitespace-pre-wrap text-sm font-medium text-gray-900">
                                {item.titulo}
                              </h3>
                            </div>
                            <p className="mt-1 truncate text-sm text-gray-500">
                              {item.descripcion}
                            </p>
                          </div>
                        </div>
                        <div>
                          <div className="-mt-px flex divide-x divide-gray-200">
                            <div className="flex w-0 flex-1 hover:bg-gray-100">
                              <button onClick={e => selectDuplicateForm(item.formato_id, item.titulo)} className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center rounded-bl-lg border border-transparent py-4 text-sm font-medium text-gray-700 hover:text-gray-500">
                                <DocumentDuplicateIcon
                                  className="h-5 w-5 text-gray-400"
                                  aria-hidden="true"
                                />
                                <span className="ml-3">Duplicar</span>
                              </button>
                            </div>
                            <Link
                              to={`/t3b/admin/forms/${item.formato_id}`}
                              className="-ml-px flex w-0 flex-1 items-center justify-center hover:bg-gray-100 py-4 text-sm font-medium text-gray-700 hover:text-gray-500"
                            >
                              <PencilSquareIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">Editar</span>
                            </Link>
                          </div>
                        </div>
                      </li>
                    ))}
                </ul>
              )}
            </div>
          </div>
        </main>
        <Notification
          title={errorTitle}
          message={error}
          type="danger"
          ref={notificationRef}
        />
      </div>
      <Transition.Root show={openCreate} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={setOpenCreate}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="h-0 flex-1 overflow-y-auto">
                        <div className="bg-red-700 py-6 px-4 sm:px-6">
                          <div className="flex items-center justify-between">
                            <Dialog.Title className="text-lg font-medium text-white">
                              Nuevo Formato
                            </Dialog.Title>
                            <div className="ml-3 flex h-7 items-center">
                              <button
                                type="button"
                                className="rounded-md bg-red-700 text-red-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                onClick={() => setOpenCreate(false)}
                              >
                                <span className="sr-only">Close panel</span>
                                <XMarkIcon
                                  className="h-6 w-6"
                                  aria-hidden="true"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="mt-1">
                            <p className="text-sm text-red-300">
                              Crea un nuevo formato incluyendo el nombre del
                              formato y descripción.
                            </p>
                          </div>
                        </div>
                        <div className="flex flex-1 flex-col justify-between">
                          <div className="divide-y divide-gray-200 px-4 sm:px-6">
                            <div className="space-y-6 pt-6 pb-5">
                              <div>
                                <label
                                  htmlFor="project-name"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Nombre
                                </label>
                                <div className="mt-1">
                                  <input
                                    type="text"
                                    name="project-name"
                                    placeholder="e.g. Evaluación de staff con negociación"
                                    value={name}
                                    maxLength={50}
                                    onChange={(e) => setName(e.target.value)}
                                    id="project-name"
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                  />
                                  {name.length >= 50 && <p
                                    className="block text-sm font-medium text-red-500"
                                  >
                                    El nombre no debe ser mayor a 50 caracteres
                                  </p>}
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Descripción
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    id="description"
                                    name="description"
                                    placeholder="e.g. Evaluación de staff con competencias de negociación"
                                    value={descripcion}
                                    onChange={(e) =>
                                      setDescripcion(e.target.value)
                                    }
                                    rows={4}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                              <div>
                                <label
                                  htmlFor="description"
                                  className="block text-sm font-medium text-gray-900"
                                >
                                  Instrucciones
                                </label>
                                <div className="mt-1">
                                  <textarea
                                    id="description"
                                    name="description"
                                    placeholder="e.g. Evaluación de staff con competencias de negociación"
                                    value={instructions}
                                    onChange={(e) =>
                                      setInstructions(e.target.value)
                                    }
                                    rows={4}
                                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                    defaultValue={""}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="flex flex-shrink-0 justify-end px-4 py-4">
                        <button
                          type="button"
                          className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                          onClick={() => setOpenCreate(false)}
                          disabled={loadingCreate}
                        >
                          Cancelar
                        </button>
                        <button
                          type="button"
                          onClick={createForm}
                          disabled={loadingCreate}
                          className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        >
                          {loadingCreate ? "Creando Formato..." : "Crear"}
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      <BigModalTemplate ref={duplicateModalRef}>
        <div className="flex justify-end">
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => duplicateModalRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
              >
                Duplicar formato
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  ¿Estás seguro que deseas duplicar el formato?
                </p>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => duplicateModalRef.current.hideModal()}
                className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={duplicateForm}
                disabled={loadingDuplicate}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                {loadingDuplicate ? "Duplicando Formato..." : "Duplicar"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
    </>
  );
}
