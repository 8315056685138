import {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useCallback,
} from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { getDateLabel } from "../../utils/date_utils";
import BigModalTemplate from "./BigModalTemplate";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import Notification from "../notifications/Notification";
import axios from "axios";
import fileDownload from "js-file-download";
import face from "./../../assets/img/faces/avatar.png";

const AssessmentObjectiveValidateModal = forwardRef(({ callback }, ref) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [progress, setProgress] = useState([]);
  const refModal = useRef();

  useImperativeHandle(ref, () => ({
    showModal(objective) {
      fetchObjectiveInfo(objective);
      refModal.current.showModal();
    },
    hideModal() {
      refModal.current.hideModal();
    },
  }));

  const [objective, setObjective] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadingObjective, setLoadingObjective] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();

  const fetchObjectiveInfo = useCallback((objectiveId) => {
    const fetchObjectiveInfo = () => {
      API.get(`/objectives/${objectiveId}`, {
        headers: { Authorization: token },
      })
        .then((result) => {
          setLoadingObjective(false);
          if (result.status == 200) {
            setObjective(result.data);
          } else {
            setObjective(null);
            setErrorTitle("Error");
            setError("Error al buscar la información del objetivo");
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          setLoadingObjective(false);
          setObjective(null);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    fetchObjectiveInfo();
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const saveProgress = async () => {
    if(progress==""){
      setError(
        "Ingresa el comentario"
      );
      return notificationRef.current.showNotification();
    }
    setLoadingSave(true);
    let body = {
      descripcion: progress,
      resultado_objetivo_id: 4,
      numero_empleado: user.numero_empleado,
      fecha_captura: new Date(),
      evidencias: [],
    };
    let evidencias = [];
    for (let i = 0; i < files.length; i++) {
      let item = files[i];
      let file = {
        last_modified_date: item.lastModifiedDate,
        name: item.name,
        size: item.size,
        type: item.type,
        file: await getBase64(item),
      };
      evidencias.push(file);
    }
    body.evidencias = evidencias;
    API.post(`/objectives/${objective.objetivo_id}/newprogress`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setLoadingSave(false);
        if (result.status == 200) {
          refModal.current.hideModal();
          callback();
        } else {
          setObjective(null);
          setError("Error al registrar la información del objetivo");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingSave(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al registrar la información del objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al registrar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  return (
    <BigModalTemplate ref={refModal}>
      <div className="min-h-full">
        <main className="py-8 px-4 sm:px-6 lg:px-8">
          <div className="mx-auto max-w-3xl lg:max-w-7xl">
            <div className="">
              <div>
                <h3 className="text-lg font-medium leading-6 text-gray-900">
                  Detalles del objetivo
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Antes de validar el objetivo revisa los avances registrados y evidencias
                </p>
              </div>
              <div className="mt-5 border-t border-gray-200">
                <dl className="sm:divide-y sm:divide-gray-200">
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Objetivo
                    </dt>
                    {loadingObjective ? (
                      <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                    ) : (
                      <dd className="mt-1 text-gray-900 font-semibold sm:col-span-2 sm:mt-0">
                        {objective?.titulo}
                      </dd>
                    )}
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Entregable
                    </dt>
                    {loadingObjective ? (
                      <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                    ) : (
                      <dd className="mt-1 text-gray-900 text-sm sm:col-span-2 sm:mt-0">
                        {objective?.descripcion}
                      </dd>
                    )}
                  </div>
                  <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                    <dt className="text-sm font-medium text-gray-500">
                      Fecha de entrega
                    </dt>
                    {loadingObjective ? (
                      <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                    ) : (
                      <dd className="mt-1 text-gray-900 text-sm sm:col-span-2 sm:mt-0">
                        {getDateLabel(objective?.fecha_entrega)}
                      </dd>
                    )}
                  </div>
                </dl>
              </div>
            </div>
            <div className="space-y-8 mt-4">
              <div className="space-y-8 divide-y divide-gray-200">
                <div>
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                    <div className="sm:col-span-6">
                      <label
                        htmlFor="about"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Comentarios de Validación
                      </label>
                      <div className="mt-1">
                        <textarea
                          id="about"
                          name="about"
                          rows={3}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                          value={progress}
                          onChange={(e) => setProgress(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="pt-5">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={(e) => refModal.current.hideModal()}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    disabled={loadingSave}
                    onClick={saveProgress}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {loadingSave ? "Validando objetivo" : "Validar"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
        <Notification
          title={errorTitle}
          message={error}
          type="danger"
          ref={notificationRef}
        />
      </div>
    </BigModalTemplate>
  );
});

export default AssessmentObjectiveValidateModal;
