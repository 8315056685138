import PropTypes from 'prop-types';
import { useRef } from 'react';
import { classNames } from '../../constants/functions';
import AssessmentObjectiveRegisterProgressModal from '../modals/AssessmentObjectiveRegisterProgressModal';
import AssessmentObjectiveValidateModal from '../modals/AssessmentObjectiveValidateModal';
import ObjectiveProgressModal from '../modals/ObjectiveProgressModal';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { CheckCircleIcon, EyeIcon } from '@heroicons/react/24/solid';

const AssessmentObjective = ({id, status, title, description, hasActions, self, superior, callback}) => {
  let registerRef = useRef();
  let validateRef = useRef();
  let progressRef = useRef();

  const getObjectiveStatusClassName = (status_id) => {
    let className = "";
    if(status_id < 4){
      className = "bg-red-100 text-red-800";
    } else {
      className = "bg-blue-100 text-blue-800";
    }
    return className;
  }

  const getObjectiveStatusLabel = (status_id) => {
    let className = "";
    if(status_id < 4){
      className = "No alcanzado";
    } else {
      className = "Alcanzado";
    }
    return className;
  }

  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames("relative bg-white p-6 flex shadow rounded-lg border")}
    >
      <div className="flex-1 flex flex-col">
        <div className="flex justify-end">
          <span
            className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${getObjectiveStatusClassName(
              status
            )}`}
          >
            {getObjectiveStatusLabel(status)}
          </span>
        </div>
        <h3 className="text-lg font-medium focus:outline-none">
          {title}
        </h3>
        <p className="mt-2 text-sm text-gray-500 flex-1">
          {description}
        </p>
        {hasActions && <div className="flex text-sm font-medium mt-4">
          { status < 3 && self && <button
            onClick={e => registerRef.current.showModal(id)}
            className="text-blue-600 hover:text-blue-800 flex items-center"
          >
            <PencilSquareIcon className="h-5 w-5 mr-1"/>
            Registrar progreso
          </button>}
          { status > 1 && <div className="ml-4 border-l border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              onClick={e => progressRef.current.showModal(id)}
              className="text-blue-600 hover:text-blue-800 flex items-center"
            >
              <EyeIcon className="h-5 w-5 mr-1"/>
              Ver progreso
            </button>
          </div>}
          { status == 3 && superior && <div className="ml-4 border-l border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              onClick={e => validateRef.current.showModal(id)}
              className="text-blue-600 hover:text-blue-800 flex items-center"
            >
              <CheckCircleIcon className="h-5 w-5 mr-1"/>
              Validar Objetivo
            </button>
          </div>}
        </div>}
      </div>
      <AssessmentObjectiveRegisterProgressModal ref={registerRef} callback={callback}/>
      <AssessmentObjectiveValidateModal ref={validateRef} callback={callback}/>
      <ObjectiveProgressModal ref={progressRef} />
    </div>
  )
};

AssessmentObjective.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  title: PropTypes.string,
  hasActions: PropTypes.bool,
  description: PropTypes.string,
  self: PropTypes.bool,
  superior: PropTypes.bool,
  callback: PropTypes.func
};

AssessmentObjective.defaultProps = {
  id: 1,
  status: 1,
  title: "Default title",
  hasActions: true,
  description: "Default description",
  self: false,
  superior: false,
  callback: null
};

export default AssessmentObjective;