import axios from 'axios';
import { useEffect } from 'react';
import { logout } from '../redux/reducers/auth';
import { useDispatch } from 'react-redux';

const API = axios.create({
    baseURL: 'https://a0cckaqab5.execute-api.us-west-1.amazonaws.com/beta',
    headers: {
        'content-type':'application/json'
    }
});

const AxiosInterceptor = ({ children }) => {
    const dispatch = useDispatch();
    useEffect(() => {

        const resInterceptor = response => {
            return response;
        }

        const errInterceptor = error => {
            if(error.response?.status==401){
                dispatch(logout("Inicia sesión nuevamente"));
            }
            return Promise.reject(error);
        }


        const interceptor = API.interceptors.response.use(resInterceptor, errInterceptor);

        return () => API.interceptors.response.eject(interceptor);

    }, [])
    return children;
}

export default API;
export { AxiosInterceptor };
