export const months_es = {
    0: "Enero",
    1: "Febrero",
    2: "Marzo",
    3: "Abril",
    4: "Mayo",
    5: "Junio",
    6: "Julio",
    7: "Agosto",
    8: "Septiembre",
    9: "Octubre",
    10: "Noviembre",
    11: "Diciembre",
}

export const months_db_es = {
  1: "Enero",
  2: "Febrero",
  3: "Marzo",
  4: "Abril",
  5: "Mayo",
  6: "Junio",
  7: "Julio",
  8: "Agosto",
  9: "Septiembre",
  10:"Octubre",
  11: "Noviembre",
  12: "Diciembre",
}

export const months_short_es = {
    0: "Ene",
    1: "Feb",
    2: "Mar",
    3: "Abr",
    4: "May",
    5: "Jun",
    6: "Jul",
    7: "Ago",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dic",
}

export const getDateLabel = (date) => {
  let label = "";
  let userDate = new Date(date);
  label = `${userDate.getDate()} de ${
    months_es[userDate.getMonth()]
  }, ${userDate.getFullYear()}`;
  return label;
};

export const getDateTimeLabel = (date) => {
  let label = "";
  let userDate = new Date(date);
  label = `${userDate.getDate()} de ${
    months_es[userDate.getMonth()]
  }, ${userDate.getFullYear()} a las
  ${userDate.getHours()}:${userDate.getMinutes()}`;
  return label;
};

export const getShortDateTimeLabel = (date) => {
  let label = "";
  let userDate = new Date(date);
  label = `${userDate.getDate()}/${userDate.getMonth() + 1}/${userDate.getFullYear()} ${userDate.getHours()}:${userDate.getMinutes()}`;
  return label;
};

export const getEndOfDay = (date) => {
  let endDate = new Date(date);
  endDate.setHours(23,59,59,999);
  return endDate;
};

export const getStartOfDay = (date) => {
  let startDate = new Date(date);
  startDate.setHours(0, 0, 0, 0);
  return startDate;
};

export const formatDate = (date) => {
  var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
      month = '0' + month;
  if (day.length < 2)
      day = '0' + day;

  return [year, month, day].join('-');
}

export const sortArrayOfObjectsByDate = (a, b, field) => {
  return new Date(a[field]) - new Date(b[field]);
};