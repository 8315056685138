import PropTypes from "prop-types";
import { Fragment, useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { Listbox, Transition } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  CheckIcon,
  ChevronUpDownIcon,
} from "@heroicons/react/24/outline";
import { base64StringToBlob } from "blob-util";
import API2 from "../../services/API2";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const OperationCompare = ({ period, titulo }) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [global, setGlobal] = useState({});
  const [loadingReport, setLoadingReport] = useState(false);

  const [positions, setPositions] = useState([]);
  const [principals, setPrincipals] = useState([]);
  const [regions, setRegions] = useState([]);
  const [tipo, setTipo] = useState(1);
  const [principal, setPrincipal] = useState("0");
  const [region, setRegion] = useState([]);
  const [position, setPosition] = useState([]);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [colaborador, setColaborador] = useState([]);

  useEffect(() => {
    const params = { foo: "foo" };
    const options = { headers: { Authorization: token }, params };
    API.get("/edd/direcciones", options).then(({ data, status }) => {
      if (status === 200) {
        setPrincipals(
          data.map((f) => {
            f.id = f.direccion_id;
            f.label = f.nombre;
            return f;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    setRegion([]);
    setArea([]);
    setPosition([]);
    let params = {};
    if (principal == "0") {
      params = { sin_direccion: "" };
    } else {
      params = { direccion_id: principal };
    }
    const options = { headers: { Authorization: token }, params };
    API.get("/edd/direcciones", options).then(({ data, status }) => {
      if (status === 200) {
        setRegions(
          data.map((f) => {
            f.id = f.region_id;
            f.label = f.region_nombre;
            return f;
          })
        );
      }
    });
  }, [principal]);

  useEffect(() => {
    if (region.length > 0) {
      let regs = [...region].map((r) => r.region_id);
      const params = { regiones: regs.join() };
      const options = { headers: { Authorization: token }, params };
      API2.get("/zonas", options).then(({ data, status }) => {
        if (status === 200) {
          setAreas(
            data.map((f) => {
              f.id = f.area_id;
              f.label = f.area_nombre;
              return f;
            })
          );
        }
      });
    }
    setAreas([]);
    setArea([]);
    setPositions([]);
    setPosition([]);
    setColaborador([]);
    setColaboradores([]);
  }, [region]);

  useEffect(() => {
    if (area.length > 0) {
      let ars = [...area].map((r) => r.zona_id);
      let params = {
        zonas: ars.join()
      };
      const optionsTwo = {
        headers: { Authorization: token },
        params,
      };
      API2.get("/distritos", optionsTwo).then(({ data, status }) => {
        if (status === 200) {
          setPositions(
            data.map((f) => {
              f.id = f.puesto_id;
              f.label = f.puesto;
              return f;
            })
          );
        }
      });
    }
    setPositions([]);
    setPosition([]);
    setColaborador([]);
    setColaboradores([]);
  }, [area]);

  useEffect(() => {
    if (position.length > 0) {
      let regs = [...position].map((r) => r.distrito_id);
      let params = { distritos: regs.join() };
      const optionsTwo = {
        headers: { Authorization: token },
        params,
      };
      API.get("/centros-costos", optionsTwo).then(({ data, status }) => {
        if (status === 200) {
          setColaboradores(
            data.map((f) => {
              f.id = f.numero_empleado;
              f.label = f.nombre_completo;
              return f;
            })
          );
        }
      });
    }
    setColaborador([]);
    setColaboradores([]);
  }, [position]);

  const downloadFile = () => {
    let regs = [...region].map((r) => r.region_id);
    let params = {
      period: period,
      tipo: tipo,
      region: regs.join(),
      zona: "0",
      distrito: "0",
      centro_costos: "0",
    };
    if (area.length > 0) {
      let ars = [...area].map((r) => r.zona_id);
      params.zona = ars.join();
    }
    if ([2,4,3,5,6,7].includes(tipo) && position.length > 0) {
      let posts = [...position].map((r) => r.distrito_id);
      params.distrito = posts.join();
    }
    if ([3,5,6,7].includes(tipo) && colaborador.length > 0) {
      let colab = [...colaborador].map((r) => r.centro_costos_id);
      params.centro_costos = colab.join();
    }
    const headers = { Authorization: token };
    setLoadingReport(true);
    API2.get("/edd/reportes/comparativa-operaciones", {
      params,
      headers,
      responseType: "application/pdf",
    })
      .then((response) => {
        setLoadingReport(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(response.data, "application/pdf");
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `Comparativa_Operaciones.pdf`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReport(false);
      });
  };

  const selectPosition = (newPositions) => {
    let gz = newPositions.find((p) => p.puesto_id == 76);
    if (!gz) {
      setPosition(newPositions);
    } else {
      setPosition([gz]);
    }
  };

  return (
    <>
      <div className="rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6">
        <dt className="truncate text-sm font-medium text-gray-500">
          Comparativa Operaciones
        </dt>
        <dd className="mt-1 text-3xl tracking-tight text-gray-900">
          <section className="my-4 w-full grid grid-cols-1 sm:grid-cols-6 gap-4">
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label htmlFor="tipo" className="block text-xs text-red-500">
                TIPO
              </label>
              <div className="mt-1">
                <div>
                  <select
                    id="tipo"
                    name="tipo"
                    value={tipo}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) => setTipo(Number(e.target.value))}
                  >
                    <option value={1}>GZ vs GZ</option>
                    <option value={2}>GZ: GD vs GD</option>
                    <option value={3}>GZ: GT vs GT</option>
                    <option value={4}>GD vs GD</option>
                    <option value={5}>GD: GT vs GT</option>
                    <option value={7}>GT vs GT</option>
                    <option value={6}>C vs C</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label htmlFor="principal" className="block text-xs text-red-500">
                DIRECCIÓN
              </label>
              <div className="mt-1">
                <div>
                  <select
                    id="principal"
                    name="principal"
                    value={principal}
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) => setPrincipal(Number(e.target.value))}
                  >
                    <option value={0}>TODAS LAS DIRECCIONES</option>
                    {principals.map((r) => (
                      <option
                        key={`key-option-principal-${r.direccion_id}`}
                        value={r.direccion_id}
                      >
                        {r.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label htmlFor="region" className="block text-xs text-red-500">
                REGION
              </label>
              <div className="mt-1">
                <div>
                  <Listbox value={region} onChange={setRegion} multiple>
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button className="mt-1 block w-full rounded-md bg-white border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <span className="inline-flex w-full truncate">
                              <span className="truncate">
                                {region.length < 1
                                  ? "TODAS LAS REGIONES"
                                  : [...region]
                                      .map((r) => r.region_nombre)
                                      .join(", ")}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {regions.map((r) => (
                                <Listbox.Option
                                  key={`key-option-region-${r.region_id}`}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={r}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex">
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "truncate"
                                          )}
                                        >
                                          {r.region_nombre}
                                        </span>
                                      </div>
                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                ZONAS
              </label>
              <div className="mt-1">
                <div>
                  <Listbox value={area} onChange={setArea} multiple>
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button className="mt-1 block w-full rounded-md bg-white border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <span className="inline-flex w-full truncate">
                              <span className="truncate">
                                {area.length < 1
                                  ? "TODAS LAS ZONAS"
                                  : [...area]
                                      .map((r) => r.gz_nombre)
                                      .join(", ")}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {areas.map((r) => (
                                <Listbox.Option
                                  key={`key-option-area-${r.zona_id}`}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={r}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex">
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "truncate"
                                          )}
                                        >
                                          {r.gz_nombre}
                                        </span>
                                      </div>
                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>
            {[2,3,4,5,6,7].includes(tipo) && <div className="mb-4 col-span-1 sm:col-span-2">
              <label htmlFor="position" className="block text-xs text-red-500">
                DISTRITOS
              </label>
              <div className="mt-1">
                <div>
                  <Listbox value={position} onChange={selectPosition} multiple>
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button className="mt-1 block w-full rounded-md bg-white border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <span className="inline-flex w-full truncate">
                              <span className="truncate">
                                {position.length < 1
                                  ? "TODOS LOS DISTRITOS"
                                  : [...position]
                                      .map((r) => `${r.distrito_codigo} ${r.gd_nombre ? r.gd_nombre : ""}`)
                                      .join(", ")}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {positions.map((r) => (
                                <Listbox.Option
                                  key={`key-option-puesto-${r.distrito_id}`}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={r}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex">
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "truncate"
                                          )}
                                        >
                                          {`${r.distrito_codigo} ${r.gd_nombre ? r.gd_nombre : ""}`}
                                        </span>
                                      </div>
                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>}
            {[3,5,6,7].includes(tipo) && <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="position"
                className="block text-xs text-red-500"
              >
                CENTROS DE COSTOS
              </label>
              <div className="mt-1">
                <div>
                  <Listbox
                    value={colaborador}
                    onChange={setColaborador}
                    multiple
                  >
                    {({ open }) => (
                      <>
                        <div className="relative">
                          <Listbox.Button className="mt-1 block w-full rounded-md bg-white border border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm">
                            <span className="inline-flex w-full truncate">
                              <span className="truncate">
                                {colaborador.length < 1
                                  ? "TODOS LOS CENTROS DE COSTOS"
                                  : [...colaborador]
                                      .map((r) => r.nombre)
                                      .join(", ")}
                              </span>
                            </span>
                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                              <ChevronUpDownIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </span>
                          </Listbox.Button>
                          <Transition
                            show={open}
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                          >
                            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                              {colaboradores.map((r) => (
                                <Listbox.Option
                                  key={`key-option-num-emp-${r.centro_costos_id}`}
                                  className={({ active }) =>
                                    classNames(
                                      active
                                        ? "bg-indigo-600 text-white"
                                        : "text-gray-900",
                                      "relative cursor-default select-none py-2 pl-3 pr-9"
                                    )
                                  }
                                  value={r}
                                >
                                  {({ selected, active }) => (
                                    <>
                                      <div className="flex">
                                        <span
                                          className={classNames(
                                            selected
                                              ? "font-semibold"
                                              : "font-normal",
                                            "truncate"
                                          )}
                                        >
                                          {r.nombre}
                                        </span>
                                      </div>
                                      {selected ? (
                                        <span
                                          className={classNames(
                                            active
                                              ? "text-white"
                                              : "text-indigo-600",
                                            "absolute inset-y-0 right-0 flex items-center pr-4"
                                          )}
                                        >
                                          <CheckIcon
                                            className="h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </span>
                                      ) : null}
                                    </>
                                  )}
                                </Listbox.Option>
                              ))}
                            </Listbox.Options>
                          </Transition>
                        </div>
                      </>
                    )}
                  </Listbox>
                </div>
              </div>
            </div>}
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label htmlFor="position" className="block text-xs">
                &nbsp;
              </label>
              <div className="mt-1">
                <button
                  type="button"
                  onClick={downloadFile}
                  disabled={loadingReport}
                  className={`col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 ${
                    area == "0" ? "" : "hover:bg-indigo-200"
                  } focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                >
                  <ArrowDownTrayIcon
                    className={`h-5 w-5 mr-1 ${
                      loadingReport ? "animate-bounce" : ""
                    }`}
                  />
                  {loadingReport
                    ? "Descargando Reporte"
                    : "Reporte Operaciones"}
                </button>
              </div>
            </div>
          </section>
        </dd>
      </div>
    </>
  );
};

OperationCompare.propTypes = {
  period: PropTypes.number,
  titulo: PropTypes.string,
};

OperationCompare.defaultProps = {
  period: null,
  titulo: "",
};

export default OperationCompare;
