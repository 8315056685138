import { useCallback } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import { useNavigate, useParams } from "react-router-dom";
import { useRef } from "react";
import { sortByOrder } from "../../constants/functions";
import logo from "./../../assets/img/brand/logo.svg";
import API from "../../services/API";
import { PrinterIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/20/solid";
import API2 from "../../services/API2";
import { formatDate } from "../../utils/date_utils";

const Print = () => {
  const navigate = useNavigate();
  const pathParams = useParams();
  const { printType, assessmentId } = pathParams;
  let { user, token } = useSelector((state) => state.authentication);
  let printComponent = useRef();

  const [assessment, setAssessment] = useState({});
  const [global, setGlobal] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [sections, setSections] = useState([]);
  const [nextObjectives, setNextObjectives] = useState([]);
  const [nextProjects, setNextProjects] = useState([]);
  const [shoppingObjectives, setShoppingObjectives] = useState([]);
  const [shoppingObjectivesResults, setShoppingObjectivesResults] = useState([]);
  const [nextParentObjectives, setNextParentObjectives] = useState([]);
  const [pastObjectives, setPastObjectives] = useState([]);
  const [pastProjects, setPastProjects] = useState([]);
  const [salesObjectives, setSalesObjectives] = useState([]);
  const [salesAnswers, setSalesAnswers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState({});
  const [assessmentUser, setAssessmentUser] = useState({});

  let superAdministrador = user?.certificados.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;
  let administradorRegional = user?.certificados.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;

  useEffect(() => {
    const params = { user_assessment: assessmentId, report: true };
    API.get(`/assessments`, { params, headers: { Authorization: token } })
      .then(({ status, data }) => {
        let assess = data[0];
        if (!assess.evaluacion_usuario_id) {
          navigate("/assessments");
        }
        if (status === 200) {
          setAssessment(assess);
        }
      })
      .catch((error) => {});
    API.get(`/results/global`, { params, headers: { Authorization: token } })
      .then(({ status, data }) => {
        if (status === 200) {
          setGlobal(data[0]);
        } else {
          setGlobal({});
        }
      })
      .catch((error) => {
        setGlobal({});
      });
  }, [assessmentId]);

  useEffect(() => {
    const getResults = () =>
      new Promise((resolve, reject) => {
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/results`,
          { headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setResults(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getUser = () =>
      new Promise((resolve, reject) => {
        return API.get(`/users/${assessment.numero_empleado}`, { headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              setAssessmentUser(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getPlans = () =>
      new Promise((resolve, reject) => {
        if (assessment.tipo_plan === 3) {
          return resolve();
        }
        const params = {
          users: assessment.numero_empleado,
          period: assessment.evaluacion_usuario_id,
        };
        return API.get(`/plans`, { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              if (
                [
                  "GERENTE DE ZONA",
                  "GERENTE DE TIENDA",
                  "GERENTE DE DISTRITO",
                  "DIRECTOR REGIONAL",
                ].includes(assessment.puesto)
              ) {
                setPlans(
                  data.filter(
                    (a) =>
                      a.evaluacion_registrado_id ===
                      assessment.evaluacion_usuario_id
                  )
                );
              } else {
                if (Number(printType) == 1) {
                  setPlans(
                    data.filter(
                      (a) =>
                        a.evaluacion_registrado_id ===
                          assessment.evaluacion_usuario_id &&
                        Number(a.tipo_plan) === 2
                    )
                  );
                } else if (Number(printType) == 2) {
                  setPlans(
                    data.filter(
                      (a) =>
                        a.evaluacion_registrado_id ===
                          assessment.evaluacion_usuario_id &&
                        Number(a.tipo_plan) === 1
                    )
                  );
                }  else {
                  setPlans(
                    data.filter(
                      (a) =>
                        a.evaluacion_registrado_id ===
                          assessment.evaluacion_usuario_id
                    )
                  );
                }
              }
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getSalesObjectiveAnswers = () =>
      new Promise((resolve, reject) => {
        if (assessment.tipo_objetivos !== 3) {
          return resolve();
        }
        const params = { print: true };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/salesobjectives`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let newSalesAnswers = {};
              for (let answer of data) {
                newSalesAnswers[answer.objetivo_venta_id] = { ...answer };
              }
              setSalesAnswers(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getAnswers = () =>
      new Promise((resolve, reject) => {
        let type = 2;
        if (printType) {
          type = Number(printType) > 2 ? 2 : Number(printType);
        }
        let params = { type };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/answers`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let newAnswers = {};
              for (let answer of data) {
                newAnswers[answer.pregunta_id] = { ...answer };
              }
              setAnswers(newAnswers);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getObjectivesData = () =>
      new Promise((resolve, reject) => {
        let params = {
          users: assessment.numero_empleado,
          period: assessment.evaluacion_usuario_id,
        };
        return API.get(`/objectives`, { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              setPastObjectives(
                [...data].filter(
                  (o) =>
                    o.evaluacion_calificado_id ===
                    assessment.evaluacion_usuario_id
                )
              );
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getAssessedProjects = () =>
      new Promise((resolve, reject) => {
        let params = {
          projects: true,
          assess_period: assessment.evaluacion_usuario_id,
        };
        return API.get(`/objectives`, { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              setPastProjects(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getShoppingObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          evaluacion_usuario_id: assessment.evaluacion_usuario_id,
        };
        return API.get(`assessments/${assessment.evaluacion_usuario_id}/shoppingobjectives`,
        { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if(status==200){
              setShoppingObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getShoppingObjectivesResult = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          evaluacion_calificado_id: assessment.evaluacion_usuario_id,
        };
        return API.get(`assessments/${assessment.evaluacion_usuario_id}/shoppingobjectives`,
        { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if(status==200){
              setShoppingObjectivesResults(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let tipo_objetivo_id = 2;
        let params = {
          evaluacion_usuario_id: assessment.evaluacion_usuario_id,
        };
        if (printType && Number(printType) !== 3) {
          // tipo_objetivo_id = Number(printType) > 2 ? 1 : printType;
          params.tipo_objetivo_id = printType;
        }
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let response = data
              if (Number(printType) == 3) {
                response =
                  data.filter(
                    (a) => Number(a.estatus_retroalimentacion) == 2
                  );
              }
              setNextObjectives(response);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextProjects = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        return API2.get(
          `/edd/evaluacion-usuario/${assessment.evaluacion_usuario_id}/next-projects`,
          { headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let response = data
              setNextProjects(response);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextParentObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          evaluacion_usuario_id: assessment.evaluacion_usuario_id,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives-parent`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextParentObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const filterSections = (section) => {
      if (assessment.numero_empleado === user.numero_empleado) {
        return section.type_assessment !== 3;
      }
      if (user.colaboradores.includes(assessment.numero_empleado)) {
        return section.type_assessment !== 2;
      }
      return true;
    };
    if (assessment.evaluacion_usuario_id) {
      Promise.all([
        API.get(`/forms/${assessment.formato_id}/content`, { headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              setSections(data.formato_json.filter(filterSections));
            }
          })
          .catch((error) => {}),
        getObjectivesData(),
        getAssessedProjects(),
        getAnswers(),
        getUser(),
        getPlans(),
        getSalesObjectiveAnswers(),
        getNextObjectives(),
        getShoppingObjectives(),
        getShoppingObjectivesResult(),
        getNextParentObjectives(),
        getNextProjects(),
        getResults(),
      ])
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [printType, assessmentId, assessment]);

  const getObjectiveStatus = (obj) => {
    let status = "";
    switch (obj) {
      case 4:
        status = "Alcanzado";
        break;
      default:
        status = "No alcanzado";
        break;
    }
    return status;
  };

  const roundPercentage = (number) => {
    let num = number * 100;
    let result = Math.round(num * 100) / 100;
    if(isNaN(result)){
      result = 0;
    }
    return result;
  };

  const reactToPrintContent = useCallback(() => {
    return printComponent.current;
  }, [printComponent.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${Number(printType) < 2 ? "Autoevaluacion_" : "Evaluacion_"}${
      assessmentUser?.nombre_completo
    }`,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: "{ size: auto; margin: 0mm; }",
  });

  const getLabelAnswers = (question) => {
    let label = "";
    let maxOption = question.answers.find((a) => a.is_max);
    if (answers[question.pregunta_id].valor_pregunta < maxOption.value) {
      label = "No cumple requerimiento. ";
    } else if (answers[question.pregunta_id].valor_pregunta > maxOption.value) {
      label = "Excede requerimiento. ";
    } else {
      label = "Cumple requerimiento. ";
    }
    return label;
  };

  const getGlobalGrade = (type) => {
    let grade =
      type >= 2
        ? global.calificacion_global_evaluacion
        : global.calificacion_global_autoevaluacion;
    return Math.round(grade * 100) / 100;
  };

  const getGlobalGradeNoOp = (type) => {
    let grade =
      type >= 2
        ? global.calificacion_global_evaluacion
        : global.calificacion_global_autoevaluacion;
    return Math.round(grade * 100) / 100;
  };

  const checkForRound = (value) => {
    if(value.match(/^-?\d+$/) || value.match(/^\d+\.\d+$/)){
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'decimal',
        maximumFractionDigits: 2
      });
      return formatter.format(value);
    }else if(value.match(/^\d+\.\d+$/)){
      return value;
    }else{
      return value;
    }
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center px-2 sm:px-4 md:px-8 py-8">
        <div className="w-full max-w-5xl mt-8 flex justify-end">
          <div className={`grid ${(superAdministrador || ((user?.numero_empleado == assessment.numero_empleado || user?.colaboradores.includes(assessment.numero_empleado)) && assessment.estatus_evaluacion_id == 4)) ? "grid-cols-2 gap-4" : "grid-cols-1"}`}>
            {(superAdministrador || ((user?.numero_empleado == assessment.numero_empleado || user?.colaboradores.includes(assessment.numero_empleado)) && assessment.estatus_evaluacion_id == 4)) && <select
              id="location"
              name="location"
              className="col-span-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={printType}
              onChange={e => navigate(`/t3b/assessments/${assessmentId}/print/${e.target.value}`)}
            >
              <option value={1}>Autoevaluación</option>
              <option value={2}>Evaluación</option>
              <option value={3}>Global</option>
            </select>}
            <button
              type="button"
              onClick={handlePrint}
              className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Imprimir
              <PrinterIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="bg-white max-w-5xl mt-4 shadow border w-full">
          <div ref={printComponent}>
            <div className="m-6 sm:m-8 md:m-10 lg:m-16 print:m-0 print:sm:m-0 print:md:m-0 print:lg:m-0">
              <div className="w-full flex justify-center">
                <img
                  src={logo}
                  alt=""
                  className="h-24 w-24"
                />
              </div>
              <p className="text-center text-lg font-medium text-red-500">{assessment.periodo_nombre}</p>
              <p className="text-center text-xl font-bold text-gray-900">{assessment.titulo}</p>
              <div  className="grid grid-cols-1 sm:grid-cols-2 my-6">
                <div className="grid grid-cols-1">
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">NOMBRE: </span>{assessment.nombre_completo}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">PUESTO: </span>{assessment.puesto}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">REGIÓN: </span>{assessment.region}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">ÁREA: </span>{assessment.area}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">EVALUADOR: </span>{assessment.nombre_completo_evaluador}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">FECHA DE REVISIÓN: </span>{assessment.revision_fecha_timezone ? formatDate(assessment.revision_fecha_timezone) : ''}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">¿AUTOEVALUACIÓN FINALIZADA?: </span>{assessment.autoevaluacion_terminada ? "SÍ" : "NO"}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">¿EVALUACIÓN FINALIZADA?: </span>{assessment.evaluacion_terminada ? "SÍ" : "NO"}
                    </p>
                  </div>
                </div>
                <div className="flex mb-6">
                  <div className="flex flex-col items-start">
                    {Number(assessment.tipo_objetivos) === 3 && (
                      <>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Objetivos cumplidos: </span>{roundPercentage(results?.cumplidos / results?.objetivos_ventas)}{" "}%
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Competencias cumplidas: </span>
                          {
                            Number(printType) >= 2 ? (
                              <>
                                {roundPercentage(Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.cumple_evaluacion) / (Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.cumple_evaluacion) + Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.excede_evaluacion) + Number(results.secciones_valores?.find( (s) => s.seccion_titulo === "Competencias del Puesto")?.insatisfactorio_evaluacion)))}{" "}%
                              </>
                            ) : (
                              <>
                                {roundPercentage(Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.cumple_autoevaluacion) / (Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.cumple_autoevaluacion) + Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.excede_autoevaluacion) + Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Competencias del Puesto")?.insatisfactorio_autoevaluacion)))}{" "}%
                              </>
                            )
                          }
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Cultura cumplidas: </span>
                          {
                            Number(printType) >= 2 ? (
                              <>
                                {roundPercentage(Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.cumple_evaluacion) /(Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.cumple_evaluacion) +Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.excede_evaluacion) +Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.insatisfactorio_evaluacion)))}{" "}%
                              </>
                            ) : (
                              <>
                                {roundPercentage(Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.cumple_autoevaluacion) /(Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.cumple_autoevaluacion) +Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.excede_autoevaluacion) +Number(results.secciones_valores?.find((s) => s.seccion_titulo === "Cultura 3B")?.insatisfactorio_autoevaluacion)))}{" "}%
                              </>
                            )
                          }
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Global: </span>{getGlobalGrade(Number(printType))}{" "}%
                        </p>
                      </>
                    )}
                    {Number(assessment.tipo_objetivos) === 1 && (
                      <>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Objetivos logrados: </span>{results.objetivos > 0 ? roundPercentage(results.validados / results.objetivos) : "N/A"}{" "}%
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Excede requerimiento: </span>
                          {
                            Number(printType) >= 2 ? (
                              <>
                                {roundPercentage(Number(results.excede_evaluacion) /(Number(results.cumple_evaluacion) +Number(results.excede_evaluacion) +Number(results.insatisfactorio_evaluacion)))}{" "}%
                              </>
                            ) : (
                              <>
                                {roundPercentage(Number(results.excede_autoevaluacion) /(Number(results.cumple_autoevaluacion) +Number(results.excede_autoevaluacion) +Number(results.insatisfactorio_autoevaluacion)))}{" "}%
                              </>
                            )
                          }
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Cumple requerimiento: </span>
                          {
                            Number(printType) >= 2 ? (
                              <>
                                {roundPercentage(Number(results.cumple_evaluacion) /(Number(results.cumple_evaluacion) +Number(results.excede_evaluacion) +Number(results.insatisfactorio_evaluacion)))}{" "}%
                              </>
                            ) : (
                              <>
                                {roundPercentage(Number(results.cumple_autoevaluacion) /(Number(results.cumple_autoevaluacion) +Number(results.excede_autoevaluacion) +Number(results.insatisfactorio_autoevaluacion)))}{" "}%
                              </>
                            )
                          }
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Insatisfactorio: </span>
                          {
                            Number(printType) >= 2 ? (
                              <>
                                {roundPercentage(Number(results.insatisfactorio_evaluacion) /(Number(results.cumple_evaluacion) +Number(results.excede_evaluacion) +Number(results.insatisfactorio_evaluacion)))}{" "}%
                              </>
                            ) : (
                              <>
                                {roundPercentage(Number(results.insatisfactorio_autoevaluacion) /(Number(results.cumple_autoevaluacion) +Number(results.excede_autoevaluacion) +Number(results.insatisfactorio_autoevaluacion)))}{" "}%
                              </>
                            )
                          }
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Global: </span>{getGlobalGradeNoOp(Number(printType))}{" "}%
                        </p>
                      </>
                    )}
                    {Number(assessment.tipo_objetivos) === 4 && (
                      <>
                        {
                          Number(printType) >= 2 ? (
                            <>
                              {global?.calificacion_evaluacion_string?.split(",").map((seccion_calificacion, index)=>(
                                <p className="text-gray-900 text-sm print:text-xs mb-1" key={`key-seccion-calificacion-${index}`}>
                                  <span className="text-red-500 font-bold">{seccion_calificacion.split("=")[0]}: </span>{Number(seccion_calificacion.split("=")[1]).toFixed(2)}{" "}%
                                </p>
                              ))}
                            </>
                          ) : (
                            <>
                              {global?.calificacion_autoevaluacion_string?.split(",").map((seccion_calificacion, index)=>(
                                <p className="text-gray-900 text-sm print:text-xs mb-1" key={`key-seccion-calificacion-${index}`}>
                                  <span className="text-red-500 font-bold">{seccion_calificacion.split("=")[0]}: </span>{Number(seccion_calificacion.split("=")[1]).toFixed(2)}{" "}%
                                </p>
                              ))}
                            </>
                          )
                        }
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">% Global: </span>{getGlobalGradeNoOp(Number(printType))}{" "}%
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex-col items-start mt-2">
                {(Number(printType) == 1 ? [...sections] : [...sections].filter(s => Number(s.tipo_evaluacion) != 2))?.sort(sortByOrder)?.map((section) => (
                  <div key={`section-${section.seccion_id}`} className={`pb-3 ${section.value == 0 && section.type != 1 ? 'break-inside-avoid-page' : 'break-inside-auto'}`}>
                    <p className="pb-2 text-left text-xl font-extrabold text-gray-900">{section.title}</p>
                    {section.type === 1 && section.subsections.sort(sortByOrder)?.map((subsection) => (
                      <div className={subsection.questions.length > 5 ? "pb-2 break-inside-auto" : "pb-2 break-inside-avoid-page"} key={`subsection-${subsection.apartado_id}`}>
                        <p className="text-left font-bold text-red-600 mb-1">{subsection.title == "Comentarios" && Number(printType) > 1 ? subsection.title + " jefe inmediato" : subsection.title}</p>
                        <ul className="list-disc px-5 space-y-1">
                          {subsection.questions.sort(sortByOrder)?.map((question, index) => (
                            <li key={`question-${question.pregunta_id}`} className="break-inside-avoid-page">
                              <p className="text-sm print:text-xs font-medium">{Number(printType) == 1 ? question.title_auto : (subsection.title == "Comentarios" ? '' : question.title)}</p>
                              {
                                Number(printType) == 1
                                  ? question.title_auto != question.placeholder_auto && <p className="text-sm print:text-xs">{question.placeholder_auto}</p>
                                  : question.title != question.placeholder && <p className="text-sm print:text-xs">{question.placeholder}</p>
                              }
                              {/* {question.type==3 && <p className="text-sm print:text-xs break-before-all">{Number(printType) == 1 ? question.placeholder_auto : question.placeholder}</p>} */}
                              {
                                {
                                  1: (<p className="text-sm print:text-xs font-light whitespace-pre-wrap">{answers[question.pregunta_id] && (<span>{answers[question.pregunta_id].respuesta_texto}</span>)}</p>),
                                  2: (<p className="text-sm print:text-xs font-light whitespace-pre-wrap">{answers[question.pregunta_id] &&question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta) && (<span>{question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta)?.title}</span>)}</p>),
                                  3: (<p className="text-sm print:text-xs font-light whitespace-pre-wrap">{answers[question.pregunta_id] && (<span>{answers[question.pregunta_id].respuesta_switch? <span className="text-green-600 font-medium">Sí</span> : <span className="text-red-600 font-medium">No</span>}.{` ${answers[question.pregunta_id].respuesta_texto}`}</span>)}</p>),
                                  4: (<p className="text-sm print:text-xs font-light whitespace-pre-wrap">{answers[question.pregunta_id] &&answers[question.pregunta_id].respuesta_etiquetas && (<span>{answers[question.pregunta_id].respuesta_etiquetas.join()}</span>)}</p>),
                                  5: (<p className="text-sm print:text-xs font-light whitespace-pre-wrap">{answers[question.pregunta_id] &&question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta) && (<span><b>{getLabelAnswers(question)}</b>{question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta)?.title}</span>)}</p>),
                                }[question.type]
                              }
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                    {[2, 99].includes(section.type) &&
                      pastObjectives.map((objective, objIdx) => (
                        <div key={`key-objective-${objective.objetivo_id}`} className="mb-4 break-inside-avoid">
                          <div>
                            <p className="text-left font-bold text-xs text-gray-900">OBJETIVO {objIdx + 1}</p>
                            <p className="font-semibold text-gray-900 print:text-sm">{objective.titulo}</p>
                            <p className="font-regular text-gray-900 text-sm"><span className="font-medium underline">Entregable tangible:</span>&nbsp;{objective.descripcion}</p>
                            <p className="font-regular text-gray-900 text-sm underline mb-1">Fecha límite de cumplimiento: {objective.fecha_entrega}</p>
                            <p className={`text-sm font-semibold flex items-center ${objective.estatus_objetivo_id == 4 ? "text-green-600" : "text-red-600"}`}>
                              {objective.estatus_objetivo_id == 4 ? <CheckCircleIcon className="h-5 w-5 mr-1"/> : <XCircleIcon className="h-5 w-5 mr-1"/>}
                              {getObjectiveStatus(objective.estatus_objetivo_id)}
                            </p>
                          </div>
                        </div>
                    ))}
                    {[3, 33].includes(section.type) &&
                      nextObjectives.map((objective, nextIndex) => (
                        <div key={`next-objective-${nextIndex}`} className="mb-4 break-inside-avoid">
                          <div>
                            <p className="text-left font-bold text-xs text-gray-900">OBJETIVO {nextIndex + 1}</p>
                            {Number(printType) === 3 ? (
                              objective.tipo_objetivo_id === 2 ? (
                                <p className="font-medium text-gray-500 text-sm">
                                  Propuesto por {assessment.nombre_completo_evaluador}
                                </p>
                              ) : (
                                <p className="font-medium text-gray-500 text-sm">
                                  Propuesto por {assessment.nombre_completo}
                                </p>
                              )
                            ) : (
                              <></>
                            )}
                            <p className="font-semibold text-gray-900 print:text-sm">{objective.titulo}</p>
                            <p className="font-regular text-gray-800 text-sm"><span className="font-medium underline">Entregable tangible:</span>&nbsp;{objective.descripcion}</p>
                            <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {objective.fecha_entrega}</p>
                          </div>
                        </div>
                    ))}
                    {[34].includes(section.type) &&
                      <div className="m-0 overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-300 border mb-2">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Categoría
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Grupo
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Línea
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Comprador
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Descripción
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Unidad
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Tipo
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]">
                                Fecha de entrega
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {shoppingObjectives.map((shopping) => (
                              <tr key={`key-shopping-objective-${shopping.objetivo_compras_proximo_id}`} className="divide-x divide-gray-200">
                                <td className="p-2 text-xs font-medium text-gray-900 print:text-[10px]">{shopping.categoria_id == 1 ? 'Lanzamientos MP y Bajas MC' : shopping.categoria_id == 2 ? 'Pruebas' : 'Relanzamientos'}</td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">{shopping.grupo}</td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">{shopping.linea}</td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">{shopping.nombre_completo}</td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">{shopping.descripcion}</td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">{shopping.unidad}</td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">{shopping.tipo}</td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px] whitespace-nowrap">{shopping.fecha_entrega}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    }
                    {[4].includes(section.type) && (
                      <div className="m-0 overflow-x-auto my-6">
                        <table className="min-w-full divide-y divide-gray-300 border mb-2">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th scope="col" className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 print:text-xs">
                                Objetivo
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                                Esperado
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                                Real
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                                Resultado
                              </th>
                              <th scope="col" className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs">
                                Comentarios
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {salesAnswers.map((sale) => (
                              <tr key={`key-sale-objective-${sale.objetivo_venta_id}`} className="divide-x divide-gray-200">
                                <td className="p-2 text-sm font-medium text-gray-900 print:text-xs">
                                  {sale.titulo}
                                </td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">{checkForRound(sale.valor_esperado)}</td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">{checkForRound(sale.valor_real)}</td>
                                <td className={`whitespace-nowrap p-2 text-sm text-center font-medium print:text-xs ${sale.cumplido==='Cumplido' ? 'text-green-600' : sale.cumplido==='No Aplica' ? 'text-yellow-500' : 'text-red-600'}`}>{sale.cumplido}</td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">{Number(printType) < 2 ? sale.comentarios_autoevaluacion : sale.comentarios}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {[5,6].includes(section.type) && (
                      <ul className="list-disc px-5 space-y-1">
                        {plans.filter((a) => a.is_active)?.map((action, actionIdx) => (
                          <li
                            key={`key-development-${action.accion_id}`}
                          >
                            <p className="text-left font-bold text-xs text-gray-900">ACCIÓN {actionIdx + 1}</p>
                            {Number(printType) == 3 ? (
                              Number(action.tipo_plan) === 1 ? (
                                <p className="font-medium text-gray-500 text-sm">
                                  Propuesto por {assessment.nombre_completo_evaluador}
                                </p>
                              ) : (
                                <p className="font-medium text-gray-500 text-sm">
                                  Propuesto por {assessment.nombre_completo}
                                </p>
                              )
                            ) : (
                              <></>
                            )}
                            <p className="font-regular text-gray-800 text-sm">{action.descripcion}</p>
                            <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {action.fecha_entrega}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                    {[8].includes(section.type) && <table className="w-full break-inside-avoid">
                      <tbody>
                        {nextProjects.map((objective, nextIndex) => (
                          <tr key={`next-project-${nextIndex}`} className="border border-gray-400 break-inside-avoid">
                            <td className="p-4 break-inside-avoid">
                              <p className="text-left font-bold text-red-500">PROYECTO {nextIndex + 1}</p>
                              <p className="font-semibold text-gray-900 print:text-sm">{objective.titulo}</p>
                              <p className="font-regular text-red-500 text-sm"><span className="font-medium underline">Valor:</span>&nbsp;{objective.valor}%</p>
                              <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {objective.semestre}{objective.semestre==1 ? "er" : "do"} Semestre del 2024</p>
                              <p className="text-left font-bold text-xs text-gray-900 underline">RESULTADOS CLAVE</p>
                              <ul className="list-disc pl-4 text-xs text-gray-900">
                                  {(objective.krs.map((kr)=>(
                                      <li>{kr.titulo}</li>
                                  )))}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>}
                    {[9].includes(section.type) && <table className="w-full break-inside-avoid">
                      <tbody>
                        {pastProjects.map((objective, pastIndex) => (
                          <tr key={`past-project-${pastIndex}`} className="border border-gray-400 break-inside-avoid">
                            <td className="p-4 break-inside-avoid">
                              <p className="text-left font-bold text-red-500">PROYECTO {pastIndex + 1}</p>
                              <p className="font-semibold text-gray-900 print:text-sm">{objective.titulo}</p>
                              <div className="flex space-x-4">
                                <p className="font-regular text-red-500 text-sm"><span className="font-medium underline">Valor:</span>&nbsp;{objective.valor}%</p>
                                <p className="font-regular text-red-500 text-sm"><span className="font-medium underline">Logrado:</span>&nbsp;{objective.logrado}%</p>
                              </div>
                              <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {objective.semestre}{objective.semestre==1 ? "er" : "do"} Semestre del 2023</p>
                              <p className="text-left font-bold text-xs text-gray-900 underline">RESULTADOS CLAVE</p>
                              <ul className="list-disc pl-4 text-xs text-gray-900">
                                  {(objective.krs.map((kr)=>(
                                      <li>{kr.titulo}</li>
                                  )))}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>}
                    {section.type === 40 && <>
                      <div className="m-0 overflow-x-auto min-w-full mb-8">
                        <table className="min-w-full divide-y divide-gray-300 border mb-2">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Categoría
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Grupo
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Línea
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Descripción
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Unidad
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Tipo
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Fecha de entrega
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                              >
                                Resultado
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {shoppingObjectivesResults.map((shopping) => (
                              <tr
                                key={`key-shopping-objective-${shopping.objetivo_compras_proximo_id}`}
                                className="divide-x divide-gray-200"
                              >
                                <td className="p-2 text-xs font-medium text-gray-900 print:text-[10px]">
                                  {shopping.categoria_id == 1
                                    ? "Lanzamientos MP y Bajas MC"
                                    : shopping.categoria_id == 2
                                    ? "Pruebas"
                                    : "Relanzamientos"}
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  {shopping.grupo}
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  {shopping.linea}
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  {shopping.descripcion}
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">
                                  {shopping.unidad}
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">
                                  {shopping.tipo}
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px] whitespace-nowrap">
                                  {shopping.fecha_entrega}
                                </td>
                                <td className={`p-2 text-xs font-bold ${shopping.resultado_eva=="SI" ? "text-green-600" : shopping.resultado_eva == "NO" ? "text-red-600" : "text-yellow-600"} text-center print:text-[10px]`}>
                                  {shopping.resultado_eva}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      {
                        section.subsections.sort(sortByOrder)?.map((subsection) => (
                          <div className="pb-2" key={`subsection-${subsection.apartado_id}`}>
                            <p className="text-left font-bold text-red-600 mb-1">{subsection.title == "Comentarios" && Number(printType) > 1 ? subsection.title + " jefe inmediato" : subsection.title}</p>
                            <ul className="list-disc px-5 space-y-1">
                              {subsection.questions.sort(sortByOrder)?.map((question, index) => (
                                <li key={`question-${question.pregunta_id}`}>
                                  <p className="text-sm print:text-xs font-medium break-before-all">{Number(printType) == 1 ? question.title_auto : (subsection.title == "Comentarios" ? '' : question.title)}</p>
                                  {
                                    {
                                      1: (<p className="text-sm print:text-xs font-light">{answers[question.pregunta_id] && (<span>{answers[question.pregunta_id].respuesta_texto}</span>)}</p>),
                                      2: (<p className="text-sm print:text-xs font-light">{answers[question.pregunta_id] &&question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta) && (<span>{question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta)?.title}</span>)}</p>),
                                      3: (<p className="text-sm print:text-xs font-light">{answers[question.pregunta_id] && (<span>{answers[question.pregunta_id].respuesta_switch? <span className="text-green-600 font-medium">Sí</span> : <span className="text-red-600 font-medium">No</span>}.{` ${answers[question.pregunta_id].respuesta_texto}`}</span>)}</p>),
                                      4: (<p className="text-sm print:text-xs font-light">{answers[question.pregunta_id] &&answers[question.pregunta_id].respuesta_etiquetas && (<span>{answers[question.pregunta_id].respuesta_etiquetas.join()}</span>)}</p>),
                                      5: (<p className="text-sm print:text-xs font-light">{answers[question.pregunta_id] &&question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta) && (<span><b>{getLabelAnswers(question)}</b>{question.answers.find((a) =>a.value ===answers[question.pregunta_id].valor_pregunta)?.title}</span>)}</p>),
                                    }[question.type]
                                  }
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))
                      }
                    </>}
                  </div>
                ))}
              </div>
              <div className="w-full flex-col items-start mt-24">
                {Number(printType) === 3 && (
                  <div className="grid grid-cols-2 gap-10">
                    <div className="col-span-2 sm:col-span-1">
                      <p className="text-center text-sm text-gray-900 font-bold border-t">{assessment.nombre_completo}</p>
                      <p className="text-center text-xs text-gray-900">{assessment.puesto}</p>
                    </div>
                    <div className="col-span-2 sm:col-span-1">
                      <p className="text-center text-sm text-gray-900 font-bold border-t">{assessment.nombre_completo_evaluador}</p>
                      <p className="text-center text-xs text-gray-900">{assessment.puesto_evaluador}</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Print;
