import { Fragment, useState } from "react";
import {
  Dialog,
  Disclosure,
  Transition,
  Listbox
} from "@headlessui/react";
import {
  DocumentMinusIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { CheckIcon, ChevronUpDownIcon, ChevronDownIcon, UserGroupIcon, ExclamationCircleIcon } from "@heroicons/react/20/solid";
import { CheckCircleIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { Link } from "react-router-dom";

const positions = [
  {
    id: 1,
    title: 'Enero',
    status: true,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Enero del 2020',
  },
  {
    id: 2,
    title: 'Febrero',
    status: true,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Febrero del 2020',
  },
  {
    id: 3,
    title: 'Marzo',
    status: true,
    people: 104,
    closeDate: '2020-01-14',
    closeDateFull: '4 de Marzo del 2020',
  },
  {
    id: 4,
    title: 'Abril',
    status: true,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Abril del 2020',
  },
  {
    id: 5,
    title: 'Mayo',
    status: true,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Mayo del 2020',
  },
  {
    id: 6,
    title: 'Junio',
    status: true,
    people: 104,
    closeDate: '2020-01-14',
    closeDateFull: '4 de Junio del 2020',
  },
  {
    id: 7,
    title: 'Julio',
    status: true,
    people: 104,
    closeDate: '2020-01-14',
    closeDateFull: '4 de Julio del 2020',
  },
  {
    id: 8,
    title: 'Agosto',
    status: true,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Agosto del 2020',
  },
  {
    id: 9,
    title: 'Septiembre',
    status: false,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Septiembre del 2020',
  },
  {
    id: 10,
    title: 'Octubre',
    status: false,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Octubre del 2020',
  },
  {
    id: 11,
    title: 'Noviembre',
    status: false,
    people: 104,
    closeDate: '2020-01-07',
    closeDateFull: '7 de Noviembre del 2020',
  },
  {
    id: 12,
    title: 'Diciembre',
    status: false,
    people: 104,
    closeDate: '2020-01-14',
    closeDateFull: '4 de Diciembre del 2020',
  },
]
const people = [
  { id: 1, name: 'Periodo 2018', status: 3 },
  { id: 2, name: 'Periodo 2019', status: 3 },
  { id: 3, name: 'Periodo 2020', status: 3 },
  { id: 4, name: 'Periodo 2021', status: 3 },
  { id: 5, name: 'Periodo 2022', status: 1 },
  { id: 6, name: 'Periodo 2023', status: 2 },
  { id: 7, name: 'Periodo 2024', status: 2 },
  { id: 8, name: 'Periodo 2025', status: 2 },
  { id: 9, name: 'Periodo 2026', status: 2 }
]
const filters = [
  {
    id: "category",
    name: "Zona",
    options: [
      {
        value: "new-arrivals",
        label: "Camilo Andres Alvarado Torres",
        checked: false,
      },
      { value: "tees", label: "Jesus G. Hernandez Aguilar", checked: false },
      { value: "objects", label: "Juan M. Cuadros Munoz", checked: true },
    ],
  },
  {
    id: "color",
    name: "Distrito",
    options: [
      { value: "white", label: "Jose Luis Zavala Sanchez", checked: false },
      { value: "beige", label: "Ariel Rodriguez Otero", checked: false },
      {
        value: "blue",
        label: "Nayeli  Adriana Guerrero Castillo",
        checked: false,
      },
      { value: "yel", label: "Eugenio Garcia Silva", checked: false },
      { value: "rose", label: "Ana Gabriela Armeida Cortes", checked: false },
    ],
  },
  {
    id: "sizes",
    name: "Estado",
    options: [
      { value: "s", label: "Abierta", checked: false },
      { value: "m", label: "Por abrir", checked: false },
    ],
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Changes() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(people[4]);

  const periodClassName = (status) => {
    let className = "";
    switch(status){
      case 1:
        className = "bg-green-400";
        break;
      case 3:
        className = "bg-red-400";
        break;
      default:
        className = "bg-gray-200";
        break;
    }
    return className;
  }
  return (
    <div className="min-w-0">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <Transition.Root show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 sm:hidden"
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          Filtros
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Filters */}
                      <form className="mt-4">
                        {filters.map((section) => (
                          <Disclosure
                            as="div"
                            key={section.name}
                            className="border-t border-gray-200 px-4 py-6"
                          >
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                    <span className="font-medium text-gray-900">
                                      {section.name}
                                    </span>
                                    <span className="ml-6 flex items-center">
                                      <ChevronDownIcon
                                        className={classNames(
                                          open ? "-rotate-180" : "rotate-0",
                                          "h-5 w-5 transform"
                                        )}
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {section.options.map(
                                      (option, optionIdx) => (
                                        <div
                                          key={option.value}
                                          className="flex items-center"
                                        >
                                          <input
                                            id={`filter-mobile-${section.id}-${optionIdx}`}
                                            name={`${section.id}[]`}
                                            defaultValue={option.value}
                                            type="checkbox"
                                            defaultChecked={option.checked}
                                            className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                          />
                                          <label
                                            htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                            className="ml-3 text-sm text-gray-500"
                                          >
                                            {option.label}
                                          </label>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div className="flex flex-col justify-start pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  Cambios
                </h1>
                <p className="mt-4 text-sm text-gray-700">
                  Navega entre los diferentes meses del periodo de evaluación, sube los archivos de cambio de centros de costo por mes, consulta el historial de cambios y actualiza la información de ser necesario.
                </p>
              </div>
              <div className="sm:flex-auto mt-4">
                <Listbox value={selected} onChange={setSelected}>
                  {({ open }) => (
                    <>
                      <Listbox.Label className="block text-sm font-medium text-gray-700">Periodo</Listbox.Label>
                      <div className="relative mt-1">
                        <Listbox.Button className="relative cursor-default rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 text-left shadow-sm focus:border-red-500 focus:outline-none focus:ring-1 focus:ring-red-500 sm:text-sm">
                          <span className="flex items-center">
                            <span
                              aria-label={selected.online ? 'Online' : 'Offline'}
                              className={classNames(
                                periodClassName(selected.status),
                                'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                              )}
                            />
                            <span className="ml-3 block truncate">{selected.name}</span>
                          </span>
                          <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                            <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                          </span>
                        </Listbox.Button>

                        <Transition
                          show={open}
                          as={Fragment}
                          leave="transition ease-in duration-100"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <Listbox.Options className="absolute z-10 mt-1 max-h-60 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                            {people.map((person) => (
                              <Listbox.Option
                                key={person.id}
                                className={({ active }) =>
                                  classNames(
                                    active ? 'text-white bg-red-600' : 'text-gray-900',
                                    'relative cursor-default select-none py-2 pl-3 pr-9'
                                  )
                                }
                                value={person}
                              >
                                {({ selected, active }) => (
                                  <>
                                    <div className="flex items-center">
                                      <span
                                        className={classNames(
                                          periodClassName(person.status),
                                          'inline-block h-2 w-2 flex-shrink-0 rounded-full'
                                        )}
                                        aria-hidden="true"
                                      />
                                      <span
                                        className={classNames(selected ? 'font-semibold' : 'font-light', 'ml-3 block truncate')}
                                      >
                                        {person.name}
                                      </span>
                                    </div>

                                    {selected ? (
                                      <span
                                        className={classNames(
                                          active ? 'text-white' : 'text-red-600',
                                          'absolute inset-y-0 right-0 flex items-center pr-4'
                                        )}
                                      >
                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                      </span>
                                    ) : null}
                                  </>
                                )}
                              </Listbox.Option>
                            ))}
                          </Listbox.Options>
                        </Transition>
                      </div>
                    </>
                  )}
                </Listbox>
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <div className="-my-2 -mx-4 overflow-hidden sm:-mx-0 lg:-mx-0  bg-white shadow sm:rounded-md">
              <ul className="divide-y divide-gray-200">
                {positions.map((position) => (
                  <li key={position.id}>
                    <Link to={`/t3b/admin/pfchanges/2022/${position.id}`} className="block hover:bg-gray-50">
                      <div className="flex items-center px-4 py-4 sm:px-6">
                        <div className="flex min-w-0 flex-1 items-center">
                          <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                            <div>
                              <p className="truncate text-sm font-medium text-indigo-600">{position.title}</p>
                              {
                                position.status
                                ? <p className="hidden md:flex mt-2 items-center text-sm text-gray-500">
                                  <UserGroupIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  <span className="truncate">{position.people} cambios</span>
                                </p>
                                : <p className="hidden md:flex mt-2 items-center text-sm text-gray-500">
                                  <DocumentMinusIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                                  <span className="truncate">Archivo pendiente por cargar</span>
                                </p>
                              }
                            </div>
                            <div className="block">
                              <div>
                                {
                                  position.status
                                  ? <p className="text-sm text-gray-900">Cargado el <time dateTime={position.closeDate}>{position.closeDateFull}</time></p>
                                  : <p className="text-sm text-gray-900">Carga pendiente</p>
                                }
                                {
                                  position.status
                                  ? <p className="mt-2 flex items-center text-sm text-gray-500">
                                    <CheckCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-green-400" aria-hidden="true" />
                                    1 archivo cargado
                                  </p>
                                  : <p className="mt-2 flex items-center text-sm text-gray-500">
                                    <ExclamationCircleIcon className="mr-1.5 h-5 w-5 flex-shrink-0 text-yellow-400" aria-hidden="true" />
                                    Aún no hay archivos cargados
                                  </p>
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                        </div>
                      </div>
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
