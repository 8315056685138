import { useCallback, useEffect, useRef, useState } from "react";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundTwoDecimals } from "../../utils/math_utils";
import { getDateLabel } from "../../utils/date_utils";
import GlobalResult from "../../components/dashboard/GlobalResult";
import ActionPlans from "../../components/dashboard/ActionPlans";
import NewObjectives from "../../components/dashboard/NewObjectives";
import PieChartObjectives from "../../components/dashboard/PieChartObjectives";
import PieChartAssessments from "../../components/dashboard/PieChartAssessments";
import BarChartCompetences from "../../components/dashboard/BarChartCompetences";
import BarChartSalesObjectives from "../../components/dashboard/BarChartSalesObjectives";
import BarChartCulture from "../../components/dashboard/BarChartCulture";
import { base64StringToBlob } from "blob-util";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import AdminReportsActions from "../../components/dropdowns/AdminReportsActions";
import SecondLines from "../../components/dashboard/SecondLines";
import OperationCompare from "../../components/dashboard/OperationCompare";

const initialDate = new Date();
let fiveDaysDate = new Date();
fiveDaysDate.setDate(initialDate.getDate() + 4);
let firstDayCurrentMonth = new Date();
firstDayCurrentMonth.setDate(1);

export default function Reports() {
  const { user, token } = useSelector((state) => state.authentication);
  const [loadingPeriod, setLoadingPeriod] = useState(true);
  const [loadingReportData, setLoadingReportData] = useState(true);
  const [loadingReportArea, setLoadingReportArea] = useState(false);
  const [loadingReportAreaPDF, setLoadingReportAreaPDF] = useState(false);
  const [loadingReportRegion, setLoadingReportRegion] = useState(false);
  const [loadingReportIntegral, setLoadingReportIntegral] = useState(false);
  const [reportData, setReportData] = useState(null);
  const [period, setPeriod] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [forms, setForms] = useState([]);
  const [regions, setRegions] = useState([]);
  const [zonas, setZonas] = useState([]);
  const [zona, setZona] = useState("0");
  const [distrito, setDistrito] = useState("0");
  const [distritos, setDistritos] = useState([]);
  const [region, setRegion] = useState(0);
  const [form, setForm] = useState(null);
  const [areas, setAreas] = useState([]);
  const [areasList, setAreasList] = useState([]);
  const [area, setArea] = useState("0");
  const [salesType, setSalesType] = useState("GERENTE DE TIENDA");
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();
  const [bossProfile, setBossProfile] = useState(true);

  useEffect(() => {
    const options = { headers: { Authorization: token } };
    API.get("/regions", options).then(({ data, status }) => {
      if (status === 200) {
        setRegions(
          data.map((f) => {
            f.id = f.region_id;
            f.label = f.region_nombre;
            return f;
          })
        );
      }
    });
    API.get("/areas", options).then(({ data, status }) => {
      if (status === 200) {
        setAreas(
          data.map((f) => {
            f.id = f.area_id;
            f.label = f.area_nombre;
            return f;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    const isSuperAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;
    if(isSuperAdministrador){
      if(Number(region) != 0){
        let regionSelected = regions.find(r => r.region_id==region);
        setAreasList(areas.filter(a => a.tipo_region_id==regionSelected?.tipo_region_id));
      } else {
        setAreasList([]);
      }
    } else {
      setAreasList(areas.filter(a => a.tipo_region_id==2));
    }
  }, [user, region, regions, areas]);

  useEffect(() => {
    const fetchZonas = () => {
      const options = {
        headers: { Authorization: token },
        params: {
          tipo_area: 'ZONA',
          region
        }
      };
      API.get("/areas", options).then(({ data, status }) => {
        if (status === 200) {
          setZonas(
            data.map((f) => {
              f.id = f.region_id;
              f.label = f.region_nombre;
              return f;
            })
          );
        }
      });
    }
    if(region!="0"){
      setZona("0");
      setDistrito("0");
      fetchZonas();
    }
  }, [region]);

  useEffect(() => {
    const fetchDistritos = () => {
      const options = {
        headers: { Authorization: token },
        params: {
          tipo_area: 'DISTRITO',
          zona,
          region
        }
      };
      API.get("/areas", options).then(({ data, status }) => {
        if (status === 200) {
          setDistritos(
            data.map((f) => {
              f.id = f.region_id;
              f.label = f.region_nombre;
              return f;
            })
          );
        }
      });
    }
    if(region!="0"){
      setDistrito("0");
      fetchDistritos();
    }
  }, [zona]);

  useEffect(() => {
    if (user) {
      if(user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length < 1){
        setRegion(user.region_id);
      }
      const options = { headers: { Authorization: token } };
      setLoadingPeriod(true);
      API.get("/periods", options)
        .then(({ data, status }) => {
          setLoadingPeriod(false);
          if (status === 200) {
            setPeriods(data);
            setPeriod(data[0]);
            // fetchReportsEdd(data);
          }
        })
        .catch((error) => {
          setLoadingPeriod(false);
          setErrorTitle(error.status);
          setError(error.message);
          notificationRef.current.showNotification();
        });
    }
  }, [user]);

  useEffect(() => {
    if (user && period) {
      const options = {
        params: { periodo_id: period.periodo_id },
        headers: { Authorization: token },
      };
      API.get("/forms", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setForms(data);
            if (data.length > 1) {
              let goodForm = data.find((f) => f.tipo_objetivos == 1);
              setForm(goodForm.formato_id);
            }
          }
        })
        .catch((error) => {
          setErrorTitle(error.status);
          setError(error.message);
          notificationRef.current.showNotification();
        });
    }
  }, [user, period]);

  const changeResults = (value) => {
    if (value == true && bossProfile == false) {
      setBossProfile(true);
    }
    if (value == false && bossProfile == true) {
      setBossProfile(false);
    }
  };

  const downloadFileArea = () => {
    let params = {
      periodo_id: period.periodo_id,
      region_id: region,
      periodo: period.titulo,
      region: regions.find((r) => r.region_id == region)?.region_nombre,
      area: area,
    };
    const headers = { Authorization: token };
    setLoadingReportArea(true);
    API.get("/results/files/areas", {
      params,
      headers,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingReportArea(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${period.titulo}_${region}_${area}.xlsx`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportArea(false);
      });
  };

  const downloadFileAreaPDF = () => {
    let params = {
      periodo_id: period.periodo_id,
      region_id: region,
      periodo: period.titulo,
      region: regions.find((r) => r.region_id == region)?.region_nombre,
      area: area,
    };
    const headers = { Authorization: token };
    setLoadingReportAreaPDF(true);
    API.get("/results/files/areas/pdf", {
      params,
      headers,
      responseType:
        "application/pdf",
    })
      .then((response) => {
        setLoadingReportAreaPDF(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/pdf"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `${period.titulo}_${region}_${area}.pdf`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportAreaPDF(false);
      });
  };

  const downloadFileRegion = () => {
    let params = {
      periodo_id: period.periodo_id,
      region_id: region,
      periodo: period.titulo,
      region: regions.find((r) => r.region_id == region)?.region_nombre,
    };
    const headers = { Authorization: token };
    setLoadingReportRegion(true);
    API.get("/results/files/regions", {
      params,
      headers,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingReportRegion(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `${period.titulo}_${region}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportRegion(false);
      });
  };

  const downloadFileIntegral = () => {
    let params = {
      periodo: period.periodo_id,
      area: area,
      zona: zona,
      distrito: distrito,
      job: salesType,
      region: region,
    };
    const headers = { Authorization: token };
    setLoadingReportIntegral(true);
    API.get("/results/files/integral", {
      params,
      headers,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingReportIntegral(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `Integral_${period.titulo}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportIntegral(false);
      });
  };

  const selectPeriod = (value) => {
    let newPeriod = periods.find(p => Number(p.periodo_id) == Number(value));
    setPeriod(newPeriod);
  }

  const superAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;

  return (
    <>
      <div className="flex-1 max-w-full">
        <main className="py-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div>
              {loadingPeriod ? (
                <div className="w-full h-full flex items-center justify-center bg-white rounded-lg">
                  <video
                    className="w-96 h-96 flex flex-1"
                    alt={"Tiendas 3B"}
                    muted
                    loop
                    autoPlay
                  >
                    <source
                      src={
                        "https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"
                      }
                      type="video/mp4"
                    />
                    Sin soporte de video MP4
                  </video>
                </div>
              ) : (
                <>
                  <section aria-labelledby="profile-overview-title">
                    <div className="overflow-hidden rounded-lg bg-white shadow">
                      <h2 className="sr-only" id="profile-overview-title">
                        Reportes de administración
                      </h2>
                      <div className="border-t border-gray-200 bg-white px-6 py-5 text-center text-sm font-medium">
                        <nav
                          className="flex flex-col items-center justify-center"
                          aria-label="Progress"
                        >
                          <select
                            id="periods"
                            name="periods"
                            value={period?.periodo_id}
                            className="rounded-md border-gray-300 py-1 pl-3 pr-10 text-xl font-bold uppercase focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                            onChange={(e) => selectPeriod(e.target.value)}
                          >
                            {periods.map((p) => (
                              <option
                                key={`key-option-period-${p.periodo_id}`}
                                value={p.periodo_id}
                              >
                                {p.titulo}
                              </option>
                            ))}
                          </select>
                          <p className="mt-1 text font-light">
                            <span className="font-bold text-gray-500">
                              Del {getDateLabel(period?.fecha_inicio+"T00:00:00")} al{" "}
                              {getDateLabel(period?.fecha_fin+"T00:00:00")}
                            </span>
                          </p>
                        </nav>
                      </div>
                    </div>
                  </section>
                  <section className="flex justify-center my-4">
                    <button
                      className={`px-4 py-1 font-medium text-sm rounded-l border border-red-500  ${
                        bossProfile
                          ? "bg-red-500 text-white"
                          : " bg-transparent text-red-500"
                      }`}
                      onClick={(e) => changeResults(true)}
                    >
                      Evaluación
                    </button>
                    <button
                      className={`px-4 py-1 font-medium text-sm rounded-r border border-red-500  ${
                        !bossProfile
                          ? "bg-red-500 text-white"
                          : " bg-transparent text-red-500"
                      }`}
                      onClick={(e) => changeResults(false)}
                    >
                      Autoevaluación
                    </button>
                  </section>
                  <section className="my-4 w-full grid grid-cols-1 sm:grid-cols-6 gap-4">
                    <div className="mb-4 col-span-1 sm:col-span-2">
                      <label
                        htmlFor="question-title"
                        className="block text-xs text-red-500"
                      >
                        REGION
                      </label>
                      <div className="mt-1">
                        <div>
                          { superAdministrador
                           ? <select
                              id="location"
                              name="location"
                              value={region}
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) => setRegion(Number(e.target.value))}
                            >
                              <option value={0}>TODAS LAS REGIONES</option>
                              {regions.map((r) => (
                                <option
                                  key={`key-option-region-${r.region_id}`}
                                  value={r.region_id}
                                >
                                  {r.region_nombre}
                                </option>
                              ))}
                            </select>
                            : <p>{user.region}</p>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 col-span-1 sm:col-span-2">
                      <label
                        htmlFor="question-title"
                        className="block text-xs text-red-500"
                      >
                        AREA
                      </label>
                      <div className="mt-1">
                        <div>
                          <select
                            id="location"
                            name="location"
                            value={area}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setArea(e.target.value)}
                          >
                            <option value={0}>TODAS LAS AREAS</option>
                            {areasList.map((r) => (
                              <option
                                key={`key-option-area-${r.area_id}`}
                                value={r.area_codigo}
                              >
                                {r.area_nombre}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mb-4 col-span-1 sm:col-span-2">
                      <label
                        htmlFor="question-title"
                        className="block text-xs text-red-500"
                      >
                        FORMATO
                      </label>
                      <div className="mt-1">
                        <div>
                          <select
                            id="location"
                            name="location"
                            value={form}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setForm(e.target.value)}
                          >
                            {forms.map((r) => (
                              <option
                                key={`key-option-area-${r.formato_id}`}
                                value={r.formato_id}
                              >
                                {r.titulo}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                  </section>
                  <section className="my-4 w-full grid grid-cols-1 sm:grid-cols-8 gap-4">
                    <button
                      type="button"
                      onClick={downloadFileIntegral}
                      disabled={loadingReportIntegral}
                      className="col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                    >
                      <ArrowDownTrayIcon
                        className={`h-5 w-5 mr-1 ${
                          loadingReportIntegral ? "animate-bounce" : ""
                        }`}
                      />
                      {loadingReportIntegral
                        ? "Descargando archivo"
                        : "Reporte Integral"}
                    </button>
                    <button
                      type="button"
                      onClick={downloadFileRegion}
                      disabled={loadingReportRegion || region == "0"}
                      className={`col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-orange-100 px-4 py-2 text-sm font-medium text-orange-700 ${region == "0" ? "" : "hover:bg-orange-200"} focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
                    >
                      <ArrowDownTrayIcon
                        className={`h-5 w-5 mr-1 ${
                          loadingReportRegion ? "animate-bounce" : ""
                        }`}
                      />
                      {loadingReportRegion
                        ? "Descargando archivo"
                        : "Reporte Regional"}
                    </button>
                    <button
                      type="button"
                      onClick={downloadFileAreaPDF}
                      disabled={loadingReportAreaPDF || area == "0"}
                      className={`col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 ${area == "0" ? "" : "hover:bg-indigo-200"} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    >
                      <ArrowDownTrayIcon
                        className={`h-5 w-5 mr-1 ${
                          loadingReportAreaPDF ? "animate-bounce" : ""
                        }`}
                      />
                      {loadingReportAreaPDF
                        ? "Descargando pdf"
                        : "Reporte Area PDF"}
                    </button>
                    <button
                      type="button"
                      onClick={downloadFileArea}
                      disabled={loadingReportArea || area == "0"}
                      className={`col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 ${area == "0" ? "" : "hover:bg-indigo-200"} focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                    >
                      <ArrowDownTrayIcon
                        className={`h-5 w-5 mr-1 ${
                          loadingReportArea ? "animate-bounce" : ""
                        }`}
                      />
                      {loadingReportArea
                        ? "Descargando archivo"
                        : "Reporte Area"}
                    </button>
                    <div className="col-span-2 inline-flex items-center">
                      <AdminReportsActions
                        periodo_nombre={period?.titulo}
                        periodo={period?.periodo_id}
                        region={region}
                        area={area}
                        zona={zona}
                        distrito={distrito}
                        job={salesType}
                        formato={form}
                      />
                    </div>
                  </section>
                  <>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
                      <GlobalResult
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        region={region}
                        area={area}
                      />
                      <ActionPlans
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        region={region}
                        area={area}
                      />
                      <NewObjectives
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        region={region}
                        area={area}
                      />
                      <PieChartObjectives
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        region={region}
                        area={area}
                      />
                      <PieChartAssessments
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        region={region}
                        area={area}
                      />
                      {superAdministrador && <SecondLines
                        period={period?.periodo_id}
                        titulo={period?.titulo}
                      />}
                      {superAdministrador && <OperationCompare
                        period={period?.periodo_id}
                        titulo={period?.titulo}
                      />}
                      <BarChartCompetences
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        form={form}
                        region={region}
                        area={area}
                      />
                      <BarChartCulture
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        form={form}
                        region={region}
                        area={area}
                      />
                    </dl>
                    <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
                      <div className="col-span-1 sm:col-span-2">
                        <label
                          htmlFor="question-title"
                          className="block text-xs text-red-500"
                        >
                          PUESTO
                        </label>
                        <div className="mt-1">
                          <div>
                            <select
                              id="location"
                              name="location"
                              value={salesType}
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) =>
                                setSalesType(e.target.value)
                              }
                            >
                              <option value={'GERENTE DE TIENDA'}>GERENTE DE TIENDA</option>
                              <option value={'GERENTE DE DISTRITO'}>GERENTE DE DISTRITO</option>
                              <option value={'GERENTE DE ZONA'}>GERENTE DE ZONA</option>
                              <option value={'DIRECTOR REGIONAL'}>DIRECTOR REGIONAL</option>
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 sm:col-span-2">
                        <label
                          htmlFor="question-title"
                          className="block text-xs text-red-500"
                        >
                          ZONA
                        </label>
                        <div className="mt-1">
                          <div>
                            <select
                              id="location"
                              name="location"
                              value={zona}
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) => setZona(e.target.value)}
                            >
                              <option value={0}>SELECCIONA UNA ZONA</option>
                              {zonas.map((r) => (
                                <option
                                  key={`key-option-area-${r.area_id}`}
                                  value={r.area_codigo}
                                >
                                  {r.area_nombre}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-1 sm:col-span-2">
                        <label
                          htmlFor="question-title"
                          className="block text-xs text-red-500"
                        >
                          DISTRITO
                        </label>
                        <div className="mt-1">
                          <div>
                            <select
                              id="location"
                              name="location"
                              value={distrito}
                              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                              onChange={(e) => setDistrito(e.target.value)}
                            >
                              <option value={0}>SELECCIONA UN DISTRITO</option>
                              {distritos.map((r) => (
                                <option
                                  key={`key-option-area-${r.area_id}`}
                                  value={r.area_codigo}
                                >
                                  {r.area_nombre}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <BarChartSalesObjectives
                        period={period?.periodo_id}
                        isBoss={bossProfile}
                        region={region}
                        area={area}
                        zona={zona}
                        distrito={distrito}
                        salesType={salesType}
                      />
                    </dl>
                  </>
                </>
              )}
            </div>
          </div>
        </main>
      </div>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </>
  );
}
