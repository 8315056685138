import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useEffect, useState, useRef } from "react";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../services/API";
import { getDateLabel } from "../../utils/date_utils";

export default function ValidateObjective() {
  let pathParams = useParams();
  let navigate = useNavigate();
  let { user, token } = useSelector((state) => state.authentication);
  const [objective, setObjective] = useState(null);
  const [files, setFiles] = useState([]);
  const [loadingObjective, setLoadingObjective] = useState(true);
  const [loadingSave, setLoadingSave] = useState(false);
  const [progress, setProgress] = useState("");
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();

  useEffect(() => {
    const fetchObjectiveInfo = () => {
      API.get(`/objectives/${pathParams.objectiveId}`, {
        headers: { Authorization: token },
      })
        .then((result) => {
          setLoadingObjective(false);
          if (result.status == 200) {
            setObjective(result.data);
          } else {
            setObjective(null);
            setErrorTitle("Error");
            setError("Error al buscar la información del objetivo");
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          setErrorTitle("Error");
          setLoadingObjective(false);
          setObjective(null);
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    fetchObjectiveInfo();
  }, []);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const saveProgress = async () => {
    setLoadingSave(true);
    let body = {
      descripcion: progress,
      resultado_objetivo_id: 4,
      numero_empleado: user.numero_empleado,
      fecha_captura: new Date(),
      evidencias: [],
    };
    let evidencias = [];
    for (let i = 0; i < files.length; i++) {
      let item = files[i];
      let file = {
        last_modified_date: item.lastModifiedDate,
        name: item.name,
        size: item.size,
        type: item.type,
        file: await getBase64(item),
      };
      evidencias.push(file);
    }
    body.evidencias = evidencias;
    API.post(`/objectives/${objective.objetivo_id}/newprogressv2`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setLoadingSave(false);
        if (result.status == 201) {
          let navigateRoute = "/t3b/home";
          if (objective.numero_empleado != user.numero_empleado) {
            navigateRoute = `/t3b/employees/${objective.numero_empleado}`;
          }
          navigate(navigateRoute, { replace: true });
        } else {
          setObjective(null);
          setError("Error al registrar la información del objetivo");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingSave(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al registrar la información del objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al registrar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  return (
    <div className="min-h-full">
      <main className="py-8 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:max-w-7xl">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/t3b/home"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-4 text-sm font-medium text-gray-700">
                    Validar Objetivo
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          <div className="mt-6">
            <div>
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Detalles del objetivo
              </h3>
              <p className="mt-1 text-sm text-gray-500">
                Antes de validar el objetivo revisa los avances registrados y evidencias
              </p>
            </div>
            <div className="mt-5 border-t border-gray-200">
              <dl className="sm:divide-y sm:divide-gray-200">
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Objetivo
                  </dt>
                  {loadingObjective ? (
                    <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                  ) : (
                    <dd className="mt-1 text-gray-900 font-semibold sm:col-span-2 sm:mt-0">
                      {objective?.titulo}
                    </dd>
                  )}
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Entregable tangible
                  </dt>
                  {loadingObjective ? (
                    <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                  ) : (
                    <dd className="mt-1 text-gray-900 text-sm sm:col-span-2 sm:mt-0">
                      {objective?.descripcion}
                    </dd>
                  )}
                </div>
                <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5">
                  <dt className="text-sm font-medium text-gray-500">
                    Fecha límite de cumplimiento
                  </dt>
                  {loadingObjective ? (
                    <dd className="mt-1 bg-gray-200 font-semibold sm:col-span-2 sm:mt-0 rounded-lg h-6 animate-pulse"></dd>
                  ) : (
                    <dd className="mt-1 text-gray-900 text-sm sm:col-span-2 sm:mt-0">
                      {getDateLabel(objective ? objective?.fecha_entrega+"T00:00:00" : new Date())}
                    </dd>
                  )}
                </div>
              </dl>
            </div>
          </div>
          <div className="space-y-8 mt-4">
            <div className="space-y-8 divide-y divide-gray-200">
              <div>
                <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Comentarios de Validación
                    </label>
                    <div className="mt-1">
                      <textarea
                        id="about"
                        placeholder="Retroalimentación al cumplimiento del objetivo"
                        name="about"
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                        value={progress}
                        onChange={(e) => setProgress(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="pt-5">
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={(e) => navigate(-1)}
                  className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  Cancelar
                </button>
                <button
                  type="button"
                  disabled={loadingSave}
                  onClick={saveProgress}
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                >
                  {loadingSave ? "Validando Objetivo" : "Validar"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </main>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
