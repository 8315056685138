import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundPercentage } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  BarChart,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import PlusFolder from "../vectors/PlusFolder";

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center pt-2 items-center space-x-6 flex-wrap">
      {payload.map((entry, index) => {
        const { payload } = entry;
        return (
          <li key={`item-${index}`} className="flex items-center">
            <div
              className="h-4 w-4 mr-2"
              style={{
                backgroundColor: payload.fill,
                border: 1,
                borderStyle: "solid",
                borderColor: payload.fill,
              }}
            ></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        );
      })}
    </ul>
  );
};

const BarChartSalesObjectives = ({ period, isBoss, region, zona, distrito, salesType }) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [sales, setSales] = useState([]);
  const [loadingSales, setLoadingSales] = useState(true);

  useEffect(() => {
    if (period) {
      fetchBarChartSalesObjectives();
    }
  }, [period, region, zona, distrito, salesType]);

  const fetchBarChartSalesObjectives = useCallback(() => {
    let params = { job: salesType ,period };
    if (region != "0") {
      params.region = region;
    }
    if(zona!="0"){
      params.zona = zona;
    }
    if(distrito!="0"){
        params.distrito = distrito;
    }
    setLoadingSales(true);
    const headers = { Authorization: token };
    API.get(`/results/salesobjectives`, { params, headers })
      .then(({ status, data }) => {
        setLoadingSales(false);
        if (status === 200) {
          let dataObj = data || [];
          setSales(dataObj);
        } else {
          setSales([]);
        }
      })
      .catch((error) => {
        setLoadingSales(false);
        setSales([]);
      });
  }, [period, region, token, salesType, zona, distrito]);

  const getSales = () => {
    let data = [];
    let labelC = 'porcentaje_cumplidos';
    let labelI = 'porcentaje_no_cumplidos';
    sales.forEach( c => {
        let apartado = {
            name: c.titulo,
            Cumple: roundPercentage(c[labelC]),
            "No Cumple": roundPercentage(c[labelI]),
        }
        data.push(apartado);
    })
    return data;
  }

  return (
    <>
      <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
        <dt className="w-full text-center text-lg font-medium text-gray-500">
          Objetivos de ventas
        </dt>
        {loadingSales ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="flex-1">
            {!loadingSales && sales.length < 1
              ? <div className="flex flex-col h-full items-center justify-center mt-3">
                <PlusFolder />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No hay objetivos de ventas
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  No hay objetivos de ventas en los formatos con los filtros seleccionados para realizar el cálculo.
                </p>
              </div>
              :<ResponsiveContainer width="100%" height="100%">
              <BarChart
                margin={{ top: 15 }}
                data={getSales()}
              >
                <Tooltip />
                <Legend
                  content={renderLegend}
                  wrapperStyle={{ display: "flex" }}
                />
                <XAxis dataKey="name" />
                <YAxis type="number" />
                <Bar
                  dataKey="Cumple"
                  stackId="a"
                  fill="#16a34a66"
                  stroke="#16a34a"
                ></Bar>
                <Bar
                  dataKey="No Cumple"
                  stackId="a"
                  fill="#dc262666"
                  stroke="#dc2626"
                />
              </BarChart>
            </ResponsiveContainer>
            }
          </dd>
        )}
      </div>
    </>
  );
};

BarChartSalesObjectives.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number
};

BarChartSalesObjectives.defaultProps = {
  period: null,
  isBoss: true,
  region: 0
};

export default BarChartSalesObjectives;
