import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundPercentage, roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

const COLORSOBJFILL = ["#16a34a66", "#dc262666"];
const COLORSOBJSTROKE = ["#16a34a", "#dc2626"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);


  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center pt-2 items-center space-x-6 flex-wrap">
      {
        payload.map((entry, index) => {
          const { payload } = entry;
          return (
          <li key={`item-${index}`} className="flex items-center">
            <div className="h-4 w-4 mr-2" style={{backgroundColor: payload.fill, border: 1, borderStyle: "solid", borderColor: payload.fill}}></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        )})
      }
    </ul>
  );
}

const PieChartAssessments = ({ period, isBoss, region, area }) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [assessments, setAssessments] = useState({});
  const [loadingAssessments, setLoadingASsesloadingAssessments] = useState(true);

  useEffect(() => {
    if (period) {
      fetchPieChartAssessments();
    }
  }, [period, region, area]);

  const fetchPieChartAssessments = useCallback(() => {
    let params = { period };
    if (region != 0) {
      params.region = region;
    }
    if (area != "0") {
      params.area = area;
    }
    setLoadingASsesloadingAssessments(true);
    const headers = { Authorization: token };
    API.get(`/results/assessmentsclosure`, { params, headers })
      .then(({ status, data }) => {
        setLoadingASsesloadingAssessments(false);
        if (status === 200) {
          let dataObj = data[0] || {};
          setAssessments(dataObj);
        } else {
          setAssessments({});
        }
      })
      .catch((error) => {
        setLoadingASsesloadingAssessments(false);
        setAssessments({});
      });
  }, [period, region, area, token]);

  const getAssessmentsData = (type) => {
    let cerradas = 0;
    let noCerradas = 0;
    let auto = 0;
    let noAuto = 0;
    let total = 0;
    let labelObj = 'autoevaluaciones_terminadas';
    let name = 'Finalizadas';
    let nameNo = 'No Finalizadas';
    if(isBoss){
        labelObj = 'evaluaciones_terminadas';
        name = 'Finalizadas';
        nameNo = 'No Finalizadas';
    }
    total = Number(assessments['evaluaciones']);
    cerradas = Number(assessments['evaluaciones_cerradas']);
    noCerradas = (total - Number(assessments['evaluaciones_cerradas']));

    auto = Number(assessments[labelObj]);
    noAuto = (total - Number(assessments[labelObj]));
    const data1 = [
        { name: 'Cerradas', value: cerradas },
        { name: 'No Cerradas', value: noCerradas }
    ];
    const data2 = [
        { name: name, value: auto },
        { name: nameNo, value: noAuto }
    ];
    let response = data1;
    switch(type){
        case 0:
            response = data1;
            break;
        case 1:
            response = data2;
            break;
        default:
            response = data1;
            break;
    }
    return response;
  };
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-3 h-96">
        <dt className="truncate text-sm font-medium text-gray-500">
          Formatos Finalizados
        </dt>
        {loadingAssessments ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="h-full">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  nameKey="name"
                  dataKey="value"
                  data={getAssessmentsData(1)}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="80%"
                  fill="#E31B22"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {getAssessmentsData(1).map((entry, index) => (
                    <Cell
                      key={`key-objective-smart-estatus-cell-${index}`}
                      fill={COLORSOBJFILL[index]}
                      stroke={COLORSOBJSTROKE[index]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
              </PieChart>
            </ResponsiveContainer>
          </dd>
        )}
      </div>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-3 h-96">
        <dt className="truncate text-sm font-medium text-gray-500">
            Formatos Cerrados
        </dt>
        {loadingAssessments ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="h-full">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  nameKey="name"
                  dataKey="value"
                  data={getAssessmentsData(0)}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="80%"
                  fill="#E31B22"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {getAssessmentsData(0).map((entry, index) => (
                    <Cell
                      key={`key-objective-ventas-estatus-cell-${index}`}
                      fill={COLORSOBJFILL[index]}
                      stroke={COLORSOBJSTROKE[index]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
              </PieChart>
            </ResponsiveContainer>
          </dd>
        )}
      </div>
    </>
  );
};

PieChartAssessments.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number,
  area: PropTypes.string,
};

PieChartAssessments.defaultProps = {
  period: null,
  isBoss: true,
  region: 0,
  area: "0",
};

export default PieChartAssessments;
