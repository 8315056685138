import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";
import { useState, useRef, useEffect } from "react";
import Notification from "../../components/notifications/Notification";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { CheckIcon } from '@heroicons/react/20/solid'
import API from "../../services/API";
import { base64StringToBlob } from "blob-util";
import {
  ExclamationTriangleIcon,
  PaperClipIcon,
} from "@heroicons/react/24/outline";
import CommonModalTemplate from "../../components/modals/CommonModalTemplate";
import face from "./../../assets/img/faces/avatar.png";
import { logout } from "../../redux/reducers/auth";

const tiers = [
  {
    id: 'tier-1',
    stepNumber: 1,
    description: 'Descarga el archivo haciendo click en el botón de "Descargar objetivos" más abajo.',
  },
  {
    id: 'tier-2',
    stepNumber: 2,
    description: 'El archivo tiene tres pestañas, en estas encontrarás los objetivos que te asignaron para cumplir en el periodo.',
  },
  {
    id: 'tier-3',
    stepNumber: 3,
    description: 'Ubica la columna "Resultados" (la última columna disponible de cada pestaña), en esta deberás responder según tus resultados',
  },
  {
    id: 'tier-4',
    stepNumber: 4,
    description: 'SI(Si se cumplió el objetivo), NO(Si no cumpliste el objetivo), CANCELADO(Si el objetivo ya no es válido y no se debe cumplir).',
  },
  {
    id: 'tier-5',
    stepNumber: 5,
    description: 'Sube el archivo haciendo click en el botón de "Cargar Resultados" más abajo.',
  },
  {
    id: 'tier-6',
    stepNumber: 6,
    description: ' Recuerda NO mover las demás columnas que no sea la de "Resultados"',
  },
]

const LoaderSpinnerGD = () => {
  return (
    <svg
      class="animate-spin ml-2 h-4 w-4 text-yellow-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

const LoaderSpinner = () => {
  return (
    <svg
      class="animate-spin ml-2 h-5 w-5 text-red-500"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
    >
      <circle
        class="opacity-25"
        cx="12"
        cy="12"
        r="10"
        stroke="currentColor"
        stroke-width="4"
      ></circle>
      <path
        class="opacity-75"
        fill="currentColor"
        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
      ></path>
    </svg>
  );
};

export default function UploadShoppingAuto() {
  let pathParams = useParams();
  let navigate = useNavigate();
  let { user, token } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [covs, setCovs] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [positionToUpload, setPositionToUpload] = useState([]);
  const [files, setFiles] = useState([]);
  const [successMessage, setSuccessMessage] = useState("Error");
  const [successTitle, setSuccessTitle] = useState("Hubo un error con el servicio");
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [loadingFilePosition, setLoadingFilePosition] = useState(0);
  const [loadingFileEmployee, setLoadingFileEmployee] = useState(0);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingCov, setLoadingCov] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const notificationRef = useRef();
  const fileInputRef = useRef();
  const confirmModalRef = useRef();
  const confirmAuthorizeModalRef = useRef();
  const notificationRefSuccess = useRef();

  useEffect(() => {
    const fetchCovs = () => {
      let params = { user: user.numero_empleado };
      API.get("/covs", { params, headers: { Authorization: token } })
        .then((reponse) => {
          let { status, data } = reponse;
          if (status == 200) {
            setCovs(data);
          } else {
            setCovs([]);
            setErrorTitle("Error al buscar los formatos de carga");
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          setCovs([]);
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            } else {
              setError(error.response.data);
            }
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los formatos de carga"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los formatos de carga"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    fetchCovs();
  }, []);

  useEffect(() => {
    const fetchEmployees = (users) => {
      const params = { users };
      const options = { params, headers: { Authorization: token } };
      API.get("/usersearch", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setEmployees(data);
          } else {
            setEmployees([]);
          }
        })
        .catch((error) => {
          setEmployees([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };

    if (user) {
      if (user?.colaboradores.length > 0) {
        fetchEmployees(user?.colaboradores.join());
      }
    }
  }, [user]);

  const downloadCovFile = (numero_empleado, puesto, tipo, nombre) => {
    let params = {
      numero_empleado,
      puesto,
      tipo,
    };
    setLoadingFilePosition(puesto);
    setLoadingFileEmployee(numero_empleado);
    setLoadingFile(true);
    API.get("/covs/file", {
      headers: { Authorization: token },
      params,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingFile(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `COV ${nombre}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingFile(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar el formato de carga"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar el formato de carga"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const onAddFiles = (e) => {
    let a = Array.prototype.slice.call(e.target.files);
    if (a.length > 0) {
      setFiles(a);
      confirmModalRef.current.showModal();
    }
    e.target.value = null;
    e.target.files = null;
  };

  const fetchCovs = () => {
    let params = { user: user.numero_empleado };
    API.get("/covs", { params, headers: { Authorization: token } })
      .then((reponse) => {
        let { status, data } = reponse;
        if (status == 200) {
          setCovs(data);
        } else {
          setCovs([]);
          setErrorTitle("Error al buscar los formatos de carga");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setCovs([]);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los formatos de carga"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los formatos de carga"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const saveCov = async () => {
    setLoadingCov(true);
    let item = files[0];
    let body = {
      file: await getBase64(item)
    };
    API.post(`/objectives/shopping/current`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setLoadingCov(false);
        if (result.status == 200) {
          fetchCovs();
          confirmModalRef.current.hideModal();
          setSuccessMessage("Resultados cargados");
          setSuccessTitle("Guardado con éxito");
          notificationRefSuccess.current.showNotification();
          navigate("/t3b/home", { replace: true })
        } else {
          setError("Error al cargar los objetivos de venta");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingCov(false);
        confirmModalRef.current.hideModal();
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al cargar los objetivos de venta"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al cargar los objetivos de venta"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const authorizeCov = async () => {
    setLoadingCov(true);
    let item = files[0];
    let params = {
      user: positionToUpload[0],
      puesto: positionToUpload[1],
      nivel: positionToUpload[2],
      tipo: positionToUpload[3],
    };
    API.get(`/covs/authorize`, {
      headers: { Authorization: token },
      params,
    })
      .then((result) => {
        setLoadingCov(false);
        if (result.status == 200) {
          fetchCovs();
          confirmAuthorizeModalRef.current.hideModal();
        } else {
          setError("Error al autorizar los objetivos de venta");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingCov(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al autorizar los objetivos de venta"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al autorizar los objetivos de venta"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const selectCov = (numero_empleado, puesto, nivel, slug) => {
    setPositionToUpload([numero_empleado, puesto, nivel, slug]);
    fileInputRef.current.click();
  };

  const selectAuthorizeCov = (numero_empleado, puesto, nivel, slug) => {
    setPositionToUpload([numero_empleado, puesto, nivel, slug]);
    confirmAuthorizeModalRef.current.showModal();
  };

  const downloadShoppingFile = () => {
    let params = {
      numero_empleado: user.numero_empleado,
      calificar: "SI",
      tipo: 1
    }
    setLoadingFile(true);
    API.get('/objectives/shopping/current',{ headers: { Authorization: token } ,params, responseType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    .then((response) => {
      setLoadingFile(false);
        const link = document.createElement('a');
        const blob = base64StringToBlob(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `ObjetivosAreaActuales.xlsx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    .catch((error) => {
      setLoadingFile(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(logout("Inicie sesión nuevamente"));
        } else {
          setError(error.response.data);
        }
      } else if (error.request) {
        setError(
          "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los objetivos del área"
        );
      } else {
        setError(
          "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los objetivos del área"
        );
      }
      notificationRef.current.showNotification();
    })
  }

  return (
    <div className="min-h-full">
      <main className="py-8 px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-3xl lg:max-w-7xl">
          <nav className="flex" aria-label="Breadcrumb">
            <ol className="flex items-center space-x-4">
              <li>
                <div>
                  <Link
                    to="/t3b/home"
                    className="text-gray-400 hover:text-gray-500"
                  >
                    <HomeIcon
                      className="h-5 w-5 flex-shrink-0"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Home</span>
                  </Link>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <ChevronRightIcon
                    className="h-5 w-5 flex-shrink-0 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-4 text-sm font-medium text-gray-700">
                    Carga de resultados de objetivos del área
                  </span>
                </div>
              </li>
            </ol>
          </nav>
          <div className="mt-6">
            <div>
              <h3 className="text-2xl sm:text-3xl font-medium leading-6 text-gray-900">
                Carga de resultados de objetivos del área
              </h3>
              <p className="mt-2 text-sm text-gray-500">
                Si tienes dudas de cómo ejecutar la carga de resultados de tus objetivos del área <span onClick={e => setShowInstructions(true)} className="text-indigo-500 hover:text-indigo-700 underline cursor-pointer">lee el instructivo</span>
              </p>
            </div>
          </div>
          {showInstructions && <div className="pt-4 sm:pt-10">
            <div className="">
              <div className="mx-auto px-6 lg:px-8">
                <div className="mx-auto grid grid-cols-1 gap-8 lg:grid-cols-3">
                  {tiers.map((tier) => (
                    <div
                      key={tier.id}
                      className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                    >
                      <div>
                        <div className="flex items-baseline gap-x-2">
                          <span className="text-3xl font-bold tracking-tight text-gray-900 h-14 w-14 flex justify-center items-center border-2 rounded-full">
                            {tier.stepNumber}
                          </span>
                        </div>
                        <p className="mt-6 text-base leading-7 text-gray-600">
                          {tier.description}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>}
          <div className="pt-12">
            <div className="">
              <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="mx-auto grid max-w-md grid-cols-1 gap-8 lg:max-w-4xl lg:grid-cols-2">
                  <div
                    className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                  >
                    <div className="flex items-baseline gap-x-2">
                      <span className="text-2xl font-bold tracking-tight text-gray-900">Descarga tus objetivos</span>
                    </div>
                    <button onClick={downloadShoppingFile}  className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                      {loadingFile ? 'Descargando archivo' : 'Descargar objetivos'}
                    </button>
                  </div>
                  <div
                    className="flex flex-col justify-between rounded-3xl bg-white p-8 shadow-xl ring-1 ring-gray-900/10 sm:p-10"
                  >
                    <div className="flex items-baseline gap-x-2">
                      <span className="text-2xl font-bold tracking-tight text-gray-900">Carga tus resultados</span>
                    </div>
                    <button onClick={(evt) => fileInputRef.current.click()} className="mt-8 block rounded-md bg-indigo-600 px-3.5 py-2 text-center text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">
                    Cargar Resultados
                    </button>
                    <input
                        id="file-upload"
                        name="file-upload"
                        type="file"
                        multiple={false}
                        ref={fileInputRef}
                        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        className="sr-only"
                        onChange={onAddFiles}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="mt-6">
              <div className="bg-red-100 flex items-end px-6 flex-wrap sm:flex-wrap md:flex-nowrap">
                <div className="py-6 flex-1 min-w-full sm:min-w-full md:min-w-0">
                  <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                    <span className="block">¿Cómo capturo los objetivos de venta?</span>
                  </h2>
                  <p className="mt-4 leading-6 text-gray-900">
                    Revisa las guías para conocer como capturar correctamente los objetivos de venta de tus colaboradores.
                  </p>
                  <a
                    href="#"
                    className="mt-6 inline-flex items-center rounded-md border border-transparent bg-red-600 px-5 py-2 text-base font-medium text-white shadow hover:bg-red-800"
                  >
                    Revisar guías
                  </a>
                </div>
                <div>
                  <img
                    className="object-cover h-auto w-64 sm:w-64 md:w-96"
                    src={computers}
                    alt="covs"
                  />
                </div>
              </div>
            </div> */}
          <ul className="mt-6 grid grid-cols-2 gap-6">
            {covs.map((cov, index) => (
              <li
                className="col-span-2 sm:col-span-1 divide-y divide-gray-200 rounded-lg bg-white shadow"
                key={`key-cov-${index}`}
              >
                <div className="w-full space-y-6 p-6">
                  <div className="w-full">
                    <p className="text-3xl text-gray-900 font-bold mb-3">
                      {cov.nombre} {cov.slug}
                    </p>
                    <ul className="mt-4 list-disc m-4">
                      {cov.evaluaciones
                        .filter((e) => !e.cov_cargados)
                        .map((assessment, assessmentIndex) => (
                          <li
                            className="my-1 text-sm font-medium"
                            key={`key-cov-evaluacion-${assessment.evaluacion_id}`}
                          >
                            {assessment.total} evaluaciones por cargar
                          </li>
                        ))}
                      {cov.evaluaciones
                        .filter(
                          (e) =>
                            e.cov_cargados && e.estatus_cov_id < cov.autorizar
                        )
                        .map((assessment, assessmentIndex) => (
                          <li
                            className="my-1 text-sm font-medium"
                            key={`key-cov-evaluacion-${assessment.evaluacion_id}`}
                          >
                            {assessment.total} evaluaciones por autorizar
                          </li>
                        ))}
                      {cov.evaluaciones
                        .filter(
                          (e) =>
                            e.cov_cargados && e.estatus_cov_id >= cov.autorizar
                        )
                        .map((assessment, assessmentIndex) => (
                          <li
                            className="my-1 text-sm font-medium"
                            key={`key-cov-evaluacion-${assessment.evaluacion_id}`}
                          >
                            {assessment.total} evaluaciones autorizados
                          </li>
                        ))}
                    </ul>
                    {cov.puesto == 75 && cov.autorizar == cov.mine ? (
                      <>
                        <button
                          onClick={(e) =>
                            downloadCovFile(
                              user.numero_empleado,
                              cov.puesto,
                              cov.nivel,
                              cov.nombre + " " + cov.slug
                            )
                          }
                          disabled={loadingFile}
                          className="font-medium text-red-600 hover:text-red-800 flex items-center hover:underline"
                        >
                          <PaperClipIcon className="h-5 w-5 mr-1" />
                          {loadingFile &&
                          loadingFileEmployee == user.numero_empleado &&
                          loadingFilePosition == cov.puesto
                            ? "Descargando"
                            : "Descargar"}{" "}
                          archivo completo
                          {loadingFile &&
                            loadingFileEmployee == user.numero_empleado &&
                            loadingFilePosition == cov.puesto && (
                              <LoaderSpinner />
                            )}
                        </button>
                        <div className="mt-6 grid grid-cols-2 gap-6">
                          <label htmlFor="file-upload">
                            <button
                              onClick={(e) =>
                                selectCov(
                                  user.numero_empleado,
                                  cov.puesto,
                                  cov.nivel,
                                  cov.slug
                                )
                              }
                              className="w-full col-span-2 sm:col-span-1 rounded-md border border-transparent bg-green-100 px-4 py-2 text-base font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                              Cargar completo
                            </button>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              multiple={false}
                              ref={fileInputRef}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              className="sr-only"
                              onChange={onAddFiles}
                            />
                          </label>
                          {cov.mine == cov.autorizar && (
                            <button
                              onClick={(e) =>
                                selectAuthorizeCov(
                                  user.numero_empleado,
                                  cov.puesto,
                                  cov.mine,
                                  cov.slug
                                )
                              }
                              className="col-span-2 sm:col-span-1 rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Autorizar completo
                            </button>
                          )}
                        </div>
                        {employees.map((e) => (
                          <div className="mt-6 flex space-x-2 justify-between flex-wrap">
                            <div className="flex items-center space-x-2">
                              <img
                                className="h-8 w-8 rounded-full object-cover"
                                src={
                                  e.imagen_perfil
                                    ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${e.imagen_perfil}`
                                    : face
                                }
                                alt=""
                              />
                              <p className="truncate text-sm font-medium text-gray-900">
                                {e.nombre_completo}
                              </p>
                            </div>
                            <div className="flex items-center justify-center space-x-2">
                              <div className="">
                                <button
                                  onClick={(evt) =>
                                    downloadCovFile(
                                      e.numero_empleado,
                                      cov.puesto,
                                      1,
                                      cov.nombre + " " + cov.slug
                                    )
                                  }
                                  disabled={loadingFile}
                                  className="w-full rounded-md border border-transparent bg-yellow-100 px-4 py-2 text-xs font-medium text-yellow-700 hover:bg-yellow-200 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2 flex items-center space-x-2"
                                >
                                  {loadingFile &&
                                  loadingFileEmployee == e.numero_empleado &&
                                  loadingFilePosition == cov.puesto
                                    ? "Descargando"
                                    : "Descargar"}
                                  {loadingFile &&
                                    loadingFileEmployee == e.numero_empleado &&
                                    loadingFilePosition == cov.puesto && (
                                      <LoaderSpinnerGD />
                                    )}
                                </button>
                              </div>
                              <div className="">
                                <label htmlFor="file-upload">
                                  <button
                                    onClick={(evt) =>
                                      selectCov(
                                        user.numero_empleado,
                                        cov.puesto,
                                        cov.nivel,
                                        cov.slug
                                      )
                                    }
                                    className="w-full rounded-md border border-transparent bg-green-100 px-4 py-2 text-xs font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                                  >
                                    Cargar
                                  </button>
                                  <input
                                    id="file-upload"
                                    name="file-upload"
                                    type="file"
                                    multiple={false}
                                    ref={fileInputRef}
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                    className="sr-only"
                                    onChange={onAddFiles}
                                  />
                                </label>
                              </div>
                              <div className="">
                                {cov.mine == cov.autorizar && (
                                  <button
                                    onClick={(evt) =>
                                      selectAuthorizeCov(
                                        user.numero_empleado,
                                        cov.puesto,
                                        cov.mine,
                                        e.numero_empleado
                                      )
                                    }
                                    className="w-full rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                  >
                                    Autorizar
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    ) : (
                      <>
                        <button
                          onClick={(e) =>
                            downloadCovFile(
                              user.numero_empleado,
                              cov.puesto,
                              cov.nivel,
                              cov.nombre + " " + cov.slug
                            )
                          }
                          disabled={loadingFile}
                          className="font-medium text-red-600 hover:text-red-800 flex items-center hover:underline"
                        >
                          <PaperClipIcon className="h-5 w-5 mr-1" />
                          {loadingFile &&
                          loadingFileEmployee == user.numero_empleado &&
                          loadingFilePosition == cov.puesto
                            ? "Descargando"
                            : "Descargar"}{" "}
                          archivo
                          {loadingFile &&
                            loadingFileEmployee == user.numero_empleado &&
                            loadingFilePosition == cov.puesto && (
                              <LoaderSpinner />
                            )}
                        </button>
                        <div className="mt-6 grid grid-cols-2 gap-6">
                          <label htmlFor="file-upload">
                            <button
                              onClick={(e) =>
                                selectCov(
                                  user.numero_empleado,
                                  cov.puesto,
                                  cov.nivel,
                                  cov.slug
                                )
                              }
                              className="w-full col-span-2 sm:col-span-1 rounded-md border border-transparent bg-green-100 px-4 py-2 text-base font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                            >
                              Cargar
                            </button>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              multiple={false}
                              ref={fileInputRef}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              className="sr-only"
                              onChange={onAddFiles}
                            />
                          </label>
                          {cov.mine >= cov.autorizar && (
                            <button
                              onClick={(e) =>
                                selectAuthorizeCov(
                                  user.numero_empleado,
                                  cov.puesto,
                                  cov.mine,
                                  cov.slug
                                )
                              }
                              className="col-span-2 sm:col-span-1 rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-base font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              Autorizar
                            </button>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="space-y-8 divide-y divide-gray-200 mt-4"></div>
        </div>
      </main>
      <CommonModalTemplate ref={confirmModalRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Cargar objetivos de venta
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de cargar el archivo de objetivos de venta? Una
                vez enviada no podrás editar los valores
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingCov}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={saveCov}
          >
            {loadingCov ? "Cargando" : "Cargar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => confirmModalRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={confirmAuthorizeModalRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Autorizar objetivos de venta
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de autorizar los objetivos de venta? Después de
                este paso los colaboradores serán capaces de responder sus
                formatos de evaluación y autoevaluación
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingCov}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={authorizeCov}
          >
            {loadingCov ? "Autorizando" : "Autorizar"}
          </button>
          <button
            type="button"
            disabled={loadingCov}
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => confirmAuthorizeModalRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
      <Notification
        title={successTitle}
        message={successMessage}
        type="success"
        ref={notificationRefSuccess}
      />
    </div>
  );
}
