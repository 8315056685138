import {
  MagnifyingGlassIcon,
  TrashIcon,
  UsersIcon,
} from "@heroicons/react/24/solid";
import { useEffect, useRef } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import InfinityLoader from "../../components/loaders/InfinityLoader";
import PlusFolder from "../../components/vectors/PlusFolder";
import {
  timeLapseOptions,
  timeOptionsAssessments,
} from "../../constants/profitcenters";
import API from "../../services/API";
import face from "./../../assets/img/faces/avatar.png";

export default function AddAssessments() {
  const { user, token } = useSelector((state) => state.authentication);
  const pathParams = useParams();
  const [assessment, setAssessment] = useState({});
  const [period, setPeriod] = useState({});
  const [typeUpload, setTypeUpload] = useState(1);
  const [timeType, setTimeType] = useState(1);
  const [timeLapse, setTimeLapse] = useState(1);
  const [timeFilter, setTimeFilter] = useState(3);
  const [loading, setLoading] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [collapseCorpo, setdivCorpo] = useState(false);
  const [collapseRegions, setdivRegions] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [areasCorpo, setAreasCorpo] = useState([]);
  const [areasRegions, setAreasRegions] = useState([]);
  const [regionsTypes, setRegionsTypes] = useState([]);
  const [positions, setPositions] = useState([]);
  const [areas, setAreas] = useState([]);
  const [positionsCorpo, setPositionsCorpo] = useState([]);
  const [positionsRegions, setPositionsRegions] = useState([]);
  const [collapseCorpoPositions, setdivCorpoPositions] = useState([]);
  const [collapseRegionsPositions, setdivRegionsPositions] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [checkedCorpo, setCheckedCorpo] = useState({});
  const [checkedRegions, setCheckedRegions] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [modal, setModal] = useState(false);
  const [foundUsers, setFoundUsers] = useState([]);
  const [error, setError] = useState("Ocurrió un error");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();
  const navigate = useNavigate();

  const fetchPositions = useCallback(() => {
    API.get("/positions", {
      headers: { Authorization: token },
    })
      .then((result) => {
        const { data, status } = result;
        if(status==200){
          setPositions(data);
        } else {
          setErrorTitle("Error");
          setError("Ocurrió un error inesperado en el sistema");
          return notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setErrorTitle("Error de red");
          setError("Revisa tu conexión a internet o que el servidor se encuentre en línea");
          return notificationRef.current.showNotification();
        }
        if (error.response) {
          if (error.response.status == 401) {
            setErrorTitle("Sesión caducada");
            setError("Debes iniciar sesión nuevamente");
            notificationRef.current.showNotification();
            return navigate("/");
          } else if (error.response.status == 403) {
            setErrorTitle("No autorizado");
            setError("No estás autorizado para ver esta página");
            notificationRef.current.showNotification();
            return navigate("/");
          } else {
            setErrorTitle("Error en el servidor");
            setError(error.response?.data?.message);
            return notificationRef.current.showNotification();
          }
        }
        if (error.request) {
          setErrorTitle("Error en la petición");
          setError("La petición enviada por tu dispositivo marcó un error");
          return notificationRef.current.showNotification();
        }
        setErrorTitle("Error");
        setError("Ocurrió un error inesperado en el sistema");
        return notificationRef.current.showNotification();
      });
  }, []);

  const fetchAreas = useCallback(() => {
    API.get("/areas", {
      headers: { Authorization: token },
    })
      .then((result) => {
        const { data, status } = result;
        if(status==200){
          setAreas(data);
        } else {
          setErrorTitle("Error");
          setError("Ocurrió un error inesperado en el sistema");
          return notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setErrorTitle("Error de red");
          setError("Revisa tu conexión a internet o que el servidor se encuentre en línea");
          return notificationRef.current.showNotification();
        }
        if (error.response) {
          if (error.response.status == 401) {
            setErrorTitle("Sesión caducada");
            setError("Debes iniciar sesión nuevamente");
            notificationRef.current.showNotification();
            return navigate("/");
          } else if (error.response.status == 403) {
            setErrorTitle("No autorizado");
            setError("No estás autorizado para ver esta página");
            notificationRef.current.showNotification();
            return navigate("/");
          } else {
            setErrorTitle("Error en el servidor");
            setError(error.response?.data?.message);
            return notificationRef.current.showNotification();
          }
        }
        if (error.request) {
          setErrorTitle("Error en la petición");
          setError("La petición enviada por tu dispositivo marcó un error");
          return notificationRef.current.showNotification();
        }
        setErrorTitle("Error");
        setError("Ocurrió un error inesperado en el sistema");
        return notificationRef.current.showNotification();
      });
  }, []);

  const fetchRegionsTypes = useCallback(() => {
    API.get("/regions/types", {
      headers: { Authorization: token },
    })
      .then((result) => {
        const { data, status } = result;
        if(status==200){
          setRegionsTypes(data);
        } else {
          setErrorTitle("Error");
          setError("Ocurrió un error inesperado en el sistema");
          return notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        if (error.code === "ERR_NETWORK") {
          setErrorTitle("Error de red");
          setError("Revisa tu conexión a internet o que el servidor se encuentre en línea");
          return notificationRef.current.showNotification();
        }
        if (error.response) {
          if (error.response.status == 401) {
            setErrorTitle("Sesión caducada");
            setError("Debes iniciar sesión nuevamente");
            notificationRef.current.showNotification();
            return navigate("/");
          } else if (error.response.status == 403) {
            setErrorTitle("No autorizado");
            setError("No estás autorizado para ver esta página");
            notificationRef.current.showNotification();
            return navigate("/");
          } else {
            setErrorTitle("Error en el servidor");
            setError(error.response?.data?.message);
            return notificationRef.current.showNotification();
          }
        }
        if (error.request) {
          setErrorTitle("Error en la petición");
          setError("La petición enviada por tu dispositivo marcó un error");
          return notificationRef.current.showNotification();
        }
        setErrorTitle("Error");
        setError("Ocurrió un error inesperado en el sistema");
        return notificationRef.current.showNotification();
      });
  }, []);

  useEffect(() => {
    fetchRegionsTypes();
    fetchAreas();
    fetchPositions();
  }, []);

  useEffect(() => {
    const fetchPeriod = () => {
      API.get("/periods", {
        params: { periodo_id: pathParams.periodId },
        headers: { Authorization: token },
      })
        .then(({ data, status }) => {
          if (status === 200) {
            setPeriod(data);
          }
        })
        .catch((error) => {
          setPeriod({});
          setErrorTitle("Error al buscar");
          setError("Ocurrió un error al buscar los periodos");
          return notificationRef.current.showNotification();
        });
    };
    fetchPeriod();
    fetchAssessment();
  }, []);

  const fetchAssessment = useCallback(() => {
    const params = { evaluacion_id: pathParams.assessmentId };
    const options = {
      params,
      headers: { Authorization: token },
    };
    API.get(`/periods/${pathParams.periodId}/assessments`, options)
      .then(({ data, status }) => {
        if (status === 200) {
          setAssessment(data);
        }
      })
      .catch((error) => {
        setAssessment({});
      });
  }, [token, pathParams]);

  useEffect(() => {
    const options = { headers: { Authorization: token } };
    API.get("/regions/areas", options).then(({ data, status }) => {
      if (status === 200) {
        setAreasCorpo(
          data.corpo.map((f) => {
            f.checked = false;
            return f;
          })
        );
        setAreasRegions(
          data.regions.map((f) => {
            f.checked = false;
            return f;
          })
        );
      }
    });
    API.get("/regions/areas/positions", options).then(({ data, status }) => {
      if (status === 200) {
        setPositionsCorpo(
          data.corpo.map((f) => {
            f.checked = false;
            return f;
          })
        );
        setPositionsRegions(
          data.regions.map((f) => {
            f.checked = false;
            return f;
          })
        );
      }
    });
  }, []);

  const checkAllCorpo = () => {
    let check = true;
    for (let i = 0; i < areasCorpo.length; i++) {
      if (!checkedCorpo[areasCorpo[i].area_codigo]) {
        check = false;
      } else {
        let areaPositions = [...positionsCorpo].filter(
          (p) => p.area === areasCorpo[i].area_codigo
        );
        for (let j = 0; j < areaPositions.length; j++) {
          let puesto = areaPositions[j].puesto_id;
          if (!checkedCorpo[areasCorpo[i].area_codigo][puesto]) {
            check = false;
            break;
          }
        }
      }
      if (!check) break;
    }
    return check;
  };

  const checkAllRegion = (tipo_id) => {
    let check = true;
    let regionAreas = [];
    for (let i = 0; i < areas.length; i++) {
      let area = areas[i];
      if(area.tipo_region_id==tipo_id){
        regionAreas.push(area.area_id);
      }
    }
    for (let i = 0; i < positions.length; i++) {
      let position = positions[i];
      if (regionAreas.includes(position.area_id)) {
        if(!checkedItems.includes(position.puesto_id)){
          check = false;
          break;
        }
      }
    }
    return check;
  };

  const changeAllRegion = (e, tipo_id) => {
    let newChecked = [...checkedItems];
    let regionAreas = [];
    for (let i = 0; i < areas.length; i++) {
      let area = areas[i];
      if(area.tipo_region_id==tipo_id){
        regionAreas.push(area.area_id);
      }
    }
    if(e.target.checked){
      for (let i = 0; i < positions.length; i++) {
        let position = positions[i];
        if (regionAreas.includes(position.area_id)) {
          if(!newChecked.includes(position.puesto_id)){
            newChecked.push(position.puesto_id);
          }
        }
      }
    } else {
      for (let i = 0; i < positions.length; i++) {
        let position = positions[i];
        if (regionAreas.includes(position.area_id)) {
          const index = newChecked.indexOf(position.puesto_id);
          if (index > -1) {
            newChecked.splice(index, 1);
          }
        }
      }
    }
    setCheckedItems(newChecked);
  };

  const checkAllArea = (area_id) => {
    let check = true;
    for (let i = 0; i < positions.length; i++) {
      let position = positions[i];
      if (area_id == position.area_id) {
        if(!checkedItems.includes(position.puesto_id)){
          check = false;
          break;
        }
      }
    }
    return check;
  };

  const changeAllArea = (e, area_id) => {
    let newChecked = [...checkedItems];
    if(e.target.checked){
      for (let i = 0; i < positions.length; i++) {
        let position = positions[i];
        if (area_id == position.area_id) {
          if(!newChecked.includes(position.puesto_id)){
            newChecked.push(position.puesto_id);
          }
        }
      }
    } else {
      for (let i = 0; i < positions.length; i++) {
        let position = positions[i];
        if (area_id == position.area_id) {
          const index = newChecked.indexOf(position.puesto_id);
          if (index > -1) {
            newChecked.splice(index, 1);
          }
        }
      }
    }
    setCheckedItems(newChecked);
  };

  const changeAllCorpo = (e) => {
    let newChecked = { ...checkedCorpo };
    for (let i = 0; i < areasCorpo.length; i++) {
      if (!newChecked[areasCorpo[i].area_codigo]) {
        newChecked[areasCorpo[i].area_codigo] = {};
      }
      let areaPositions = [...positionsCorpo].filter(
        (p) => p.area === areasCorpo[i].area_codigo
      );
      for (let j = 0; j < areaPositions.length; j++) {
        let puesto = areaPositions[j].puesto_id;
        newChecked[areasCorpo[i].area_codigo][puesto] = e.target.checked;
      }
    }
    setCheckedCorpo(newChecked);
  };

  const checkAllCorpoArea = (area) => {
    let check = true;
    let areaPositions = [...positionsCorpo].filter((p) => p.area === area);
    for (let i = 0; i < areaPositions.length; i++) {
      let puesto = areaPositions[i].puesto_id;
      if (checkedCorpo[area]) {
        if (!checkedCorpo[area][puesto]) {
          check = false;
          break;
        }
      } else {
        check = false;
      }
    }
    return check;
  };

  const changeAllCorpoArea = (e, area) => {
    let newChecked = { ...checkedCorpo };
    let areaPositions = [...positionsCorpo].filter((p) => p.area === area);
    if (!newChecked[area]) {
      newChecked[area] = {};
    }
    for (let i = 0; i < areaPositions.length; i++) {
      newChecked[area][areaPositions[i].puesto_id] = e.target.checked;
    }
    setCheckedCorpo(newChecked);
  };

  const checkAllRegions = () => {
    let check = true;
    for (let i = 0; i < areasRegions.length; i++) {
      if (!checkedRegions[areasRegions[i].area_codigo]) {
        check = false;
      } else {
        let areaPositions = [...positionsRegions].filter(
          (p) => p.area === areasRegions[i].area_codigo
        );
        for (let j = 0; j < areaPositions.length; j++) {
          let puesto = areaPositions[j].puesto_id;
          if (!checkedRegions[areasRegions[i].area_codigo][puesto]) {
            check = false;
            break;
          }
        }
      }
      if (!check) break;
    }
    return check;
  };

  const changeAllRegions = (e) => {
    let newChecked = { ...checkedRegions };
    for (let i = 0; i < areasRegions.length; i++) {
      if (!newChecked[areasRegions[i].area_codigo]) {
        newChecked[areasRegions[i].area_codigo] = {};
      }
      let areaPositions = [...positionsRegions].filter(
        (p) => p.area === areasRegions[i].area_codigo
      );
      for (let j = 0; j < areaPositions.length; j++) {
        let puesto = areaPositions[j].puesto_id;
        newChecked[areasRegions[i].area_codigo][puesto] = e.target.checked;
      }
    }
    setCheckedRegions(newChecked);
  };

  const checkAllRegionsArea = (area) => {
    let check = true;
    let areaPositions = [...positionsRegions].filter((p) => p.area === area);
    for (let i = 0; i < areaPositions.length; i++) {
      let puesto = areaPositions[i].puesto_id;
      if (checkedRegions[area]) {
        if (!checkedRegions[area][puesto]) {
          check = false;
          break;
        }
      } else {
        check = false;
      }
    }
    return check;
  };

  const changeAllRegionsArea = (e, area) => {
    let newChecked = { ...checkedRegions };
    let areaPositions = [...positionsRegions].filter((p) => p.area === area);
    if (!newChecked[area]) {
      newChecked[area] = {};
    }
    for (let i = 0; i < areaPositions.length; i++) {
      newChecked[area][areaPositions[i].puesto_id] = e.target.checked;
    }
    setCheckedRegions(newChecked);
  };

  const selectUser = (user) => {
    setSearchTerm("");
    setFoundUsers([]);
    setSelectedUsers([...selectedUsers, { ...user }]);
  };

  const deleteUser = (index) => {
    let newUsers = [...selectedUsers];
    newUsers.splice(index, 1);
    setSelectedUsers(newUsers);
  };

  const toggledivCorpo = () => setdivCorpo(!collapseCorpo);
  const toggledivRegions = () => setdivRegions(!collapseRegions);

  const toggleCheckedItem = (puesto_id) => {
    let newChecked = [...checkedItems];
    const index = newChecked.indexOf(puesto_id);
    if (index > -1) {
      newChecked.splice(index, 1);
    } else {
      newChecked.push(puesto_id);
    }
    setCheckedItems(newChecked);
  };

  const toggleCheckedCorpo = (area, position) => {
    let newChecked = { ...checkedCorpo };
    if (area in newChecked) {
      if (position in newChecked[area]) {
        newChecked[area][position] = !newChecked[area][position];
      } else {
        newChecked[area][position] = true;
      }
    } else {
      newChecked[area] = {};
      newChecked[area][position] = true;
    }
    setCheckedCorpo(newChecked);
  };

  const toggleCheckedRegions = (area, position) => {
    let newChecked = { ...checkedRegions };
    if (area in newChecked) {
      if (position in newChecked[area]) {
        newChecked[area][position] = !newChecked[area][position];
      } else {
        newChecked[area][position] = true;
      }
    } else {
      newChecked[area] = {};
      newChecked[area][position] = true;
    }
    setCheckedRegions(newChecked);
  };

  const toggledivCorpoPosition = (area) => {
    let newdiv = [...collapseCorpoPositions];
    if (newdiv.includes(area)) {
      const index = newdiv.indexOf(area);
      if (index > -1) {
        newdiv.splice(index, 1);
      }
    } else {
      newdiv.push(area);
    }
    setdivCorpoPositions(newdiv);
  };

  const toggledivRegionsPosition = (area) => {
    let newdiv = [...collapseRegionsPositions];
    if (newdiv.includes(area)) {
      const index = newdiv.indexOf(area);
      if (index > -1) {
        newdiv.splice(index, 1);
      }
    } else {
      newdiv.push(area);
    }
    setdivRegionsPositions(newdiv);
  };

  const loadAssessments = () => {
    let arrCorpo = [];
    let arrRegions = [];
    Object.keys(checkedCorpo).forEach((keyArea) => {
      let area = areasCorpo.find((a) => a.area_codigo === keyArea);
      Object.keys(checkedCorpo[keyArea]).forEach((keyPosition) => {
        if (checkedCorpo[keyArea][keyPosition]) {
          let item = [
            Number(assessment.evaluacion_id), // evaluacion_id
            "CORPORATIVO", // tipo
            Number(area.area_id), // area
            Number(keyPosition), // puesto
            timeType, // condicion
            timeFilter, // duracion
            timeLapse, // unidad
          ];
          arrCorpo.push(item);
        }
      });
    });
    Object.keys(checkedRegions).forEach((keyArea) => {
      let area = areasRegions.find((a) => a.area_codigo === keyArea);
      Object.keys(checkedRegions[keyArea]).forEach((keyPosition) => {
        if (checkedRegions[keyArea][keyPosition]) {
          let item = [
            Number(assessment.evaluacion_id), // evaluacion_id
            "REGIONAL", // tipo
            Number(area.area_id), // area
            Number(keyPosition), // puesto
            timeType, // condicion
            timeFilter, // duracion
            timeLapse, // unidad
          ];
          arrRegions.push(item);
        }
      });
    });
    let positionsInsert = [];
    for (let i = 0; i < positions.length; i++) {
      let position = positions[i];
      if(checkedItems.includes(position.puesto_id)){
        let area = areas.find(a => a.area_id==position.area_id);
        let region = regionsTypes.find(r => r.tipo_id==area.tipo_region_id);
        let item = [
          Number(assessment.evaluacion_id), // evaluacion_id
          region.nombre, // tipo
          Number(area.area_id), // area
          Number(position.puesto_id), // puesto
          timeType, // condicion
          timeFilter, // duracion
          timeLapse, // unidad
        ];
        positionsInsert.push(item);
      }
    }
    let body = {
      items: positionsInsert
    };
    const options = { headers: { Authorization: token } };
    setLoading(true);
    API.post("/assessments/insert", body, options)
      .then((result) => {
        setLoading(false);
        if (result) {
          let { status } = result;
          if (status === 200) {
            navigate(-1);
          } else {
          }
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const loadAssessmentsUsers = () => {
    if (selectedUsers < 1) {
      // return toast.error("Selecciona al menos un usuario para continuar");
    }
    let users = selectedUsers.map((u) => u.numero_empleado);
    let body = {
      persons: true,
      assessment: assessment.evaluacion_id,
      estatus_cov_id: [9, 11, 12, 13].includes(assessment.formato_id) ? 3 : 1,
      users,
    };
    const options = { headers: { Authorization: token } };
    setLoading(true);
    API.post("/assessments/insert", body, options)
      .then((result) => {
        setLoading(false);
        if (result) {
          let { status } = result;
          if (status === 200) {
            navigate(-1);
          } else {
          }
        } else {
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const searchUsers = (e) => {
    e.preventDefault();
    if (searchTerm === "") {
      setError("Ingresa un texto para buscar un usuario");
      return notificationRef.current.showNotification();
    }
    const options = {
      headers: { Authorization: token },
      params: {
        search: searchTerm,
        active: true,
      },
    };
    setLoadingUsers(true);
    API.get("/usersearch", options)
      .then(({ data }) => {
        setLoadingUsers(false);
        setFoundUsers(data);
      })
      .catch((err) => {
        setFoundUsers([]);
        setLoadingUsers(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al buscar los usuarios"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al buscar los usuarios"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  return (
    <div className="flex-1 w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="flex flex-col">
              <div className="flex flex-col justify-start pb-2">
                <div className="sm:flex-auto">
                  <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                    Agregar evaluados
                  </h1>
                  <p className="mt-2 text-lg text-gray-700">
                    {assessment?.titulo} - {period.titulo}
                  </p>
                </div>
              </div>
            </div>
            <section className="flex justify-center my-4">
              <button
                className={`px-4 py-1 font-medium text-sm rounded-l border border-red-500  ${
                  typeUpload == 1
                    ? "bg-red-500 text-white"
                    : " bg-transparent text-red-500"
                }`}
                onClick={(e) => setTypeUpload(1)}
              >
                Por Puesto
              </button>
              <button
                className={`px-4 py-1 font-medium text-sm rounded-r border border-red-500  ${
                  typeUpload == 2
                    ? "bg-red-500 text-white"
                    : " bg-transparent text-red-500"
                }`}
                onClick={(e) => setTypeUpload(2)}
              >
                Por Persona
              </button>
            </section>
            <div className="w-full px-4 pt-8">
              <div className="mx-auto w-full rounded-2xl">
                {typeUpload === 1 && (
                  <div>
                    <div className="grid grid-cols-1 sm:grid-cols-6 gap-4">
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="city"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Condición Antigüedad
                        </label>
                        <div className="mt-1">
                          <select
                            id="time-type"
                            name="time-type"
                            type="select"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={timeType}
                            defaultValue={0}
                            onChange={(e) => setTimeType(e.target.value)}
                          >
                            <option value={0} disabled hidden>
                              Selecciona una opción
                            </option>
                            {timeOptionsAssessments.map((t) => (
                              <option
                                key={`time-type-option-${t.id}`}
                                value={t.id}
                              >
                                {t.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="region"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Duración Antigüedad
                        </label>
                        <div className="mt-1">
                          <input
                            id="time-filter"
                            name="time-filter"
                            placeholder="Antigüedad"
                            type="number"
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            value={timeFilter}
                            onChange={(e) => setTimeFilter(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="sm:col-span-2">
                        <label
                          htmlFor="postal-code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Unidad Antigüedad
                        </label>
                        <div className="mt-1">
                          <select
                            id="time-lapse"
                            name="time-lapse"
                            type="select"
                            value={timeLapse}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setTimeLapse(e.target.value)}
                          >
                            {timeLapseOptions.map((t) => (
                              <option
                                key={`time-lapse-option-${t.id}`}
                                value={t.id}
                              >
                                {t.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="mx-auto w-full mt-8">
                      {
                        regionsTypes.map(region => (
                          <Disclosure as="div" className="mb-2">
                            {({ open }) => (
                              <>
                                <div className="flex w-full justify-between rounded-lg bg-red-100 px-4 py-2 text-left text-sm font-medium focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                                  <div className="flex justify-start items-center">
                                    <input
                                      type="checkbox"
                                      className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                      checked={checkAllRegion(region.tipo_id)}
                                      onChange={e => changeAllRegion(e, region.tipo_id)}
                                    />
                                    <Disclosure.Button>
                                      <p
                                        className="hover:cursor-pointer text-red-900 hover:text-red-700"
                                      >
                                        {region.nombre}
                                      </p>
                                    </Disclosure.Button>
                                  </div>
                                  <ChevronUpIcon
                                    className={`${
                                      open ? "rotate-180 transform" : ""
                                    } h-5 w-5 text-red-500`}
                                  />
                                </div>
                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 space-y-2">
                                  {[...areas].filter(a => a.activo && a.tipo_region_id == region.tipo_id).map((area) => (
                                    <>
                                      <Disclosure>
                                        {({ open }) => (
                                          <>
                                            <div className="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                                              <div className="flex justify-start items-center">
                                                <input
                                                  type="checkbox"
                                                  className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                  checked={checkAllArea(
                                                    area.area_id
                                                  )}
                                                  onChange={(e) =>
                                                    changeAllArea(
                                                      e,
                                                      area.area_id
                                                    )
                                                  }
                                                />
                                                <Disclosure.Button>
                                                  <p
                                                    className="hover:cursor-pointer text-purple-900 hover:text-purple-700"
                                                  >
                                                    {area.area_nombre}
                                                  </p>
                                                </Disclosure.Button>
                                              </div>
                                              <ChevronUpIcon
                                                className={`${
                                                  open ? "rotate-180 transform" : ""
                                                } h-5 w-5 text-purple-500`}
                                              />
                                            </div>
                                            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500 space-y-1 grid grid-cols-1 sm:grid-cols-2 rounded-xl bg-white">
                                              {[...positions]
                                                .filter(
                                                  (p) => p.area_id === area.area_id && p.activo
                                                )
                                                .map((position) => (
                                                  <div
                                                    className=""
                                                    key={`div-corpo-position-${position.puesto_id}`}
                                                  >
                                                    <div
                                                      check
                                                      className="mb-2 me-sm-2 mb-sm-0"
                                                    >
                                                      <input
                                                        type="checkbox"
                                                        className="h-4 w-4 mr-2 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                        checked={checkedItems.includes(position.puesto_id)}
                                                        onChange={(e) =>
                                                          toggleCheckedItem(position.puesto_id)
                                                        }
                                                      />
                                                      <label check>
                                                        {position.puesto_nombre}
                                                      </label>
                                                    </div>
                                                  </div>
                                                ))}
                                            </Disclosure.Panel>
                                          </>
                                        )}
                                      </Disclosure>
                                    </>
                                  ))}
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))
                      }
                    </div>
                    <div className="flex justify-end">
                      <button
                        className="bg-green-200 text-green-700 hover:bg-green-300 rounded-lg py-2 px-3 mt-8"
                        onClick={loadAssessments}
                        disabled={loading}
                      >
                        Cargar Evaluaciones
                      </button>
                    </div>
                  </div>
                )}
                {typeUpload === 2 && (
                  <div>
                    <div className="mb-10">
                      <div className="flex justify-between items-center mb-4">
                        <h1 className="text-xl font-bold tracking-tight text-gray-900">
                          Usuarios seleccionados
                        </h1>
                        {selectedUsers.length > 0 && (
                          <button
                            className="bg-green-200 text-green-700 hover:bg-green-300 rounded-lg py-2 px-3"
                            onClick={loadAssessmentsUsers}
                            disabled={loading}
                          >
                            Cargar Evaluaciones
                          </button>
                        )}
                      </div>
                      {selectedUsers.length < 1 && (
                        <p className="text-red-700">
                          No hay usuarios seleccionados
                        </p>
                      )}
                      <ul className="divide-y">
                        {selectedUsers.map((item, index) => (
                          <li
                            key={`selected-user-${item.numero_empleado}`}
                            className="py-1 flex justify-between items-center"
                          >
                            <div>
                              <p className="text-sm text-gray-900">
                                {item.nombre_completo}
                              </p>
                              <p className="text-sm text-gray-500">
                                {item.puesto}
                              </p>
                            </div>
                            <button
                              className="rounded bg-red-200 text-red-500 py-1 px-3 flex items-center hover:bg-red-300"
                              onClick={(e) => deleteUser(index)}
                            >
                              <TrashIcon className="h-5 w-5 mr-1" />
                              Eliminar
                            </button>
                          </li>
                        ))}
                      </ul>
                    </div>
                    <div>
                      <form onSubmit={searchUsers}>
                        <label
                          htmlFor="usersearch"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Buscar usuarios
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm mb-4">
                          <div className="relative flex flex-grow items-stretch focus-within:z-10">
                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                              <UsersIcon
                                className="h-5 w-5 text-gray-400"
                                aria-hidden="true"
                              />
                            </div>
                            <input
                              type="text"
                              name="usersearch"
                              id="usersearch"
                              required
                              value={searchTerm}
                              onChange={(e) => setSearchTerm(e.target.value)}
                              className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                              placeholder="Número de empleado, nombre o apellidos"
                            />
                          </div>
                          <button
                            type="submit"
                            onClick={searchUsers}
                            className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                          >
                            <MagnifyingGlassIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                            <span>Buscar</span>
                          </button>
                        </div>
                      </form>
                    </div>
                    <div className="mt-8 flex flex-col">
                      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle">
                          {loadingUsers ? (
                            <>
                              <InfinityLoader />
                            </>
                          ) : (
                            <>
                              <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                                <table className="min-w-full divide-y divide-gray-300">
                                  <thead className="bg-gray-50">
                                    <tr>
                                      <th
                                        scope="col"
                                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                                      >
                                        Nombre
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Posición
                                      </th>
                                      <th
                                        scope="col"
                                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                                      >
                                        Región
                                      </th>
                                      <th
                                        scope="col"
                                        className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                                      >
                                        <span className="sr-only">Edit</span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody className="divide-y divide-gray-200 bg-white">
                                    {foundUsers.map((person) => (
                                      <tr
                                        key={`key-row-employee-${person.numero_empleado}`}
                                      >
                                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                          <div className="flex items-center">
                                            <div className="h-10 w-10 flex-shrink-0">
                                              <img
                                                className="h-10 w-10 rounded-full object-cover"
                                                src={
                                                  person?.imagen_perfil
                                                    ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${person?.imagen_perfil}`
                                                    : face
                                                }
                                                alt=""
                                              />
                                            </div>
                                            <div className="ml-4">
                                              <div className="font-medium text-gray-900">
                                                {person.nombre_completo}
                                              </div>
                                              <div className="text-gray-500">
                                                {person.email}
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          <div className="text-gray-900">
                                            {person.puesto}
                                          </div>
                                          <div className="text-gray-500">
                                            {person.area}
                                          </div>
                                        </td>
                                        <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                          {person.region}
                                        </td>
                                        <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                          <button
                                            onClick={(e) => selectUser(person)}
                                            className="text-red-600 hover:text-red-900"
                                          >
                                            Seleccionar
                                            <span className="sr-only">
                                              , {person.name}
                                            </span>
                                          </button>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                              {foundUsers.length < 1 && (
                                <div className="text-center mt-3">
                                  <PlusFolder />
                                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                                    No hay usuarios con los filtros
                                    seleccionados
                                  </h3>
                                  <p className="mt-1 text-sm text-gray-500">
                                    Utiliza el campo de búsqueda para buscar
                                    usuarios.
                                  </p>
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
