import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import './index.css';
import Login from "./routes/Login";
import HomeLayout from "./routes/HomeLayout";
import AdminLayout from "./routes/AdminLayout";
import CommonHome from "./routes/Common/Home";
import RegisterProgress from "./routes/Common/RegisterProgress";
import Users from "./routes/Admin/Users";
import ProfitCenters from "./routes/Admin/ProfitCenters";
import Changes from "./routes/Admin/Changes";
import AnswerAssessment from "./routes/Common/AnswerAssessment";
import Profile from "./routes/Common/Profile";
import Print from "./routes/Common/Print";
import History from "./routes/Common/History";
import Results from "./routes/Common/Results";
import EmployeeProfile from "./routes/Common/EmployeeProfile";
import UploadCovs from "./routes/Common/UploadCovs";
import CompleteObjective from "./routes/Common/CompleteObjective";
import ValidateObjective from "./routes/Common/ValidateObjective";
import Feedback from "./routes/Common/Feedback";
import UpdateFeedback from "./routes/Common/UpdateFeedback";
import ConfirmFeedback from "./routes/Common/ConfirmFeedback";
import Periods from "./routes/Admin/Periods";
import PeriodFormats from "./routes/Admin/PeriodFormats";
import Objectives from "./routes/Admin/Objectives";
import Assessments from "./routes/Admin/Assessments";
import EmployeeObjectives from "./routes/Admin/EmployeeObjectives";
import Reports from "./routes/Admin/Reports";
import Formats from "./routes/Admin/Formats";
import EditFormat from "./routes/Admin/EditFormat";
import AddAssessments from "./routes/Admin/AddAssessments";
import Organization from "./routes/Admin/Organization";
import TokenLogin from "./routes/Common/TokenLogin";
import Progress from "./routes/Admin/Progress";
import ReopenObjective from "./routes/Common/ReopenObjective";
import { useDispatch, useSelector } from "react-redux";
import { logout, refreshUser } from "./redux/reducers/auth";
import { useState } from "react";
import UploadShoppingAuto from "./routes/Common/UploadShoppingAuto";
import UploadShoppingEva from "./routes/Common/UploadShoppingEva";

const useConstructor = (callBack = () => {}) => {
  const [hasBeenCalled, setHasBeenCalled] = useState(false);
  if (hasBeenCalled) return;
  callBack();
  setHasBeenCalled(true);
}

export default function App() {
  const dispatch = useDispatch();
  const { user, token } = useSelector(state => state.authentication);

  useConstructor(async () => {
    if(user){
      dispatch(refreshUser({token}))
    }
  });

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/token-login" element={<TokenLogin />} />
        <Route path="/t3b" element={<HomeLayout />} >
          <Route path="home" element={<CommonHome />} />
          <Route path="results" element={<Results />} />
          <Route path="profile" element={<Profile />} />
          <Route path="history" element={<History />} />
          <Route path="covs" element={<UploadCovs />} />
          <Route path="objetivos-compras-auto" element={<UploadShoppingAuto />} />
          <Route path="objetivos-compras-eva" element={<UploadShoppingEva />} />
          <Route path="employees">
            <Route path=":employeeId">
              <Route index element={<EmployeeProfile />} />
            </Route>
            </Route>
          <Route path="assessments">
            <Route path=":assessmentId">
              <Route path="answer" element={<AnswerAssessment />} />
              <Route path="feedback" element={<Feedback />} />
              <Route path="update_feedback" element={<UpdateFeedback />} />
              <Route path="confirm_feedback" element={<ConfirmFeedback />} />
              <Route path="print/:printType" element={<Print />} />
            </Route>
          </Route>
          <Route path="objectives">
            <Route path=":objectiveId">
              <Route path="progress" element={<RegisterProgress />} />
              <Route path="complete" element={<CompleteObjective />} />
              <Route path="reopen" element={<ReopenObjective />} />
              <Route path="Validate" element={<ValidateObjective />} />
            </Route>
          </Route>
          <Route path="admin/forms/:format" element={<EditFormat />} />
          <Route path="admin/users/:employeeId" element={<Organization />} />
          <Route path="admin" element={<AdminLayout />} >
            <Route path="users" element={<Users />} />
            <Route path="profitcenters" element={<ProfitCenters />} />
            <Route path="pfchanges">
              <Route index element={<Changes />} />
              <Route path=":year/:month" element={<Users />} />
            </Route>
            <Route path="covs" element={<Users />} />
            <Route path="forms" element={<Formats />} />
            <Route path="reports" element={<Reports />} />
            <Route path="periods">
              <Route index element={<Periods />} />
              <Route path=":periodId">
                <Route index element={<PeriodFormats />} />
                <Route path="assessments">
                  <Route path=":assessmentId">
                    <Route index element={<Assessments />} />
                    <Route path="add" element={<AddAssessments />} />
                  </Route>
                </Route>
              </Route>
            </Route>
            <Route path="progress" element={<Progress />} />
            <Route path="objectives">
              <Route index element={<Objectives />} />
              <Route path=":employeeId" element={<EmployeeObjectives />} />
            </Route>
          </Route>
        </Route>
        <Route
          path="*"
          element={<Navigate to="/t3b/home" replace />}
        />
      </Routes>
    </BrowserRouter>
  );
}