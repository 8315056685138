import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import { Link } from "react-router-dom";
import { useState } from "react";
import { useRef } from "react";
import CommonModalTemplate from "../modals/CommonModalTemplate";
import { useSelector } from "react-redux";
import API from "../../services/API";
import Notification from "../notifications/Notification";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AdminAssessmentAction({
  id,
  status,
  evaluacion,
  autoevaluacion,
  deleteCallback,
  endCallback,
  employeeId,
  closeCallback,
}) {
  const [loadingAction, setLoadingAction] = useState(false);
  const deleteModalRef = useRef();
  const notificacionRef = useRef();
  const {token,user} = useSelector(state => state.authentication);
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notificationMessage, setNotificationMessage] = useState("Error");
  const [notificationType, setNotificationType] = useState("danger");

  const closeAssessment = () => {
    let body = {
      estatus_evaluacion_id: 4,
      is_active: true,
    };
    const options = {headers: {Authorization: token}}
    API.patch(`/assessments/${id}`, body, options)
      .then(({ status, data }) => {
        if (status === 200) {
          setLoadingAction(true);
          closeCallback(id);
        }
      })
      .catch((error) => {

      });
  };

  const endAssessment = (type) => {
    let body = {
      estatus_evaluacion_id: status,
    };
    if (type === 1) {
      body.autoevaluacion_terminada = true;
    } else {
      body.evaluacion_terminada = true;
    }
    const options = {headers: {Authorization: token}}
    API.patch(`/assessments/${id}`, body, options)
      .then(({ status, data }) => {
        if (status === 200) {
          setLoadingAction(true);
          endCallback(id, type, true);
        }
      })
      .catch((error) => {

      });
  };

  const reopenAssessment = (type) => {
    let body = {
      estatus_evaluacion_id: 2,
    };
    if (type === 1) {
      body.autoevaluacion_terminada = false;
      body.estatus_cierre_id = 1;
      body.revision_terminada = false;
    } else {
      body.evaluacion_terminada = false;
      body.revision_terminada = false;
      body.revision_agendada = false;
      body.estatus_cierre_id = 1;

    }
    const options = {headers: {Authorization: token}}
    API.patch(`/assessments/${id}`, body, options)
      .then(({ status, data }) => {
        if (status === 200) {
          setLoadingAction(true);
          endCallback(id, type, false);
        }
      })
      .catch((error) => {

      });
  };

  const reopenObjectives = (type) => {
    let body = {
      numero_empleado: employeeId,
    };
    const options = {headers: {Authorization: token}}
    API.post(`/objectives/reset`, body, options)
      .then(({ status, data }) => {
        if (status === 200) {
          setNotificationType("success");
          setNotificationTitle("Objetivos reseteados");
          setNotificationMessage("Los objetivos del colaborador se han reseteado");
          notificacionRef.current.showNotification();
        }
      })
      .catch((error) => {
        setNotificationType("danger");
        setNotificationTitle("Error");
        setNotificationMessage("Los objetivos del colaborador no se han podido resetear");
        notificacionRef.current.showNotification();
      });
  };

  const deleteAssessment = (assessment) => {
    const body = {
      is_active: false,
      estatus_evaluacion_id: status,
    };
    setLoadingAction(true);
    const options = {headers: {Authorization: token}}
    API.patch(`/assessments/${id}`, body, options)
      .then(({ status, data }) => {
        if (status === 200) {
          setLoadingAction(false);
          deleteCallback(id);
        }
      })
      .catch((error) => {
      });
  };

  let superAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
          Acciones
          <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-30 mt-2 w-56 origin-top-right divide-y divide-gray-200 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="">
            {superAdministrador && <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/t3b/assessments/${id}/print/1`}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Imprimir autoevaluación
                </Link>
              )}
            </Menu.Item>}
            {superAdministrador && <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/t3b/assessments/${id}/print/2`}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Imprimir evaluación
                </Link>
              )}
            </Menu.Item>}
            {(status == 4 || superAdministrador) && <Menu.Item>
              {({ active }) => (
                <Link
                  to={`/t3b/assessments/${id}/print/3`}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Imprimir global
                </Link>
              )}
            </Menu.Item>}
          </div>
          {superAdministrador && <div className="">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => reopenObjectives()}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Reabrir objetivos
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => reopenAssessment(1)}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Reabrir autoevaluación
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => reopenAssessment(2)}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Reabrir evaluación
                </button>
              )}
            </Menu.Item>
          </div>}
          {superAdministrador && <div className="">
            {/* <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => endAssessment(1)}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Finalizar autoevaluación
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => endAssessment(2)}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Finalizar evaluación
                </button>
              )}
            </Menu.Item> */}
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={closeAssessment}
                  className={classNames(
                    active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Cerrar evaluación
                </button>
              )}
            </Menu.Item>
          </div>}
          {superAdministrador && <div className="">
            <Menu.Item>
              {({ active }) => (
                <button
                  onClick={e => deleteModalRef.current.showModal()}
                  className={classNames(
                    active ? "bg-gray-100 text-red-900" : "text-red-700",
                    "w-full text-left block px-4 py-2 text-sm"
                  )}
                >
                  Eliminar
                </button>
              )}
            </Menu.Item>
          </div>}
        </Menu.Items>
      </Transition>
      <CommonModalTemplate ref={deleteModalRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Elminar evaluación</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de eliminar el formato de evaluación?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingAction}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteAssessment}
          >
            {loadingAction ? "Eliminando" : "Eliminar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteModalRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <Notification
        ref={notificacionRef}
        title={notificationTitle}
        message={notificationMessage}
        type={notificationType}
      />
    </Menu>
  );
}
