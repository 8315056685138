import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { classNames, sortByOrder } from "../../constants/functions";
import { RadioGroup } from "@headlessui/react";
import AssessmentObjective from "../cards/AssessmentObjective";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import PlusFolder from "../vectors/PlusFolder";
import BigModalTemplate from "../modals/BigModalTemplate";
import { useRef } from "react";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { useState } from "react";
import {
  CalendarIcon,
  ExclamationTriangleIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import API from "../../services/API";
import AssessmentNewSimpleObjective from "../cards/AssessmentNewSimpleObjective";
import { data } from "autoprefixer";
import CommonModalTemplate from "../modals/CommonModalTemplate";
import AssessmentNewParentObjective from "../cards/AssessmentNewParentObjective";
import Notification from "../notifications/Notification";
import { useOutletContext } from "react-router-dom";
import { base64StringToBlob } from "blob-util";
import AssessmentNewProject from "../cards/AssessmentNewProject";
import AssessmentProject from "../cards/AssessmentProject";

const AssessmentSections = ({
  section,
  assessment,
  objectives,
  projects,
  answers,
  setAnswers,
  salesObjectives,
  salesAnswers,
  plans,
  setPlans,
  setSalesAnswers,
  nextObjectives,
  setNextObjectives,
  nextProjects,
  setNextProjects,
  saveIntermediateAnswer,
  saveInstantAnswers,
  nextParentObjectives,
  setNextParentObjectives,
  objectivesCallback,
  questionsRefs,
  shoppingObjectives
}) => {
  const { user, token } = useSelector((state) => state.authentication);
  const developmentModalRef = useRef();
  const simpleObjective = useRef();
  const simpleChildObjective = useRef();
  const parentObjective = useRef();
  const deleteNextParentObjetiveRef = useRef();
  const deleteNextChildObjetiveRef = useRef();
  const deleteNextObjetiveRef = useRef();
  const deleteNextProjectRef = useRef();
  const deleteActionPlanRef = useRef();
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingUploadShopping, setLoadingUploadShopping] = useState(false);
  const [selectedNextObjective, setSelectedNextObjective] = useState(0);
  const [selectedNextParentObjective, setSelectedNextParentObjective] =
    useState(0);
  const [selectedActionPlan, setSelectedActionPlan] = useState(0);
  const [captureErrors, setCaptureErrors] = useState([]);
  const [keyResults, setKeyResults] = useState([]);
  const [title, setTitle] = useState("");
  const [weight, setWeight] = useState(1);
  const [semester, setSemester] = useState(1);
  const [description, setDescription] = useState("");
  const [dateCreate, setDateCreate] = useState(new Date());
  const [hasSecondaryObjectives, setHasSecondaryObjectives] = useState(false);
  const [loadingDevelopment, setLoadingDevelopment] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState(
    "Hubo un error con el servicio"
  );
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notificationType, setNotificationType] = useState("danger");
  const [files, setFiles] = useState([]);
  const notificationRef = useRef();
  const fileInputRef = useRef();
  const confirmModalRef = useRef();
  const simpleProject = useRef();
  const { showNotification } = useOutletContext();

  const changeAnswer = (pregunta_id, respuesta_id, valor_pregunta) => {
    let newAnswers = { ...answers };
    newAnswers[pregunta_id] = {
      evaluacion_usuario_id: assessment.evaluacion_usuario_id,
      pregunta_id,
      respuesta_id,
      valor_pregunta,
      evaluacion_tipo_id:
        assessment.numero_empleado === user.numero_empleado ? 1 : 2,
      respuesta_texto: null,
      respuesta_switch: null,
      respuesta_etiquetas: [],
    };
    setAnswers(newAnswers);
    saveIntermediateAnswer();
  };

  const changeTextAnswer = (pregunta_id, respuesta_texto) => {
    let newAnswers = { ...answers };
    newAnswers[pregunta_id] = {
      evaluacion_usuario_id: assessment.evaluacion_usuario_id,
      pregunta_id,
      respuesta_id: null,
      evaluacion_tipo_id:
        assessment.numero_empleado === user.numero_empleado ? 1 : 2,
      respuesta_texto,
      respuesta_switch: null,
      respuesta_etiquetas: [],
    };
    setAnswers(newAnswers);
    saveIntermediateAnswer();
  };

  const changeSwitchAnswer = (
    pregunta_id,
    respuesta_switch,
    respuesta_texto
  ) => {
    let newAnswers = { ...answers };
    newAnswers[pregunta_id] = {
      evaluacion_usuario_id: assessment.evaluacion_usuario_id,
      pregunta_id,
      respuesta_id: null,
      evaluacion_tipo_id:
        assessment.numero_empleado === user.numero_empleado ? 1 : 2,
      respuesta_texto,
      respuesta_switch,
      respuesta_etiquetas: [],
    };
    setAnswers(newAnswers);
    saveIntermediateAnswer();
  };

  const changeOrAddDevelopmentAction = (index, field, value) => {
    let newPlans = [...plans];
    if (newPlans.length < 1) {
      newPlans.push({
        descripcion: "",
        is_active: true,
        fecha_entrega: new Date(),
        tipo_plan: 2,
        evaluacion_registrado_id: assessment.evaluacion_usuario_id,
      });
    }
    newPlans[index][field] = value;
    setPlans(newPlans);
  };

  const changeSalesAnswer = (objective, field, answer) => {
    let newSalesAnswers = { ...salesAnswers };
    if (!newSalesAnswers[objective]) {
      newSalesAnswers[objective] = {
        objetivo_venta_id: objective,
        evaluacion_usuario_id: assessment.evaluacion_usuario_id,
        valor_esperado: "",
        valor_real: "",
        comentarios: "",
        comentarios_autoevaluacion: "",
      };
    }
    newSalesAnswers[objective][field] = answer;
    setSalesAnswers(newSalesAnswers);
    saveIntermediateAnswer();
  };

  const changeDevelopmentAction = (index, field, value) => {
    let newPlans = [...plans];
    newPlans[index][field] = value;
    setPlans(newPlans);
  };

  const newNextObjective = () => {
    setSelectedNextObjective(0);
    setTitle("");
    setDescription("");
    setDateCreate(new Date());
    simpleObjective.current.showModal();
  };

  const newNextProject = () => {
    setSelectedNextObjective(0);
    setTitle("");
    setWeight(1)
    setSemester(1);
    setKeyResults([]);
    simpleProject.current.showModal();
  };

  const newNextChildObjective = (parent) => {
    setSelectedNextObjective(0);
    setSelectedNextParentObjective(parent);
    setTitle("");
    setDescription("");
    setDateCreate(new Date());
    simpleChildObjective.current.showModal();
  };

  const newNextParentObjective = () => {
    setSelectedNextParentObjective(0);
    setTitle("");
    setDescription("");
    setDateCreate(new Date());
    parentObjective.current.showModal();
  };

  const selectNextObjectiveToDelete = (objectiveId) => {
    setSelectedNextObjective(objectiveId);
    deleteNextObjetiveRef.current.showModal();
  };

  const selectNextProjectToDelete = (projectId) => {
    setSelectedNextObjective(projectId);
    deleteNextProjectRef.current.showModal();
  };

  const selectNextChildObjectiveToDelete = (
    parentObjectiveId,
    childObjectiveId
  ) => {
    setSelectedNextParentObjective(parentObjectiveId);
    setSelectedNextObjective(childObjectiveId);
    deleteNextChildObjetiveRef.current.showModal();
  };

  const selectNextParentObjectiveToDelete = (parentObjectiveId) => {
    setSelectedNextParentObjective(parentObjectiveId);
    deleteNextParentObjetiveRef.current.showModal();
  };

  const selectActionPlanToDelete = (planId) => {
    setSelectedActionPlan(planId);
    deleteActionPlanRef.current.showModal();
  };

  const getSelectedAnswerClassName = (value, questionAnswers) => {
    let className = "bg-red-600 text-white border-red-600";
    const maxValue =
      questionAnswers.find((element) => element.is_max === true).value || 2;
    if (value < maxValue) {
      className = "bg-red-100 text-red-800 border-red-600";
    } else if (value == maxValue) {
      className = "bg-green-100 text-green-800 border-green-600";
    } else {
      className = "bg-blue-100 text-blue-800 border-blue-600";
    }
    return className;
  };

  const selectNextChildObjectiveToEdit = (
    objectiveId,
    titulo,
    descripcion,
    fecha
  ) => {
    setSelectedNextObjective(objectiveId);
    setTitle(titulo);
    setDescription(descripcion);
    setDateCreate(new Date(fecha));
    simpleChildObjective.current.showModal();
  };

  const selectNextObjectiveToEdit = (
    objectiveId,
    titulo,
    descripcion,
    fecha
  ) => {
    setSelectedNextObjective(objectiveId);
    setTitle(titulo);
    setDescription(descripcion);
    setDateCreate(new Date(fecha));
    simpleObjective.current.showModal();
  };

  const selectNextProjectToEdit = (
    objectiveId,
    titulo,
    valor,
    semestre,
    krs
  ) => {
    setSelectedNextObjective(objectiveId);
    setTitle(titulo);
    setWeight(valor)
    setSemester(semestre);
    setKeyResults(JSON.parse(JSON.stringify(krs)));
    simpleProject.current.showModal();
  };

  const selectNextParentObjectiveToEdit = (objectiveId, titulo) => {
    setSelectedNextParentObjective(objectiveId);
    setTitle(titulo);
    parentObjective.current.showModal();
  };

  const deleteNextObjective = () => {
    let newNextObjectives = [...nextObjectives];
    let editObjective = newNextObjectives.find(
      (o) => o.objetivo_proximo_id == selectedNextObjective
    );
    if (editObjective) {
      editObjective.is_active = false;
    }
    setNextObjectives(newNextObjectives);
    deleteNextObjetiveRef.current.hideModal();
    saveInstantAnswers();
  };

  const deleteNextProject = () => {
    let newNextProjects = [...nextProjects];
    let editProject = newNextProjects.find(
      (o) => o.proyecto_id == selectedNextObjective
    );
    if (editProject) {
      editProject.activo = false;
      let body = {
        type: "proyecto",
        usuario: assessment.numero_empleado,
        assessment_type:
          user.numero_empleado == assessment.numero_empleado ? 1 : 2,
        item: {
          titulo: title,
          valor: weight,
          semestre: semester,
          activo: false,
          usuario: assessment.numero_empleado,
          proyecto_id: selectedNextObjective,
          krs: []
        },
      };
      setLoadingDevelopment(true);
      let headers = { Authorization: token };
      API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, {
        headers,
      })
        .then((response) => {
          setLoadingDevelopment(false);
          if (response.status == 200) {
            deleteNextProjectRef.current.hideModal();
          } else {
            alert("Hubo un error al eliminar el proyecto");
          }
        })
        .catch((error) => {
          console.log(error);
          setLoadingDevelopment(false);
          setNotificationTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setNotificationMessage("La sesión ha caducado");
            }
            setNotificationMessage(error.response.data);
          } else if (error.request) {
            setNotificationMessage(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del proyecto"
            );
          } else {
            setNotificationMessage(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del proyecto"
            );
          }
          notificationRef.current.showNotification();
        });
    }
  };

  const deleteNextChildObjective = () => {
    if (selectedNextParentObjective != 0 && selectedNextObjective) {
      let newParentObjectives = [...nextParentObjectives];
      let editObjective = newParentObjectives
        .find((o) => o.objetivo_padre_id == selectedNextParentObjective)
        ?.children.find((o) => o.objetivo_proximo_id == selectedNextObjective);
      if (editObjective) {
        editObjective.is_active = false;
      }
      setNextParentObjectives(newParentObjectives);
    } else {
      let newNextObjectives = [...nextObjectives];
      let editObjective = newNextObjectives.find(
        (o) => o.objetivo_proximo_id == selectedNextParentObjective
      );
      if (editObjective) {
        editObjective.is_active = false;
      }
      setNextObjectives(newNextObjectives);
    }
    deleteNextChildObjetiveRef.current.hideModal();
    saveInstantAnswers();
  };

  const deleteNextParentObjective = () => {
    let newParentObjectives = [...nextParentObjectives];
    let editObjective = newParentObjectives.find(
      (o) => o.objetivo_padre_id == selectedNextParentObjective
    );
    if (editObjective) {
      editObjective.is_active = false;
    }
    setNextParentObjectives(newParentObjectives);
    deleteNextParentObjetiveRef.current.hideModal();
    saveInstantAnswers();
  };

  const deleteActionPlan = () => {
    let newPlans = [...plans];
    let editPlan = newPlans.find((o) => o.accion_id == selectedActionPlan);
    if (editPlan) {
      editPlan.is_active = false;
    }
    setPlans(newPlans);
    deleteActionPlanRef.current.hideModal();
    saveInstantAnswers();
  };

  const addDevelopment = () => {
    let newErrors = [];
    if (description == "") {
      newErrors.push("description");
    }
    setCaptureErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }
    let body = {
      type: "plan",
      usuario: assessment.numero_empleado,
      assessment_type:
        user.numero_empleado == assessment.numero_empleado ? 1 : 2,
      item: {
        tipo_plan: user.numero_empleado == assessment.numero_empleado ? 2 : 1,
        descripcion: description,
        fecha_entrega: dateCreate,
      },
    };
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, {
      headers,
    })
      .then((response) => {
        setLoadingDevelopment(false);
        if (response.status == 200) {
          let newItem = {
            accion_id: response.data.accion_id,
            descripcion: description,
            estatus_accion_id: 1,
            estatus_periodo_id: 1,
            evaluacion_registrado_id: assessment.evaluacion_usuario_id,
            fecha_entrega: `${dateCreate.getFullYear()}-${
              dateCreate.getMonth() + 1
            }-${dateCreate.getDate()}`,
            is_active: true,
            numero_empleado: assessment.numero_empleado,
            tipo_plan:
              user.numero_empleado == assessment.numero_empleado ? 2 : 1,
          };
          let newPlans = [...plans, newItem];
          setPlans(newPlans);
          setDescription("");
          setDateCreate(new Date());
          developmentModalRef.current.hideModal();
        } else {
          alert("Hubo un error a crear el objeto");
        }
      })
      .catch((error) => {
        setLoadingDevelopment(false);
        setNotificationTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setNotificationMessage("La sesión ha caducado");
          }
          setNotificationMessage(error.response.data);
        } else if (error.request) {
          setNotificationMessage(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
          );
        } else {
          setNotificationMessage(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const addObjective = () => {
    let newErrors = [];
    if (title == "") {
      newErrors.push("title");
    }
    if (description == "") {
      newErrors.push("description");
    }
    setCaptureErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }
    let body = {
      type: "objetivo",
      usuario: assessment.numero_empleado,
      assessment_type:
        user.numero_empleado == assessment.numero_empleado ? 1 : 2,
      item: {
        titulo: title,
        descripcion: description,
        fecha_entrega: dateCreate,
        is_active: true,
      },
    };
    if (selectedNextObjective != 0) {
      body.item.objetivo_proximo_id = selectedNextObjective;
    }
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, {
      headers,
    })
      .then((response) => {
        setLoadingDevelopment(false);
        if (response.status == 200) {
          let newObjectives = [...nextObjectives];
          if (selectedNextObjective != 0) {
            let oldItem = newObjectives.find(
              (o) => o.objetivo_proximo_id == selectedNextObjective
            );
            if (oldItem) {
              oldItem.titulo = title;
              oldItem.descripcion = description;
              oldItem.fecha_entrega = dateCreate;
            }
          } else {
            let newItem = {
              objetivo_proximo_id: response.data.objetivo_proximo_id,
              estatus_objetivo_id: 1,
              titulo: title,
              descripcion: description,
              fecha_entrega: dateCreate,
              is_active: true,
            };
            newObjectives.push(newItem);
          }
          setNextObjectives(newObjectives);
          setTitle("");
          setDescription("");
          setDateCreate(new Date());
          simpleObjective.current.hideModal();
          parentObjective.current.hideModal();
        } else {
          alert("Hubo un error a crear el objeto");
        }
      })
      .catch((error) => {
        setLoadingDevelopment(false);
        setNotificationTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setNotificationMessage("La sesión ha caducado");
          }
          setNotificationMessage(error.response.data);
        } else if (error.request) {
          setNotificationMessage(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
          );
        } else {
          setNotificationMessage(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const addProject = () => {
    let newErrors = [];
    if (title == "") {
      newErrors.push("title");
    }
    if(keyResults.length < 3){
      newErrors.push("krcount");
    }
    for (let index = 0; index < keyResults.length; index++) {
      const element = keyResults[index];
      if(element.titulo==""){
        newErrors.push("krtitulo"+index);
      }
    }
    if (weight == "" || weight < 1) {
      newErrors.push("weightmin");
    }
    if (weight > 100) {
      newErrors.push("weightmax");
    } else {
      let porcentaje = 0;
      for (let index = 0; index < nextProjects.length; index++) {
        const element = nextProjects[index];
        if(!element.activo){
          continue;
        }
        if (selectedNextObjective != 0) {
          if(element.proyecto_id!=selectedNextObjective){
            porcentaje += Number(element.valor);
          }
        } else {
          porcentaje += Number(element.valor);
        }
      }
      if ((porcentaje + Number(weight)) > 100) {
        newErrors.push("weight");
      }
    }
    setCaptureErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }
    let body = {
      type: "proyecto",
      usuario: assessment.numero_empleado,
      assessment_type:
        user.numero_empleado == assessment.numero_empleado ? 1 : 2,
      item: {
        titulo: title,
        valor: weight,
        semestre: semester,
        activo: true,
        usuario: assessment.numero_empleado,
        krs: keyResults
      },
    };
    if (selectedNextObjective != 0) {
      body.item.proyecto_id = selectedNextObjective;
    }
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, {
      headers,
    })
      .then((response) => {
        setLoadingDevelopment(false);
        if (response.status == 200) {
          let newProjects = [...nextProjects];
          if (selectedNextObjective != 0) {
            let oldItem = newProjects.find(
              (o) => o.proyecto_id == selectedNextObjective
            );
            if (oldItem) {
              oldItem.titulo = title;
              oldItem.valor = weight;
              oldItem.semestre = semester;
              oldItem.activo = true;
              oldItem.krs = [...keyResults];
            }
          } else {
            let newItem = {
              proyecto_id: response.data.proyecto_id,
              titulo: title,
              valor: weight,
              semestre: semester,
              activo: true,
              krs: [...keyResults]
            };
            newProjects.push(newItem);
          }
          setNextProjects(newProjects);
          setTitle("");
          setWeight(1)
          setSemester(1);
          setKeyResults([]);
          simpleProject.current.hideModal();
        } else {
          alert("Hubo un error a crear el proyecto");
        }
      })
      .catch((error) => {
        console.log(error);
        setLoadingDevelopment(false);
        setNotificationTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setNotificationMessage("La sesión ha caducado");
          }
          setNotificationMessage(error.response.data);
        } else if (error.request) {
          setNotificationMessage(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del proyecto"
          );
        } else {
          setNotificationMessage(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del proyecto"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const addChildObjective = () => {
    let newErrors = [];
    if (title == "") {
      newErrors.push("title");
    }
    if (description == "") {
      newErrors.push("description");
    }
    setCaptureErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }

    let body = {};
    if (selectedNextObjective != 0) {
      body = {
        type: "objetivo",
        usuario: assessment.numero_empleado,
        assessment_type:
          user.numero_empleado == assessment.numero_empleado ? 1 : 2,
        item: {
          titulo: title,
          descripcion: description,
          fecha_entrega: dateCreate,
          is_active: true,
        },
      };
      body.item.objetivo_proximo_id = selectedNextObjective;
    } else {
      body = {
        type: "objetivo_hijo",
        usuario: assessment.numero_empleado,
        assessment_type:
          user.numero_empleado == assessment.numero_empleado ? 1 : 2,
        item: {
          titulo: title,
          descripcion: description,
          fecha_entrega: dateCreate,
          is_active: true,
        },
      };
    }
    if (selectedNextParentObjective != 0) {
      body.item.objetivo_padre_id = selectedNextParentObjective;
    }
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, {
      headers,
    })
      .then((response) => {
        setLoadingDevelopment(false);
        if (response.status == 200) {
          if (selectedNextParentObjective != 0) {
            let newParentObjectives = [...nextParentObjectives];
            if (selectedNextObjective != 0) {
              let oldItem = newParentObjectives
                .find((o) => o.objetivo_padre_id == selectedNextParentObjective)
                ?.children.find(
                  (o) => o.objetivo_proximo_id == selectedNextObjective
                );
              if (oldItem) {
                oldItem.titulo = title;
                oldItem.descripcion = description;
                oldItem.fecha_entrega = dateCreate;
              }
            } else {
              let newItem = {
                objetivo_proximo_id: response.data.objetivo_proximo_id,
                objetivo_padre_id: selectedNextParentObjective,
                estatus_objetivo_id: 1,
                titulo: title,
                descripcion: description,
                fecha_entrega: dateCreate,
              };
              let toEdit = newParentObjectives.find(
                (o) => o.objetivo_padre_id == selectedNextParentObjective
              );
              if (!toEdit.children) {
                toEdit.children = [];
              }
              toEdit.children.push(newItem);
            }
            setNextParentObjectives(newParentObjectives);
          } else {
            let newObjectives = [...nextObjectives];
            if (selectedNextObjective != 0) {
              let oldItem = newObjectives.find(
                (o) => o.objetivo_proximo_id == selectedNextObjective
              );
              if (oldItem) {
                oldItem.titulo = title;
                oldItem.descripcion = description;
                oldItem.fecha_entrega = dateCreate;
              }
            } else {
              let newItem = {
                objetivo_proximo_id: response.data.objetivo_proximo_id,
                estatus_objetivo_id: 1,
                titulo: title,
                descripcion: description,
                fecha_entrega: dateCreate,
                is_active: true,
              };
              newObjectives.push(newItem);
            }
            setNextObjectives(newObjectives);
          }
          setTitle("");
          setDescription("");
          setDateCreate(new Date());
          simpleChildObjective.current.hideModal();
        } else {
          alert("Hubo un error a crear el objeto");
        }
      })
      .catch((error) => {
        setLoadingDevelopment(false);
        setNotificationTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setNotificationMessage("La sesión ha caducado");
          }
          setNotificationMessage(error.response.data);
        } else if (error.request) {
          setNotificationMessage(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
          );
        } else {
          setNotificationMessage(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const addParentObjective = () => {
    let newErrors = [];
    if (title == "") {
      newErrors.push("title");
    }
    setCaptureErrors(newErrors);
    if (newErrors.length > 0) {
      return;
    }

    let body = {
      type: "objetivo_padre",
      usuario: assessment.numero_empleado,
      assessment_type:
        user.numero_empleado == assessment.numero_empleado ? 1 : 2,
      item: {
        titulo: title,
        descripcion: description,
        is_active: true,
        children: [],
      },
    };
    if (selectedNextParentObjective != 0) {
      body.item.objetivo_padre_id = selectedNextParentObjective;
    }
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, {
      headers,
    })
      .then((response) => {
        setLoadingDevelopment(false);
        if (response.status == 200) {
          let newObjectives = [...nextParentObjectives];
          if (selectedNextParentObjective != 0) {
            let oldItem = newObjectives.find(
              (o) => o.objetivo_padre_id == selectedNextParentObjective
            );
            if (oldItem) {
              oldItem.titulo = title;
            }
          } else {
            let newItem = {
              objetivo_padre_id: response.data.objetivo_padre_id,
              estatus_objetivo_id: 1,
              titulo: title,
              children: [],
            };
            newObjectives.push(newItem);
          }
          setNextParentObjectives(newObjectives);
          setTitle("");
          setDescription("");
          setDateCreate(new Date());
          parentObjective.current.hideModal();
        } else {
          alert("Hubo un error a crear el objeto");
        }
      })
      .catch((error) => {
        setLoadingDevelopment(false);
        setNotificationTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setNotificationMessage("La sesión ha caducado");
          }
          setNotificationMessage(error.response.data);
        } else if (error.request) {
          setNotificationMessage(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
          );
        } else {
          setNotificationMessage(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const saveShoppingObjectives = async () => {
    setLoadingUploadShopping(true);
    let item = files[0];
    let body = {
      file: await getBase64(item),
    };
    API.post(
      `assessments/${assessment.evaluacion_usuario_id}/shoppingobjectives/file`,
      body,
      {
        headers: { Authorization: token },
      }
    )
      .then((result) => {
        setLoadingUploadShopping(false);
        if (result.status == 200) {
          confirmModalRef.current.hideModal();
        } else {
          setNotificationMessage("Error al cargar los objetivos de venta");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        confirmModalRef.current.hideModal();
        setLoadingUploadShopping(false);
        if (error.response) {
          if (error.response.status === 401) {
            setNotificationMessage("La sesión ha caducado");
          }
          setNotificationMessage(error.response.data);
        } else if (error.request) {
          setNotificationMessage(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al cargar los objetivos de venta"
          );
        } else {
          setNotificationMessage(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al cargar los objetivos de venta"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const downloadFileShopping = (tipo) => {
    const headers = { Authorization: token };
    setLoadingFile(true);
    API.get(
      `assessments/${assessment.evaluacion_usuario_id}/shoppingobjectives/file`,
      {
        headers,
        responseType:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      }
    )
      .then((response) => {
        setLoadingFile(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `ObjetivosArea_2023.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingFile(false);
      });
  };

  const addKR = (e) => {
    setCaptureErrors([]);
    setKeyResults([...keyResults, {"titulo":""}])
  };

  const onChangeKRTitle = (value, index) => {
    setCaptureErrors([]);
    let newKR = [...keyResults];
    newKR[index].titulo = value;
    setKeyResults(newKR);
  };

  const onDeleteKR = (index) => {
    let newKR = [...keyResults];
    newKR.splice(index, 1);
    setKeyResults(newKR);
  };

  const onAddFiles = (e) => {
    let a = Array.prototype.slice.call(e.target.files);
    if (a.length > 0) {
      setFiles(a);
      confirmModalRef.current.showModal();
    }
    e.target.value = "";
  };

  let tabIndex = 0;

  return (
    <>
      <div
        key={`key-assessment-section-${section.seccion_id}`}
        className="w-full mb-4 flex flex-col items-start p-3 ease-in duration-150 min-w-full relative"
      >
        {section.type == 1 &&
          section.subsections.sort(sortByOrder).map((subsection, subindex) => (
            <div
              className="mb-8 w-full"
              key={`key-assessment-section-subsection-${subsection.apartado_id}`}
            >
              <p className="text-red-600 font-medium text-3xl mb-4">
                {subsection.title}
              </p>
              <ul className={`w-full flex flex-col justify-center space-y-6`}>
                {subsection.questions
                  .sort(sortByOrder)
                  .map((question, qIdx) => (
                    <li
                      ref={(el) =>
                        (questionsRefs.current[question.pregunta_id] = el)
                      }
                      className="w-full p-4 shadow bg-gray-100 rounded-lg"
                      key={`key-assessment-section-subsection-question-${question.pregunta_id}`}
                    >
                      {
                        {
                          1: (
                            <>
                              <p className="font-semibold lg:text-lg mb-1 text-gray-900">
                                {user?.numero_empleado ===
                                assessment.numero_empleado
                                  ? question.title_auto
                                  : question.title}
                              </p>
                              <textarea
                                id={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                name={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                placeholder={
                                  user?.numero_empleado ===
                                  assessment.numero_empleado
                                    ? question.placeholder_autoevaluacion
                                    : question.placeholder
                                }
                                rows={3}
                                className="block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                value={
                                  answers[question.pregunta_id]
                                    ? answers[question.pregunta_id]
                                        .respuesta_texto
                                    : ""
                                }
                                onChange={(e) =>
                                  changeTextAnswer(
                                    question.pregunta_id,
                                    e.target.value
                                  )
                                }
                              />
                            </>
                          ),
                          2: (
                            <>
                              <p className="font-semibold lg:text-lg mb-3 sm:mb-1 text-gray-900">
                                {user?.numero_empleado ===
                                assessment.numero_empleado
                                  ? question.title_auto
                                  : question.title}
                              </p>
                              <p className="mb-1 text-gray-900">
                                {user?.numero_empleado ===
                                assessment.numero_empleado
                                  ? question.title_auto != question.placeholder_autoevaluacion && question.placeholder_autoevaluacion
                                  : question.title != question.placeholder && question.placeholder}
                              </p>
                              <ul
                                className={`w-full flex flex-col sm:flex-row justify-between  space-y-2 sm:space-y-0 sm:space-x-2 lg:space-x-4`}
                              >
                                {question.answers
                                  .sort(sortByOrder)
                                  .map((a, aIdx) => {
                                    tabIndex++;
                                    return (
                                      <li
                                        key={`key-assessment-section-subsection-question-answer-${a.respuesta_id}`}
                                        tabIndex={tabIndex}
                                        className={classNames(
                                          answers[question.pregunta_id]
                                            ?.respuesta_id == a.respuesta_id
                                            ? getSelectedAnswerClassName(
                                                a.value,
                                                question.answers
                                              )
                                            : "bg-white hover:bg-zinc-200 text-gray-900",
                                          "flex justify-between items-center p-3 w-full border rounded-md hover:cursor-pointer relative transition-all ease-in-out duration-300"
                                        )}
                                        onClick={(e) =>
                                          changeAnswer(
                                            question.pregunta_id,
                                            a.respuesta_id,
                                            a.value
                                          )
                                        }
                                      >
                                        <p className="font-medium flex-1">
                                          {a.title}
                                        </p>
                                      </li>
                                    );
                                  })}
                              </ul>
                            </>
                          ),
                          3: (
                            <>
                              <p className="font-semibold lg:text-lg text-gray-900">
                                {user?.numero_empleado ===
                                assessment.numero_empleado
                                  ? question.title_auto
                                  : question.title}
                              </p>
                              <p className="font-light mb-3 sm:mb-1 text-gray-600">
                                {user?.numero_empleado ===
                                assessment.numero_empleado
                                  ? question.placeholder_autoevaluacion
                                  : question.placeholder}
                              </p>
                              <div className="grid grid-cols-4 gap-4">
                                <div className="col-span-4 md:col-span-1">
                                  <RadioGroup
                                    value={
                                      answers[question.pregunta_id]
                                        ? answers[question.pregunta_id]
                                            .respuesta_switch
                                        : null
                                    }
                                    onChange={(value) =>
                                      changeSwitchAnswer(
                                        question.pregunta_id,
                                        value,
                                        answers[question.pregunta_id]
                                          ? answers[question.pregunta_id]
                                              .respuesta_texto
                                          : ""
                                      )
                                    }
                                  >
                                    <div className="isolate -space-y-px rounded-md bg-white shadow-sm">
                                      <RadioGroup.Option
                                        value={true}
                                        className={({ checked }) =>
                                          classNames(
                                            checked
                                              ? "bg-green-100 border-green-200 z-10"
                                              : "border-gray-300",
                                            "rounded-tl-md rounded-tr-md relative border px-4 py-3 flex cursor-pointer focus:outline-none"
                                          )
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                checked
                                                  ? "bg-green-600 border-transparent"
                                                  : "bg-white border-gray-300",
                                                active
                                                  ? "ring-2 ring-offset-2 ring-green-500"
                                                  : "",
                                                "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                              )}
                                              aria-hidden="true"
                                            >
                                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                                            </span>
                                            <span className="ml-3 flex flex-col">
                                              <RadioGroup.Label
                                                as="span"
                                                className={classNames(
                                                  checked
                                                    ? "text-green-900"
                                                    : "text-gray-900",
                                                  "block text-sm font-medium"
                                                )}
                                              >
                                                SÍ
                                              </RadioGroup.Label>
                                            </span>
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                      <RadioGroup.Option
                                        value={false}
                                        className={({ checked }) =>
                                          classNames(
                                            checked
                                              ? "bg-red-100 border-red-200 z-10"
                                              : "border-gray-300",
                                            "rounded-bl-md rounded-br-md relative border px-4 py-3 flex cursor-pointer focus:outline-none"
                                          )
                                        }
                                      >
                                        {({ active, checked }) => (
                                          <>
                                            <span
                                              className={classNames(
                                                checked
                                                  ? "bg-red-600 border-transparent"
                                                  : "bg-white border-gray-300",
                                                active
                                                  ? "ring-2 ring-offset-2 ring-red-500"
                                                  : "",
                                                "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                              )}
                                              aria-hidden="true"
                                            >
                                              <span className="rounded-full bg-white w-1.5 h-1.5" />
                                            </span>
                                            <span className="ml-3 flex flex-col">
                                              <RadioGroup.Label
                                                as="span"
                                                className={classNames(
                                                  checked
                                                    ? "text-red-900"
                                                    : "text-gray-900",
                                                  "block text-sm font-medium"
                                                )}
                                              >
                                                NO
                                              </RadioGroup.Label>
                                            </span>
                                          </>
                                        )}
                                      </RadioGroup.Option>
                                    </div>
                                  </RadioGroup>
                                </div>
                                <div className="col-span-4 md:col-span-3">
                                  <textarea
                                    id={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                    name={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                    placeholder={"Comentarios..."}
                                    rows={3}
                                    className="block w-full min-h-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                    value={
                                      answers[question.pregunta_id]
                                        ? answers[question.pregunta_id]
                                            .respuesta_texto
                                        : ""
                                    }
                                    onChange={(e) =>
                                      changeSwitchAnswer(
                                        question.pregunta_id,
                                        answers[question.pregunta_id]
                                          ? answers[question.pregunta_id]
                                              .respuesta_switch
                                          : null,
                                        e.target.value
                                      )
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ),
                          5: (
                            <>
                              <p className="font-semibold lg:text-lg mb-3 sm:mb-1 text-gray-900">
                                {user?.numero_empleado ===
                                assessment.numero_empleado
                                  ? question.title_auto
                                  : question.title}
                              </p>
                              <ul
                                className={`w-full flex flex-col sm:flex-row justify-between  space-y-2 sm:space-y-0 sm:space-x-2 lg:space-x-4`}
                              >
                                {question.answers.sort(sortByOrder).map((a) => (
                                  <li
                                    key={`key-assessment-section-subsection-question-answer-${a.respuesta_id}`}
                                    className={classNames(
                                      answers[question.pregunta_id]
                                        ?.respuesta_id == a.respuesta_id
                                        ? getSelectedAnswerClassName(
                                            a.value,
                                            question.answers
                                          )
                                        : "bg-white hover:bg-zinc-200 text-gray-900",
                                      "flex justify-between items-center p-3 w-full border rounded-md hover:cursor-pointer relative transition-all ease-in-out duration-300"
                                    )}
                                    onClick={(e) =>
                                      changeAnswer(
                                        question.pregunta_id,
                                        a.respuesta_id,
                                        a.value
                                      )
                                    }
                                  >
                                    <p className="font-medium flex-1 text-sm">
                                      {a.title}
                                    </p>
                                  </li>
                                ))}
                              </ul>
                            </>
                          ),
                        }[question.type]
                      }
                    </li>
                  ))}
              </ul>
            </div>
          ))}
        {section.type === 2 && (
          <div className="mb-8 w-full">
            <ul className="space-y-4">
              {objectives.map((objective) => (
                <AssessmentObjective
                  key={`key-past-objective-${objective.objetivo_id}`}
                  id={objective.objetivo_id}
                  status={objective.estatus_objetivo_id}
                  title={objective.titulo}
                  description={objective.descripcion}
                  self={user?.numero_empleado == assessment?.numero_empleado}
                  superior={user?.colaboradores.includes(
                    assessment?.numero_empleado
                  )}
                  callback={objectivesCallback}
                />
              ))}
            </ul>
          </div>
        )}
        {section.type === 3 && (
          <>
            <div className="w-full">
              <div className="w-full">
                <p className="text-3xl text-center mb-6 font-bold">
                  Los objetivos y entregables deberán ser tan claros que una
                  persona ajena a tu departamento pueda comprenderlos.
                </p>
                <div className="w-full flex justify-end">
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                      type="button"
                      onClick={newNextObjective}
                      className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <PlusIcon className="h-6 w-6 mr-1" />
                      Agregar objetivo
                    </button>
                  </div>
                </div>
                <div className="mt-8 flex flex-col space-y-4">
                  {nextObjectives.length > 0 ? (
                    nextObjectives.map((objective) => (
                      <AssessmentNewSimpleObjective
                        key={`key-new-simple-objective-${objective.objetivo_proximo_id}`}
                        id={objective.objetivo_proximo_id}
                        active={objective.is_active}
                        status={objective.estatus_objetivo_id}
                        title={objective.titulo}
                        description={objective.descripcion}
                        dueDate={
                          objective.fecha_entrega instanceof Date
                            ? objective.fecha_entrega
                            : new Date(objective.fecha_entrega + "T00:00:00")
                        }
                        selectNextObjectiveToDelete={
                          selectNextObjectiveToDelete
                        }
                        selectNextObjectiveToEdit={selectNextObjectiveToEdit}
                      />
                    ))
                  ) : (
                    <div className="text-center mt-3">
                      <PlusFolder />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Sin nuevos objetivos
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Da click en el botón "Agregar objetivo" y crea un nuevo
                        objetivo para el siguiente periodo.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {section.type === 33 && (
          <>
            <div className="w-full">
              <div className="w-full">
                <p className="text-lg font-bold mb-4">
                  Recuerda, los objetivos y entregables deberán ser tan claros
                  que una persona ajena a tu departamento pueda comprenderlos.
                </p>
                {/* {assessment.numero_empleado === user.numero_empleado && (
                  <p className="text-lg font-bold">
                    En esta sección deberás colocar tus próximos objetivos, recuerda
                    redactarlos en formato smart. Para tu formato de evaluación, existen
                    dos tipos de objetivo, el principal que engloba el objetivo total del
                    periodo y que no tiene valor, y los secundarios que juntos ejecutan
                    todo el objetivo principal y son los que tienen valor en la evaluación
                  </p>
                )} */}
                <div className="w-full flex justify-end">
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                      type="button"
                      onClick={newNextParentObjective}
                      className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <PlusIcon className="h-6 w-6 mr-1" />
                      Agregar objetivo
                    </button>
                  </div>
                </div>
                <div className="mt-8 flex flex-col space-y-4">
                  {nextParentObjectives.length < 0 &&
                  nextObjectives.length < 0 ? (
                    <div className="text-center mt-3">
                      <PlusFolder />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Sin nuevos objetivos
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Da click en el botón "Agregar objetivo" y crea un nuevo
                        objetivo para el siguiente periodo.
                      </p>
                    </div>
                  ) : (
                    <>
                      {nextParentObjectives.map((objective) => (
                        <AssessmentNewParentObjective
                          key={`key-new-parent-objective-${objective.objetivo_padre_id}`}
                          id={objective.objetivo_padre_id}
                          active={objective.is_active}
                          status={objective.estatus_objetivo_id}
                          title={objective.titulo}
                          description={objective.descripcion}
                          dueDate={
                            new Date(objective.fecha_entrega + "T00:00:00")
                          }
                          selectNextParentObjectiveToDelete={
                            selectNextParentObjectiveToDelete
                          }
                          selectNextObjectiveToDelete={
                            selectNextChildObjectiveToDelete
                          }
                          selectNextObjectiveToEdit={
                            selectNextChildObjectiveToEdit
                          }
                          selectNextParentObjectiveToEdit={
                            selectNextParentObjectiveToEdit
                          }
                          descendants={objective.children}
                          newNextChildObjective={newNextChildObjective}
                        />
                      ))}
                      {nextObjectives
                        .filter((o) => o.objetivo_padre_id == null)
                        .sort(
                          (a, b) =>
                            new Date(a.fecha_entrega) -
                            new Date(b.fecha_entrega)
                        )
                        .map((objective) => (
                          <AssessmentNewSimpleObjective
                            key={`key-new-parent-objective-${objective.objetivo_proximo_id}`}
                            id={objective.objetivo_proximo_id}
                            active={objective.is_active}
                            status={objective.estatus_objetivo_id}
                            title={objective.titulo}
                            description={objective.descripcion}
                            dueDate={
                              new Date(objective.fecha_entrega + "T00:00:00")
                            }
                            selectNextParentObjectiveToDelete={
                              selectNextParentObjectiveToDelete
                            }
                            selectNextObjectiveToDelete={
                              selectNextChildObjectiveToDelete
                            }
                            selectNextObjectiveToEdit={
                              selectNextChildObjectiveToEdit
                            }
                            selectNextParentObjectiveToEdit={
                              selectNextParentObjectiveToEdit
                            }
                            descendants={objective.children}
                            newNextChildObjective={newNextChildObjective}
                          />
                        ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {section.type === 34 && (
          <>
            <div className="w-full">
              <div className="w-full">
                <div className="w-full flex justify-center">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:p-6">
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        Descarga los objetivos del área
                      </h3>
                      <div className="mt-2 block space-y-4">
                        <div className="max-w-xl text-sm text-gray-500">
                          <p>
                            En esta sección podrás descargar el archivo con los
                            objetivos del área para que puedas revisarlos.
                          </p>
                        </div>
                        <div className="sm:flex sm:flex-shrink-0 sm:items-start gap-4">
                          <button
                            type="button"
                            onClick={downloadFileShopping}
                            disabled={loadingFile}
                            className="inline-flex items-center rounded-md border border-transparent bg-green-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                          >
                            {loadingFile ? "Descargando " : "Descargar "}
                            archivo
                          </button>
                          <label htmlFor="file-upload">
                            <button
                              disabled={loadingUploadShopping}
                              onClick={(e) => fileInputRef.current.click()}
                              className="inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                            >
                              {loadingUploadShopping ? "Cargando " : "Cargar "}
                              archivo
                            </button>
                            <input
                              id="file-upload"
                              name="file-upload"
                              type="file"
                              multiple={false}
                              ref={fileInputRef}
                              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                              className="sr-only"
                              onChange={onAddFiles}
                            />
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {section.type === 4 && (
          <div className="mb-8 w-full">
            <div className="overflow-hidden bg-white shadow rounded-md border">
              <ul className="divide-y divide-gray-200">
                {salesObjectives.map((objective) => (
                  <li
                    key={`key-sales-objective-${objective.objetivo_venta_id}`}
                  >
                    <div className="px-4 py-4 sm:px-6">
                      <span
                        className={`rounded-full px-2 text-sm font-semibold leading-5 ${
                          objective.cumplido === "Cumplido"
                            ? "bg-green-100 text-green-800"
                            : "bg-red-100 text-red-800"
                        }`}
                      >
                        {objective.cumplido}
                      </span>
                      <div className="flex justify-between space-y-2 sm:space-x-2 sm:space-y-0 mt-1 flex-col sm:flex-row">
                        <div className="flex flex-1 flex-col items-top h-full">
                          <div className="flex items-center">
                            <p className="font-medium text-gray-900 mr-2">
                              {objective.titulo}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-sm font-semibold text-gray-500 mr-1">
                              Objetivo:
                            </p>
                            <p className="text-sm leading-5 text-gray-500">
                              {objective.valor_esperado === "" ||
                              !objective.valor_esperado
                                ? "Sin responder"
                                : objective.valor_esperado}
                            </p>
                          </div>
                          <div className="flex">
                            <p className="text-sm font-semibold text-gray-500 mr-1">
                              Real:
                            </p>
                            <p className="text-sm leading-5 text-gray-500">
                              {objective.valor_real === "" ||
                              !objective.valor_real
                                ? "Sin responder"
                                : objective.valor_real}
                            </p>
                          </div>
                        </div>
                        <div className="flex-1">
                          {user.numero_empleado ===
                          assessment.numero_empleado ? (
                            <textarea
                              placeholder={"Comentarios..."}
                              rows={2}
                              className="block w-full min-h-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                              value={
                                salesAnswers[objective.objetivo_venta_id]
                                  ? salesAnswers[objective.objetivo_venta_id]
                                      .comentarios_autoevaluacion
                                  : ""
                              }
                              onChange={(e) =>
                                changeSalesAnswer(
                                  objective.objetivo_venta_id,
                                  "comentarios_autoevaluacion",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <textarea
                              placeholder={"Comentarios..."}
                              rows={2}
                              className="block w-full min-h-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                              value={
                                salesAnswers[objective.objetivo_venta_id]
                                  ? salesAnswers[objective.objetivo_venta_id]
                                      .comentarios
                                  : ""
                              }
                              onChange={(e) =>
                                changeSalesAnswer(
                                  objective.objetivo_venta_id,
                                  "comentarios",
                                  e.target.value
                                )
                              }
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
        {section.type === 5 && (
          <div className="w-full">
            <div className="w-full">
              <p className="text-3xl text-center mb-6 font-bold">
                Acciones concretas de capacitación, tales como: cursos,
                talleres, becas, actualización de procesos, entrenamiento
                específico, etc.
              </p>
              <div className="w-full flex justify-end">
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    onClick={(e) => developmentModalRef.current.showModal()}
                    className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <PlusIcon className="h-6 w-6 mr-1" />
                    Agregar acción
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                {plans.length > 0 ? (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Plan
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Fecha límite de cumplimiento
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                              >
                                <span className="sr-only">Quitar</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {plans
                              .filter((a) => a.is_active)
                              .map((action, actionIndex) => (
                                <tr
                                  key={`key-development-action-${actionIndex}`}
                                  className="divide-x divide-gray-200"
                                >
                                  <td className="py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                    {action.descripcion}
                                  </td>
                                  <td className="p-4 text-sm text-gray-500">
                                    {action.fecha_entrega}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                    <button
                                      onClick={(e) =>
                                        selectActionPlanToDelete(
                                          action.accion_id
                                        )
                                      }
                                      className="text-red-600 hover:text-red-900"
                                    >
                                      Eliminar
                                      <span className="sr-only"> acción</span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center mt-3">
                    <PlusFolder />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Sin acciones de capacitación
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Da click en el botón "Agregar acción" y crea acciones de
                      capacitación para el colaborador.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {section.type === 6 && (
          <div className="w-full">
            <div className="w-full">
              <p className="text-3xl text-center mb-6 font-bold">
                Acciones concretas de capacitación, tales como: cursos,
                talleres, becas, actualización de procesos, entrenamiento
                específico, etc.
              </p>
              <div className="w-full flex justify-end">
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  <button
                    type="button"
                    onClick={(e) => developmentModalRef.current.showModal()}
                    className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                  >
                    <PlusIcon className="h-6 w-6 mr-1" />
                    Agregar acción
                  </button>
                </div>
              </div>
              <div className="mt-8 flex flex-col">
                {plans.length > 0 ? (
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                              >
                                Plan
                              </th>
                              <th
                                scope="col"
                                className="px-4 py-3.5 text-left text-sm font-semibold text-gray-900"
                              >
                                Fecha límite de cumplimiento
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 pl-4 pr-4 text-left text-sm font-semibold text-gray-900 sm:pr-6"
                              >
                                <span className="sr-only">Quitar</span>
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {plans
                              .filter((a) => a.is_active)
                              .map((action, actionIndex) => (
                                <tr
                                  key={`key-development-action-${actionIndex}`}
                                  className="divide-x divide-gray-200"
                                >
                                  <td className="py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-6">
                                    {action.descripcion}
                                  </td>
                                  <td className="p-4 text-sm text-gray-500">
                                    {action.fecha_entrega}
                                  </td>
                                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm text-gray-500 sm:pr-6">
                                    <button
                                      onClick={(e) =>
                                        selectActionPlanToDelete(
                                          action.accion_id
                                        )
                                      }
                                      className="text-red-600 hover:text-red-900"
                                    >
                                      Eliminar
                                      <span className="sr-only"> acción</span>
                                    </button>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="text-center mt-3">
                    <PlusFolder />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      Sin acciones de capacitación
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Da click en el botón "Agregar acción" y crea acciones de
                      capacitación para el colaborador.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        {section.type === 8 && (
          <>
            <div className="w-full">
              <div className="w-full">
                <p className="text-3xl text-center mb-2 font-bold">
                  Recuerda que cada proyecto debe de contar con resultados clave,{" "}
                  por ejemplo:
                </p>
                <p className="text-2xl text-center mb-2 font-bold">
                  Proyecto:
                </p>
                <p className="text-2xl text-center mb-2">
                  Nueva versión del portal de proveedores
                </p>
                <p className="text-xl text-center mb-2 font-bold">
                  Resultados clave:
                </p>
                <ul className="text-xl text-center mb-6">
                  <li>Levantamiento de requerimientos</li>
                  <li>Desarrollo de la base de datos</li>
                  <li>Desarrollo del backend</li>
                  <li>Desarrollo del frontend</li>
                  <li>Resultados de las pruebas</li>
                  <li>Lanzamiento</li>
                  <li>Documentación</li>
                </ul>
                <div className="w-full flex justify-end">
                  <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                    <button
                      type="button"
                      onClick={newNextProject}
                      className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                    >
                      <PlusIcon className="h-6 w-6 mr-1" />
                      Agregar proyecto
                    </button>
                  </div>
                </div>
                <div className="mt-8 flex flex-col space-y-4">
                  {nextProjects.filter(np => np.activo).length > 0 ? (
                    nextProjects.filter(np => np.activo).map((project) => (
                      <AssessmentNewProject
                        key={`key-new-project-${project.proyecto_id}`}
                        id={project.proyecto_id}
                        active={project.activo}
                        status={project.estatus}
                        title={project.titulo}
                        dueDate={project.semestre}
                        value={project.valor}
                        krs={[...project.krs]}
                        selectNextProjectToDelete={
                          selectNextProjectToDelete
                        }
                        selectNextProjectToEdit={selectNextProjectToEdit}
                      />
                    ))
                  ) : (
                    <div className="text-center mt-3">
                      <PlusFolder />
                      <h3 className="mt-2 text-sm font-medium text-gray-900">
                        Sin proyectos para el periodo
                      </h3>
                      <p className="mt-1 text-sm text-gray-500">
                        Da click en el botón "Agregar proyecto" y crea un nuevo
                        proyecto para el siguiente periodo.
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        )}
        {section.type === 9 && (
          <div className="mb-8 w-full">
            <ul className="space-y-4">
              {projects.map((project) => (
                <AssessmentProject
                  key={`key-past-project-${project.proyecto_id}`}
                  id={project.proyecto_id}
                  status={project.estatus}
                  title={project.titulo}
                  valor={project.valor}
                  logrado={project.logrado}
                  semestre={project.semestre}
                  krs={project.krs}
                  self={user?.numero_empleado == assessment?.numero_empleado}
                  superior={user?.colaboradores.includes(
                    assessment?.numero_empleado
                  )}
                  callback={objectivesCallback}
                />
              ))}
            </ul>
          </div>
        )}
        {section.type == 40 && (
          <>
            <div className="m-0 overflow-x-auto min-w-full mb-8">
              <table className="min-w-full divide-y divide-gray-300 border mb-2">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-200">
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Categoría
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Grupo
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Línea
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Descripción
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Unidad
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Tipo
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Fecha de entrega
                    </th>
                    <th
                      scope="col"
                      className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                    >
                      Resultado
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {shoppingObjectives.map((shopping) => (
                    <tr
                      key={`key-shopping-objective-${shopping.objetivo_compras_id}`}
                      className="divide-x divide-gray-200"
                    >
                      <td className="p-2 text-xs font-medium text-gray-900 print:text-[10px]">
                        {shopping.categoria_id == 1
                          ? "Lanzamientos MP y Bajas MC"
                          : shopping.categoria_id == 2
                          ? "Pruebas"
                          : "Relanzamientos"}
                      </td>
                      <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                        {shopping.grupo}
                      </td>
                      <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                        {shopping.linea}
                      </td>
                      <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                        {shopping.descripcion}
                      </td>
                      <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">
                        {shopping.unidad}
                      </td>
                      <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">
                        {shopping.tipo}
                      </td>
                      <td className="p-2 text-xs text-gray-900 text-center print:text-[10px] whitespace-nowrap">
                        {shopping.fecha_entrega}
                      </td>
                      <td className={`p-2 text-xs font-bold ${shopping.resultado_eva=="SI" ? "text-green-600" : shopping.resultado_eva == "NO" ? "text-red-600" : "text-yellow-600"} text-center print:text-[10px]`}>
                        {shopping.resultado_eva}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {section.subsections
              .sort(sortByOrder)
              .map((subsection, subindex) => (
                <div
                  className="mb-8 w-full"
                  key={`key-assessment-section-subsection-${subsection.apartado_id}`}
                >
                  <p className="text-red-600 font-medium text-3xl mb-4">
                    {subsection.title}
                  </p>
                  <ul
                    className={`w-full flex flex-col justify-center space-y-6`}
                  >
                    {subsection.questions
                      .sort(sortByOrder)
                      .map((question, qIdx) => (
                        <li
                          ref={(el) =>
                            (questionsRefs.current[question.pregunta_id] = el)
                          }
                          className="w-full p-4 shadow bg-gray-100 rounded-lg"
                          key={`key-assessment-section-subsection-question-${question.pregunta_id}`}
                        >
                          {
                            {
                              1: (
                                <>
                                  <p className="font-semibold lg:text-lg mb-1 text-gray-900">
                                    {user?.numero_empleado ===
                                    assessment.numero_empleado
                                      ? question.title_auto
                                      : question.title}
                                  </p>
                                  <textarea
                                    id={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                    name={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                    placeholder={
                                      user?.numero_empleado ===
                                      assessment.numero_empleado
                                        ? question.placeholder_autoevaluacion
                                        : question.placeholder
                                    }
                                    rows={3}
                                    className="block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                    value={
                                      answers[question.pregunta_id]
                                        ? answers[question.pregunta_id]
                                            .respuesta_texto
                                        : ""
                                    }
                                    onChange={(e) =>
                                      changeTextAnswer(
                                        question.pregunta_id,
                                        e.target.value
                                      )
                                    }
                                  />
                                </>
                              ),
                              2: (
                                <>
                                  <p className="font-semibold lg:text-lg mb-3 sm:mb-1 text-gray-900">
                                    {user?.numero_empleado ===
                                    assessment.numero_empleado
                                      ? question.title_auto
                                      : question.title}
                                  </p>
                                  <ul
                                    className={`w-full flex flex-col sm:flex-row justify-between  space-y-2 sm:space-y-0 sm:space-x-2 lg:space-x-4`}
                                  >
                                    {question.answers
                                      .sort(sortByOrder)
                                      .map((a, aIdx) => {
                                        tabIndex++;
                                        return (
                                          <li
                                            key={`key-assessment-section-subsection-question-answer-${a.respuesta_id}`}
                                            tabIndex={tabIndex}
                                            className={classNames(
                                              answers[question.pregunta_id]
                                                ?.respuesta_id == a.respuesta_id
                                                ? getSelectedAnswerClassName(
                                                    a.value,
                                                    question.answers
                                                  )
                                                : "bg-white hover:bg-zinc-200 text-gray-900",
                                              "flex justify-between items-center p-3 w-full border rounded-md hover:cursor-pointer relative transition-all ease-in-out duration-300"
                                            )}
                                            onClick={(e) =>
                                              changeAnswer(
                                                question.pregunta_id,
                                                a.respuesta_id,
                                                a.value
                                              )
                                            }
                                          >
                                            <p className="font-medium flex-1">
                                              {a.title}
                                            </p>
                                          </li>
                                        );
                                      })}
                                  </ul>
                                </>
                              ),
                              3: (
                                <>
                                  <p className="font-semibold lg:text-lg mb-3 sm:mb-1 text-gray-900">
                                    {user?.numero_empleado ===
                                    assessment.numero_empleado
                                      ? question.title_auto
                                      : question.title}
                                  </p>
                                  <div className="grid grid-cols-4 gap-4">
                                    <div className="col-span-4 md:col-span-1">
                                      <RadioGroup
                                        value={
                                          answers[question.pregunta_id]
                                            ? answers[question.pregunta_id]
                                                .respuesta_switch
                                            : null
                                        }
                                        onChange={(value) =>
                                          changeSwitchAnswer(
                                            question.pregunta_id,
                                            value,
                                            answers[question.pregunta_id]
                                              ? answers[question.pregunta_id]
                                                  .respuesta_texto
                                              : ""
                                          )
                                        }
                                      >
                                        <div className="isolate -space-y-px rounded-md bg-white shadow-sm">
                                          <RadioGroup.Option
                                            value={true}
                                            className={({ checked }) =>
                                              classNames(
                                                checked
                                                  ? "bg-green-100 border-green-200 z-10"
                                                  : "border-gray-300",
                                                "rounded-tl-md rounded-tr-md relative border px-4 py-3 flex cursor-pointer focus:outline-none"
                                              )
                                            }
                                          >
                                            {({ active, checked }) => (
                                              <>
                                                <span
                                                  className={classNames(
                                                    checked
                                                      ? "bg-green-600 border-transparent"
                                                      : "bg-white border-gray-300",
                                                    active
                                                      ? "ring-2 ring-offset-2 ring-green-500"
                                                      : "",
                                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                  )}
                                                  aria-hidden="true"
                                                >
                                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                </span>
                                                <span className="ml-3 flex flex-col">
                                                  <RadioGroup.Label
                                                    as="span"
                                                    className={classNames(
                                                      checked
                                                        ? "text-green-900"
                                                        : "text-gray-900",
                                                      "block text-sm font-medium"
                                                    )}
                                                  >
                                                    SÍ
                                                  </RadioGroup.Label>
                                                </span>
                                              </>
                                            )}
                                          </RadioGroup.Option>
                                          <RadioGroup.Option
                                            value={false}
                                            className={({ checked }) =>
                                              classNames(
                                                checked
                                                  ? "bg-red-100 border-red-200 z-10"
                                                  : "border-gray-300",
                                                "rounded-bl-md rounded-br-md relative border px-4 py-3 flex cursor-pointer focus:outline-none"
                                              )
                                            }
                                          >
                                            {({ active, checked }) => (
                                              <>
                                                <span
                                                  className={classNames(
                                                    checked
                                                      ? "bg-red-600 border-transparent"
                                                      : "bg-white border-gray-300",
                                                    active
                                                      ? "ring-2 ring-offset-2 ring-red-500"
                                                      : "",
                                                    "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                  )}
                                                  aria-hidden="true"
                                                >
                                                  <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                </span>
                                                <span className="ml-3 flex flex-col">
                                                  <RadioGroup.Label
                                                    as="span"
                                                    className={classNames(
                                                      checked
                                                        ? "text-red-900"
                                                        : "text-gray-900",
                                                      "block text-sm font-medium"
                                                    )}
                                                  >
                                                    NO
                                                  </RadioGroup.Label>
                                                </span>
                                              </>
                                            )}
                                          </RadioGroup.Option>
                                        </div>
                                      </RadioGroup>
                                    </div>
                                    <div className="col-span-4 md:col-span-3">
                                      <textarea
                                        id={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                        name={`key-assessment-section-subsection-question-answer-${question.pregunta_id}`}
                                        placeholder={"Comentarios..."}
                                        rows={3}
                                        className="block w-full min-h-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                        value={
                                          answers[question.pregunta_id]
                                            ? answers[question.pregunta_id]
                                                .respuesta_texto
                                            : ""
                                        }
                                        onChange={(e) =>
                                          changeSwitchAnswer(
                                            question.pregunta_id,
                                            answers[question.pregunta_id]
                                              ? answers[question.pregunta_id]
                                                  .respuesta_switch
                                              : null,
                                            e.target.value
                                          )
                                        }
                                      />
                                    </div>
                                  </div>
                                </>
                              ),
                              5: (
                                <>
                                  <p className="font-semibold lg:text-lg mb-3 sm:mb-1 text-gray-900">
                                    {user?.numero_empleado ===
                                    assessment.numero_empleado
                                      ? question.title_auto
                                      : question.title}
                                  </p>
                                  <ul
                                    className={`w-full flex flex-col sm:flex-row justify-between  space-y-2 sm:space-y-0 sm:space-x-2 lg:space-x-4`}
                                  >
                                    {question.answers
                                      .sort(sortByOrder)
                                      .map((a) => (
                                        <li
                                          key={`key-assessment-section-subsection-question-answer-${a.respuesta_id}`}
                                          className={classNames(
                                            answers[question.pregunta_id]
                                              ?.respuesta_id == a.respuesta_id
                                              ? getSelectedAnswerClassName(
                                                  a.value,
                                                  question.answers
                                                )
                                              : "bg-white hover:bg-zinc-200 text-gray-900",
                                            "flex justify-between items-center p-3 w-full border rounded-md hover:cursor-pointer relative transition-all ease-in-out duration-300"
                                          )}
                                          onClick={(e) =>
                                            changeAnswer(
                                              question.pregunta_id,
                                              a.respuesta_id,
                                              a.value
                                            )
                                          }
                                        >
                                          <p className="font-medium flex-1 text-sm">
                                            {a.title}
                                          </p>
                                        </li>
                                      ))}
                                  </ul>
                                </>
                              ),
                            }[question.type]
                          }
                        </li>
                      ))}
                  </ul>
                </div>
              ))}
          </>
        )}
      </div>
      <BigModalTemplate ref={developmentModalRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Nueva acción de capacitación</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => developmentModalRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              ACCIÓN
            </label>
            <div className="mt-1">
              <input
                id="description"
                name="description"
                type="text"
                value={description}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setDescription(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("description") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("description") && (
                <p className="text-xs text-red-500">
                  Ingresa una acción de capacitación para continuar
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHA LÍMITE DE CUMPLIMIENTO
            </label>
            <div className="">
              <DatePicker
                onChange={setDateCreate}
                value={dateCreate}
                format="dd/MM/y"
                minDate={
                  new Date() > new Date(assessment.minima_fecha_objetivos)
                    ? new Date()
                    : new Date(assessment.minima_fecha_objetivos)
                }
                maxDate={new Date(assessment.maximo_fecha_objetivos)}
                calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                clearIcon={null}
                prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => developmentModalRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingDevelopment}
                onClick={addDevelopment}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loadingDevelopment ? "Creando plan..." : "Crear"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={simpleObjective} noClickOutside={true}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">
            {selectedNextObjective != 0 ? "Editar" : "Nuevo"} objetivo
          </p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => simpleObjective.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <p className="text-sm mb-3 text-gray-600">
          Recuerda, los objetivos y entregables deberán ser tan claros que una
          persona ajena a tu departamento pueda comprenderlos.
        </p>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              OBJETIVO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Objetivo SMART"
                value={title}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setTitle(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("title") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("title") && (
                <p className="text-xs text-red-500">
                  Ingresa el título del objetivo
                </p>
              )}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              ENTREGABLE TANGIBLE
            </label>
            <div className="mt-1">
              <input
                id="description"
                name="description"
                type="text"
                placeholder="Entregable tangible"
                value={description}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setDescription(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("description") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("description") && (
                <p className="text-xs text-red-500">
                  Ingresa el entregable tangible del objetivo
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHA LÍMITE DE CUMPLIMIENTO
            </label>
            <div className="">
              <DatePicker
                onChange={setDateCreate}
                value={dateCreate}
                format="dd/MM/y"
                minDate={
                  new Date() > new Date(assessment.minima_fecha_objetivos)
                    ? new Date()
                    : new Date(assessment.minima_fecha_objetivos)
                }
                maxDate={new Date(assessment.maximo_fecha_objetivos)}
                calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                clearIcon={null}
                prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => simpleObjective.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingDevelopment}
                onClick={addObjective}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {selectedNextObjective != 0
                  ? loadingDevelopment
                    ? "Editando objetivo..."
                    : "Editar"
                  : loadingDevelopment
                  ? "Creando objetivo..."
                  : "Crear"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={simpleProject} noClickOutside={true}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">
            {selectedNextObjective != 0 ? "Editar" : "Nuevo"} proyecto
          </p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => simpleProject.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        {/* <p className="text-sm mb-3 text-gray-600">
          Recuerda, los objetivos y entregables deberán ser tan claros que una
          persona ajena a tu departamento pueda comprenderlos.
        </p> */}
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="project-title"
              className="block text-xs text-red-500"
            >
              PROYECTO
            </label>
            <div className="mt-1">
              <input
                id="project-title"
                name="project-title"
                type="text"
                placeholder="Título del proyecto"
                value={title}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setTitle(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("title") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("title") && (
                <p className="text-xs text-red-500">
                  Ingresa el título del proyecto
                </p>
              )}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="project-weight"
              className="block text-xs text-red-500"
            >
              VALOR EN PORCENTAJE (MAXIMO 100% ENTRE TODOS LOS PROYECTOS)
            </label>
            <div className="mt-1">
              <input
                id="project-weight"
                name="project-weight"
                type="number"
                placeholder="Valor en porcentaje"
                value={weight}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setWeight(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("weight") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("weightmin") && (
                <p className="text-xs text-red-500">
                  El VALOR EN PORCENTAJE debe ser al menos 1.
                </p>
              )}
              {captureErrors.includes("weightmax") && (
                <p className="text-xs text-red-500">
                  El VALOR EN PORCENTAJE no debe superar 100
                </p>
              )}
              {captureErrors.includes("weight") && (
                <p className="text-xs text-red-500">
                  El VALOR EN PORCENTAJE de este proyecto más el VALOR EN PORCENTAJE de los demás proyectos no deben superar 100.
                </p>
              )}
            </div>
          </div>
          <div className="mb-8">
            <label
              htmlFor="project-date"
              className="block text-xs text-red-500"
            >
              FECHA LÍMITE DE CUMPLIMIENTO
            </label>
            <div className="w-full">
              <select
                id="project-date"
                name="project-date"
                value={semester}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setSemester(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("date") ? "border-red-500" : ""
                }`}
              >
                <option value={1}>Semestre 1</option>
                <option value={2}>Semestre 2</option>
              </select>
            </div>
          </div>
          <div className="mb-2">
            <div className="flex space-x-8">
              <p
                className="block text-gray-900"
              >
                RESULTADOS CLAVE
              </p>
              <button onClick={addKR} className="text-indigo-600 hover:text-indigo-900">
                Agregar resultado clave
              </button>
            </div>
            {captureErrors.includes("krcount") && (
              <p className="text-xs text-red-500">
                Debes ingresar al menos 3 resultados clave
              </p>
            )}
            <div className="w-full space-y-3 mt-4">
              {
                keyResults.map((kr, index)=>(
                  <div key={`kr-${index}`}>
                    <div className="flex space-x-2 items-center">
                      <p className="w-8">{index + 1}. </p>
                      <input
                        id={`kr-title-${index}`}
                        name={`kr-title-${index}`}
                        type="text"
                        placeholder={`Resultado clave ${index+1}`}
                        value={kr.titulo}
                        onChange={e => onChangeKRTitle(e.target.value, index)}
                        className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm`}
                      />
                      <button onClick={e => onDeleteKR(index)} className="text-red-600 hover:text-red-900">
                        <TrashIcon className="h-5 w-5"/>
                      </button>
                    </div>
                    {captureErrors.includes("krtitulo"+index) && (
                      <p className="text-xs text-red-500">
                        Debes ingresar el valor del resultado clave
                      </p>
                    )}
                  </div>
                ))
              }
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => simpleProject.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingDevelopment}
                onClick={addProject}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {selectedNextObjective != 0
                  ? loadingDevelopment
                    ? "Editando proyecto..."
                    : "Editar"
                  : loadingDevelopment
                  ? "Creando proyecto..."
                  : "Crear"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={simpleChildObjective}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">
            {selectedNextObjective != 0 ? "Editar" : "Nuevo"} objetivo
            secundario
          </p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => simpleChildObjective.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <p className="text-sm mb-3 text-gray-600">
          Recuerda, los objetivos y entregables deberán ser tan claros que una
          persona ajena a tu departamento pueda comprenderlos.
        </p>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              OBJETIVO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Objetivo SMART"
                value={title}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setTitle(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("title") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("title") && (
                <p className="text-xs text-red-500">
                  Ingresa el título del objetivo
                </p>
              )}
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              ENTREGABLE TANGIBLE
            </label>
            <div className="mt-1">
              <input
                id="description"
                name="description"
                type="text"
                placeholder="Entregable tangible"
                value={description}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setDescription(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("description") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("description") && (
                <p className="text-xs text-red-500">
                  Ingresa el entregable tangible del objetivo
                </p>
              )}
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHA LÍMITE DE CUMPLIMIENTO
            </label>
            <div className="">
              <DatePicker
                onChange={setDateCreate}
                value={dateCreate}
                format="dd/MM/y"
                minDate={
                  new Date() > new Date(assessment.minima_fecha_objetivos)
                    ? new Date()
                    : new Date(assessment.minima_fecha_objetivos)
                }
                maxDate={new Date(assessment.maximo_fecha_objetivos)}
                calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                clearIcon={null}
                prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => simpleChildObjective.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingDevelopment}
                onClick={addChildObjective}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {selectedNextObjective != 0
                  ? loadingDevelopment
                    ? "Editando objetivo..."
                    : "Editar"
                  : loadingDevelopment
                  ? "Creando objetivo..."
                  : "Crear"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={parentObjective}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">
            {selectedNextParentObjective != 0 ? "Editar" : "Nuevo"} objetivo
          </p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => parentObjective.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              OBJETIVO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Objetivo SMART"
                value={title}
                onChange={(e) => {
                  setCaptureErrors([]);
                  setTitle(e.target.value);
                }}
                className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                  captureErrors.includes("title") ? "border-red-500" : ""
                }`}
              />
              {captureErrors.includes("title") && (
                <p className="text-xs text-red-500">
                  Ingresa el título del objetivo
                </p>
              )}
            </div>
          </div>
          <div className="mb-4">
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  id="has-seconday-objetives"
                  name="has-seconday-objetives"
                  type="checkbox"
                  checked={hasSecondaryObjectives}
                  onChange={(e) => setHasSecondaryObjectives(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label
                  htmlFor="has-seconday-objetives"
                  className="font-medium text-gray-700"
                >
                  ¿Tendrá objetivos secundarios?
                </label>
                {/* <p className="text-gray-500">Get notified when someones posts a comment on a posting.</p> */}
              </div>
            </div>
          </div>
          {!hasSecondaryObjectives && (
            <div className="mb-4">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                ENTREGABLE TANGIBLE
              </label>
              <div className="mt-1">
                <input
                  id="description"
                  name="description"
                  type="text"
                  placeholder="Entregable tangible"
                  value={description}
                  onChange={(e) => {
                    setCaptureErrors([]);
                    setDescription(e.target.value);
                  }}
                  className={`block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm ${
                    captureErrors.includes("description")
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {captureErrors.includes("description") && (
                  <p className="text-xs text-red-500">
                    Ingresa el entregable tangible del objetivo
                  </p>
                )}
              </div>
            </div>
          )}
          {!hasSecondaryObjectives && (
            <div className="mb-2">
              <label
                htmlFor="question-description"
                className="block text-xs text-red-500"
              >
                FECHA LÍMITE DE CUMPLIMIENTO
              </label>
              <div className="">
                <DatePicker
                  onChange={setDateCreate}
                  value={dateCreate}
                  format="dd/MM/y"
                  minDate={
                    new Date() > new Date(assessment.minima_fecha_objetivos)
                      ? new Date()
                      : new Date(assessment.minima_fecha_objetivos)
                  }
                  maxDate={new Date(assessment.maximo_fecha_objetivos)}
                  calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                  clearIcon={null}
                  prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                  nextLabel={
                    <ChevronRightIcon className="h-5 w-5 text-white" />
                  }
                />
              </div>
            </div>
          )}
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => parentObjective.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingDevelopment}
                onClick={
                  hasSecondaryObjectives ? addParentObjective : addObjective
                }
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {selectedNextParentObjective != 0
                  ? loadingDevelopment
                    ? "Editando objetivo..."
                    : "Editar"
                  : loadingDevelopment
                  ? "Creando objetivo..."
                  : "Crear"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <CommonModalTemplate ref={deleteNextObjetiveRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Eliminar objetivo
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de querer eliminar este objetivo? Una vez
                eliminado, esta acción sólo puede ser revertida por el
                administrador
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteNextObjective}
          >
            Eliminar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteNextObjetiveRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={deleteNextProjectRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Eliminar proyecto
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de querer eliminar este proyecto y sus resultados clave? Una vez
                eliminado, esta acción no puede ser revertida
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteNextProject}
          >
            Eliminar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteNextProjectRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={deleteNextChildObjetiveRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Eliminar objetivo
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de querer eliminar este objetivo? Una vez
                eliminado, esta acción sólo puede ser revertida por el
                administrador
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteNextChildObjective}
          >
            Eliminar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteNextChildObjetiveRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={deleteNextParentObjetiveRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Eliminar objetivo
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de querer eliminar este objetivo padre? Una vez
                eliminado, esta acción sólo puede ser revertida por el
                administrador
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteNextParentObjective}
          >
            Eliminar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteNextParentObjetiveRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={deleteActionPlanRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Eliminar plan
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de querer eliminar este plan de acción? Una vez
                eliminado, esta acción sólo puede ser revertida por el
                administrador
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteActionPlan}
          >
            Eliminar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteActionPlanRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={confirmModalRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Cargar objetivos de área
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de cargar el archivo de objetivos de área?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingUploadShopping}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={saveShoppingObjectives}
          >
            {loadingUploadShopping ? "Cargando" : "Cargar"}
          </button>
          <button
            type="button"
            disabled={loadingUploadShopping}
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => confirmModalRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <Notification
        title={notificationTitle}
        message={notificationMessage}
        type={notificationType}
        ref={notificationRef}
      />
    </>
  );
};

AssessmentSections.propTypes = {
  section: PropTypes.object,
  assessment: PropTypes.object,
  objectives: PropTypes.array,
  projects: PropTypes.array,
  nextObjectives: PropTypes.array,
  nextParentObjectives: PropTypes.array,
  answers: PropTypes.object,
  salesObjectives: PropTypes.array,
  salesAnswers: PropTypes.object,
  setAnswers: PropTypes.func,
  setSalesAnswers: PropTypes.func,
  plans: PropTypes.array,
  setPlans: PropTypes.func,
  setNextObjectives: PropTypes.func,
  setNextParentObjectives: PropTypes.func,
  saveIntermediateAnswer: PropTypes.func,
  saveInstantAnswers: PropTypes.func,
  objectivesCallback: PropTypes.func,
};

AssessmentSections.defaultProps = {
  section: {},
  assessment: {},
  objectives: [],
  projects: [],
  nextObjectives: [],
  nextParentObjectives: [],
  answers: {},
  salesObjectives: [],
  salesAnswers: {},
  setSalesAnswers: () => {},
  setAnswers: () => {},
  plans: [],
  setPlans: () => {},
  setNextObjectives: () => {},
  setNextParentObjectives: () => {},
  saveIntermediateAnswer: () => {},
  saveInstantAnswers: () => {},
  objectivesCallback: () => {},
};

export default AssessmentSections;
