import { EyeIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { useRef } from "react";
import { classNames } from "../../constants/functions";
import { months_short_es } from "../../utils/date_utils";
import ObjectiveProgressModal from "../modals/ObjectiveProgressModal";

const HistoryObjective = ({ id, status, title, titleParent, description, dueDate }) => {
  const progressRef = useRef();

  const getObjectiveStatusClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 4:
        className = "bg-blue-100 text-blue-800";
        break;
      default:
        className = "bg-red-100 text-red-800";
        break;
    }
    return className;
  };

  const getObjectiveStatusLabel = (status_id) => {
    let className = "";
    switch (status_id) {
      case 4:
        className = "Alcanzado";
        break;
      default:
        className = "No alcanzado";
        break;
    }
    return className;
  };

  const watchProgress = () => {
    progressRef.current.showModal(id);
  };

  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames("relative bg-white px-6 py-3 flex")}
    >
      <div className="flex-1 flex flex-col">
        <div className="flex justify-end">
          <span
            className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${getObjectiveStatusClassName(
              status
            )}`}
          >
            {getObjectiveStatusLabel(status)}
          </span>
        </div>
        {titleParent && <h3 className="text-lg font-bold underline focus:outline-none">{titleParent}</h3>}
        <h3 className="text-lg font-medium focus:outline-none">{title}</h3>
        <p className="mt-1 text-sm text-gray-500 flex-1">{description}</p>
        <p className="mt-1 mb-2 text-sm text-gray-900 font-normal">
          Fecha de entrega:{" "}
          <span className="underline">
            {new Date(dueDate).getDate()} de {months_short_es[new Date(dueDate).getMonth()]}. del {new Date(dueDate).getFullYear()}
          </span>
        </p>
        <div className="flex text-sm font-medium mt-2 flex-wrap">
          <button
            onClick={watchProgress}
            type="button"
            className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
          >
            <EyeIcon className="h-5 w-5 mr-1"/>
            Ver progreso
          </button>
        </div>
      </div>
      <ObjectiveProgressModal ref={progressRef} />
    </div>
  );
};

HistoryObjective.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  periodStatus: PropTypes.number,
  title: PropTypes.string,
  titleParent: PropTypes.string,
  dueDate: PropTypes.string,
  startDate: PropTypes.string,
  description: PropTypes.string,
  superior: PropTypes.bool,
  self: PropTypes.bool
};

HistoryObjective.defaultProps = {
  id: 1,
  status: 1,
  periodStatus: 1,
  superior: false,
  self: false,
  titleParent: null,
  title: "Default title",
  dueDate: "2023-01-01",
  startDate: "2023-01-01",
  description: "Default description",
};

export default HistoryObjective;
