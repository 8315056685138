import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AdminHistoryObjective from "../../components/cards/AdminHistoryObjective";
import PlusFolder from "../../components/vectors/PlusFolder";
import API from "../../services/API";
import face from "./../../assets/img/faces/avatar.png";

export default function EmployeeObjectives() {
  const [person, setPerson] = useState({});
  const [periods, setPeriods] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [allObjectives, setAllObjectives] = useState([]);
  const pathParams = useParams();
  const {user,token} = useSelector(state => state.authentication);
  useEffect(()=>{
    const fetchUser = () => {
      const headers = { Authorization: token }
      API.get('/users/'+pathParams.employeeId, {headers})
      .then(({ data })=>{
        setPerson(data);
      })
      .catch((err)=>{
        setPerson({});
      })
    }
    const fetchPeriods = () => {
      const headers = { Authorization: token }
      API.get('/periods', { headers})
      .then(({ data })=>{
        fetchObjectives(data, pathParams.employeeId);
      })
      .catch((err)=>{
        setPeriods([]);
      })
    }
    fetchPeriods();
    fetchUser();
  },[]);

  const fetchObjectives = useCallback(async(periods, employee)=>{
    const fetchObjectives = async(periodo_id) => {
      const params = {
        users: employee,
        hist_period: periodo_id
      }
      try {
        const headers = { Authorization: token }
        let response = await API.get('/objectives', { headers, params });
        return response.data
      } catch (error) {
        return []
      }
    }
    let now = new Date()
    let yearPeriods = [...periods].filter(p => new Date(p.fecha_fin).getFullYear() == now.getFullYear());
    if(now.getMonth() < 3){
      let lastYearDate = new Date();
      lastYearDate.setFullYear(lastYearDate.getFullYear() - 1)
      yearPeriods = [...periods].filter(p => new Date(p.fecha_fin).getFullYear() == lastYearDate.getFullYear());
    }
    let obj = []
    let allObj = []
    for(let period of yearPeriods){
        let newObj = await fetchObjectives(period.periodo_id);
        allObj = [...allObj, ...newObj];
        let newPer = {
            ...period,
            objectives: newObj || []
        }
        obj = [...obj, newPer];
    }
    setPeriods(obj);
    setAllObjectives(allObj);
  }, []);

  return (
    <div className="flex-1 w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-16 w-16 rounded-full"
                    src={person?.imagen_perfil ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${person?.imagen_perfil}` : face}
                    alt=""
                  />
                  <span className="absolute inset-0 rounded-full shadow-inner" aria-hidden="true" />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{person?.nombre_completo}</h1>
                <p className="text-sm font-medium text-gray-500">
                  {person?.puesto}
                </p>
              </div>
            </div>
            <div className="w-full px-4 pt-16">
              <div className="mx-auto w-full rounded-2xl">
                {
                  periods.map((periodo)=>(
                    <Disclosure as="div" className="mt-2" key={`key-period-${periodo.periodo_id}`}>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className="flex w-full justify-between rounded-lg bg-red-100 px-4 py-4 text-left text-sm font-medium text-red-900 hover:bg-red-200 focus:outline-none focus-visible:ring focus-visible:ring-red-500 focus-visible:ring-opacity-75">
                            <span>{periodo.titulo}</span>
                            <div className="flex items-center">
                              <span>
                                {
                                  periodo.objectives.length > 0
                                  ? `${periodo.objectives.filter(o => o.estatus_objetivo_id == 4).length} de ${periodo.objectives.length} (${(periodo.objectives.filter(o => o.estatus_objetivo_id == 4).length/periodo.objectives.length).toFixed(2)})`
                                  : "Sin objetivos"
                                }
                              </span>
                              <ChevronUpIcon
                                className={`${
                                  open ? "" : "rotate-180 transform"
                                } ml-1 h-5 w-5 text-red-500`}
                              />
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                            <div className="mt-3 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow">
                             {
                                periodo.objectives.map(objective => (
                                  <AdminHistoryObjective
                                    key={`key-objetive-row-${objective.objetivo_id}`}
                                    id={objective.objetivo_id}
                                    status={objective.estatus_objetivo_id}
                                    title={objective.titulo}
                                    description={objective.descripcion}
                                    dueDate={objective.fecha_entrega+"T00:00:00"}
                                  />
                                ))
                              }
                            </div>
                            {
                              periodo.objectives.length < 1 && <div className="text-center mt-3">
                                <PlusFolder />
                                <h3 className="mt-2 text-sm font-medium text-gray-900">No hay objetivos en el periodo</h3>
                                <p className="mt-1 text-sm text-gray-500">El usuario aún no terminado su evaluación del.</p>
                              </div>
                            }
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
