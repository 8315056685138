import { useEffect, useRef, useState, Fragment, useCallback } from "react";
import { Transition } from "@headlessui/react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../services/API";
import API2 from "../../services/API2";
import logo from "./../../assets/img/brand/logo.svg";
import backwall from "./../../assets/img/brand/BACK_WALL_WELCOME.png";
import BACK_WALL_1S from "./../../assets/img/brand/BACK_WALL_1S.png";
import { CalendarIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import Notification from "../../components/notifications/Notification";
import CommonModalTemplate from "../../components/modals/CommonModalTemplate";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import AssessmentSections from "../../components/sections/AssessmentSections";
import { sortByOrder } from "../../constants/functions";
import {default as WDatePicker} from "react-date-picker/dist/entry.nostyle";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default function AnswerAssessment() {
  const [assessment, setAssessment] = useState([]);
  const [shoppingObjectives, setShoppingObjectives] = useState([]);
  const [answers, setAnswers] = useState({});
  const [dateFeedback, setDateFeedback] = useState(new Date());
  const [activeTab, setActiveTab] = useState("");
  const [sections, setSections] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [projects, setProjects] = useState([]);
  const [salesObjectives, setSalesObjectives] = useState([]);
  const [salesAnswers, setSalesAnswers] = useState({});
  const [plans, setPlans] = useState([]);
  const [currentMenuDirection, setCurrentMenuDirection] = useState([0, true]);
  const [saveTimeout, setSaveTimeout] = useState();
  const [loading, setLoading] = useState(true);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [assessmentSent, setAssessmentSent] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [errorOnAssessmentTitle, setErrorOnAssessmentTitle] = useState("");
  const [errorOnAssessmentMessage, setErrorOnAssessmentMessage] = useState("");
  const [nextObjectives, setNextObjectives] = useState([]);
  const [nextProjects, setNextProjects] = useState([]);
  const [nextParentObjectives, setNextParentObjectives] = useState([]);
  const modalRef = useRef();
  const modalRefEnd = useRef();
  const topRef = useRef();
  const notificationRef = useRef();
  const selfAssessmentRef = useRef();
  const bossAssessmentRef = useRef();
  const bossNoAutoAssessmentRef = useRef();
  const errorOnAssessmentRef = useRef();
  const questionsRefs = useRef([]);
  const pathParams = useParams();
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.authentication);

  useEffect(() => {
    const getUserAssessment = (id) => {
      const params = { user_assessment: id };
      API.get(`/assessments`, {
        headers: { Authorization: token },
        params,
      })
        .then(({ status, data }) => {
          let assess = data[0];
          if (!assess) {
            navigate("/t3b/home", { replace: true });
          }
          if (status === 200) {
            let now = new Date();
            let startDate = new Date(assess.fecha_inicio+"T00:00:00");
            let endDate = new Date(assess.fecha_fin+"T23:59:59");
            if(now < startDate || now > endDate){
              navigate("/t3b/home", { replace: true });
            } else if (assess.numero_empleado != user?.numero_empleado && !user?.colaboradores.includes(assess.numero_empleado)) {
              navigate("/t3b/home", { replace: true });
            }
            setAssessment(assess);
            // modalRef.current.showModal();
          } else if (status == 204) {
            setError("El formato no tiene información");
            notificationRef.current.showNotification();
          } else {
            setError(
              "Ha ocurrido un error al buscar la información del formato"
            );
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            } else {
              setError(error.response.data);
            }
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar el formato"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar el formato"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    if (!assessment.evaluacion_usuario_id) {
      getUserAssessment(pathParams.assessmentId);
    }
  }, [pathParams.assessmentId]);

  useEffect(() => {
    let headers = { Authorization: token };

    const filterSections = (section) => {
      if (assessment.numero_empleado === user?.numero_empleado) {
        return section.type_assessment !== 3;
      }
      if (user?.colaboradores.includes(assessment.numero_empleado)) {
        return section.type_assessment !== 2;
      }
      return true;
    };

    const getAssessmentContent = () =>
      new Promise((resolve, reject) => {
        API.get(`/forms/${assessment.formato_id}/content`, { headers })
          .then(({ status, data }) => {
            if (status === 200) {
              let filtered = data.formato_json
                .filter(filterSections)
                .sort((a, b) => a.order - b.order);
              setActiveTab(filtered[0].seccion_id);
              setSections(filtered);
              resolve();
              // getAnswers(filtered);
            }
          })
          .catch((error) => {
            reject(
              new Error(
                "Ha ocurrido un error al al cargar el contenido del formato"
              )
            );
          });
      });

    const getUserObjectives = (numero_empleado, period) =>
      new Promise((resolve, reject) => {
        const params = { users: numero_empleado, period };
        const options = { params, headers };
        API.get("/objectives", options)
          .then(({ data, status }) => {
            if (status === 200) {
              setObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(
              new Error(
                "Ha ocurrido un error al al cargar los objetivos del usuario"
              )
            );
          });
      });

    const getUserProyects = (numero_empleado, period) =>
      new Promise((resolve, reject) => {
        const params = { current_users: numero_empleado, projects: true, period };
        const options = { params, headers };
        API.get("/objectives", options)
          .then(({ data, status }) => {
            if (status === 200) {
              setProjects(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(
              new Error(
                "Ha ocurrido un error al al cargar los proyectos del usuario"
              )
            );
          });
      });

    const getSalesObjectiveAnswers = () =>
      new Promise((resolve, reject) => {
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/salesobjectives`,
          { headers }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let newSalesAnswers = {};
              for (let answer of data) {
                newSalesAnswers[answer.objetivo_venta_id] = { ...answer };
              }
              setSalesObjectives(data);
              setSalesAnswers(newSalesAnswers);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getAnswers = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          type: assessment.numero_empleado === user.numero_empleado ? 1 : 2,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/answers`,
          { headers, params }
        )
          .then(({ status, data }) => {
            let newAnswers = {};
            if (status === 200) {
              for (let answer of data) {
                newAnswers[answer.pregunta_id] = { ...answer };
              }
            }
            setAnswers((prevState) => {
              return { ...newAnswers };
            });
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getPlans = () =>
      new Promise((resolve, reject) => {
        let users =
          assessment.numero_empleado === user.numero_empleado
            ? user.numero_empleado
            : user.colaboradores.toString();
        const params = { users, period: assessment.evaluacion_usuario_id };
        return API.get(`/plans`, { headers, params })
          .then(({ status, data }) => {
            if (status === 200) {
              if (assessment.numero_empleado === user.numero_empleado) {
                setPlans(
                  data.filter(
                    (a) =>
                      a.evaluacion_registrado_id ===
                        assessment.evaluacion_usuario_id &&
                      Number(a.tipo_plan) === 2
                  )
                );
              } else {
                setPlans(
                  data.filter(
                    (a) =>
                      a.evaluacion_registrado_id ===
                        assessment.evaluacion_usuario_id &&
                      Number(a.tipo_plan) === 1
                  )
                );
              }
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getShoppingObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          evaluacion_calificado_id: assessment.evaluacion_usuario_id,
        };
        return API.get(`assessments/${assessment.evaluacion_usuario_id}/shoppingobjectives`,
        { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if(status==200){
              setShoppingObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          tipo_objetivo_id:
            assessment.numero_empleado === user.numero_empleado ? 1 : 2,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives`,
          { headers, params }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getNextProjects = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        return API2.get(
          `/edd/evaluacion-usuario/${assessment.evaluacion_usuario_id}/next-projects`,
          { headers }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextProjects(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getNextParentObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          tipo_objetivo_id:
            assessment.numero_empleado === user.numero_empleado ? 1 : 2,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives-parent`,
          { headers, params }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextParentObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getAssessmentInfo = () => {
      Promise.all([
        getAssessmentContent(),
        getUserObjectives(assessment.numero_empleado, assessment.periodo_id),
        getUserProyects(assessment.numero_empleado, assessment.periodo_id),
        getAnswers(),
        getSalesObjectiveAnswers(),
        getNextObjectives(),
        getNextParentObjectives(),
        getNextProjects(),
        getShoppingObjectives(),
        getPlans(),
      ])
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
          notificationRef.current.showNotification();
        });
    };
    if (assessment.evaluacion_usuario_id) {
      getAssessmentInfo();
    }
  }, [assessment]);

  const getUserObjectivesCallback = () => {
    let headers = { Authorization: token };
    const params = { users: assessment.numero_empleado, period: assessment.periodo_id };
    const options = { params, headers };
    API.get("/objectives", options)
      .then(({ data, status }) => {
        if (status === 200) {
          return setObjectives(data);
        }
        setLoading(false);
        setError("Ha ocurrido un error al al cargar los objetivos del usuario");
        notificationRef.current.showNotification();
      })
      .catch((error) => {
        setLoading(false);
        setError("Ha ocurrido un error al al cargar los objetivos del usuario");
        notificationRef.current.showNotification();
      });
  }

  const errorInPage = async(page) => {
    let err = false;
    let errQuestion = null;
    if (page) {
      const { type } = page;
      let unanswered = [];
      if ([1,40].includes(type)) {
        for (let i = 0; i < page.subsections.length; i++) {
          let sub = page.subsections[i];
          for (let j = 0; j < sub.questions.length; j++) {
            let ques = sub.questions[j];
            let titleQuestion =
              user?.numero_empleado === assessment.numero_empleado
                ? ques.title_auto
                : ques.title;
            if (!answers[ques.pregunta_id]) {
              unanswered.push(ques.pregunta_id);
              errQuestion = ques.pregunta_id;
              setError(`Responde la pregunta: ${titleQuestion}`);
              err = true;
              break;
            } else if (
              ques.type == 1 &&
              answers[ques.pregunta_id]?.respuesta_texto == ""
            ) {
              unanswered.push(ques.pregunta_id);
              errQuestion = ques.pregunta_id;
              setError(`Responde la pregunta: ${titleQuestion}`);
              err = true;
              break;
            } else if (
              ques.type == 1 &&
              answers[ques.pregunta_id]?.respuesta_texto?.length < 70
            ) {
              unanswered.push(ques.pregunta_id);
              errQuestion = ques.pregunta_id;
              setError(`La respuesta debe contener al menos 70 caracteres de la pregunta: ${titleQuestion}`);
              err = true;
              break;
            } else if (
              ques.type == 3 &&
              (answers[ques.pregunta_id]?.respuesta_switch == null ||
                answers[ques.pregunta_id]?.respuesta_texto == "" ||
                answers[ques.pregunta_id]?.respuesta_texto == null)
            ) {
              unanswered.push(ques.pregunta_id);
              errQuestion = ques.pregunta_id;
              setError(
                `Responde la pregunta y el comentario de: ${titleQuestion}`
              );
              err = true;
              break;
            } else if (
              ques.type == 3 &&
              answers[ques.pregunta_id]?.respuesta_texto?.length < 70
            ) {
              unanswered.push(ques.pregunta_id);
              errQuestion = ques.pregunta_id;
              setError(
                `El comentario debe contener al menos 70 caracteres de la pregunta: ${titleQuestion}`
              );
              err = true;
              break;
            }
          }
          if (err) {
            break;
          }
        }
      } else if (type == 33) {
        if(nextParentObjectives.length < 1 && nextObjectives.length < 1){
          setError(`Ingresa al menos 1 objetivo para realizar el siguiente año`);
          err = true;
        } else {
          //revisar si en los objetivos hay uno del semestre uno viendo también la fecha del día de hoy
          let today = new Date();
          let firstDateFirstSemester = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
          let lastDateFirstSemester = new Date(today.getFullYear(), 5, 30, 23, 59, 59, 999);
          if(today < lastDateFirstSemester && assessment.objetivos_s1){
            let hasRequiredFirstSemester = true;
            for (let index = 0; index < nextObjectives.length; index++) {
              const element = nextObjectives[index];
              let fechaEntrega = new Date(element.fecha_entrega);
              if(fechaEntrega >= firstDateFirstSemester && fechaEntrega <= lastDateFirstSemester){
                hasRequiredFirstSemester = false;
                break;
              }
            }
            if(hasRequiredFirstSemester){
              setError(assessment.objetivos_s1_redaccion);
              err = true;
            }
          }

          let firstDateSecondSemester = new Date(today.getFullYear(), 6, 1, 0, 0, 0, 0);
          let lastDateSecondSemester = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999);
          let hasRequiredSecondSemester = true;
          if(assessment.objetivos_s2){
            for (let index = 0; index < nextObjectives.length; index++) {
              const element = nextObjectives[index];
              let fechaEntrega = new Date(element.fecha_entrega);
              if(fechaEntrega >= firstDateSecondSemester && fechaEntrega <= lastDateSecondSemester){
                hasRequiredSecondSemester = false;
                break;
              }
            }
            if(hasRequiredSecondSemester){
              setError(assessment.objetivos_s2_redaccion);
              err = true;
            }
          }
        }
      } else if (type == 5) {
        if(plans.length < 1){
          setError(`Ingresa al menos una acción de capacitación para realizar el siguiente año`);
          err = true;
        }
      } else if (type == 6) {
        if(plans.length < 1){
          setError(`Ingresa al menos una acción de capacitación para realizar el siguiente año`);
          err = true;
        }
      } else if (type == 8) {
        let porcentaje = 0;
        for (let index = 0; index < nextProjects.length; index++) {
          const element = nextProjects[index];
          if(!element.activo){
            continue;
          }
          porcentaje += Number(element.valor);
        }
        if (porcentaje != 100) {
          setError(`La suma del VALOR EN PORCENTAJE de todos los proyectos, debe sumar 100`);
          err = true;
        }
        if(nextProjects.filter(p => p.activo).length < 3){
          setError(`Ingresa al menos 3 proyectos para realizar el siguiente periodo`);
          err = true;
        }
      } else if (type == 3) {
        if(nextObjectives.length < 1){
          setError(`Ingresa al menos 1 objetivo para realizar el siguiente año`);
          err = true;
        } else {
          //revisar si en los objetivos hay uno del semestre uno viendo también la fecha del día de hoy
          let today = new Date();
          let firstDateFirstSemester = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
          let lastDateFirstSemester = new Date(today.getFullYear(), 5, 30, 23, 59, 59, 999);
          if(today < lastDateFirstSemester && assessment.objetivos_s1){
            let hasRequiredFirstSemester = true;
            for (let index = 0; index < nextObjectives.length; index++) {
              const element = nextObjectives[index];
              let fechaEntrega = new Date(element.fecha_entrega);
              if(fechaEntrega >= firstDateFirstSemester && fechaEntrega <= lastDateFirstSemester){
                hasRequiredFirstSemester = false;
                break;
              }
            }
            if(hasRequiredFirstSemester){
              setError(assessment.objetivos_s1_redaccion);
              err = true;
            }
          }

          let firstDateSecondSemester = new Date(today.getFullYear(), 6, 1, 0, 0, 0, 0);
          let lastDateSecondSemester = new Date(today.getFullYear(), 11, 31, 23, 59, 59, 999);
          let hasRequiredSecondSemester = true;
          if(assessment.objetivos_s2){
            for (let index = 0; index < nextObjectives.length; index++) {
              const element = nextObjectives[index];
              let fechaEntrega = new Date(element.fecha_entrega);
              if(fechaEntrega >= firstDateSecondSemester && fechaEntrega <= lastDateSecondSemester){
                hasRequiredSecondSemester = false;
                break;
              }
            }
            if(hasRequiredSecondSemester){
              setError(assessment.objetivos_s2_redaccion);
              err = true;
            }
          }
        }
      } else if (type == 4) {
        for (let i = 0; i < salesObjectives.length; i++) {
          let objective = salesObjectives[i];
          if (!salesAnswers[objective.objetivo_venta_id]) {
            let titleQuestion = objective.titulo;
            setError(`Responde el comentario de: ${titleQuestion}`);
            err = true;
            break;
          } else if (
            user?.numero_empleado === assessment.numero_empleado &&
            (!salesAnswers[objective.objetivo_venta_id]
              .comentarios_autoevaluacion ||
              salesAnswers[objective.objetivo_venta_id]
                .comentarios_autoevaluacion == "")
          ) {
            let titleQuestion = objective.titulo;
            setError(`Responde el comentario de: ${titleQuestion}`);
            err = true;
            break;
          } else if (
            user.colaboradores.includes(assessment.numero_empleado) &&
            (!salesAnswers[objective.objetivo_venta_id].comentarios ||
              salesAnswers[objective.objetivo_venta_id].comentarios == "")
          ) {
            let titleQuestion = objective.titulo;
            setError(`Responde el comentario de: ${titleQuestion}`);
            err = true;
            break;
          }
        }
      } else if (type == 34) {
        let params = {
          evaluacion_registrado_id: assessment.evaluacion_usuario_id,
        };
        try {
          let response = await API.get(`assessments/${assessment.evaluacion_usuario_id}/shoppingobjectives`,{ params, headers: { Authorization: token } })
          console.log(response);
          console.log(response.data);
          if(response.data.length < 1){
            setError(`Debes subir los objetivos del área que vas a realizar en el siguiente periodo.`);
            err = true;
          }
        } catch (error) {
          setError(`No se han podido consultar los objetivos del área registrados en la evaluación`);
          err = true;
        }
      }
    }
    return [err, errQuestion];
  }

  const goNextPage = async (index, page) => {
    let [err, errQuestion] = await errorInPage(page);
    if (err) {
      setTimeout(() => {
        notificationRef.current.hideNotification();
      }, 4000);
      if(errQuestion){
        questionsRefs.current[errQuestion].scrollIntoView({behavior: 'smooth'});
      }
      return notificationRef.current.showNotification();
    }
    saveInstantAnswers();
    setCurrentMenuDirection([index + 1, true]);
    setTimeout(() => {
      topRef.current.scrollTo(0, 0);
    }, 200);
  };

  const saveAssessment = () => {
    let hasSales = false;
    let body = {
      hasSales,
      pastObjectives: objectives,
      pastProjects: projects,
      answers: Object.values(answers),
      sales: Object.values(salesAnswers),
      nextObjectives,
      plans,
      user: assessment.numero_empleado,
      assessment_type:
        assessment.numero_empleado === user.numero_empleado ? 1 : 2,
      assessment_status: 2,
      autoevaluacion: true
    };
    if(user.colaboradores.includes(assessment.numero_empleado)){
      body.evaluacion = true;
    } else {
      body.iniciada = true;
    }
    setLoadingEnd(true);
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/answers`, body, { headers: { Authorization: token } })
      .then((result) => {
        setLoadingEnd(false);
        setAssessmentSent(true);
        selfAssessmentRef.current.hideModal();
        bossAssessmentRef.current.hideModal();
        bossNoAutoAssessmentRef.current.hideModal();
        setTimeout(()=>{
          let navigateURL = "/t3b/home";
          if(user.colaboradores.includes(assessment.numero_empleado)){
            navigateURL = `/t3b/employees/${assessment.numero_empleado}`;
          }
          navigate(navigateURL, { replace: true });
        },10000);
      })
      .catch((error) => {
        setLoadingEnd(false);
        setErrorTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al guardar la entrevista"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al guardar la entrevista"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const endAssessment = () => {
    let hasSales = false;
    let body = {
      hasSales,
      pastObjectives: objectives,
      pastProjects: projects,
      answers: Object.values(answers),
      sales: Object.values(salesAnswers),
      nextObjectives,
      plans,
      user: assessment.numero_empleado,
      assessment_type: assessment.numero_empleado === user.numero_empleado ? 1 : 2,
      assessment_status: 2,
      evaluacion: true,
      numero_empleado_evaluador: user.numero_empleado,
      revision_fecha: dateFeedback
    };
    setLoadingEnd(true);
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/answers`, body, { headers: { Authorization: token } })
      .then((result) => {
        setLoadingEnd(false);
        bossAssessmentRef.current.hideModal()
        bossNoAutoAssessmentRef.current.hideModal()
        setAssessmentSent(true);
        if(assessment.siguiente_periodo){
          sendEmail();
        }
        setTimeout(()=>{
          let navigateURL = "/t3b/home";
          navigate(navigateURL, { replace: true });
        },10000);
      })
      .catch((error) => {
        setLoadingEnd(false);
        setErrorTitle("Error");
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al guardar la entrevista"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al guardar la entrevista"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const sendEmail = useCallback(()=>{
    const email = {
      period: assessment.periodo_nombre,
      name_to: assessment.nombre_completo,
      email_to: assessment.email || user.email,
      name_from: user.nombre_completo,
      email_from: user.email,
      start_date: dateFeedback.toISOString()
    }
    API.post(`/sendrevisionmail`, email, { headers: {accept: 'text/Calendar', Authorization: token} })
    .then((result)=>{
        if(result.status===200){
            let a = document.createElement("a");
            a.href = "data:text/Calendat;base64," + result.data;
            a.download = "revision.ics"; //File name Here
            a.click();
        }
    })
    .catch( error =>{
      setErrorTitle("Error");
      if (error.response) {
        if (error.response.status === 401) {
          setError("La sesión ha caducado");
        }
        setError(error.response.data);
      } else if (error.request) {
        setError(
          "La petición fue realizada, pero no hubo respuesta por parte del servidor enviar el mail de retroalimentación"
        );
      } else {
        setError(
          "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet enviar el mail de retroalimentación"
        );
      }
      notificationRef.current.showNotification();
    })
  },[token, assessment, user, dateFeedback])

  const saveIntermediateAnswer = () => {
    clearTimeout(saveTimeout);
    let time = setTimeout(() => {
      saveAssessmentNoExit(2);
    }, 6000);
    setSaveTimeout(time);
  };

  const saveInstantAnswers = () => {
    saveAssessmentNoExit(2);
  };

  const clickSendAssessment = async (page) => {
    saveInstantAnswers();
    let [err] = await errorInPage(page);
    if (err) {
      setTimeout(() => {
        notificationRef.current.hideNotification();
      }, 4000);
      return notificationRef.current.showNotification();
    }
    if(user.numero_empleado==assessment.numero_empleado){
      selfAssessmentRef.current.showModal();
    } else {
      if(assessment.siguiente_periodo){
        bossAssessmentRef.current.showModal();
      } else {
        bossNoAutoAssessmentRef.current.showModal();
      }
    }
  };

  const saveAssessmentNoExit = (status) => {
    let hasSales = false;
    let body = {
      hasSales,
      pastObjectives: objectives,
      pastProjects: projects,
      answers: Object.values(answers),
      sales: Object.values(salesAnswers),
      nextObjectives,
      nextParentObjectives,
      plans,
      user: assessment.numero_empleado,
      assessment_type:
        assessment.numero_empleado === user.numero_empleado ? 1 : 2,
      assessment_status: status,
      iniciada: true,
    };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/answers`, body, { headers: { Authorization: token } })
      .then((result) => {})
      .catch((error) => {
      });
  };

  const goPrevPage = (index) => {
    if (index == 0) {
      return setCurrentMenuDirection([-1, false]);
    }
    setCurrentMenuDirection([index - 1, false]);
    setTimeout(() => {
      topRef.current.scrollTo(0, 0);
    }, 200);
  };

  const setHoursDatePicker = (date, hours) => {
    let newDate = new Date(date);
    newDate.setHours(hours, 0, 0, 0);
    return newDate;
  }

  const setHoursMinutesDatePicker = (date, hours, minutes) => {
    let newDate = new Date(date);
    newDate.setHours(hours, minutes, 0, 0);
    return newDate;
  }

  const [currentMenu, direction] = currentMenuDirection;

  return (
    <>
      {loading ? (
        <div className="min-h-screen w-full h-full flex items-center justify-center bg-white">
          <video
            className="w-96 h-96 flex flex-1"
            alt={"Tiendas 3B"}
            muted
            loop
            autoPlay
          >
            <source
              src={"https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"}
              type="video/mp4"
            />
            Sin soporte de video MP4
          </video>
        </div>
      ) : (
        <>
          {!assessmentSent ? (
            <main
              className="relative min-w-screen flex-1 bg-zinc-100 overflow-y-auto custom-scrollbar z-10"
              ref={topRef}
            >
              <div className="sticky top-0 bg-red-200 h-2 z-20 w-full">
                <div
                  className="bg-red-700 h-2 transition-all ease-in-out duration-500"
                  style={{
                    width: `${((currentMenu + 1) / sections.length) * 100}%`,
                  }}
                ></div>
              </div>
              {sections?.length > 0 &&
                sections?.sort(sortByOrder).map((page, indexPages) => (
                  <Fragment key={`key-page-${page.seccion_id}`}>
                    <Transition
                      as={Fragment}
                      show={currentMenu == indexPages}
                      enter="transform transition duration-500"
                      enterFrom={
                        direction ? "translate-x-full" : "-translate-x-full"
                      }
                      enterTo={direction ? "translate-x-0" : "translate-x-0"}
                      leave="transform duration-500 transition ease-in-out"
                      leaveFrom={direction ? "translate-x-0" : "translate-x-0"}
                      leaveTo={
                        direction ? "-translate-x-full" : "translate-x-full"
                      }
                    >
                      <div className="flex-1 flex flex-col justify-start absolute top-0 w-full z-10 h-full">
                        <div className="mb-2 shadow-lg border-gray-300 relative w-full bg-white">
                          <p className="relative tracking-widest text-3xl md:text-5xl text-red-500 text-center py-12 flex flex-col font-bold uppercase z-10 whitespace-pre-wrap">
                            {page.title}
                          </p>
                        </div>
                        <div className="relative z-10 flex-1">
                          <div className="relative mt-8 flex flex-col items-center justify-center py-2 px-2 max-w-7xl mx-auto z-10">
                            <AssessmentSections
                              section={page}
                              assessment={assessment}
                              objectives={objectives}
                              projects={projects}
                              nextObjectives={nextObjectives}
                              nextProjects={nextProjects}
                              nextParentObjectives={nextParentObjectives}
                              shoppingObjectives={shoppingObjectives}
                              answers={answers}
                              salesObjectives={salesObjectives}
                              salesAnswers={salesAnswers}
                              setSalesAnswers={setSalesAnswers}
                              setAnswers={setAnswers}
                              plans={plans}
                              setPlans={setPlans}
                              setNextObjectives={setNextObjectives}
                              setNextProjects={setNextProjects}
                              setNextParentObjectives={setNextParentObjectives}
                              saveIntermediateAnswer={saveIntermediateAnswer}
                              saveInstantAnswers={saveInstantAnswers}
                              objectivesCallback={getUserObjectivesCallback}
                              questionsRefs={questionsRefs}
                            />
                          </div>
                        </div>
                        <div className="p-8 border-t border-gray-300 flex justify-end space-x-2 bg-white">
                          {indexPages > 0 && (
                            <button
                              onClick={(e) => goPrevPage(indexPages)}
                              className="relative inline-flex items-center rounded-md px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-200 focus:z-20 transition duration-150 ease-in"
                            >
                              <span className="sr-only">Anterior</span>
                              <ChevronLeftIcon
                                className="h-9 w-9"
                                aria-hidden="true"
                              />
                              Anterior
                            </button>
                          )}
                          {indexPages + 1 == sections?.length ? (
                            <button
                              className="relative inline-flex items-center rounded-md border border-red-700 bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:z-20"
                              onClick={(e) => clickSendAssessment(page)}
                            >
                              <span className="sr-only">Enviar</span>
                              Enviar
                            </button>
                          ) : (
                            <button
                              onClick={(e) => goNextPage(indexPages, page)}
                              className="relative inline-flex items-center rounded-md border border-red-700 bg-red-600 px-2 py-2 text-sm font-medium text-white hover:bg-red-700 focus:z-20"
                            >
                              Siguiente
                              <span className="sr-only">Siguiente</span>
                              <ChevronRightIcon
                                className="h-9 w-9"
                                aria-hidden="true"
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    </Transition>
                  </Fragment>
                ))}
            </main>
          ) : (
            <div className="w-full h-screen flex items-center justify-center bg-white">
              <img
                src={backwall}
                alt={"T3B"}
                className="absolute w-full h-full z-0 object-cover rounded-lg left-0 top-0"
              />
              <div className="relative items-center flex flex-col z-10 p-10">
                <img
                  src={logo}
                  alt={"T3B"}
                  className="w-40 h-40 -mt-10 mb-5 object-contain rounded-lg"
                />
                <p className="text-3xl font-light text-center mb-2">
                  El formato de {user?.numero_empleado == assessment.numero_empleado ? "autoevaluación" : "evaluación"} ha sido enviado.
                </p>
                <p className="text-3xl font-light text-center mb-2">
                  ¡Muchas gracias por tu participación en la evaluación de desempeño!
                </p>
                <p className="text-xl font-light text-center mb-2">
                  En 10 segundos serás redireccionado a la pantalla de inicio
                </p>
              </div>
            </div>
          )}
          <Notification
            title={errorTitle}
            message={error}
            type="danger"
            ref={notificationRef}
          />
        </>
      )}
      <BigModalTemplate ref={modalRef}>
        <img
          src={backwall}
          alt={"T3B"}
          className="absolute w-full h-full z-0 object-cover rounded-lg left-0 top-0"
        />
        <div className="relative items-center flex flex-col z-10">
          <p className="text-3xl font-medium text-center text-gray-800">
            {assessment?.nombre_completo}
          </p>
          <p className="text-lg text-center mb-2 text-red-500">
            {assessment?.puesto}
          </p>
          <p className="text-3xl font-medium text-center mb-2">
            {assessment?.titulo}
          </p>
          <p className="text-xl text-center">Instrucciones</p>
          <p className="font-light text-left mb-4 whitespace-pre-line">
            {assessment?.instrucciones}
          </p>
          <button
            onClick={(e) => modalRef.current.hideModal()}
            type="button"
            className="flex items-center rounded-md border border-transparent bg-red-200 px-6 py-3 text-base font-medium text-gray-900 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
          >
            Iniciar
            <ChevronRightIcon className="h-8 w-8" />
          </button>
        </div>
      </BigModalTemplate>
      <CommonModalTemplate ref={modalRefEnd}>
        {loadingEnd ? (
          <div className="w-full h-full flex items-center justify-center bg-white">
            <video
              className="w-48 h-48 flex flex-1"
              alt={"Tiendas 3B"}
              muted
              loop
              autoPlay
            >
              <source
                src={
                  "https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"
                }
                type="video/mp4"
              />
              Sin soporte de video MP4
            </video>
          </div>
        ) : (
          <>
            <img
              src={backwall}
              alt={"T3B"}
              className="absolute w-full h-full z-0 object-cover rounded-lg left-0 top-0"
            />
            <div className="relative items-center flex flex-col z-10">
              <p className="text-3xl font-medium text-center mb-2">
                Muchas gracias, has respondido satisfatoriamente el formato de evaluación de desempeño
              </p>
            </div>
          </>
        )}
      </CommonModalTemplate>
      <CommonModalTemplate ref={selfAssessmentRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Enviar {assessment.final_autoevaluacion}</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas enviar la {assessment.final_autoevaluacion}?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingEnd}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={saveAssessment}
          >
            {loadingEnd ? "Enviando" : "Enviar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => selfAssessmentRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={errorOnAssessmentRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">{errorOnAssessmentTitle}</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {errorOnAssessmentMessage}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={() => errorOnAssessmentRef.current.hideModal()}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Entendido
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={bossNoAutoAssessmentRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Enviar {assessment.final_evaluacion}</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas enviar la {assessment.final_evaluacion}?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingEnd}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={endAssessment}
          >
            {loadingEnd ? "Enviando" : "Enviar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => bossNoAutoAssessmentRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <CommonModalTemplate ref={bossAssessmentRef}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Programar retroalimentación y enviar {assessment.final_evaluacion}</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Elige la fecha y hora que realizarás la reatroalimentación cara a cara con el colaborador
              </p>
              <div className="my-2">
                <label
                  htmlFor="question-description"
                  className="block text-xs text-red-500"
                >
                  FECHA DE RETROALIMENTACIÓN
                </label>
                <div className="">
                  <WDatePicker
                    onChange={setDateFeedback}
                    value={dateFeedback}
                    calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                    clearIcon={null}
                    locale="es-MX"
                    format="dd/MM/y"
                    maxDate={new Date(assessment.fecha_fin+"T23:59:59")}
                    minDate={new Date()}
                    prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                    nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
                  />
                </div>
              </div>
              <div className="my-2">
                <label
                  htmlFor="question-description"
                  className="block text-xs text-red-500"
                >
                  HORA DE RETROALIMENTACIÓN
                </label>
                <div className="">
                  <DatePicker
                    selected={dateFeedback}
                    onChange={(date) => setDateFeedback(date)}
                    showTimeSelect
                    showTimeSelectOnly
                    timeCaption="Time"
                    dateFormat="h:mm aa"
                    minDate={new Date()}
                    minTime={setHoursMinutesDatePicker(dateFeedback, dateFeedback.getHours(), dateFeedback.getMinutes())}
                    maxTime={setHoursDatePicker(dateFeedback, 18)}
                    customInput={
                      <input
                        placeholder='Hora'
                        type="text"
                        className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                        value={dateFeedback}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            onClick={endAssessment}
            disabled={loadingEnd}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
          >
            {loadingEnd ? "Enviando" : "Enviar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => bossAssessmentRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
    </>
  );
}
