import { useState, forwardRef, useRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { getDateLabel } from "../../utils/date_utils";
import BigModalTemplate from "./BigModalTemplate";
import face from "./../../assets/img/faces/avatar.png";
import { PaperClipIcon } from "@heroicons/react/24/solid";
import axios from "axios";
var fileDownload = require('js-file-download');


const ObjectiveProgressModal = forwardRef((props, ref) => {
  const { token } = useSelector(state => state.authentication)
  const [progress, setProgress] = useState([]);
  const [loadingFile, setLoadingFile] = useState(false);
  const refModal = useRef();

  useImperativeHandle(ref, () => ({
    showModal(objective) {
      fetchObjectiveProgress(objective);
      refModal.current.showModal();
    },
    hideModal() {
      refModal.current.hideModal();
    },
  }));

  const fetchObjectiveProgress = (objetivo_id) => {
    API.get(`/objectives/${objetivo_id}/progress`, { headers: { Authorization: token } })
      .then(({ status, data }) => {
        if (status === 200) {
          setProgress(data);
        } else if (status === 204) {
          setProgress([]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const downloadFile = (uuid, name) => {
    setLoadingFile(true);
    axios.get(`https://dfh9lj2viqrbd.cloudfront.net/processes/EDD/EVIDENCIAS/${uuid}`,{ responseType: 'blob' })
    .then((response) => {
      setLoadingFile(false);
      fileDownload(response.data, name);
    })
    .catch((error) => {
      setLoadingFile(false);

    })
  }

  return (
    <BigModalTemplate ref={refModal}>
      <div className="flow-root">
        <ul role="list" className="-mb-8">
          {progress.map((item, itemIdx) => (
            <li key={`key-progress-${item.avance_id}`}>
              <div className="relative pb-8">
                {itemIdx !== progress.length - 1 ? (
                  <span
                    className="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200"
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex items-start space-x-3">
                  <div className="relative">
                    <img
                      className="flex h-10 w-10 items-center justify-center rounded-full bg-gray-400 ring-8 ring-white object-cover"
                      src={
                        item?.imagen_perfil
                          ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${item.imagen_perfil}`
                          : face
                      }
                      alt=""
                    />
                  </div>
                  <div className="min-w-0 flex-1">
                    <div>
                      <div className="text-sm">
                        <p
                          className="font-medium text-gray-900"
                        >
                          {item.nombre_completo}
                        </p>
                      </div>
                      <p className="mt-0.5 text-sm text-gray-500">
                        El {getDateLabel(new Date(item.fecha_captura))}
                      </p>
                    </div>
                    <div className="mt-2 text-sm text-gray-700">
                      <p>{item.descripcion}</p>
                    </div>
                    {item.evidencias.length > 0 && <ul role="list" className="divide-y divide-gray-200 rounded-md border border-gray-200 w-full mt-1">
                      {item.evidencias.map((f)=>(
                        <li className="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
                          <div className="flex w-0 flex-1 items-center">
                            <PaperClipIcon className="h-5 w-5 flex-shrink-0 text-gray-400" aria-hidden="true" />
                            <span className="ml-2 w-0 flex-1 truncate">{f.name}</span>
                          </div>
                          <div className="ml-4 flex-shrink-0">
                            <button
                              onClick={e=>downloadFile(f.uuid, f.name)}
                              className="font-medium text-green-600 hover:text-green-500"
                            >
                              {loadingFile ? "Descargando" : "Descargar"}
                            </button>
                          </div>
                        </li>
                      ))}
                    </ul>}
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </BigModalTemplate>
  );
});

export default ObjectiveProgressModal;
