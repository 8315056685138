export default function HomeSkeleton() {
  return (
    <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
      <div className="">
        <section aria-labelledby="profile-overview-title w-full animate-pulse">
          <div className="overflow-hidden rounded-lg bg-white shadow">
            <div className="bg-white p-6">
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="sm:flex sm:space-x-5 w-full">
                  <div className="flex-shrink-0">
                    <div className="mx-auto h-20 w-20 rounded-full bg-gray-300 animate-pulse">
                    </div>
                  </div>
                  <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left space-y-2 max-w-full sm:flex-1">
                    <p className="bg-gray-300 rounded-md animate-pulse max-w-full w-64 h-4"></p>
                    <p className="bg-gray-300 rounded-md animate-pulse max-w-full w-96 h-8"></p>
                    <p className="bg-gray-300 rounded-md animate-pulse max-w-full w-60 h-4"></p>
                  </div>
                </div>
                <div className="mt-5 flex justify-center sm:mt-0">
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200 bg-gray-50 px-6 py-5 text-center text-sm font-medium">
              <nav
                className="flex items-center justify-center"
                aria-label="Progress"
              >
                <p className="text-lg font-light h-5">
                  <p className="bg-gray-300 rounded-md animate-pulse max-w-full w-64 h-6"></p>
                </p>
              </nav>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};
