import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Notification from "../../components/notifications/Notification";
import API from "../../services/API";
import face from "./../../assets/img/faces/avatar.png";
import { refreshUser } from "./../../redux/reducers/auth";

const educationValues = [
  {
    id: "1",
    label: "Primaria",
  },
  {
    id: "2",
    label: "Secundaria",
  },
  {
    id: "3",
    label: "Preparatoria",
  },
  {
    id: "4",
    label: "Licenciatura",
  },
  {
    id: "5",
    label: "Maestría",
  },
  {
    id: "6",
    label: "Doctorado",
  },
];

export default function Profile() {
  const { user, token } = useSelector((state) => state.authentication);
  const [email, setEmail] = useState(user?.email);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [phone, setPhone] = useState(user?.teledono);
  const [education, setEducation] = useState(user?.escolaridad_id);
  const [notificationMessage, setNotificationMessage] = useState("Hubo un error con el servicio");
  const [notificationTitle, setNotificationTitle] = useState("Error");
  const [notificationType, setNotificationType] = useState("danger");
  const navigate = useNavigate();
  const notificationRef = useRef();
  const dispatch = useDispatch();

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const updateUser = async (e) => {
    e.preventDefault();
    if (email == "") {
      setNotificationMessage("Debes actualizar al menos el email para recibir alertas");
      return notificationRef.current.showNotification();
    }
    let body = {
      email,
      escolaridad: education,
    };
    if (file) {
      body.image = {
        file: await getBase64(file),
        name: file.name,
        contentType: file.type,
      };
    }
    setLoading(true);
    let options = {
      headers: { Authorization: token }
    }
    API.patch(`/users/${user.numero_empleado}`, body, options)
      .then(async (result) => {
        setLoading(false);
        setFile(null);
        navigate("/t3b/home");
        dispatch(refreshUser({ token: token }))
      })
      .catch((error) => {
        setLoading(false);
        setNotificationMessage("Ocurrió un error al actualizar tus datos");
        return notificationRef.current.showNotification();
      });
  };

  const onChangeFile = (e) => {
    var files = e.target.files;
    setFile(files[0]);
  }


  return (
    <>
      <div className="min-h-full">
        <main className="py-8">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <div className="mx-auto py-10 px-4 sm:px-6 lg:py-12 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                Mi Cuenta
              </h1>

              <form className="divide-y-gray-200 mt-6 space-y-8 divide-y" onSubmit={updateUser}>
                <div className="grid grid-cols-1 gap-y-6 sm:grid-cols-6 sm:gap-x-6">
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Nombre Completo
                    </label>
                    <p className="mt-1 block w-full text-gray-900 focus:border-gray-500 focus:ring-gray-500 sm:text-sm">
                      {user?.nombre}
                    </p>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="username"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Puesto
                    </label>
                    <p className="mt-1 block w-full text-gray-900 focus:border-gray-500 focus:ring-gray-500 sm:text-sm">
                      {user?.puesto}
                    </p>
                  </div>

                  <div className="sm:col-span-6">
                    <label
                      htmlFor="photo"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Foto
                    </label>
                    <div className="mt-1 flex items-center">
                      <img
                        className="inline-block h-12 w-12 rounded-full object-cover"
                        src={
                          file
                          ? URL.createObjectURL(file)
                          : user?.imagen_perfil
                            ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${user?.imagen_perfil}`
                            : face
                        }
                        alt="User Profile"
                      />
                      <div className="ml-4 flex">
                        <div className="relative flex cursor-pointer items-center rounded-md border border-gray-300 bg-white py-2 px-3 shadow-sm focus-within:outline-none focus-within:ring-2 focus-within:ring-gray-500 focus-within:ring-offset-2 focus-within:ring-offset-gray-50 hover:bg-gray-50">
                          <label
                            htmlFor="user-photo"
                            className="pointer-events-none relative text-sm font-medium text-gray-900"
                          >
                            <span>Cambiar</span>
                            <span className="sr-only"> foto de usuario</span>
                          </label>
                          <input
                            id="user-photo"
                            name="user-photo"
                            type="file"
                            onChange={onChangeFile}
                            accept="image/png,image/jpg,image/jpeg,image/webp"
                            className="absolute inset-0 h-full w-full cursor-pointer rounded-md border-gray-300 opacity-0"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-y-6 pt-8 sm:grid-cols-6 sm:gap-x-6">
                  <div className="sm:col-span-6">
                    <h2 className="text-xl font-medium text-gray-900">
                      Información Personal
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      Esta información no va a ser compartida con los demás
                      colaboradores, sólo será utilizada por el departamento de
                      Recursos Humanos con el objetivo de obtener reportes para
                      las primeras y segundas líneas.
                    </p>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email-address"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Email
                    </label>
                    <input
                      type="text"
                      name="email-address"
                      id="email-address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 text-gray-900 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="phone-number"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Teléfono
                    </label>
                    <input
                      type="text"
                      name="phone-number"
                      id="phone-number"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 text-gray-900 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    />
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="school"
                      className="block text-sm font-medium text-gray-900"
                    >
                      Escolaridad
                    </label>
                    <select
                      id="school"
                      name="school"
                      value={education}
                      onChange={(e) => setEducation(e.target.value)}
                      className="mt-1 block w-full rounded-md border-gray-300 text-gray-900 shadow-sm focus:border-gray-500 focus:ring-gray-500 sm:text-sm"
                    >
                      {educationValues.map((e) => (
                        <option key={`key-education-${e.id}`} value={e.id}>
                          {e.label}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="flex justify-end pt-8">
                  <button
                    type="submit"
                    disabled={loading}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {
                      loading
                      ? "Guardando cambios"
                      : "Guardar"
                    }
                  </button>
                </div>
              </form>
            </div>
          </div>
        </main>
        <Notification
          title={notificationTitle}
          message={notificationMessage}
          type={notificationType}
          ref={notificationRef}
        />
      </div>
    </>
  );
}
