import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  DocumentArrowDownIcon,
  DocumentArrowUpIcon,
  MagnifyingGlassIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { ChevronDownIcon, UserPlusIcon } from "@heroicons/react/20/solid";
import { base64StringToBlob } from "blob-util";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import { useRef } from "react";
import API from "../../services/API";
import PlusFolder from "../../components/vectors/PlusFolder";
import face from "./../../assets/img/faces/avatar.png";
import InfinityLoader from "../../components/loaders/InfinityLoader";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import FindUsersPalette from "../../components/commandpalettes/FindUsersPalette";
import NoSpaceTemplate from "../../components/modals/NoSpaceTemplate";
import { Link } from "react-router-dom";
import { formatDate, getShortDateTimeLabel } from "../../utils/date_utils";
import DatePicker from "react-date-picker/dist/entry.nostyle";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";

const sortOptions = [
  { name: "Nombre", href: "#", current: true },
  { name: "Posición", href: "#", current: false },
  { name: "Área", href: "#", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Users() {
  const { user, token } = useSelector((state) => state.authentication);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [loadingResetUsers, setLoadingResetUsers] = useState(false);
  const [loadingActivateUsers, setLoadingActivateUsers] = useState(false);
  const [loadingEditUsers, setLoadingEditUsers] = useState(false);
  const [loadingEditBoss, setLoadingEditBoss] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [currentUser, setCurrentUser] = useState({});
  const [currentBoss, setCurrentBoss] = useState({});
  const [users, setUsers] = useState([]);
  const [usersBoss, setUsersBoss] = useState([]);
  const [statusCredentials, setStatusCredentials] = useState(8);
  const [errorTitle, setErrorTitle] = useState("Error");
  const [search, setSearch] = useState("");
  const [title, setTitle] = useState("");
  const [regions, setRegions] = useState([]);
  const [currentCredentials, setCurrentCredentials] = useState([]);
  const [areas, setAreas] = useState([]);
  const [positions, setPositions] = useState([]);
  const notificationRef = useRef();
  const findBossesRef = useRef();
  const editUserRef = useRef();
  const newUserRef = useRef();
  const inputRef = useRef();
  const loadUsersRef = useRef();
  const deleteUserRef = useRef();
  const [employeeId, setEmployeeId] = useState("");
  const [employeeName, setEmployeeName] = useState("");
  const [employeeFSurname, setEmployeeFSurname] = useState("");
  const [employeeMSurname, setEmployeeMSurname] = useState("");
  const [employeeEmail, setEmployeeEmail] = useState("");
  const [employeeRegion, setEmployeeRegion] = useState("");
  const [employeeArea, setEmployeeArea] = useState("");
  const [employeePosition, setEmployeePosition] = useState("");
  const [employeeBoss, setEmployeeBoss] = useState("");
  const [deleteCreate, setDeleteCreate] = useState(new Date());
  const [deleteReason, setDeleteReason] = useState("0");
  const [deleteConfirm, setDeleteConfirm] = useState("");

  const downloadUsersFile = (e) => {
    e.preventDefault();
    setLoadingFile(true);
    API.get("/users/file", {
      headers: { Authorization: token },
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingFile(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `UsuariosIntegraRH.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingFile(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los usuarios"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los usuarios"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  useEffect(() => {
    const options = { headers: { Authorization: token } };
    API.get("/regions", options).then(({ data, status }) => {
      if (status === 200) {
        setRegions(
          data.map((f) => {
            f.id = f.region_id;
            f.label = f.region_nombre;
            return f;
          })
        );
      }
    });
  }, []);

  useEffect(() => {
    let params = {};
    if (currentUser.region_id !== 0) {
      params.region = currentUser.region_id;
      const options = { headers: { Authorization: token }, params };
      API.get("/areas", options).then(({ data, status }) => {
        if (status === 200) {
          setAreas(
            data.map((f) => {
              f.id = f.area_id;
              f.label = f.area_nombre;
              return f;
            })
          );
        }
      });
    }
    if (employeeRegion !== "") {
      params.region = employeeRegion;
      const options = { headers: { Authorization: token }, params };
      API.get("/areas", options).then(({ data, status }) => {
        if (status === 200) {
          setAreas(
            data.map((f) => {
              f.id = f.area_id;
              f.label = f.area_nombre;
              return f;
            })
          );
          setEmployeeArea("");
          setEmployeePosition("");
        }
      });
    }
  }, [currentUser.region_id, employeeRegion]);

  useEffect(() => {
    let params = {};
    if (currentUser.region_id && currentUser.area_id) {
      if (currentUser.region_id !== 0) {
        params.region = currentUser.region_id;
      }
      if (currentUser.area_id !== 0) {
        setPositions([]);
        params.area = currentUser.area_id;
        const options = { headers: { Authorization: token }, params };
        API.get("/positions", options).then(({ data, status }) => {
          if (status === 200) {
            setPositions(
              data.map((f) => {
                f.id = f.puesto_id;
                f.label = f.puesto_nombre;
                return f;
              })
            );
          }
        });
      }
    }
    if (employeeRegion && employeeArea) {
      if (employeeRegion !== "") {
        params.region = employeeRegion;
      }
      if (employeeArea !== "") {
        setPositions([]);
        searchUsersWithArea();
        params.area = employeeArea;
        const options = { headers: { Authorization: token }, params };
        API.get("/positions", options).then(({ data, status }) => {
          if (status === 200) {
            setPositions(
              data.map((f) => {
                f.id = f.puesto_id;
                f.label = f.puesto_nombre;
                return f;
              })
            );
          }
        });
        setEmployeePosition("");
      }
    }
  }, [
    currentUser.region_id,
    currentUser.area_id,
    employeeRegion,
    employeeArea,
  ]);

  useEffect(() => {
    if (currentUser.jefe_inmediato_id) {
      const options = { headers: { Authorization: token } };
      API.get("/users/"+currentUser.jefe_inmediato_id, options)
        .then(({ data }) => {
          setCurrentBoss(data);
        })
        .catch((err) => {
          setCurrentBoss({});
        });
    }
  }, [currentUser.jefe_inmediato_id]);

  useEffect(() => {
    if (currentUser.numero_empleado) {
      const headers = { Authorization: token };
      let params = {
        proceso: 1,
      };
      API.get(`/users/${currentUser.numero_empleado}/credentials`, {
        params,
        headers,
      })
        .then(({ status, data }) => {
          if (status == 200) {
            setCurrentCredentials(data);
            if (data.filter((d) => d.cat_permiso_id == 8).length > 0) {
              setStatusCredentials(8);
            }
            if (data.filter((d) => d.cat_permiso_id == 7).length > 0) {
              setStatusCredentials(7);
            }
            if (data.filter((d) => d.cat_permiso_id == 6).length > 0) {
              setStatusCredentials(6);
            }
          } else {
            setCurrentCredentials([]);
          }
        })
        .catch((err) => {
          setCurrentCredentials([]);
        });
    }
  }, [currentUser.numero_empleado]);

  const searchUsers = (e) => {
    if(e){
      e.preventDefault();
    }
    const headers = { Authorization: token };
    let params = {
      search: search,
    };
    if (
      user?.certificados?.filter((c) => c.cat_permiso_id == 6 && c.estatus == 1)
        ?.length < 1
    ) {
      params.region = user.region_id;
    }
    setLoadingUsers(true);
    API.get("/edd/users", { params, headers })
      .then(({ data }) => {
        setUsers(data);
        setLoadingUsers(false);
      })
      .catch((err) => {
        setUsers([]);
        setLoadingUsers(false);
      });
  };

  const searchUsersWithArea = () => {
    const headers = { Authorization: token };
    let params = {
      with_area: employeeArea,
      with_region: employeeRegion,
    };
    API.get("/usersearch", { params, headers })
      .then(({ data }) => {
        setUsersBoss(data);
      })
      .catch((err) => {
        setUsersBoss([]);
      });
  };

  const selectUser = (current) => {
    setCurrentUser(current);
    editUserRef.current.showModal();
  };

  const changeCurrentUser = (field, value) => {
    let newCurrentUser = { ...currentUser };
    newCurrentUser[field] = value;
    setCurrentUser(newCurrentUser);
  };

  const resetPassword = async () => {
    let body = {
      password: `${currentUser.numero_3b}_t3B`,
    };
    setLoadingResetUsers(true);
    const options = {
      headers: { Authorization: token },
    };
    API.post(`/users/${currentUser.numero_empleado}/changepssw`, body, options)
      .then(async (result) => {
        setLoadingResetUsers(false);
        editUserRef.current.hideModal();
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al resetear la contraseña"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al resetear la contraseña"
          );
        }
        notificationRef.current.showNotification();
        setLoadingResetUsers(false);
      });
  };

  const updateUser = async () => {
    let pra = positions.find(
      (p) => Number(p.puesto_id) === Number(currentUser.puesto_id)
    );
    let body = {
      numero_empleado: currentUser.numero_empleado,
      nombre: currentUser.nombre,
      apellido_paterno: currentUser.apellido_paterno,
      apellido_materno: currentUser.apellido_materno,
      email: currentUser.email,
      escolaridad: currentUser.escolaridad_id,
      puesto_region_area_id: pra?.puesto_region_area_id,
      is_admin: currentUser.isAdmin,
      jefe_inmediato_id: currentUser.jefe_inmediato_id,
      is_region_admin: currentUser.is_region_admin,
      nuevo_permiso: statusCredentials,
      proceso: 1,
    };
    const options = {
      headers: { Authorization: token },
    };
    setLoadingEditUsers(true);
    API.patch(`/users/${currentUser.numero_empleado}`, body, options)
      .then(async (result) => {
        setLoadingEditUsers(false);
        if (result.status == 200) {
          let newUsers = [...users];
          let toUpdate = newUsers.find(
            (u) => u.numero_empleado == currentUser.numero_empleado
          );
          if (toUpdate) {
            toUpdate.nombre_completo = `${currentUser.nombre}${
              currentUser.apellido_paterno
                ? ` ${currentUser.apellido_paterno}`
                : ""
            }${
              currentUser.apellido_materno
                ? ` ${currentUser.apellido_materno}`
                : ""
            }`;
            toUpdate.email = currentUser.email;
            let ra = areas.find(
              (p) => Number(p.area_id) === Number(currentUser.area_id)
            );
            let r = regions.find(
              (p) => Number(p.region_id) === Number(currentUser.region_id)
            );
            toUpdate.region = r?.region_nombre;
            toUpdate.area = ra?.area_nombre;
            toUpdate.puesto = pra?.puesto_nombre;
          }
          setUsers(newUsers);
        }
        editUserRef.current.hideModal();
      })
      .catch((error) => {
        console.log(error);
        setLoadingEditUsers(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al actualizar el usuario"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al actualizar el usuario"
          );
        }
        notificationRef.current.showNotification();
        setLoadingResetUsers(false);
      });
  };

  const newUser = async () => {
    let body = {
      numero_empleado: employeeId,
      nombre: employeeName,
      apellido_paterno: employeeFSurname,
      apellido_materno: employeeMSurname,
      puesto_region_area_id: employeePosition,
      jefe_inmediato_id: employeeBoss,
      email: employeeEmail,
    };
    const options = {
      headers: { Authorization: token },
    };
    setLoadingEditUsers(true);
    API.post(`/users/${employeeId}`, body, options)
      .then(async (result) => {
        setLoadingEditUsers(false);
        newUserRef.current.hideModal();
      })
      .catch((error) => {
        setLoadingEditUsers(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al crear el usuario"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al crear el usuario"
          );
        }
        notificationRef.current.showNotification();
        setLoadingResetUsers(false);
      });
  };

  const onSelectBoss = async (person) => {
    let body = {
      numero_empleado: currentUser.numero_empleado,
      jefe_inmediato_id: person.numero_empleado,
    };
    const options = {
      headers: { Authorization: token },
    };
    findBossesRef.current.hideModal();
    setLoadingEditBoss(true);
    API.patch(`/users/${currentUser.numero_empleado}`, body, options)
      .then(async (result) => {
        setLoadingEditBoss(false);
        let newCurrentUser = { ...currentUser };
        newCurrentUser.jefe_inmediato_id = person.numero_empleado;
        setCurrentUser(newCurrentUser);
      })
      .catch((error) => {
        setLoadingEditBoss(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al cambiar el jefe inmediato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al cambiar el jefe inmediato"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const toggleUserActivation = async (person) => {
    let body = {
      is_active: !currentUser.is_active,
    };
    const options = {
      headers: { Authorization: token },
    };
    setLoadingActivateUsers(true);
    API.patch(`/users/${currentUser.numero_empleado}`, body, options)
      .then(async (result) => {
        setLoadingActivateUsers(false);
        let newCurrentUser = { ...currentUser };
        newCurrentUser.is_active = body.is_active;
        setCurrentUser(newCurrentUser);
        let newUsers = [...users];
        let foundUser = newUsers.find(
          (u) => u.numero_empleado == currentUser.numero_empleado
        );
        foundUser.is_active = body.is_active;
        setUsers(newUsers);
      })
      .catch((error) => {
        setLoadingActivateUsers(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al cambiar el jefe inmediato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al cambiar el jefe inmediato"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const uploadFile = (e) => {
    if (e.target.files[0]) {
      loadUsersRef.current.showModal();
      saveFile(e.target.files[0]);
    } else {
      setError("Selecciona un archivo para subir");
      notificationRef.current.showNotification();
    }
  };

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        reject("Error: ", error);
      };
    });
  };

  const saveFile = async (file) => {
    setProgress(10);
    let body = {
      file: await getBase64(file),
    };
    API.post(`/edd/users`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setProgress(30);
        refreshPositions(result.data);
      })
      .catch((error) => {
        setProgress(0);
        loadUsersRef.current.hideModal();
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setErrorTitle("Hubo errores en el archivo");
          setError(error.response.data.join("\n"));
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al subir el archivo de layout"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al subir el archivo de layout"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const refreshPositions = async (type) => {
    setProgress(40);
    let body = {
      type,
    };
    API.post(`/edd/users/review`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setProgress(60);
        updateUsers(type);
      })
      .catch((error) => {
        setProgress(0);
        loadUsersRef.current.hideModal();
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setErrorTitle("Hubo errores en el archivo");
          setError(error.response.data.join("\n"));
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al refrescar la información de los puesto y areas"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al refrescar la información de los puesto y areas"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const updateUsers = async (type) => {
    setProgress(70);
    let body = {
      type,
    };
    API.post(`/edd/users/update`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setProgress(80);
        refreshInfo(type);
      })
      .catch((error) => {
        setProgress(0);
        loadUsersRef.current.hideModal();
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setErrorTitle("Hubo errores en el archivo");
          setError(error.response.data.join("\n"));
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al actualizar los usuarios"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al actualizar los usuarios"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const refreshInfo = async (type) => {
    setProgress(90);
    let body = {
      type,
    };
    API.post(`/edd/users/refresh`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setProgress(100);
        loadUsersRef.current.hideModal();
      })
      .catch((error) => {
        setProgress(0);
        loadUsersRef.current.hideModal();
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setErrorTitle("Hubo errores en el archivo");
          setError(error.response.data.join("\n"));
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al actualizar los usuarios"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al actualizar los usuarios"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const openDelete = () => {
    setDeleteCreate(new Date());
    setDeleteReason("0");
    setDeleteConfirm("");
    deleteUserRef.current.showModal();
  };

  const makeDelete = () => {
    if(deleteReason=="0"){
      setErrorTitle("Llena todos los campos");
      setError("Ingresa una razón de baja");
      notificationRef.current.showNotification();
      return setTimeout(()=>{
        notificationRef.current.hideNotification();
      },[3000])
    }
    if(deleteConfirm!="Confirmo la baja"){
      setErrorTitle("Llena todos los campos");
      setError("Debes escribir en el recuadro: Confirmo la baja");
      notificationRef.current.showNotification();
      return setTimeout(()=>{
        notificationRef.current.hideNotification();
      },[3000])
    }
    notificationRef.current.hideNotification();
    let body = {
      fecha_baja: formatDate(deleteCreate),
      razon_baja: deleteReason
    }
    API.delete(`/users/${currentUser.numero_empleado}`, {
      headers: { Authorization: token },
      data: body
    })
    .then((result) => {
      editUserRef.current.hideModal();
      deleteUserRef.current.hideModal();
      searchUsers();
    })
    .catch((error) => {
      if (error.response) {
        if (error.response.status === 401) {
          setError("La sesión ha caducado");
        }
        setError(error.response.data);
      } else if (error.request) {
        setError(
          "La petición fue realizada, pero no hubo respuesta por parte del servidor al dar de baja el usuario"
        );
      } else {
        setError(
          "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al dar de baja el usuario"
        );
      }
      notificationRef.current.showNotification();
    });
  };

  let superAdministrador =
    user?.certificados?.filter((c) => c.cat_permiso_id == 6 && c.estatus == 1)
      ?.length > 0;

  return (
    <div className="flex-1 min-w-0">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <Transition.Root show={open} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-40 sm:hidden"
                onClose={setOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">
                          Filtros
                        </h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div className="flex sm:flex md:flex lg:flex flex-col sm:flex-col md:flex-row lg:flex-row items-center sm:items-center md:items-center lg:items-center justify-start sm:justify-start md:justify-between lg:justify-between pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  Usuarios
                </h1>
                <p className="mt-4 text-sm text-gray-700">
                  Una lista de todos los usuarios dentro de tu región con su
                  puesto en la organización y rol en el sistema.
                </p>
              </div>
              {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-auto">
                {superAdministrador && (
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                        Acciones
                        <ChevronDownIcon
                          className="-mr-1 ml-2 h-5 w-5"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="/#"
                                onClick={downloadUsersFile}
                                className={classNames(
                                  active
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "group flex items-center px-4 py-2 text-sm"
                                )}
                              >
                                <DocumentArrowDownIcon
                                  className={`mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500 ${
                                    loadingFile ? "animate-bounce" : ""
                                  }`}
                                  aria-hidden="true"
                                />
                                {loadingFile
                                  ? "Descargando usuarios..."
                                  : "Descargar layout"}
                              </a>
                            )}
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                )}
              </div> */}
            </div>
            <input
              id="file-upload"
              name="file-upload"
              type="file"
              ref={inputRef}
              onChange={uploadFile}
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              className="sr-only"
            />
            <form onSubmit={searchUsers}>
              <label
                htmlFor="usersearch"
                className="block text-sm font-medium text-gray-700"
              >
                Buscar usuarios
              </label>
              <div className="mt-1 flex rounded-md shadow-sm mb-4">
                <div className="relative flex flex-grow items-stretch focus-within:z-10">
                  <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    <UsersIcon
                      className="h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </div>
                  <input
                    type="text"
                    name="usersearch"
                    id="usersearch"
                    required
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                    className="block w-full rounded-none rounded-l-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="Número de empleado, nombre o apellidos"
                  />
                </div>
                <button
                  type="submit"
                  onClick={searchUsers}
                  className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-gray-300 bg-gray-50 px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500"
                >
                  <MagnifyingGlassIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                  <span>Buscar</span>
                </button>
              </div>
            </form>

            {/* Filters */}
            <section
              aria-labelledby="filter-heading"
              className="-my-2 -mx-4 flex-1 sm:-mx-6 lg:-mx-8"
            >
              <h2 id="filter-heading" className="sr-only">
                Filtros
              </h2>

              <div className="">
                <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        Ordenar por
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {sortOptions.map((option) => (
                            <Menu.Item key={`key-menu-item-${option.name}`}>
                              {({ active }) => (
                                <a
                                  href={option.href}
                                  className={classNames(
                                    option.current
                                      ? "font-medium text-gray-900"
                                      : "text-gray-500",
                                    active ? "bg-gray-100" : "",
                                    "block px-4 py-2 text-sm"
                                  )}
                                >
                                  {option.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </section>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                {loadingUsers ? (
                  <>
                    <InfinityLoader />
                  </>
                ) : (
                  <>
                    <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Nombre
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Posición
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Región
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Estatus
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Última conexión
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {users.map((person) => (
                            <tr
                              key={`key-row-employee-${person.numero_empleado}`}
                            >
                              <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-6">
                                <div className="flex items-center">
                                  <div className="h-10 w-10 flex-shrink-0">
                                    <img
                                      className="h-10 w-10 rounded-full object-cover"
                                      src={
                                        person?.imagen_perfil
                                          ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${person?.imagen_perfil}`
                                          : face
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <div className="ml-4">
                                    <div className="font-medium text-gray-900">
                                      {person.nombre_completo}
                                    </div>
                                    <div className="text-gray-500">
                                      {person.email}
                                    </div>
                                  </div>
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="text-gray-900">
                                  {person.puesto}
                                </div>
                                <div className="text-gray-500">
                                  {person.area}
                                </div>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.region}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <span
                                  className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${
                                    person.is_active
                                      ? "bg-green-100 text-green-800"
                                      : "bg-red-100 text-red-800"
                                  }`}
                                >
                                  {person.is_active ? "Activo" : "Inactivo"}
                                </span>
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {person.ultima_conexion != "None"
                                  ? getShortDateTimeLabel(
                                      person.ultima_conexion
                                    )
                                  : ""}
                              </td>
                              <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                <button
                                  onClick={(e) => selectUser(person)}
                                  className="text-red-600 hover:text-red-900"
                                >
                                  Ver
                                  <span className="sr-only">
                                    , {person.name}
                                  </span>
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    {users.length < 1 && (
                      <div className="text-center mt-3">
                        <PlusFolder />
                        <h3 className="mt-2 text-sm font-medium text-gray-900">
                          No hay usuarios con los filtros seleccionados
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                          Utiliza el campo de búsqueda para buscar usuarios.
                        </p>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <BigModalTemplate ref={newUserRef}>
          <div className="flex justify-between items-top mb-8">
            <p className="text-3xl font-medium">Nuevo usuario</p>
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={(e) => newUserRef.current.hideModal()}
            >
              <span className="sr-only">Cerrar</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-3">
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                NUMERO EMPLEADO
              </label>
              <div className="mt-1 sm:text-sm">
                <input
                  id="numero_empleado"
                  name="numero_empleado"
                  type="text"
                  placeholder="Numero de empleado del colaborador..."
                  value={employeeId}
                  onChange={(e) => setEmployeeId(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-4">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                NOMBRE
              </label>
              <div className="mt-1">
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="Nombre del colaborador..."
                  value={employeeName}
                  onChange={(e) => setEmployeeName(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-3">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                APELLIDO PATERNO
              </label>
              <div className="mt-1">
                <input
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Apellido paterno..."
                  value={employeeFSurname}
                  onChange={(e) => setEmployeeFSurname(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-3">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                APELLIDO MATERNO
              </label>
              <div className="mt-1">
                <input
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Título del periodo..."
                  value={employeeMSurname}
                  onChange={(e) => setEmployeeMSurname(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                EMAIL
              </label>
              <div className="mt-1">
                <input
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Email..."
                  value={employeeEmail}
                  onChange={(e) => setEmployeeEmail(e.target.value)}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                />
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                REGION
              </label>
              <div className="mt-1">
                <div>
                  <select
                    id="location"
                    name="location"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={employeeRegion}
                    onChange={(e) => setEmployeeRegion(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    {regions.map((r) => (
                      <option
                        key={`key-option-region-${r.region_id}`}
                        value={r.region_id}
                      >
                        {r.region_nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                AREA
              </label>
              <div className="mt-1">
                <div>
                  <select
                    id="location"
                    name="location"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={employeeArea}
                    onChange={(e) => setEmployeeArea(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    {areas.map((r) => (
                      <option
                        key={`key-option-area-${r.area_id}`}
                        value={r.area_id}
                      >
                        {r.area_nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                PUESTO
              </label>
              <div className="mt-1">
                <div>
                  <select
                    id="location"
                    name="location"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                    value={employeePosition}
                    onChange={(e) => setEmployeePosition(e.target.value)}
                  >
                    <option value="" disabled>
                      Selecciona una opción
                    </option>
                    {positions.map((r) => (
                      <option
                        key={`key-option-puesto-${r.puesto_region_area_id}`}
                        value={r.puesto_region_area_id}
                      >
                        {r.puesto_nombre}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                JEFE INMEDIATO
              </label>
              <select
                id="location"
                name="location"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                value={employeeBoss}
                onChange={(e) => setEmployeeBoss(e.target.value)}
              >
                <option value="" disabled>
                  Selecciona una opción
                </option>
                {usersBoss.map((r) => (
                  <option
                    key={`key-option-boss-new-${r.numero_empleado}`}
                    value={r.numero_empleado}
                  >
                    {r.nombre_completo}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newUserRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingEditUsers}
                onClick={newUser}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loadingEditUsers ? "Creando usuario" : "Crear"}
              </button>
            </div>
          </div>
        </BigModalTemplate>
        <BigModalTemplate ref={loadUsersRef}>
          <div>
            <h4 className="sr-only">Status</h4>
            <p className="text-sm font-medium text-gray-900">Cargando Layout</p>
            <div className="mt-6" aria-hidden="true">
              <div className="overflow-hidden rounded-full bg-gray-200">
                <div
                  className="h-2 rounded-full bg-indigo-600 transition-all duration-150 ease-in-out"
                  style={{ width: `${progress}%` }}
                />
              </div>
              <div className="mt-6 hidden grid-cols-4 text-sm font-medium text-gray-600 sm:grid">
                <div className={`text-indigo-600`}>Subiendo archivo</div>
                <div
                  className={`text-center ${
                    progress >= 25 ? "text-indigo-600" : ""
                  }`}
                >
                  Revisando puestos
                </div>
                <div
                  className={`text-center ${
                    progress >= 50 ? "text-indigo-600" : ""
                  }`}
                >
                  Actualizando usuarios
                </div>
                <div
                  className={`text-center ${
                    progress >= 75 ? "text-indigo-600" : ""
                  }`}
                >
                  Refrescando información
                </div>
              </div>
            </div>
          </div>
        </BigModalTemplate>
        <BigModalTemplate ref={editUserRef}>
          <div className="flex justify-between items-top mb-8">
            <p className="text-3xl font-medium">Editar usuario</p>
            <button
              type="button"
              className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              onClick={(e) => editUserRef.current.hideModal()}
            >
              <span className="sr-only">Cerrar</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-6 gap-3">
            <div className="mb-4 col-span-1 sm:col-span-full">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                NUMERO EMPLEADO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.numero_3b}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                NOMBRE
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.nombre}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                APELLIDO PATERNO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.apellido_paterno}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                APELLIDO MATERNO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.apellido_materno}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                EMAIL
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.email}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                REGION
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.region}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                AREA
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.area}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-2">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                PUESTO
              </label>
              <div className="mt-1 sm:text-sm">
                {currentUser.puesto}
              </div>
            </div>
            <div className="mb-4 col-span-1 sm:col-span-6">
              <label
                htmlFor="question-title"
                className="block text-xs text-red-500"
              >
                JEFE INMEDIATO
              </label>
              {loadingEditBoss ? (
                <div className="mt-1 flex items-baseline">
                  <div className="mt-1 sm:text-sm mr-8">
                    Editando jefe inmediato...
                  </div>
                </div>
              ) : (
                <div className="mt-1 flex items-baseline">
                  <div className="mt-1 sm:text-sm mr-8">
                    {currentBoss?.nombre_completo}
                  </div>
                  {superAdministrador && <button
                    onClick={(e) => findBossesRef.current.showModal()}
                    className="sm:text-sm text-green-500 hover:text-green-700 hover:underline"
                  >
                    Editar
                  </button>}
                </div>
              )}
            </div>
          </div>
          {superAdministrador && (
            <div className="py-2">
              <p className="text-xs text-red-500 uppercase">PERMISOS</p>
              <fieldset className="mt-2">
                <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                  <div className="flex items-center">
                    <input
                      id={"superadmin"}
                      name="permisos"
                      type="radio"
                      value={6}
                      checked={statusCredentials == 6}
                      onChange={(e) => setStatusCredentials(e.target.value)}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={"superadmin"}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Super Administrador
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id={"adminregional"}
                      name="permisos"
                      type="radio"
                      value={7}
                      checked={statusCredentials == 7}
                      onChange={(e) => setStatusCredentials(e.target.value)}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={"adminregional"}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Administrador regional
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id={"colaborador"}
                      name="permisos"
                      type="radio"
                      value={8}
                      checked={statusCredentials == 8}
                      onChange={(e) => setStatusCredentials(e.target.value)}
                      className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
                    />
                    <label
                      htmlFor={"colaborador"}
                      className="ml-3 block text-sm font-medium text-gray-700"
                    >
                      Colaborador
                    </label>
                  </div>
                </div>
              </fieldset>
            </div>
          )}
          <div className="pt-6">
            <div className="flex flex-col space-y-2 sm:space-y-0 sm:flex-row sm:justify-end flex-wrap">
              {!currentUser.is_active && <button
                type="button"
                disabled={loadingActivateUsers}
                onClick={toggleUserActivation}
                className="flex-1 sm:flex-auto ml-0 sm:ml-3 inline-flex justify-center rounded-md border border-transparent bg-yellow-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
              >
                {currentUser.is_active
                  ? loadingActivateUsers
                    ? "Inactivando usuario"
                    : "Inactivar"
                  : loadingActivateUsers
                  ? "Activando usuario"
                  : "Activar"}
              </button>}
              <button
                type="button"
                disabled={loadingResetUsers}
                onClick={resetPassword}
                className="flex-1 sm:flex-auto ml-0 sm:ml-3 inline-flex justify-center rounded-md border border-transparent bg-sky-400 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-sky-500 focus:ring-offset-2"
              >
                {loadingResetUsers
                  ? "Reseteando contraseña"
                  : "Resetear contraseña"}
              </button>
              {superAdministrador && (
                <button
                  type="button"
                  disabled={loadingEditUsers}
                  onClick={updateUser}
                  className="flex-1 sm:flex-auto ml-0 sm:ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                >
                  {loadingEditUsers ? "Guardando usuario" : "Guardar"}
                </button>
              )}
            </div>
          </div>
        </BigModalTemplate>
        <BigModalTemplate ref={deleteUserRef}>
          <div className="sm:flex sm:items-start">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
              <ExclamationTriangleIcon
                className="h-6 w-6 text-red-600"
                aria-hidden="true"
              />
            </div>
            <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
              <h3
                className="text-base font-semibold leading-6 text-gray-900"
              >
                Dar de baja usuario
              </h3>
              <div className="mt-2 mb-4">
                <p className="text-sm text-gray-500">
                  Selecciona las razones de la baja del usuario y la fecha que se efectuó el movimiento.
                </p>
              </div>
              <div>
                <label htmlFor="razon" className="block text-sm font-medium leading-6 text-gray-900">
                  Motivo de baja real
                </label>
                <div className="mt-2">
                  <select
                    id="razon"
                    name="razon"
                    value={deleteReason}
                    onChange={e => setDeleteReason(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="0" disabled>SELECCIONA UNA RAZÓN</option>
                    <option value="ABANDONO DE TRABAJO">ABANDONO DE TRABAJO</option>
                    <option value="AMBIENTE LABORAL">AMBIENTE LABORAL</option>
                    <option value="AUSENTISMO">AUSENTISMO</option>
                    <option value="BAJO DESEMPEÑO">BAJO DESEMPEÑO</option>
                    <option value="CAMBIO DE DOMICILIO">CAMBIO DE DOMICILIO</option>
                    <option value="CONDICIONES LABORALES">CONDICIONES LABORALES</option>
                    <option value="DESEMPEÑO">DESEMPEÑO</option>
                    <option value="DESHONESTIDAD">DESHONESTIDAD</option>
                    <option value="ENFOQUE A ESTUDIOS">ENFOQUE A ESTUDIOS</option>
                    <option value="FALTA DE APEGO A PROCESOS">FALTA DE APEGO A PROCESOS</option>
                    <option value="FIN DE CONTRATO">FIN DE CONTRATO</option>
                    <option value="INSEGURIDAD">INSEGURIDAD</option>
                    <option value="MEJOR EMPLEO">MEJOR EMPLEO</option>
                    <option value="MOTIVOS ESCOLARES">MOTIVOS ESCOLARES</option>
                    <option value="MOTIVOS FAMILIARES">MOTIVOS FAMILIARES</option>
                    <option value="MOTIVOS PERSONALES">MOTIVOS PERSONALES</option>
                    <option value="OFERTA SALARIAL">OFERTA SALARIAL</option>
                    <option value="RENUNCIA VOLUNTARIA">RENUNCIA VOLUNTARIA</option>
                    <option value="SALUD">SALUD</option>
                    <option value="TERMINO DE CONTRATO">TERMINO DE CONTRATO</option>
                  </select>
                </div>
              </div>
              <div>
                <label htmlFor="username" className="mt-6 mb-2 block text-sm font-medium leading-6 text-gray-900">
                  Fecha de baja
                </label>
                <DatePicker
                  onChange={setDeleteCreate}
                  value={deleteCreate}
                  format="dd/MM/y"
                  maxDate={new Date()}
                  calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                  clearIcon={null}
                  prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                  nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
                />
              </div>
              <div className="mt-10 mb-4">
                <p className="text-sm text-gray-500 noselect">
                  Para dar de baja el usuario escribe en el siguiente recuadro: <span className="italic font-semibold">Confirmo la baja</span>
                </p>
                <div className="mt-2">
                  <input
                    type="text"
                    name="delete-confirm"
                    id="delete-confirm"
                    autoComplete="off"
                    value={deleteConfirm}
                    onChange={e => setDeleteConfirm(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
              onClick={makeDelete}
            >
              Sí, baja
            </button>
            <button
              type="button"
              className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              onClick={() => deleteUserRef.current.hideModal()}
            >
              Cancel
            </button>
          </div>
        </BigModalTemplate>
        <NoSpaceTemplate ref={findBossesRef}>
          <FindUsersPalette callback={onSelectBoss} />
        </NoSpaceTemplate>
      </main>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
