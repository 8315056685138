import { useEffect, useRef, useState } from "react";
import { CalendarDaysIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import {
  ExclamationCircleIcon,
  TrashIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { CheckCircleIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link, useNavigate, useParams } from "react-router-dom";
import API from "../../services/API";
import { useSelector } from "react-redux";
import Notification from "../../components/notifications/Notification";
import AdminAssessmentAction from "../../components/dropdowns/AdminAssessmentAction";
import { getDateLabel } from "../../utils/date_utils";
import {
  ArrowDownTrayIcon,
  CalendarIcon,
  ChevronLeftIcon,
  PencilSquareIcon,
  UserPlusIcon,
} from "@heroicons/react/24/solid";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import DateRangePicker from "@wojtekmaj/react-daterange-picker/dist/DateRangePicker";
import { useCallback } from "react";
import { base64StringToBlob } from "blob-util";
import InfinityLoader from "../../components/loaders/InfinityLoader";

export default function Assessments() {
  const { user, token } = useSelector((state) => state.authentication);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [assessments, setAssessments] = useState([]);
  const [assessment, setAssessment] = useState({});
  const [allAssessments, setAllAssessments] = useState([]);
  const [period, setPeriod] = useState({});
  const pathParams = useParams();
  const [loadingAssessments, setLoadingAssessments] = useState(false);
  const [search, setSearch] = useState("");
  const [error, setError] = useState("Ocurrió un error");
  const [title, setTitle] = useState("");
  const [titleAuto, setTitleAuto] = useState("");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [dateRange, setDateRange] = useState([new Date(), new Date()]);
  const navigate = useNavigate();
  const notificationRef = useRef();
  const editAssessmentRef = useRef();
  const deleteAssessmentRef = useRef();

  useEffect(() => {
    const fetchPeriod = () => {
      API.get("/periods", {
        params: { periodo_id: pathParams.periodId },
        headers: { Authorization: token },
      })
        .then(({ data, status }) => {
          if (status === 200) {
            setPeriod(data);
          }
        })
        .catch((error) => {
          setPeriod({});
          setErrorTitle("Error al buscar");
          setError("Ocurrió un error al buscar los periodos");
          return notificationRef.current.showNotification();
        });
    };
    const fetchAssessments = () => {
      let params = { period_assessment: pathParams.assessmentId };
      if (
        user?.certificados?.filter(
          (c) => c.cat_permiso_id == 6 && c.estatus == 1
        )?.length < 1
      ) {
        params.region = user.region_id;
      }
      const options = {
        params,
        headers: { Authorization: token },
      };
      setLoadingAssessments(true);
      API.get(`/assessments`, options)
        .then(({ data, status }) => {
          if (status === 200) {
            setAllAssessments(data);
            setAssessments(data);
          }
          setLoadingAssessments(false);
        })
        .catch((error) => {
          setLoadingAssessments(false);
        });
    };
    fetchPeriod();
    fetchAssessment();
    fetchAssessments();
  }, []);

  const fetchAssessment = useCallback(() => {
    const params = { evaluacion_id: pathParams.assessmentId };
    const options = {
      params,
      headers: { Authorization: token },
    };
    API.get(`/periods/${pathParams.periodId}/assessments`, options)
      .then(({ data, status }) => {
        if (status === 200) {
          setAssessment(data);
          setTitle(data.titulo);
          setTitleAuto(data.titulo_autoevaluacion);
          setDateRange([
            new Date(data.fecha_inicio + "T00:00:00"),
            new Date(data.fecha_fin + "T23:59:59"),
          ]);
        }
      })
      .catch((error) => {
        setAssessment({});
      });
  }, [token, pathParams]);

  const getIndividualAssessmentStatusLabel = (iniciada, finalizada) => {
    let label = finalizada
      ? "finalizada"
      : iniciada
      ? "en proceso"
      : "no iniciada";
    return label;
  };

  const getFeedbackStatusLabel = (agendada, terminada, cierre) => {
    let label = "";
    if (!agendada) {
      label = "";
    } else {
      if (!terminada) {
        label = "Cara a Cara";
      } else {
        if (cierre == 3) {
          label = "Terminada";
        } else if (cierre == 2) {
          label = "Validando";
        } else {
          label = "Actualizando";
        }
      }
    }
    return label;
  };

  const getGlobalAssessmentStatusLabel = (status_id) => {
    let label = "";
    switch (status_id) {
      case 1:
        label = "No iniciada";
        break;
      case 2:
        label = "En proceso";
        break;
      case 3:
        label = "Proceso COV";
        break;
      case 4:
        label = "Cerrada";
        break;
      default:
        label = "No iniciada";
        break;
    }
    return label;
  };

  const getAssessmentStatusRowClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "bg-red-100";
        break;
      case 2:
        className = "bg-yellow-100";
        break;
      case 3:
        className = "bg-orange-100";
        break;
      case 4:
        className = "bg-green-100";
        break;
      default:
        className = "bg-red-100";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabelClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "text-red-800";
        break;
      case 2:
        className = "text-yellow-800";
        break;
      case 3:
        className = "text-orange-800";
        break;
      case 4:
        className = "text-green-800";
        break;
      default:
        className = "text-red-800";
        break;
    }
    return className;
  };

  const onCloseAssessment = (id) => {
    let newAssessments = [...assessments];
    let newAllAssessments = [...allAssessments];
    let indexOne = newAssessments.findIndex(
      (a) => a.evaluacion_usuario_id == id
    );
    let itemTwo = newAllAssessments.find((a) => a.evaluacion_usuario_id == id);
    if (indexOne >= 0) {
      newAssessments[indexOne].estatus_evaluacion_id = 4;
    }
    if (itemTwo >= 0) {
      newAllAssessments[itemTwo].estatus_evaluacion_id = 4;
    }
    setAssessments(newAssessments);
    setAllAssessments(newAllAssessments);
  };

  const onDeleteAssessment = (id) => {
    let newAssessments = [...assessments];
    let newAllAssessments = [...allAssessments];
    let indexOne = newAssessments.findIndex(
      (a) => a.evaluacion_usuario_id == id
    );
    let itemTwo = newAllAssessments.find((a) => a.evaluacion_usuario_id == id);
    if (indexOne >= 0) {
      newAssessments.splice(indexOne, 1);
    }
    if (itemTwo >= 0) {
      newAllAssessments.splice(indexOne, 1);
    }
    setAssessments(newAssessments);
    setAllAssessments(newAllAssessments);
  };

  const onEndAssessment = (id, type, value) => {
    let newAssessments = [...assessments];
    let newAllAssessments = [...allAssessments];
    let indexOne = newAssessments.findIndex(
      (a) => a.evaluacion_usuario_id == id
    );
    let itemTwo = newAllAssessments.find((a) => a.evaluacion_usuario_id == id);
    let field = "autoevaluacion_terminada";
    if (type == 2) {
      field = "evaluacion_terminada";
    }
    if (indexOne >= 0) {
      newAssessments[indexOne][field] = value;
    }
    if (itemTwo >= 0) {
      newAllAssessments[indexOne][field] = value;
    }
    setAssessments(newAssessments);
    setAllAssessments(newAllAssessments);
  };

  useEffect(() => {
    if (search != "") {
      setAssessments(
        allAssessments.filter(
          (a) =>
            a.nombre_completo.toUpperCase().indexOf(search.toUpperCase()) !== -1
        )
      );
    } else {
      setAssessments(allAssessments);
    }
  }, [search]);

  const onSelectDate = (date) => {
    setDateRange(date);
  };

  const patchAssessment = () => {
    setLoadingSave(true);
    const [fecha_inicio, fecha_fin] = dateRange;

    let userTimezoneOffset = fecha_fin.getTimezoneOffset() * 60000;

    let fFin = new Date(fecha_fin.getTime() - userTimezoneOffset);

    let body = {
      titulo: title,
      titulo_autoevaluacion: titleAuto,
      fecha_inicio,
      fecha_fin: fFin,
    };
    API.patch(
      `/periods/${pathParams.periodId}/assessments/${pathParams.assessmentId}`,
      body,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        setLoadingSave(false);
        if (result.status === 200) {
          editAssessmentRef.current.hideModal();
          fetchAssessment();
        }
      })
      .catch((error) => {
        setLoadingSave(false);
      });
  };

  const deleteAssessment = () => {
    setLoadingDelete(true);
    let body = {
      estatus: 2,
      titulo: title,
    };
    API.patch(
      `/periods/${pathParams.periodId}/assessments/${pathParams.assessmentId}`,
      body,
      { headers: { Authorization: token } }
    )
      .then((result) => {
        setLoadingDelete(false);
        if (result.status === 200) {
          deleteAssessmentRef.current.hideModal();
          navigate(-1);
        }
      })
      .catch((error) => {
        setLoadingDelete(false);
      });
  };

  const downloadFile = (e) => {
    e.preventDefault();
    setLoadingFile(true);
    let params = {
      periodo: pathParams.periodId,
      evaluacion: pathParams.assessmentId,
      region: user.region_id,
    };
    API.get("/assessments/filedownload", {
      params,
      headers: { Authorization: token },
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingFile(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `EvaluacionesPeriodo_${period.titulo}.xlsx`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingFile(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los usuarios"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los usuarios"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  let superAdministrador =
    user?.certificados?.filter((c) => c.cat_permiso_id == 6 && c.estatus == 1)
      ?.length > 0;

  return (
    <div className="min-w-0 w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="flex flex-col justify-start pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  {assessment?.titulo} - {period.titulo}
                </h1>
                <p className="mt-2 text-lg text-gray-700">
                  Del{" "}
                  {getDateLabel(
                    new Date(assessment?.fecha_inicio + "T00:00:00")
                  )}{" "}
                  al{" "}
                  {getDateLabel(new Date(assessment?.fecha_fin + "T00:00:00"))}.
                </p>
              </div>
              <div className="sm:flex-auto mt-4"></div>
            </div>
            <div className="flex space-x-3 justify-start sm:justify-end mb-4">
              <button
                type="button"
                onClick={downloadFile}
                disabled={loadingFile}
                className="inline-flex items-center rounded-md border border-transparent bg-indigo-200 px-6 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                // onClick={e =>  editAssessmentRef.current.showModal()}
              >
                <ArrowDownTrayIcon
                  className={`h-5 w-5 mr-1 ${
                    loadingFile ? "animate-bounce" : ""
                  }`}
                />
                {loadingFile ? "Descargando archivo" : "Descargar"}
              </button>
              {superAdministrador && (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-red-200 px-6 py-3 text-base font-medium text-red-700 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={(e) => deleteAssessmentRef.current.showModal()}
                >
                  <TrashIcon className="h-5 w-5 mr-1" />
                  Eliminar
                </button>
              )}
              {superAdministrador && (
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-yellow-200 px-6 py-3 text-base font-medium text-yellow-700 hover:bg-yellow-300 focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
                  onClick={(e) => editAssessmentRef.current.showModal()}
                >
                  <PencilSquareIcon className="h-5 w-5 mr-1" />
                  Editar
                </button>
              )}
              {superAdministrador && (
                <Link
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-green-200 px-6 py-3 text-base font-medium text-green-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  to={`/t3b/admin/periods/${pathParams.periodId}/assessments/${pathParams.assessmentId}/add`}
                >
                  <UserPlusIcon className="h-5 w-5 mr-1" />
                  Agregar evaluados
                </Link>
              )}
            </div>
          </div>
          <div>
            <label
              htmlFor="usersearch"
              className="block text-sm font-medium text-gray-700"
            >
              Buscar evaluación
            </label>
            <div className="mt-1 flex rounded-md shadow-sm mb-4">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <UsersIcon
                    className="h-5 w-5 text-gray-400"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  name="usersearch"
                  id="usersearch"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Nombre o apellidos"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col">
            {loadingAssessments ? (
              <>
                <InfinityLoader />
              </>
            ) : (
              <div className="-my-2 -mx-4 overflow-x-auto lg:overflow-visible sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                          >
                            Nombre
                          </th>
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Estatus individual
                          </th>
                          {assessment.siguiente_periodo && (
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Proceso
                            </th>
                          )}
                          <th
                            scope="col"
                            className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                          >
                            Estatus global
                          </th>
                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {assessments.map((assessment) => (
                          <tr
                            key={`key-row-${assessment.evaluacion_usuario_id}`}
                          >
                            <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <div className="font-medium text-gray-900">
                                    {assessment.nombre_completo}
                                  </div>
                                  <div className="text-gray-500">
                                    {assessment.puesto}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {!assessment.solo_evaluacion && (
                                <div className="text-gray-500">
                                  Autoevaluación{" "}
                                  {getIndividualAssessmentStatusLabel(
                                    assessment.autoevaluacion_iniciada,
                                    assessment.autoevaluacion_terminada
                                  )}
                                </div>
                              )}
                              <div className="text-gray-500">
                                Evaluación{" "}
                                {getIndividualAssessmentStatusLabel(
                                  assessment.evaluacion_iniciada,
                                  assessment.evaluacion_terminada
                                )}
                              </div>
                            </td>
                            {assessment.siguiente_periodo && (
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                <div className="text-gray-500">
                                  {getFeedbackStatusLabel(
                                    assessment.revision_agendada,
                                    assessment.revision_terminada,
                                    assessment.estatus_cierre_id
                                  )}
                                </div>
                              </td>
                            )}
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span
                                className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getAssessmentStatusRowClassName(
                                  assessment.estatus_evaluacion_id
                                )} ${getAssessmentStatusLabelClassName(
                                  assessment.estatus_evaluacion_id
                                )}`}
                              >
                                {getGlobalAssessmentStatusLabel(
                                  assessment.estatus_evaluacion_id
                                )}
                              </span>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <AdminAssessmentAction
                                id={assessment.evaluacion_usuario_id}
                                employeeId={assessment.numero_empleado}
                                status={assessment.estatus_evaluacion_id}
                                evaluacion={assessment.evaluacion_terminada}
                                autoevaluacion={
                                  assessment.autoevaluacion_terminada
                                }
                                deleteCallback={onDeleteAssessment}
                                endCallback={onEndAssessment}
                                closeCallback={onCloseAssessment}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <BigModalTemplate ref={editAssessmentRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Editar evaluación</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => editAssessmentRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              TÍTULO EN EVALUACIONES
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Título del periodo..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              TÍTULO EN AUTOEVALUACIONES
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Título del periodo..."
                value={titleAuto}
                onChange={(e) => setTitleAuto(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHAS DEL PERIODO
            </label>
            <div className="">
              <DateRangePicker
                onChange={onSelectDate}
                value={dateRange}
                locale="es"
                calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                clearIcon={null}
                prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => editAssessmentRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingSave}
                onClick={patchAssessment}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loadingSave ? "Guardando periodo" : "Guardar"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <BigModalTemplate ref={deleteAssessmentRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
            <h3
              className="text-base font-semibold leading-6 text-gray-900"
            >
              Eliminar Formato
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de eliminar el formato del periodo de evaluación,
                ya no se podrán asignar empleados a este formato a menos que se
                agregue nuevamente?
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingDelete}
            onClick={deleteAssessment}
            className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
          >
            {loadingDelete ? "Eliminando Formato" : "Eliminar"}
          </button>
          <button
            type="button"
            onClick={(e) => deleteAssessmentRef.current.hideModal()}
            className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
          >
            Cancelar
          </button>
        </div>
      </BigModalTemplate>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
