import {
  CheckCircleIcon,
  EyeIcon,
  PencilSquareIcon,
  XCircleIcon,
} from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../constants/functions";
import { months_short_es } from "../../utils/date_utils";
import ObjectiveProgressModal from "../modals/ObjectiveProgressModal";

const AdminHistoryObjective = ({
  id,
  status,
  title,
  description,
  dueDate,
  startDate,
  periodStatus,
  self,
  superior,
  endSelfAssessment,
  endSuperiorAssessment,
}) => {
  const progressRef = useRef();

  const getObjectiveStatusClassName = (status_id) => {
    let className = "";
    if (status_id < 4) {
      className = "bg-red-100 text-red-800";
    } else {
      className = "bg-blue-100 text-blue-800";
    }
    return className;
  };

  const getObjectiveStatusLabel = (status_id) => {
    let className = "";
    if (status_id < 4) {
      className = "No alcanzado";
    } else {
      className = "Alcanzado";
    }
    return className;
  };

  const getObjectiveDatePercentage = () => {
    let percentage = "";
    let beginDate = new Date(startDate);
    let endDate = new Date(dueDate);

    if (new Date() > endDate) {
      percentage = 100;
    } else {
      const diffTimeTotal = Math.abs(endDate - beginDate);
      const diffDaysTotal = Math.ceil(diffTimeTotal / (1000 * 60 * 60 * 24));

      const diffTimePartial = Math.abs(endDate - new Date());
      const diffDaysPartial = Math.ceil(
        diffTimePartial / (1000 * 60 * 60 * 24)
      );

      const percentageNumber = (diffDaysPartial / diffDaysTotal) * 100;

      percentage = 100 - percentageNumber;
    }

    return percentage;
  };

  const getObjectiveDatePercentageLabel = () => {
    let percentage = "";
    let endDate = new Date(dueDate);

    if (status < 3) {
      if (new Date() > endDate) {
        percentage = (
          <span className="text-red-600 italic">
            No se cumplió el objetivo a tiempo
          </span>
        );
      } else {
        const diffTimePartial = Math.abs(endDate - new Date());
        const diffDaysPartial = Math.ceil(
          diffTimePartial / (1000 * 60 * 60 * 24)
        );

        if (diffDaysPartial > 30) {
          percentage = `Resta ${Math.floor(diffDaysPartial / 30)} mes${
            Math.floor(diffDaysPartial / 30) != 1 ? "es" : ""
          } para completar este objetivo`;
        } else {
          percentage = `Resta ${diffDaysPartial} día${
            diffDaysPartial != 1 ? "s" : ""
          } para completar este objetivo`;
        }
      }
    }

    return percentage;
  };

  const getObjectiveDatePercentageClassName = () => {
    let className = "";
    let percentage = getObjectiveDatePercentage();
    if (percentage >= 85) {
      className = "bg-red-500";
    } else if (percentage > 60 && percentage < 85) {
      className = "bg-yellow-500";
    } else {
      className = "bg-green-500";
    }
    return className;
  };

  const watchProgress = () => {
    progressRef.current.showModal(id);
  };

  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames("relative bg-white px-6 py-3 flex")}
    >
      <div className="flex-1 flex flex-col">
        {periodStatus != 2 && (
          <div className="flex justify-end">
            <span
              className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${getObjectiveStatusClassName(
                status
              )}`}
            >
              {getObjectiveStatusLabel(status)}
            </span>
          </div>
        )}
        <h3 className="text-lg font-medium focus:outline-none">{title}</h3>
        <p className="mt-1 text-sm text-gray-500 flex-1">{description}</p>
        <p className="mt-1 mb-2 text-sm text-gray-900 font-normal">
          Fecha límite de cumplimiento:{" "}
          <span className="underline">
            {new Date(dueDate).getDate()} de{" "}
            {months_short_es[new Date(dueDate).getMonth()]}. del{" "}
            {new Date(dueDate).getFullYear()}
          </span>
        </p>
        <div className="flex flex-wrap space-x-2 items-center">
          {getObjectiveDatePercentage() < 100 && (
            <div className="h-2 flex-1 rounded-full bg-gray-300">
              <div
                className={`h-2 rounded-full ${getObjectiveDatePercentageClassName()}`}
                role="progressbar"
                style={{ width: `${getObjectiveDatePercentage()}%` }}
                aria-valuenow={getObjectiveDatePercentage()}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          )}
          <p className="m-0 font-medium text-sm mb-1">
            {getObjectiveDatePercentageLabel()}
          </p>
        </div>
        <div className="flex text-sm font-medium mt-2 flex-wrap">
          <div className="">
            <button
              onClick={watchProgress}
              type="button"
              className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
            >
              <EyeIcon className="h-5 w-5 mr-1" />
              Ver progreso
            </button>
          </div>
        </div>
      </div>
      <ObjectiveProgressModal ref={progressRef} />
    </div>
  );
};

AdminHistoryObjective.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  periodStatus: PropTypes.number,
  title: PropTypes.string,
  dueDate: PropTypes.string,
  description: PropTypes.string,
};

AdminHistoryObjective.defaultProps = {
  id: 1,
  status: 1,
  periodStatus: 1,
  title: "Default title",
  dueDate: "2023-01-01",
  description: "Default description",
};

export default AdminHistoryObjective;
