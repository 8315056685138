import { CalendarDaysIcon, PencilIcon } from '@heroicons/react/20/solid';
import { TrashIcon } from '@heroicons/react/24/outline';
import PropTypes from 'prop-types';
import { classNames } from '../../constants/functions';
import { months_short_es } from '../../utils/date_utils';

const AssessmentNewSimpleObjective = ({id, dueDate, title, description, selectNextObjectiveToDelete, selectNextObjectiveToEdit, active}) => {
  if(!active){
    return <></>;
  }
  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames("relative bg-white p-6 flex shadow rounded-lg border")}
    >
      <div className="flex-1 flex flex-col">
        <h3 className="text-lg font-medium focus:outline-none">
          {title}
        </h3>
        <p className="my-1 flex items-center text-sm font-medium text-gray-600">
          <CalendarDaysIcon  className='h-5 w-5 mr-1'/>
          Fecha límite de cumplimiento: {dueDate.getDate()} de {months_short_es[dueDate.getMonth()]}. {dueDate.getFullYear()}
        </p>
        <p className="mt-1 text-sm text-gray-500 flex-1">
          {description}
        </p>
        <div className="flex text-sm font-medium mt-4 justify-end">
          <div className="ml-4 border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              className="text-gray-700 hover:text-gray-500 flex items-center"
              onClick={e => selectNextObjectiveToEdit(id, title, description, dueDate)}
            >
              <PencilIcon className='h-5 w-5 mr-1'/>
              Editar Objetivo
            </button>
          </div>
          <div className="ml-4 border-l border-gray-200 pl-4 sm:ml-6 sm:pl-6">
            <button
              onClick={e => selectNextObjectiveToDelete(id)}
              className="text-red-600 hover:text-red-800 flex items-center"
            >
              <TrashIcon className='h-5 w-5 mr-1'/>
              Eliminar Objetivo
            </button>
          </div>
        </div>
      </div>
    </div>
  )
};

AssessmentNewSimpleObjective.propTypes = {
  id: PropTypes.number,
  dueDate: PropTypes.instanceOf(Date),
  title: PropTypes.string,
  description: PropTypes.string,
  active: PropTypes.bool,
  selectNextObjectiveToDelete: PropTypes.func,
  selectNextObjectiveToEdit: PropTypes.func
};

AssessmentNewSimpleObjective.defaultProps = {
  id: 1,
  active: true,
  dueDate: new Date(),
  title: "Default title",
  description: "Default description",
  selectNextObjectiveToDelete: () => {},
  selectNextObjectiveToEdit: () => {}
};

export default AssessmentNewSimpleObjective;