import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  BarChart,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import PlusFolder from "../vectors/PlusFolder";
import { months_db_es } from "../../utils/date_utils";

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center items-center space-x-6 flex-wrap">
      {payload.map((entry, index) => {
        const { payload } = entry;
        return (
          <li key={`item-${index}`} className="flex items-center">
            <div
              className="h-4 w-4 mr-2"
              style={{
                backgroundColor: payload.fill,
                border: 1,
                borderStyle: "solid",
                borderColor: payload.fill,
              }}
            ></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        );
      })}
    </ul>
  );
};

const BarChartObjectivesAchieved = ({ period, isBoss, region, area, form }) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [competences, setCompetences] = useState([]);
  const [loadingCompetences, setLoadingCompetences] = useState(true);

  useEffect(() => {
    if (period) {
      fetchBarChartObjectivesAchieved();
    }
  }, [period, region, area]);

  const fetchBarChartObjectivesAchieved = useCallback(() => {
    let params = { year: period };
    if (region !== 0) {
      params.region_id = region;
    }
    if (area !== "0") {
      params.area = area;
    }
    setLoadingCompetences(true);
    const headers = { Authorization: token };
    API.get(`/objectives/results/months`, { params, headers })
      .then(({ status, data }) => {
        setLoadingCompetences(false);
        if (status === 200) {
          let dataObj = data || [];
          setCompetences(dataObj);
        } else {
          setCompetences([]);
        }
      })
      .catch((error) => {
        setLoadingCompetences(false);
        setCompetences([]);
      });
  }, [period, region, area, token, form]);

  const returnEmptyMonth = (month) => {
    return{
      alcanzados: 0,
      anio: period,
      completados: 0,
      mes: month,
      no_alcanzados: 0,
      total: 0
    }
  }
  const getCompetences = (quarter) => {
    let data = [];
    let dataUntrasformed = [];
    let emptyMonth = {
      alcanzados: 0,
      anio: period,
      completados: 0,
      mes: 0,
      no_alcanzados: 0,
      total: 0
    }
    if(quarter == 1){
      let first = competences.find(c => c.mes==1);
      if(!first){
        dataUntrasformed.push(returnEmptyMonth(1));
      } else {
        dataUntrasformed.push(first);
      }
      let second = competences.find(c => c.mes==2);
      if(!second){
        dataUntrasformed.push(returnEmptyMonth(2));
      } else {
        dataUntrasformed.push(second);
      }
      let third = competences.find(c => c.mes==3);
      if(!third){
        dataUntrasformed.push(returnEmptyMonth(3));
      } else {
        dataUntrasformed.push(third);
      }
    } else if(quarter == 2){
      let first = competences.find(c => c.mes==4);
      if(!first){
        dataUntrasformed.push(returnEmptyMonth(4));
      } else {
        dataUntrasformed.push(first);
      }
      let second = competences.find(c => c.mes==5);
      if(!second){
        dataUntrasformed.push(returnEmptyMonth(5));
      } else {
        dataUntrasformed.push(second);
      }
      let third = competences.find(c => c.mes==6);
      if(!third){
        dataUntrasformed.push(returnEmptyMonth(6));
      } else {
        dataUntrasformed.push(third);
      }
    } else if(quarter == 3){
      let first = competences.find(c => c.mes==7);
      if(!first){
        dataUntrasformed.push(returnEmptyMonth(7));
      } else {
        dataUntrasformed.push(first);
      }
      let second = competences.find(c => c.mes==8);
      if(!second){
        dataUntrasformed.push(returnEmptyMonth(8));
      } else {
        dataUntrasformed.push(second);
      }
      let third = competences.find(c => c.mes==9);
      if(!third){
        dataUntrasformed.push(returnEmptyMonth(9));
      } else {
        dataUntrasformed.push(third);
      }
    }else {
      let first = competences.find(c => c.mes==10);
      if(!first){
        dataUntrasformed.push(returnEmptyMonth(10));
      } else {
        dataUntrasformed.push(first);
      }
      let second = competences.find(c => c.mes==11);
      if(!second){
        dataUntrasformed.push(returnEmptyMonth(11));
      } else {
        dataUntrasformed.push(second);
      }
      let third = competences.find(c => c.mes==12);
      if(!third){
        dataUntrasformed.push(returnEmptyMonth(12));
      } else {
        dataUntrasformed.push(third);
      }
    }
    dataUntrasformed.forEach((c) => {
      let apartado = {
        name: months_db_es[c.mes],
        Alcanzados: roundTwoDecimals(c.alcanzados),
        "No Alcanzados": roundTwoDecimals(c.no_alcanzados),
      };
      data.push(apartado);
    });
    return data;
  };

  return (
    <>
      {loadingCompetences ? (
          <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
            <dt className="w-full text-center text-lg font-medium text-gray-500">
              Objetivos Alcanzados Por Mes
            </dt>
            <InfinityLoader legend={false} className="h-60 w-full" />
          </div>
        ) : (
          <>
            <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
              <dt className="w-full text-center text-lg font-medium text-gray-500">
                Objetivos Alcanzados Por Mes T1
              </dt>
              <dd className="flex-1">
                {!loadingCompetences && competences.length < 1
                  ? <div className="flex flex-col h-full items-center justify-center mt-3">
                    <PlusFolder />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No hay evaluaciones finalizadas
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Se debe tener al menos una evaluación finalizada con los filtros seleccionados para realizar el cálculo.
                    </p>
                  </div>
                  :
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      margin={{ top: 15 }}
                      data={getCompetences(1)}
                    >
                      <Tooltip />
                      <Legend
                        content={renderLegend}
                        wrapperStyle={{ display: "flex" }}
                      />
                      <XAxis dataKey="name" />
                      <YAxis type="number" />
                      <Bar
                        dataKey="Alcanzados"
                        stackId="a"
                        fill="#16a34a66"
                        stroke="#16a34a"
                      ></Bar>
                      <Bar
                        dataKey="No Alcanzados"
                        stackId="a"
                        fill="#dc262666"
                        stroke="#dc2626"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                }
              </dd>
            </div>
            <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
              <dt className="w-full text-center text-lg font-medium text-gray-500">
                Objetivos Alcanzados Por Mes T2
              </dt>
              <dd className="flex-1">
                {!loadingCompetences && competences.length < 1
                  ? <div className="flex flex-col h-full items-center justify-center mt-3">
                    <PlusFolder />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No hay evaluaciones finalizadas
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Se debe tener al menos una evaluación finalizada con los filtros seleccionados para realizar el cálculo.
                    </p>
                  </div>
                  :
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      margin={{ top: 15 }}
                      data={getCompetences(2)}
                    >
                      <Tooltip />
                      <Legend
                        content={renderLegend}
                        wrapperStyle={{ display: "flex" }}
                      />
                      <XAxis dataKey="name" />
                      <YAxis type="number" />
                      <Bar
                        dataKey="Alcanzados"
                        stackId="a"
                        fill="#16a34a66"
                        stroke="#16a34a"
                      ></Bar>
                      <Bar
                        dataKey="No Alcanzados"
                        stackId="a"
                        fill="#dc262666"
                        stroke="#dc2626"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                }
              </dd>
            </div>
            <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
              <dt className="w-full text-center text-lg font-medium text-gray-500">
                Objetivos Alcanzados Por Mes T3
              </dt>
              <dd className="flex-1">
                {!loadingCompetences && competences.length < 1
                  ? <div className="flex flex-col h-full items-center justify-center mt-3">
                    <PlusFolder />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No hay evaluaciones finalizadas
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Se debe tener al menos una evaluación finalizada con los filtros seleccionados para realizar el cálculo.
                    </p>
                  </div>
                  :
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      margin={{ top: 15 }}
                      data={getCompetences(3)}
                    >
                      <Tooltip />
                      <Legend
                        content={renderLegend}
                        wrapperStyle={{ display: "flex" }}
                      />
                      <XAxis dataKey="name" />
                      <YAxis type="number" />
                      <Bar
                        dataKey="Alcanzados"
                        stackId="a"
                        fill="#16a34a66"
                        stroke="#16a34a"
                      ></Bar>
                      <Bar
                        dataKey="No Alcanzados"
                        stackId="a"
                        fill="#dc262666"
                        stroke="#dc2626"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                }
              </dd>
            </div>
            <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
              <dt className="w-full text-center text-lg font-medium text-gray-500">
                Objetivos Alcanzados Por Mes T4
              </dt>
              <dd className="flex-1">
                {!loadingCompetences && competences.length < 1
                  ? <div className="flex flex-col h-full items-center justify-center mt-3">
                    <PlusFolder />
                    <h3 className="mt-2 text-sm font-medium text-gray-900">
                      No hay evaluaciones finalizadas
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Se debe tener al menos una evaluación finalizada con los filtros seleccionados para realizar el cálculo.
                    </p>
                  </div>
                  :
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      margin={{ top: 15 }}
                      data={getCompetences(4)}
                    >
                      <Tooltip />
                      <Legend
                        content={renderLegend}
                        wrapperStyle={{ display: "flex" }}
                      />
                      <XAxis dataKey="name" />
                      <YAxis type="number" />
                      <Bar
                        dataKey="Alcanzados"
                        stackId="a"
                        fill="#16a34a66"
                        stroke="#16a34a"
                      ></Bar>
                      <Bar
                        dataKey="No Alcanzados"
                        stackId="a"
                        fill="#dc262666"
                        stroke="#dc2626"
                      />
                    </BarChart>
                  </ResponsiveContainer>
                }
              </dd>
            </div>
          </>
      )}
    </>
  );
};

BarChartObjectivesAchieved.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number,
  area: PropTypes.string,
};

BarChartObjectivesAchieved.defaultProps = {
  period: null,
  isBoss: true,
  region: 0,
  area: "0",
};

export default BarChartObjectivesAchieved;
