import { Fragment, useEffect, useRef, useState } from "react";
import { Menu, Dialog, Transition, Popover, RadioGroup } from "@headlessui/react";
import {
  AdjustmentsHorizontalIcon,
  Bars3BottomLeftIcon,
  CheckCircleIcon,
  CheckIcon,
  CloudArrowUpIcon,
  Cog6ToothIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  EllipsisVerticalIcon,
  ExclamationTriangleIcon,
  PlusIcon,
  PrinterIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  ChevronDownIcon,
  PencilSquareIcon,
  TrashIcon,
  ArrowUpIcon,
  ArrowDownIcon,
} from "@heroicons/react/20/solid";
import logo from "./../../assets/img/brand/logo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Notification from "../../components/notifications/Notification";
import API from "../../services/API";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import { actionPlanTypes, objectivesTypes } from "../../constants/formats";
import { useCallback } from "react";
import { sortByOrder } from "../../constants/functions";
import BlankPrint from "./BlankPrint";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const solutions = [
  {
    type: 1,
    name: "Texto Abierto",
    icon: Bars3BottomLeftIcon,
  },
  {
    type: 2,
    name: "Selección Simple",
    icon: CheckIcon,
  },
  {
    type: 3,
    name: "Switch y comentario",
    icon: AdjustmentsHorizontalIcon,
  },
  {
    type: 5,
    name: "Selección Simple Compras",
    icon: CheckIcon,
  },
];

const templateResponses = [
  {
    title: "Respuesta 1",
    value: 1,
    order: 1,
    is_active: false,
    is_max: false
  },
];

const EditFormat = () => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(-3);
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [questionType, setQuestionType] = useState(1);
  const [salesObjectives, setSalesObjectives] = useState([]);
  const [pages, setPages] = useState([]);
  const [responses, setResponses] = useState([]);
  const [currentPage, setCurrentPage] = useState({});
  const [currentPageId, setCurrentPageId] = useState(0);
  const [currentSectionId, setCurrentSectionId] = useState(0);
  const [currentQuestionObject, setCurrentQuestionObject] = useState({});
  const [currentSection, setCurrentSection] = useState({});
  const [sections, setSections] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [formatName, setFormatName] = useState("Nombre del formato");
  const [formatDescription, setFormatDescription] = useState(
    "Descripción del formato"
  );
  const [formatPeriodicidad, setFormatPeriodicidad] = useState(30);
  const [formatActive, setFormatActive] = useState(true);
  const [formatDatosGenerales, setFormatDatosGenerales] = useState(true);
  const [formatTitulo, setFormatTitulo] = useState("Título del formato");
  const [formatObjectives, setFormatObjectives] = useState("0");
  const [formatObjectivesMandatoryOne, setFormatObjectivesMandatoryOne] = useState(false);
  const [formatObjectivesMandatoryTwo, setFormatObjectivesMandatoryTwo] = useState(false);
  const [formatActionPlan, setFormatActionPlan] = useState("0");
  const [formatIsActive, setFormatIsActive] = useState(true);
  const [formatInstrucciones, setFormatInstrucciones] = useState(
    "Instrucciones del formato"
  );
  const [formatAgradecimientoTitulo, setFormatAgradecimientoTitulo] = useState(
    "Muchas gracias por tus respuestas"
  );
  const [formatAgradecimientoDescripcion, setFormatAgradecimientoDescripcion] =
    useState(
      "Avisa a tu Coordinador que has terminado tu entrevista de ajuste"
    );
  const [loading, setLoading] = useState(true);
  const [loadingPage, setLoadingPage] = useState(false);
  const [loadingSubsection, setLoadingSubsection] = useState(false);
  const [loadingQuestion, setLoadingQuestion] = useState(false);
  const [isSaved, setIsSaved] = useState(true);
  const [loadingPendingChanges, setLoadingPendingChanges] = useState(false);
  const [hasPendingChanges, setHasPendingChanges] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [subsectionTitle, setSubsectionTitle] = useState("Nuevo apartado");
  const [questionTitle, setQuestionTitle] = useState("Nueva pregunta");
  const [questionTitleAuto, setQuestionTitleAuto] = useState("Nueva pregunta");
  const [questionDescription, setQuestionDescription] = useState("Seleccione una o más respuestas");
  const [questionDescriptionAuto, setQuestionDescriptionAuto] = useState("Seleccione una o más respuestas");
  const [questionRequired, setQuestionRequired] = useState(false);
  const [questionHasValue, setQuestionHasValue] = useState(true);
  const [saveTimeout, setSaveTimeout] = useState(null);
  const [questionMaxValue, setQuestionMaxValue] = useState(5);
  const [questionMinValue, setQuestionMinValue] = useState(5);
  const [questionLimitValue, setQuestionLimitValue] = useState(1);
  const notificationRef = useRef();
  const modalRef = useRef();
  const modalSectionRef = useRef();
  const modalSectionDeleteRef = useRef();
  const modalQuestionDeleteRef = useRef();
  const navigate = useNavigate();
  const pathParams = useParams();
  const { user, token } = useSelector((state) => state.authentication);

  useEffect(() => {
    fetchContent();
  }, []);

  const fetchContent = useCallback(() => {
    setErrorTitle("Error al buscar");
    API.get(`/forms/${pathParams.format}/content`, {
      headers: { Authorization: token },
    })
      .then((reponse) => {
        setLoading(false);
        let { status, data } = reponse;
        if (status == 200) {
          setFormatName(data.titulo);
          setFormatDescription(data.descripcion);
          setFormatInstrucciones(data.instrucciones);
          setFormatObjectives(`${data.tipo_objetivos}`);
          setFormatObjectivesMandatoryOne(data.objetivos_s1);
          setFormatObjectivesMandatoryTwo(data.objetivos_s2);
          setFormatActionPlan(`${data.tipo_plan}`);
          setFormatIsActive(`${data.is_active}`);
          let newSections = [...data.formato_json];
          for (let section of newSections) {
            for (let subsection of section.subsections) {
              for (let question of subsection.questions) {
                if ([2, 5].includes(Number(question.type))) {
                  let maxAnswer = question.answers.find((a) => a.is_max);
                  question.is_max = maxAnswer?.value;
                }
              }
            }
          }
          setPages(newSections);
        } else if (status == 204) {
          setError("El formato no tiene información");
          notificationRef.current.showNotification();
        } else {
          setError("Ha ocurrido un error al buscar la información del formato");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar el formato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar el formato"
          );
        }
        notificationRef.current.showNotification();
      });
    API.get(`/forms/${pathParams.format}/objectives`, {
      headers: { Authorization: token },
    })
      .then(({ status, data }) => {
        if (status === 200) {
          setSalesObjectives(data);
        }
      })
      .catch((error) => {
        setSalesObjectives([]);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al obtener los objetivos de ventas"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al obtener los objetivos de ventas"
          );
        }
        notificationRef.current.showNotification();
      });
  }, [pathParams, token]);

  const selectPage = (page) => {
    setCurrentMenu(page.seccion_id);
    setCurrentPage({ ...page });
    setSections([...page.subsections]);

    setCurrentPageId(page.seccion_id);
    if(page.subsections.length > 0){
      let subsectionId = page.subsections.sort((a,b) => a.order-b.order)[0].apartado_id
      let section = page.subsections?.find(
        (s) => Number(s.apartado_id) == Number(subsectionId)
      );
      setCurrentSectionId(subsectionId);
      setQuestions([...section.questions]);


    } else {
      setCurrentSectionId(0);
    }
    setCurrentQuestion(0);

    setSidebarOpen(false);
  };

  const selectSection = (subsection_id) => {
    let section = pages.find(p => p.seccion_id == currentPageId)?.subsections?.find(
      (s) => Number(s.apartado_id) == Number(subsection_id)
    );
    setCurrentSectionId(subsection_id);
    setQuestions([...section.questions]);
  };

  const onChangePage = (seccion_id, value, field) => {
    setHasPendingChanges(true);
    setIsSaved(false);
    let newPage = { ...currentPage };
    newPage[field] = value;
    setCurrentPage(newPage);
    let newPages = [...pages];
    let changedPage = newPages.find((p) => p.seccion_id == seccion_id);
    changedPage[field] = value;
    setPages(newPages);
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
  };

  const onKeyUpInput = (e) => {
    e.preventDefault();
    if (hasPendingChanges && !isSaved) {
      clearTimeout(saveTimeout);
      let newInterval = setTimeout(() => {
        saveFormat();
      }, 5000);
      setSaveTimeout(newInterval);
    }
  };

  const onChangeFormatField = (value, setter) => {
    setHasPendingChanges(true);
    setIsSaved(false);
    setter(value);
    if (saveTimeout) {
      clearTimeout(saveTimeout);
    }
  };

  const saveFormat = () => {
    setLoadingPendingChanges(true);
    setHasPendingChanges(false);
    clearTimeout(saveTimeout);
    setSaveTimeout(null);

    let bodyForm = {
      titulo: formatName,
      descripcion: formatDescription,
      instrucciones: formatInstrucciones,
      tipo_objetivos: formatObjectives,
      objetivos_s1: formatObjectivesMandatoryOne,
      objetivos_s2: formatObjectivesMandatoryTwo,
      tipo_plan: formatActionPlan,
      is_active: formatIsActive,
    };
    API.patch(`/forms/${pathParams.format}`, bodyForm, {
      headers: { Authorization: token },
    })
      .then(() => {
        let body = {
          sections: pages,
          objectives: salesObjectives,
        };
        API.post(`/forms/${pathParams.format}/content`, body, {
          headers: { Authorization: token },
        })
          .then(({ status, data }) => {
            if (status === 200) {
              setIsSaved(true);
              setLoadingPendingChanges(false);
            }
          })
          .catch((error) => {
            setLoadingPendingChanges(false);
            setHasPendingChanges(true);
            setIsSaved(false);
            if (error.response) {
              if (error.response.status === 401) {
                setError("La sesión ha caducado");
              } else {
                setError(error.response.data);
              }
            } else if (error.request) {
              setError(
                "La petición fue realizada, pero no hubo respuesta por parte del servidor guardar el formato"
              );
            } else {
              setError(
                "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet guardar el formato"
              );
            }
            notificationRef.current.showNotification();
          });
      })
      .catch((error) => {
        setLoadingPendingChanges(false);
        setHasPendingChanges(true);
        setIsSaved(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            if(error.response.data.trim() == "value too long for type character varying(50)"){
              setError("El título debe ser de 50 caracteres");
            } else {
              setError(error.response.data);
            }
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor guardar el formato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet guardar el formato"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const saveFormatCallback = (sectionsPages, callback) => {
    setLoadingPendingChanges(true);
    setHasPendingChanges(false);
    clearTimeout(saveTimeout);
    setSaveTimeout(null);

    let bodyForm = {
      titulo: formatName,
      descripcion: formatDescription,
      instrucciones: formatInstrucciones,
      tipo_objetivos: formatObjectives,
      tipo_plan: formatActionPlan,
      is_active: formatIsActive,
      objetivos_s1: formatObjectivesMandatoryOne,
      objetivos_s2: formatObjectivesMandatoryTwo,
    };
    API.patch(`/forms/${pathParams.format}`, bodyForm, {
      headers: { Authorization: token },
    })
      .then(() => {
        let body = {
          sections: sectionsPages,
          objectives: salesObjectives,
        };
        API.post(`/forms/${pathParams.format}/content`, body, {
          headers: { Authorization: token },
        })
          .then(({ status, data }) => {
            if (status === 200) {
              setIsSaved(true);
              setLoadingPendingChanges(false);
              callback();
            }
          })
          .catch((error) => {
            setLoadingPendingChanges(false);
            setHasPendingChanges(true);
            setIsSaved(false);
            if (error.response) {
              if (error.response.status === 401) {
                setError("La sesión ha caducado");
              } else {
                setError(error.response.data);
              }
            } else if (error.request) {
              setError(
                "La petición fue realizada, pero no hubo respuesta por parte del servidor guardar el formato"
              );
            } else {
              setError(
                "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet guardar el formato"
              );
            }
            notificationRef.current.showNotification();
          });
      })
      .catch((error) => {
        setLoadingPendingChanges(false);
        setHasPendingChanges(true);
        setIsSaved(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            if(error.response.data == "value too long for type character varying(50)".trim()){
              setError("El título debe ser de 50 caracteres");
            } else {
              setError(error.response.data);
            }
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor guardar el formato"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet guardar el formato"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const createQuestion = (type) => {
    setCurrentQuestion(0);
    setQuestionTitle("Nueva pregunta");
    setQuestionTitleAuto("Nueva pregunta");
    switch (type) {
      case 1:
        setQuestionDescription("Selecciona una respuesta");
        setQuestionDescriptionAuto("Selecciona una respuesta");
        break;
      case 2:
        setQuestionDescription("Selecciona una o más respuestas");
        setQuestionDescriptionAuto("Selecciona una respuesta");
        break;
      case 3:
        setQuestionDescription("Selecciona una respuesta");
        setQuestionDescriptionAuto("Selecciona una respuesta");
        break;
      default:
        setQuestionDescription("Escribe tu respuesta en el recuadro");
        setQuestionDescriptionAuto("Escribe tu respuesta en el recuadro");
        break;
    }
    setResponses([...templateResponses]);
    setQuestionType(type);
    modalRef.current.showModal();
  };

  const addPage = () => {
    const newSection = {
      title: "Nueva sección",
      value: 0,
      type: 1,
      order: pages.length + 11,
      subsections: [],
    };
    let unsorted = [...pages, newSection];
    setLoadingPage(true);
    saveFormatCallback(unsorted, () => {
      fetchContent();
      setLoadingPage(false);
    });
  };

  const createSubsection = () => {
    setCurrentSectionId(0);
    setSubsectionTitle("");
    modalSectionRef.current.showModal();
  }

  const editSubsection = () => {
    let newSections = [...pages];
    let title = newSections.find(
      (s) => s.seccion_id == currentPageId
    )?.subsections?.find(s => s.apartado_id==currentSectionId)?.title;
    setSubsectionTitle(title);
    modalSectionRef.current.showModal();
  }

  const deleteSubsection = () => {
    let newSections = [...pages];
    let title = newSections.find(
      (s) => s.seccion_id == currentPageId
    )?.subsections?.find(s => s.apartado_id==currentSectionId)?.title;
    setSubsectionTitle(title);
    modalSectionDeleteRef.current.showModal();
  }

  const inactivateSubsection = () => {
    let newSections = [...pages];
    let currentSection = newSections.find(
      (s) => s.seccion_id == currentPageId
    );
    currentSection["subsections"].find(s => s.apartado_id==currentSectionId).is_active = false;
    setLoadingSubsection(true);
    saveFormatCallback(newSections, () => {
      fetchContent();
      setLoadingSubsection(false);
      modalSectionDeleteRef.current.hideModal();

      if(currentPage.subsections.length > 0){
        let subsectionId = currentPage.subsections.sort((a,b) => a.order-b.order)[0].apartado_id
        let section = currentPage.subsections?.find(
          (s) => Number(s.apartado_id) == Number(subsectionId)
        );
        setCurrentSectionId(subsectionId);
        setQuestions([...section.questions]);


      } else {
        setCurrentSectionId(0);
        setQuestions([]);
      }
      setCurrentQuestion(0);
    });
  };

  const addSubsection = () => {
    let newSections = [...pages];
    let currentSection = newSections.find(
      (s) => s.seccion_id == currentPageId
    );
    if(currentSectionId!=0){
      currentSection["subsections"].find(s => s.apartado_id==currentSectionId).title = subsectionTitle;
    } else {
      const newSubSection = {
        title: subsectionTitle,
        seccion_id: currentSection.seccion_id,
        order: currentSection["subsections"].length + 11,
        questions: [],
      };
      currentSection["subsections"].push(newSubSection);
    }
    setLoadingSubsection(true);
    saveFormatCallback(newSections, () => {
      fetchContent();
      setLoadingSubsection(false);
      modalSectionRef.current.hideModal();
    });
  };

  const addQuestion = (e) => {
    let newSections = [...pages];
    let newSubsection = newSections.find(p => p.seccion_id==currentPageId)?.subsections?.find(s => s.apartado_id == currentSectionId);
    if(currentQuestion!=0){
      let quest = newSubsection?.questions?.find(q => q.pregunta_id==currentQuestion);
      quest.title = questionTitle;
      quest.placeholder = questionDescription;
      quest.placeholder_autoevaluacion = questionDescriptionAuto;
      quest.type = questionType;
      quest.title_auto = questionTitleAuto;
      quest.subseccion_id = currentSectionId;
      quest.answers = responses;
    } else {
      let newQuestion = {
        title: questionTitle,
        type: questionType,
        order: newSubsection?.questions.length + 11,
        title_auto: questionTitleAuto,
        subseccion_id: currentSectionId,
        placeholder: questionDescription,
        placeholder_autoevaluacion: questionDescriptionAuto,
        answers: responses,
        is_active: true,
        is_max: false
      };
      newSubsection.questions.push(newQuestion);
      let newQuestions = [...questions, newQuestion]
      setQuestions(newQuestions);
    }
    setLoadingQuestion(true);
    saveFormatCallback(newSections, () => {
      fetchContent();
      setLoadingQuestion(false);
      modalRef.current.hideModal();
    });
  };

  const deleteQuestion2 = (e) => {
    e.preventDefault();
    setLoadingQuestion(true);
    let ques = questions.find((q) => q.pregunta_id == currentQuestion);
    let body = {
      pregunta_id: currentQuestion,
      titulo: ques.titulo,
      descripcion: ques.descripcion,
      obligatoria: ques.obligatoria,
      valorada: ques.valorada,
      maximo: ques.maximo,
      order: ques.order,
      minimo: ques.minimo,
      tipo: ques.tipo,
    };
    API.post(
      `/entrevistas/formatos/${pathParams.format}/paginas/${currentMenu}/preguntas`,
      body,
      { headers: { Authorization: token } }
    )
      .then((reponse) => {
        setLoadingQuestion(false);
        let { status, data } = reponse;
        if (status == 201) {
          let newQuestions = [...questions];
          if (currentQuestion > 0) {
            let quesUpdate = newQuestions.find(
              (q) => q.pregunta_id == currentQuestion
            );
            quesUpdate.titulo = body.titulo;
            quesUpdate.descripcion = body.descripcion;
            quesUpdate.obligatoria = body.obligatoria;
            quesUpdate.valorada = body.valorada;
            quesUpdate.maximo = body.maximo;
            quesUpdate.order = body.order;
            quesUpdate.minimo = body.minimo;
            quesUpdate.tipo = body.tipo;
            quesUpdate.respuestas = data.respuestas;
          } else {
            body.pregunta_id = data.pregunta_id;
            body.respuestas = data.respuestas;
          }
          setQuestions(newQuestions);
          setQuestionLimitValue(2);
          setQuestionTitle("Nueva Pregunta");
          setQuestionDescription("Ingresa tu respuesta");
          setQuestionMaxValue(5);
          setQuestionMaxValue(5);
          setQuestionRequired(true);
          setQuestionHasValue(false);
          modalRef.current.hideModal();
        } else {
          setError("Ha ocurrido un error al crear la pregunta");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingQuestion(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al crear la pregunta"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al crear la pregunta"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const addResponse = (e) => {
    e.preventDefault();
    let newSections = [...pages];
    let newResponses = [...responses];
    const newAnswer = {
      title: "Nueva respuesta",
      order: newResponses?.length + 11,
      subseccion_id: currentSectionId,
      is_active: true,
      is_max: false,
      value: 1
    };
    newResponses.push(newAnswer);
    setResponses(newResponses);
    // if(currentQuestion!=0){
    //   let newAnswers = newSections.find(p => p.seccion_id==currentPageId)?.subsections?.find(s => s.apartado_id == currentSectionId)?.questions?.find(p => p.pregunta_id == currentQuestion)?.answers;
    //   newAnswers.push(newAnswer);
    // }
    // setLoadingPage(true);
    // saveFormatCallback(newSections, () => {
    //   fetchContent();
    //   setLoadingPage(false);
    //   modalRef.current.hideModal();
    // });
  };

  const onChangeResponse = (value, field, index) => {
    let newResponses = [...responses];
    newResponses[index][field] = value;
    setResponses(newResponses);
  };

  const selectQuestion = (question) => {
    setCurrentQuestion(question.pregunta_id);
  };

  const editQuestion = (e, question) => {
    e.preventDefault();
    setQuestionType(question.type);
    setQuestionTitle(question.title);
    setQuestionTitleAuto(question.title_auto);
    setQuestionDescription(question.placeholder);
    setQuestionDescriptionAuto(question.placeholder_autoevaluacion);
    setQuestionMaxValue(question.is_max);
    let questionsResponses = JSON.parse(JSON.stringify(question.answers));
    questionsResponses = questionsResponses.filter(r => r.is_active).sort((a, b) => a.order - b.order);
    setResponses(questionsResponses);
    modalRef.current.showModal();
  };

  const clickDeleteQuestion = (e, question) => {
    e.preventDefault();
    setQuestionType(question.type);
    setQuestionTitle(question.title);
    setQuestionTitleAuto(question.title_auto);
    setQuestionDescription(question.placeholder);
    setQuestionDescriptionAuto(question.placeholder_autoevaluacion);
    setQuestionMaxValue(question.is_max);
    let questionsResponses = JSON.parse(JSON.stringify(question.answers));
    questionsResponses = questionsResponses.filter(r => r.is_active).sort((a, b) => a.order - b.order);
    setResponses(questionsResponses);
    modalQuestionDeleteRef.current.showModal();
  };

  const deleteQuestion = (e) => {
    let newSections = [...pages];
    let newSubsection = newSections.find(p => p.seccion_id==currentPageId)?.subsections?.find(s => s.apartado_id == currentSectionId);
    let quest = newSubsection?.questions?.find(q => q.pregunta_id==currentQuestion);
    quest.is_active = false;
    setLoadingQuestion(true);
    saveFormatCallback(newSections, () => {
      fetchContent();
      setLoadingQuestion(false);
      modalQuestionDeleteRef.current.hideModal();
    });
  };

  const moveUpQuestion = (e, question, index) => {
    e.preventDefault();
    let newSections = [...pages];
    let newSubsection = newSections.find(p => p.seccion_id==currentPageId)?.subsections?.find(s => s.apartado_id == currentSectionId);
    let orderUp = 0;
    let orderDown = 0;
    let questionsToEdit = newSubsection?.questions.filter(p => p.is_active)?.sort((a, b) => a.order - b.order);
    let questUp = questionsToEdit[index];
    let questDown = questionsToEdit[index - 1];
    orderUp = questUp.order;
    orderDown = questDown.order;
    questUp.order = orderDown;
    questDown.order = orderUp;
    setLoadingQuestion(true);
    saveFormatCallback(newSections, () => {
      fetchContent();
      setLoadingQuestion(false);
      modalQuestionDeleteRef.current.hideModal();
    });
  };

  const moveDownQuestion = (e, question, index) => {
    e.preventDefault();
    let newSections = [...pages];
    let newSubsection = newSections.find(p => p.seccion_id==currentPageId)?.subsections?.find(s => s.apartado_id == currentSectionId);
    let orderUp = 0;
    let orderDown = 0;
    let questionsToEdit = newSubsection?.questions.filter(p => p.is_active)?.sort((a, b) => a.order - b.order);
    let questUp = questionsToEdit[index];
    let questDown = questionsToEdit[index + 1];
    orderUp = questUp.order;
    orderDown = questDown.order;
    questUp.order = orderDown;
    questDown.order = orderUp;
    setLoadingQuestion(true);
    saveFormatCallback(newSections, () => {
      fetchContent();
      setLoadingQuestion(false);
      modalQuestionDeleteRef.current.hideModal();
    });
  };

  return (
    <>
      {loading ? (
        <div className="w-full h-full flex items-center justify-center bg-white">
          <video
            className="w-96 h-96 flex flex-1"
            alt={"Tiendas 3B"}
            muted
            loop
            autoPlay
          >
            <source
              src={"https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"}
              type="video/mp4"
            />
            Sin soporte de video MP4
          </video>
        </div>
      ) : (
        <div className="flex flex-1 overflow-hidden min-h-0">
          <Transition.Root show={sidebarOpen} as={Fragment}>
            <Dialog
              as="div"
              className="relative z-40 lg:hidden"
              onClose={setSidebarOpen}
            >
              <Transition.Child
                as={Fragment}
                enter="transition-opacity ease-linear duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition-opacity ease-linear duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
              </Transition.Child>

              <div className="fixed inset-0 z-40 flex">
                <Transition.Child
                  as={Fragment}
                  enter="transition ease-in-out duration-300 transform"
                  enterFrom="-translate-x-full"
                  enterTo="translate-x-0"
                  leave="transition ease-in-out duration-300 transform"
                  leaveFrom="translate-x-0"
                  leaveTo="-translate-x-full"
                >
                  <Dialog.Panel className="relative flex w-full max-w-xs flex-1 flex-col bg-white pt-5 pb-4">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-300"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-300"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 right-0 -mr-12 pt-2">
                        <button
                          type="button"
                          className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                          onClick={() => setSidebarOpen(false)}
                        >
                          <span className="sr-only">Cerrar barra lateral</span>
                          <XMarkIcon
                            className="h-6 w-6 text-white"
                            aria-hidden="true"
                          />
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex flex-shrink-0 items-center px-4">
                      <p className="text-gray-500 text-xs">PÁGINAS</p>
                    </div>
                    <nav
                      className="mt-5 h-full flex-shrink-0 overflow-y-auto px-4 space-y-1"
                      aria-label="Sidebar"
                    >
                      <div
                        className="flex items-center"
                        onClick={(e) => setCurrentMenu(-3)}
                      >
                        <div
                          className={classNames(
                            currentMenu == -3
                              ? "bg-red-100 text-red-500"
                              : "text-gray-500 hover:text-gray-500 hover:bg-gray-100",
                            "group flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer ease-in duration-200"
                          )}
                        >
                          <Cog6ToothIcon
                            className={classNames(
                              currentMenu == -3
                                ? "text-red-500"
                                : "text-gray-500 group-hover:text-gray-900",
                              "mr-2 h-4 w-4 flex-shrink-0 ease-in duration-200"
                            )}
                            aria-hidden="true"
                          />
                          Configuración
                        </div>
                        <div className="ml-2 h-5 w-5 group"></div>
                      </div>
                      <div
                        className="flex items-center"
                        onClick={(e) => setCurrentMenu(-4)}
                      >
                        <div
                          className={classNames(
                            currentMenu == -4
                              ? "bg-red-100 text-red-500"
                              : "text-gray-500 hover:text-gray-500 hover:bg-gray-100",
                            "group flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer ease-in duration-200"
                          )}
                        >
                          <PrinterIcon
                            className={classNames(
                              currentMenu == -4
                                ? "text-red-500"
                                : "text-gray-500 group-hover:text-gray-900",
                              "mr-2 h-4 w-4 flex-shrink-0 ease-in duration-200"
                            )}
                            aria-hidden="true"
                          />
                          Previa
                        </div>
                        <div className="ml-2 h-5 w-5 group"></div>
                      </div>
                      {pages
                        .sort((a, b) => a.order - b.order)
                        .filter(p => p.is_active)
                        .map((item) => (
                          <div
                            key={`key-item-navigation-${item.seccion_id}`}
                            className="flex items-center"
                            onClick={(e) => selectPage(item)}
                          >
                            <div
                              className={classNames(
                                currentMenu == item.seccion_id
                                  ? "bg-red-100 text-red-500"
                                  : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                                "group flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer ease-in duration-200"
                              )}
                            >
                              <DocumentTextIcon
                                className={classNames(
                                  currentMenu == item.seccion_id
                                    ? "text-red-500"
                                    : "text-gray-500 group-hover:text-gray-900",
                                  "mr-2 h-4 w-4 flex-shrink-0 ease-in duration-200"
                                )}
                                aria-hidden="true"
                              />
                              {item.title}
                            </div>
                            {
                              item.type == 1
                              ? <div className="ml-2 h-5 w-5 group">
                                  <EllipsisVerticalIcon
                                    className="text-gray-400 group-hover:cursor-pointer group-hover:text-gray-900 ease-in duration-200"
                                    aria-hidden="true"
                                  />
                                </div>
                              : <div>&nbsp;</div>
                            }
                          </div>
                        ))}
                      <div className="flex items-center">
                        <div
                          className="flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer text-green-500 hover:bg-green-100 ease-in duration-200"
                          onClick={(e) => addPage()}
                        >
                          {loadingPage ? (
                            <svg
                              className="animate-spin -ml-1 mr-3 h-4 w-4 text-green-500"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          ) : (
                            <PlusIcon className="mr-2 h-4 w-4 flex-shrink-0 text-green-500" />
                          )}
                          Agregar sección
                        </div>
                      </div>
                      <div className="space-y-1 px-2">
                        {/* {pages.map((item) => (
                          <a
                            key={item.name}
                            href={item.href}
                            className={classNames(
                              item.current
                                ? 'bg-cyan-800 text-white'
                                : 'text-cyan-100 hover:text-white hover:bg-cyan-600',
                              'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                            )}
                            aria-current={item.current ? 'page' : undefined}
                          >
                            <DocumentTextIcon className="mr-4 h-6 w-6 flex-shrink-0 text-cyan-200" aria-hidden="true" />
                            {item.name}
                          </a>
                        ))} */}
                      </div>
                    </nav>
                  </Dialog.Panel>
                </Transition.Child>
                <div className="w-14 flex-shrink-0" aria-hidden="true">
                  {/* Dummy element to force sidebar to shrink to fit close icon */}
                </div>
              </div>
            </Dialog>
          </Transition.Root>

          {/* Static sidebar for desktop */}
          <div className="hidden lg:inset-y-0 lg:block lg:w-96 overflow-y-auto bg-white pt-5 pb-4 border-r">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div className="flex flex-shrink-0 items-center px-4">
              <p className="text-gray-500 text-xs">PÁGINAS</p>
            </div>
            <nav
              className="mt-5 flex flex-1 flex-col divide-y overflow-y-auto"
              aria-label="Sidebar"
            >
              <div className="space-y-1 px-4">
                <div
                  className="flex items-center"
                  onClick={(e) => setCurrentMenu(-3)}
                >
                  <div
                    className={classNames(
                      currentMenu == -3
                        ? "bg-red-100 text-red-500"
                        : "text-gray-500 hover:text-gray-500 hover:bg-gray-100",
                      "group flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer ease-in duration-200"
                    )}
                  >
                    <Cog6ToothIcon
                      className={classNames(
                        currentMenu == -3
                          ? "text-red-500"
                          : "text-gray-500 group-hover:text-gray-900",
                        "mr-2 h-4 w-4 flex-shrink-0 ease-in duration-200"
                      )}
                      aria-hidden="true"
                    />
                    Configuración
                  </div>
                  <div className="ml-2 h-5 w-5 group"></div>
                </div>
                <div
                  className="flex items-center"
                  onClick={(e) => setCurrentMenu(-4)}
                >
                  <div
                    className={classNames(
                      currentMenu == -4
                        ? "bg-red-100 text-red-500"
                        : "text-gray-500 hover:text-gray-500 hover:bg-gray-100",
                      "group flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer ease-in duration-200"
                    )}
                  >
                    <PrinterIcon
                      className={classNames(
                        currentMenu == -4
                          ? "text-red-500"
                          : "text-gray-500 group-hover:text-gray-900",
                        "mr-2 h-4 w-4 flex-shrink-0 ease-in duration-200"
                      )}
                      aria-hidden="true"
                    />
                    Previa
                  </div>
                  <div className="ml-2 h-5 w-5 group"></div>
                </div>
                {pages
                  .sort((a, b) => a.order - b.order)
                  .filter(p => p.is_active)
                  .map((item) => (
                    <div
                      key={`key-item-navigation-${item.seccion_id}`}
                      className="flex items-center"
                      onClick={(e) => selectPage(item)}
                    >
                      <div
                        className={classNames(
                          currentMenu == item.seccion_id
                            ? "bg-red-100 text-red-500"
                            : "text-gray-500 hover:text-gray-900 hover:bg-gray-100",
                          "group flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer ease-in duration-200"
                        )}
                      >
                        <DocumentTextIcon
                          className={classNames(
                            currentMenu == item.seccion_id
                              ? "text-red-500"
                              : "text-gray-500 group-hover:text-gray-900",
                            "mr-2 h-4 w-4 flex-shrink-0 ease-in duration-200"
                          )}
                          aria-hidden="true"
                        />
                        {item.title}
                      </div>
                      {
                        item.type == 1
                        ? <div className="ml-2 h-5 w-5 group">
                            <EllipsisVerticalIcon
                              className="text-gray-400 group-hover:cursor-pointer group-hover:text-gray-900 ease-in duration-200"
                              aria-hidden="true"
                            />
                          </div>
                        : <div>&nbsp;</div>
                      }
                    </div>
                  ))}
                <div className="flex items-center">
                  <div
                    className="flex flex-1 items-center px-3 py-3 text-xs font-medium rounded-md hover:cursor-pointer text-green-500 hover:bg-green-100 ease-in duration-200"
                    onClick={(e) => addPage()}
                  >
                    {loadingPage ? (
                      <svg
                        className="animate-spin -ml-1 mr-3 h-4 w-4 text-green-500"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    ) : (
                      <PlusIcon className="mr-2 h-4 w-4 flex-shrink-0 text-green-500" />
                    )}
                    Agregar sección
                  </div>
                  <div className="ml-2 h-5 w-5 group"></div>
                </div>
              </div>
            </nav>
          </div>

          <div className="block overflow-y-auto w-full">
            <div className="px-4 sm:px-6 lg:px-8 bg-gray-50 py-2 shadow-sm">
              <div className="flex w-full items-center justify-between sm:justify-between md:justify-between lg:justify-end xl:justify-end">
                <div className="block sm:block md:block lg:hidden xl:hidden">
                  <button
                    type="button"
                    className="flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Cerrar barra lateral</span>
                    <Bars3BottomLeftIcon
                      className="h-8 w-8 text-gray-900"
                      aria-hidden="true"
                    />
                  </button>
                </div>
                {loadingPendingChanges ? (
                  <div className="flex items-center animate-pulse py-2">
                    <svg
                      className="animate-spin -ml-1 mr-2 h-5 w-5 text-gray-500"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                    <p className="text-gray-400">Guardando</p>
                  </div>
                ) : isSaved && !hasPendingChanges ? (
                  <div className="flex space-x-2 items-center py-2">
                    <CheckCircleIcon
                      className="h-6 w-6 text-green-400"
                      aria-hidden="true"
                    />
                    <p className="text-sm font-medium text-green-500">
                      Guardado
                    </p>
                  </div>
                ) : (
                  <button
                    onClick={saveFormat}
                    className="flex space-x-2 items-center rounded-md bg-green-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    <CloudArrowUpIcon className="h-6 w-6" aria-hidden="true" />
                    <p className="text-sm font-medium">Guardar</p>
                  </button>
                )}
              </div>
            </div>
            {/* Configuration page */}
            {currentMenu == -4 && (
              <BlankPrint
                name={formatName}
                sections={pages}
                objectivesType={formatObjectives}
              />
            )}
            {currentMenu == -3 && (
              <main className="px-4 py-6 sm:px-6 lg:px-8 lg:py-8">
                <div className=" rounded-lg bg-white shadow min-w-full min-h-full py-6 px-4 sm:p-6 lg:pb-8">
                  <div>
                    <h2 className="text-lg font-medium leading-6 text-gray-900">
                      Configuración
                    </h2>
                    <p className="mt-1 text-sm text-gray-500">
                      En esta sección puedes cambiar la configuración del
                      formato.
                    </p>
                  </div>
                  <div className="mt-6 flex flex-col divide-y divide-gray-200 space-y-8">
                    <div className="flex-grow space-y-6">
                      <div>
                        <label
                          htmlFor="nombre"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Nombre
                        </label>
                        <div className="mt-1">
                          <input
                            type="text"
                            name="nombre"
                            id="nombre"
                            value={formatName}
                            onChange={(e) =>
                              onChangeFormatField(e.target.value, setFormatName)
                            }
                            onKeyUp={onKeyUpInput}
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="descripcion"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Descripción
                        </label>
                        <div className="mt-1">
                          <textarea
                            type="text"
                            name="descripcion"
                            id="descripcion"
                            rows={3}
                            value={formatDescription}
                            onChange={(e) =>
                              onChangeFormatField(
                                e.target.value,
                                setFormatDescription
                              )
                            }
                            onKeyUp={onKeyUpInput}
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="descripcion"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Instrucciones
                        </label>
                        <div className="mt-1">
                          <textarea
                            type="text"
                            name="descripcion"
                            id="descripcion"
                            rows={5}
                            value={formatInstrucciones}
                            onChange={(e) =>
                              onChangeFormatField(
                                e.target.value,
                                setFormatInstrucciones
                              )
                            }
                            onKeyUp={onKeyUpInput}
                            className="mt-1 block w-full rounded-md border border-gray-300 py-2 px-3 shadow-sm focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="nombre"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Tipo de objetivos
                        </label>
                        <div className="mt-1">
                          <select
                            id="objectives-action"
                            name="objectives-action"
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                            value={formatObjectives}
                            onChange={(e) =>
                              onChangeFormatField(
                                e.target.value,
                                setFormatObjectives
                              )
                            }
                            onKeyUp={onKeyUpInput}
                          >
                            <option value="0" disabled>
                              Elige un tipo de acción de objetivos
                            </option>
                            {objectivesTypes.map((r) => (
                              <option
                                key={`key-objetives-action-${r.id}`}
                                value={r.id}
                              >
                                {r.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="nombre"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ¿Objetivos obligatorios?
                        </label>
                        <div className="mt-1">
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="mandatory-one"
                                name="mandatory-one"
                                type="checkbox"
                                checked={formatObjectivesMandatoryOne}
                                onChange={(e) =>
                                  onChangeFormatField(
                                    e.target.checked,
                                    setFormatObjectivesMandatoryOne
                                  )
                                }
                                className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label htmlFor="mandatory-one" className="text-gray-900">
                                Obligatorio Objetivos 1er Semestre
                              </label>
                            </div>
                          </div>
                          <div className="relative flex gap-x-3">
                            <div className="flex h-6 items-center">
                              <input
                                id="mandatory-two"
                                name="mandatory-two"
                                type="checkbox"
                                checked={formatObjectivesMandatoryTwo}
                                onChange={(e) =>
                                  onChangeFormatField(
                                    e.target.checked,
                                    setFormatObjectivesMandatoryTwo
                                  )
                                }
                                className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-600"
                              />
                            </div>
                            <div className="text-sm leading-6">
                              <label htmlFor="mandatory-two" className="text-gray-900">
                                Obligatorio Objetivos 2do Semestre
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="nombre"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Acciones de capacitación
                        </label>
                        <div className="mt-1">
                          <select
                            id="action-plan"
                            name="action-plan"
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                            value={formatActionPlan}
                            onChange={(e) =>
                              onChangeFormatField(
                                e.target.value,
                                setFormatActionPlan
                              )
                            }
                            onKeyUp={onKeyUpInput}
                          >
                            <option value="0" disabled>
                              Elige un tipo de plan de acción
                            </option>
                            {actionPlanTypes.map((r) => (
                              <option
                                key={`key-action-plans-${r.id}`}
                                value={r.id}
                              >
                                {r.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="nombre"
                          className="block text-sm font-medium text-gray-700"
                        >
                          ¿El formato se encuentra activo?
                        </label>
                        <div className="mt-1">
                          <select
                            id="action-plan"
                            name="action-plan"
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                            value={formatIsActive}
                            onChange={(e) =>
                              onChangeFormatField(
                                e.target.value,
                                setFormatIsActive
                              )
                            }
                            onKeyUp={onKeyUpInput}
                          >
                            <option value={true}>Sí</option>
                            <option value={false}>No</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </main>
            )}
            {/* Fist home page */}
            {currentMenu == -1 && (
              <main className="flex-1 py-20 flex flex-col justify-center items-center">
                <img src={logo} alt="T3B" className="h-32 w-auto mb-8" />
                <div className="relative group mb-8 min-w-[50%]">
                  <p className="text-xs absolute -top-6 left-0 hidden group-hover:block group-focus-within:block text-white bg-gray-500 px-4 py-1 rounded-t-md border-b border-gray-500">
                    Título del formato
                  </p>
                  <input
                    className="w-full text-center text-gray-800 border border-transparent bg-transparent group-hover:border group-hover:border-gray-400 text-4xl focus:border-gray-400 px-4 py-4 focus-visible:outline-none group-hover:border-dashed focus:border-solid"
                    value={formatTitulo}
                    onChange={(e) =>
                      onChangeFormatField(e.target.value, setFormatTitulo)
                    }
                    onKeyUp={onKeyUpInput}
                  />
                </div>
                <div className="relative group mb-8 min-w-[50%]">
                  <p className="text-xs absolute -top-6 left-0 hidden group-hover:block group-focus-within:block text-white bg-gray-500 px-4 py-1 rounded-t-md border-b border-gray-500">
                    Instrucciones del formato
                  </p>
                  <textarea
                    className="w-full text-center text-gray-800 border border-transparent bg-transparent group-hover:border group-hover:border-gray-400 focus:border-gray-400 px-4 py-4 focus-visible:outline-none focus:ring-0 focus:outline-none focus:ring-shadow-none group-hover:border-dashed focus:border-solid"
                    value={formatInstrucciones}
                    onChange={(e) =>
                      onChangeFormatField(
                        e.target.value,
                        setFormatInstrucciones
                      )
                    }
                    onKeyUp={onKeyUpInput}
                  />
                </div>
              </main>
            )}
            {/* Thanks last page */}
            {currentMenu == -2 && (
              <main className="flex-1 py-20 flex flex-col justify-center items-center">
                <div className="relative group mb-8 min-w-[50%]">
                  <p className="text-xs absolute -top-6 left-0 hidden group-hover:block group-focus-within:block text-white bg-gray-500 px-4 py-1 rounded-t-md border-b border-gray-500">
                    Título de salida
                  </p>
                  <input
                    className="w-full text-center text-gray-800 border border-transparent bg-transparent group-hover:border group-hover:border-gray-400 text-4xl focus:border-gray-400 px-4 py-4 focus-visible:outline-none group-hover:border-dashed focus:border-solid"
                    value={formatAgradecimientoTitulo}
                    onChange={(e) =>
                      onChangeFormatField(
                        e.target.value,
                        setFormatAgradecimientoTitulo
                      )
                    }
                    onKeyUp={onKeyUpInput}
                  />
                </div>
                <div className="relative group mb-2 min-w-[50%]">
                  <p className="text-xs absolute -top-6 left-0 hidden group-hover:block group-focus-within:block text-white bg-gray-500 px-4 py-1 rounded-t-md border-b border-gray-500">
                    Texto de salida
                  </p>
                  <textarea
                    className="w-full text-center text-gray-800 border border-transparent bg-transparent group-hover:border group-hover:border-gray-400 focus:border-gray-400 px-4 py-4 focus-visible:outline-none focus:ring-0 focus:outline-none focus:ring-shadow-none group-hover:border-dashed focus:border-solid"
                    value={formatAgradecimientoDescripcion}
                    onChange={(e) =>
                      onChangeFormatField(
                        e.target.value,
                        setFormatAgradecimientoDescripcion
                      )
                    }
                    onKeyUp={onKeyUpInput}
                  />
                </div>
                <img src={logo} alt="T3B" className="h-32 w-auto mb-8" />
              </main>
            )}
            {/* common pages */}
            {currentMenu >= 0 && (
              <main className="pb-8">
                {/* Page header */}
                <div className="border-b flex flex-col sm:flex-row items-center justify-center px-4 py-8">
                  <div className="flex-1 relative group mb-2">
                    <p className="text-xs absolute -top-6 left-0 hidden group-hover:block group-focus-within:block text-white bg-gray-500 px-4 py-1 rounded-t-md border-b border-gray-500">
                      Título de la sección
                    </p>
                    <input
                      className="text-4xl w-full text-center text-gray-800 border border-transparent bg-transparent group-hover:border group-hover:border-gray-400 focus:border-gray-400 px-4 py-4 focus-visible:outline-none focus:ring-0 focus:outline-none focus:ring-shadow-none group-hover:border-dashed focus:border-solid"
                      type="text"
                      value={pages.find(p => p.seccion_id==currentPageId)?.title}
                      onChange={(e) =>
                        onChangePage(
                          currentPageId,
                          e.target.value,
                          "title"
                        )
                      }
                      onKeyUp={onKeyUpInput}
                    />
                  </div>
                  <div className="relative group mb-2 w-40">
                    <p className="text-xs absolute -top-6 left-0 hidden group-hover:block group-focus-within:block text-white bg-gray-500 px-4 py-1 rounded-t-md border-b border-gray-500">
                      Valor de la sección
                    </p>
                    <input
                      className="text-4xl w-full text-center text-gray-800 border border-transparent bg-transparent group-hover:border group-hover:border-gray-400 focus:border-gray-400 px-4 py-4 focus-visible:outline-none focus:ring-0 focus:outline-none focus:ring-shadow-none group-hover:border-dashed focus:border-solid"
                      type="number"
                      value={pages.find(p => p.seccion_id==currentPageId)?.value}
                      onChange={(e) =>
                        onChangePage(
                          currentPageId,
                          e.target.value,
                          "value"
                        )
                      }
                      onKeyUp={onKeyUpInput}
                    />
                  </div>
                </div>
                {currentPage.type != 1 ? (
                  <>
                    <div className="mt-8 flex flex-col items-center justify-center py-8 px-8">
                      <ExclamationTriangleIcon className="h-32 w-32" />
                      <p className="text-xl text-gray-500 text-center">
                        Esta es una sección especial
                      </p>
                      <p className="text-xl text-gray-900 text-center mb-8">
                        ¡No hay nada más que hacer aquí!
                      </p>
                      <div className="relative w-full">
                        <div
                          className="absolute inset-0 flex items-center"
                          aria-hidden="true"
                        >
                          <div className="w-full border-t border-gray-300" />
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    {pages.find(p => p.seccion_id == currentPageId)?.subsections?.length < 1 ? (
                      <div className="mt-8 flex flex-col items-center justify-center py-8 px-8">
                        <div className="mt-5 sm:flex sm:items-center w-full">
                          <div className="w-full">
                            <label htmlFor="email" className="sr-only">
                              Apartado
                            </label>
                            <select
                              id="location"
                              name="location"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                              defaultValue={0}
                            >
                              <option value={0} disabled>
                                Selecciona un apartado
                              </option>
                            </select>
                          </div>
                          <button
                            type="submit"
                            onClick={(e) => modalSectionRef.current.showModal()}
                            className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Nuevo apartado
                          </button>
                        </div>
                        <DocumentDuplicateIcon className="h-32 w-32 mt-10" />
                        <p className="text-xl my-8">
                          <span className="text-gray-900">
                            Selecciona un apartado para obtener sus preguntas
                          </span>
                        </p>
                      </div>
                    ) : (
                      <div className="mt-8 flex flex-col items-center justify-center py-8 px-2 sm:px-8 max-w-4xl mx-auto">
                        <div className="mt-5 sm:flex sm:items-center w-full">
                          <div className="w-full">
                            <label htmlFor="email" className="sr-only">
                              Apartado
                            </label>
                            <select
                              id="location"
                              name="location"
                              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                              value={currentSectionId}
                              onChange={(e) => selectSection(e.target.value)}
                            >
                              <option value={0} disabled>
                                Selecciona un apartado
                              </option>
                              {pages.find(p => p.seccion_id == currentPageId)?.subsections?.filter(p => p.is_active)?.sort((a, b) => a.order - b.order)
                                .map((section, indexSection) => (
                                  <option
                                    key={`key-section-option-${section.apartado_id}`}
                                    value={section.apartado_id}
                                  >
                                    {section.title}
                                  </option>
                                ))}
                            </select>
                          </div>
                          {currentSectionId != 0 && <button
                            type="submit"
                            onClick={(e) => deleteSubsection()}
                            className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Eliminar apartado
                          </button>}
                          {currentSectionId != 0 && <button
                            type="submit"
                            onClick={(e) => editSubsection()}
                            className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-orange-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Editar apartado
                          </button>}
                          <button
                            type="submit"
                            onClick={(e) => createSubsection()}
                            className="whitespace-nowrap mt-3 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                          >
                            Nuevo apartado
                          </button>
                        </div>
                        {
                          currentSectionId != 0 && pages.find(p => p.seccion_id == currentPageId)?.subsections?.find(p => p.apartado_id == currentSectionId)?.questions?.length < 1 ? (
                            <>
                              <DocumentDuplicateIcon className="h-32 w-32 mt-10" />
                              <p className="text-xl my-8">
                                <span className="text-gray-900">
                                  ¡Crea una pregunta para iniciar!
                                </span>
                              </p>
                            </>
                          ) : (
                            pages.find(p => p.seccion_id == currentPageId)?.subsections?.find(p => p.apartado_id == currentSectionId)?.questions?.filter(p => p.is_active)?.sort((a, b) => a.order - b.order)
                              .map((quest, indexQuestion) => (
                                <div
                                  key={`key-question-show-${indexQuestion}`}
                                  onClick={(e) => selectQuestion(quest)}
                                  className={`z-0 relative w-full mb-2 mt-4 flex flex-col items-start px-6 py-8 hover:cursor-pointer group ease-in duration-150 min-w-[50%] border ${
                                    currentQuestion == quest.pregunta_id
                                      ? "border-red-400"
                                      : "border-transparent hover:border-gray-300"
                                  }`}
                                >
                                  {currentQuestion == quest.pregunta_id && (
                                    <Menu
                                      as="div"
                                      className="absolute top-2 right-2 inline-block text-left z-10"
                                    >
                                      <div>
                                        <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                                          Acciones
                                          <ChevronDownIcon
                                            className="-mr-1 ml-2 h-5 w-5"
                                            aria-hidden="true"
                                          />
                                        </Menu.Button>
                                      </div>

                                      <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                      >
                                        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  href="#"
                                                  onClick={(e) =>
                                                    editQuestion(e, quest)
                                                  }
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "group flex items-center px-4 py-2 text-sm"
                                                  )}
                                                >
                                                  <PencilSquareIcon
                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                  />
                                                  Editar
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </div>
                                          <div className="py-1">
                                            {indexQuestion > 0 && (
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <a
                                                    href="#"
                                                    onClick={(e) =>
                                                      moveUpQuestion(e, quest, indexQuestion)
                                                    }
                                                    className={classNames(
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                      "group flex items-center px-4 py-2 text-sm"
                                                    )}
                                                  >
                                                    <ArrowUpIcon
                                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                      aria-hidden="true"
                                                    />
                                                    Mover Arriba
                                                  </a>
                                                )}
                                              </Menu.Item>
                                            )}
                                            {indexQuestion + 1 <
                                              pages.find(p => p.seccion_id == currentPageId)?.subsections?.find(p => p.apartado_id == currentSectionId)?.questions?.length && (
                                              <Menu.Item>
                                                {({ active }) => (
                                                  <a
                                                    href="#"
                                                    onClick={(e) =>
                                                      moveDownQuestion(e, quest, indexQuestion)
                                                    }
                                                    className={classNames(
                                                      active
                                                        ? "bg-gray-100 text-gray-900"
                                                        : "text-gray-700",
                                                      "group flex items-center px-4 py-2 text-sm"
                                                    )}
                                                  >
                                                    <ArrowDownIcon
                                                      className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                      aria-hidden="true"
                                                    />
                                                    Mover Abajo
                                                  </a>
                                                )}
                                              </Menu.Item>
                                            )}
                                          </div>
                                          <div className="py-1">
                                            <Menu.Item>
                                              {({ active }) => (
                                                <a
                                                  href="#"
                                                  onClick={(e) =>
                                                    clickDeleteQuestion(e, quest)
                                                  }
                                                  className={classNames(
                                                    active
                                                      ? "bg-gray-100 text-gray-900"
                                                      : "text-gray-700",
                                                    "group flex items-center px-4 py-2 text-sm"
                                                  )}
                                                >
                                                  <TrashIcon
                                                    className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                                    aria-hidden="true"
                                                  />
                                                  Eliminar
                                                </a>
                                              )}
                                            </Menu.Item>
                                          </div>
                                        </Menu.Items>
                                      </Transition>
                                    </Menu>
                                  )}
                                  <p className="font-normal text-sm lg:text-sm text-gray-400">Orden: {quest.order}</p>
                                  <p className="font-normal text-sm lg:text-sm text-gray-400">Redacción evaluación</p>
                                  <p className="font-semibold lg:text-lg text-gray-900">{quest.title}</p>
                                  <p className="mb-2 text-gray-600">{quest.placeholder}</p>
                                  <p className="font-normal text-sm lg:text-sm text-gray-400">Redacción autoevaluación</p>
                                  <p className="font-semibold lg:text-lg text-gray-900">{quest.title_auto}</p>
                                  <p className="mb-1 text-gray-600">{quest.placeholder_autoevaluacion}</p>
                                  <p className="mt-4 text-gray-500">
                                    {quest.descripcion}
                                  </p>
                                  {
                                    quest.type == 1 && <textarea
                                      placeholder={quest.placeholder}
                                      rows={3}
                                      className="block w-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                      disabled
                                      defaultValue=""
                                    />
                                  }
                                  {
                                    quest.type == 2 && <>
                                      <ul
                                        className={`w-full flex flex-col sm:flex-row justify-between  space-y-2 sm:space-y-0 sm:space-x-2 lg:space-x-4`}
                                      >
                                        {quest.answers?.sort(sortByOrder)?.filter(p => p.is_active).map((a, aIdx) => (<li
                                            key={`key-assessment-section-subsection-quest-answer-${a.respuesta_id}`}
                                            className={classNames(
                                              "bg-white hover:bg-zinc-200 text-gray-900",
                                              "flex justify-between items-center p-3 w-full border rounded-md hover:cursor-pointer relative transition-all ease-in-out duration-300"
                                            )}
                                          >
                                            <p className="font-medium flex-1">
                                              {a.title}
                                            </p>
                                          </li>
                                        ))}
                                      </ul>
                                    </>
                                  }
                                  {
                                    quest.type == 5 &&
                                    <ul
                                      className={`w-full flex flex-col sm:flex-row justify-between  space-y-2 sm:space-y-0 sm:space-x-2 lg:space-x-4`}
                                    >
                                      {quest.answers?.filter(p => p.is_active)?.sort(sortByOrder).map((a) => (
                                        <li
                                          key={`key-assessment-section-subsection-quest-answer-${a.respuesta_id}`}
                                          className={classNames(
                                            "bg-white hover:bg-zinc-200 text-gray-900",
                                            "flex justify-between items-center p-3 w-full border rounded-md hover:cursor-pointer relative transition-all ease-in-out duration-300"
                                          )}
                                        >
                                          <p className="font-medium flex-1 text-sm">
                                            {a.title}
                                          </p>
                                        </li>
                                      ))}
                                    </ul>
                                  }
                                  {
                                    quest.type == 3 && <>
                                      <div className="grid grid-cols-4 gap-4 w-full">
                                        <div className="col-span-4 md:col-span-1">
                                          <RadioGroup
                                            value={true}
                                          >
                                            <div className="isolate -space-y-px rounded-md bg-white shadow-sm">
                                              <RadioGroup.Option
                                                value={true}
                                                className={({ checked }) =>
                                                  classNames(
                                                    checked
                                                      ? "bg-green-100 border-green-200 z-10"
                                                      : "border-gray-300",
                                                    "rounded-tl-md rounded-tr-md relative border px-4 py-3 flex cursor-pointer focus:outline-none"
                                                  )
                                                }
                                              >
                                                {({ active, checked }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        checked
                                                          ? "bg-green-600 border-transparent"
                                                          : "bg-white border-gray-300",
                                                        active
                                                          ? "ring-2 ring-offset-2 ring-green-500"
                                                          : "",
                                                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                      )}
                                                      aria-hidden="true"
                                                    >
                                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                    </span>
                                                    <span className="ml-3 flex flex-col">
                                                      <RadioGroup.Label
                                                        as="span"
                                                        className={classNames(
                                                          checked
                                                            ? "text-green-900"
                                                            : "text-gray-900",
                                                          "block text-sm font-medium"
                                                        )}
                                                      >
                                                        SÍ
                                                      </RadioGroup.Label>
                                                    </span>
                                                  </>
                                                )}
                                              </RadioGroup.Option>
                                              <RadioGroup.Option
                                                value={false}
                                                className={({ checked }) =>
                                                  classNames(
                                                    checked
                                                      ? "bg-red-100 border-red-200 z-10"
                                                      : "border-gray-300",
                                                    "rounded-bl-md rounded-br-md relative border px-4 py-3 flex cursor-pointer focus:outline-none"
                                                  )
                                                }
                                              >
                                                {({ active, checked }) => (
                                                  <>
                                                    <span
                                                      className={classNames(
                                                        checked
                                                          ? "bg-red-600 border-transparent"
                                                          : "bg-white border-gray-300",
                                                        active
                                                          ? "ring-2 ring-offset-2 ring-red-500"
                                                          : "",
                                                        "mt-0.5 h-4 w-4 shrink-0 cursor-pointer rounded-full border flex items-center justify-center"
                                                      )}
                                                      aria-hidden="true"
                                                    >
                                                      <span className="rounded-full bg-white w-1.5 h-1.5" />
                                                    </span>
                                                    <span className="ml-3 flex flex-col">
                                                      <RadioGroup.Label
                                                        as="span"
                                                        className={classNames(
                                                          checked
                                                            ? "text-red-900"
                                                            : "text-gray-900",
                                                          "block text-sm font-medium"
                                                        )}
                                                      >
                                                        NO
                                                      </RadioGroup.Label>
                                                    </span>
                                                  </>
                                                )}
                                              </RadioGroup.Option>
                                            </div>
                                          </RadioGroup>
                                        </div>
                                        <div className="col-span-4 md:col-span-3">
                                          <textarea
                                            id={`key-assessment-section-subsection-quest-answer-${quest.pregunta_id}`}
                                            name={`key-assessment-section-subsection-quest-answer-${quest.pregunta_id}`}
                                            placeholder={"Comentarios..."}
                                            rows={3}
                                            className="block w-full min-h-full rounded-md border-gray-300 focus:border-red-500 focus:ring-red-500 sm:text-sm"
                                            value={""}
                                            disabled
                                          />
                                        </div>
                                      </div>
                                    </>
                                  }
                                </div>
                              ))
                          )
                        }
                        {currentSectionId!=0 && <div className="relative w-full">
                          <div
                            className="absolute inset-0 flex items-center"
                            aria-hidden="true"
                          >
                            <div className="w-full border-t border-gray-300" />
                          </div>
                          <Popover className="relative">
                            {({ open }) => (
                              <>
                                <Popover.Button
                                  className={classNames(
                                    "relative flex justify-center items-center mx-auto focus-visible:ring-0 focus-visible:outline-0"
                                  )}
                                >
                                  <button
                                    type="button"
                                    className="z-10 group inline-flex items-center justify-center rounded-full border border-gray-300 bg-white px-2 py-2 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 ease-in duration-200"
                                  >
                                    <PlusIcon
                                      className="h-8 w-8 text-gray-400 group-hover:text-white ease-in duration-200"
                                      aria-hidden="true"
                                    />
                                  </button>
                                </Popover.Button>

                                <Transition
                                  as={Fragment}
                                  enter="transition ease-out duration-200"
                                  enterFrom="opacity-0 translate-y-1"
                                  enterTo="opacity-100 translate-y-0"
                                  leave="transition ease-in duration-150"
                                  leaveFrom="opacity-100 translate-y-0"
                                  leaveTo="opacity-0 translate-y-1"
                                >
                                  <Popover.Panel className="pb-10 absolute z-10 -ml-4 mt-3 w-screen max-w-md transform lg:left-1/2 lg:ml-0 lg:max-w-2xl lg:-translate-x-1/2">
                                    <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
                                      <div className="relative grid bg-white lg:grid-cols-2 divide-x divide-y">
                                        {solutions.map(
                                          (solution, indexButton) => (
                                            <div
                                              key={`key-solution-button-${indexButton}`}
                                              onClick={(e) =>
                                                createQuestion(solution.type)
                                              }
                                              className="flex flex-col items-center p-3 hover:bg-red-600 hover:cursor-pointer group ease-in duration-150"
                                            >
                                              <div className="flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-md bg-gray-200 text-gray-500 sm:h-10 sm:w-10 group-hover:bg-red-800 group-hover:text-white ease-in duration-150">
                                                <solution.icon
                                                  className="h-6 w-6"
                                                  aria-hidden="true"
                                                />
                                              </div>
                                              <p className="mt-4 font-medium text-gray-900 text-xs group-hover:text-white ease-in duration-150">
                                                {solution.name}
                                              </p>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    </div>
                                  </Popover.Panel>
                                </Transition>
                              </>
                            )}
                          </Popover>
                        </div>}
                      </div>
                    )}
                  </>
                )}
              </main>
            )}
          </div>
          <Notification
            title={errorTitle}
            message={error}
            type="danger"
            ref={notificationRef}
          />
          <BigModalTemplate ref={modalRef}>
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={(e) => modalRef.current.hideModal()}
              >
                <span className="sr-only">Cerrar</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="divide-y">
              <div>
                <h3 className="text-lg font-medium mb-2">
                  Redacción evaluación
                </h3>
                <div className="mb-8">
                  <label
                    htmlFor="question-title"
                    className="block text-xs text-gray-400"
                  >
                    TÍTULO DE LA PREGUNTA
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="question-title"
                      id="question-title"
                      value={questionTitle}
                      onChange={(e) => setQuestionTitle(e.target.value)}
                      placeholder="Título de la pregunta"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 font-medium"
                    />
                  </div>
                </div>
                <div className="mb-8">
                  <label
                    htmlFor="question-description"
                    className="block text-xs text-gray-400"
                  >
                    TEXTO DE AYUDA
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="question-description"
                      id="question-description"
                      value={questionDescription}
                      onChange={(e) => setQuestionDescription(e.target.value)}
                      placeholder="Texto de ayuda de la pregunta"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="text-lg font-medium mb-2 mt-2">
                  Redacción autoevaluación
                </h3>
                <div className="mb-8">
                  <label
                    htmlFor="question-title"
                    className="block text-xs text-gray-400"
                  >
                    TÍTULO DE LA PREGUNTA
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="question-title"
                      id="question-title"
                      value={questionTitleAuto}
                      onChange={(e) => setQuestionTitleAuto(e.target.value)}
                      placeholder="Título de la pregunta"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 font-medium"
                    />
                  </div>
                </div>
                <div className="mb-8">
                  <label
                    htmlFor="question-description"
                    className="block text-xs text-gray-400"
                  >
                    TEXTO DE AYUDA
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="question-description"
                      id="question-description"
                      value={questionDescriptionAuto}
                      onChange={(e) => setQuestionDescriptionAuto(e.target.value)}
                      placeholder="Texto de ayuda de la pregunta"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 text-sm"
                    />
                  </div>
                </div>
              </div>
              {![1,3].includes(questionType) && (
                <div className="pt-6 mb-8 w-full">
                  <label className="block text-xs text-gray-400">
                    RESPUESTAS
                  </label>
                  {responses
                    .map((item, indexResponse) => (
                      <div
                        className="mt-2 flex w-full items-center space-x-2"
                        key={`key-response-${indexResponse}`}
                      >
                        <input
                          type="text"
                          name={`key-response-title-${indexResponse}`}
                          id={`key-response-title-${indexResponse}`}
                          value={item.title}
                          onChange={(e) =>
                            onChangeResponse(
                              e.target.value,
                              "title",
                              indexResponse
                            )
                          }
                          placeholder="Titulo de la respuesta"
                          className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 text-sm"
                        />
                        <input
                          type="number"
                          name={`key-response-value-${indexResponse}`}
                          id={`key-response-value-${indexResponse}`}
                          value={item.value}
                          onChange={(e) =>
                            onChangeResponse(
                              e.target.value,
                              "value",
                              indexResponse
                            )
                          }
                          placeholder="Valor de la respuesta"
                          className="rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 text-sm"
                        />
                        {
                          item.type == 1
                          ? <div className="ml-2 h-5 w-5 group">
                              <EllipsisVerticalIcon
                                className="text-gray-400 group-hover:cursor-pointer group-hover:text-gray-900 ease-in duration-200"
                                aria-hidden="true"
                              />
                            </div>
                          : <div>&nbsp;</div>
                        }
                      </div>
                    ))}
                  <div className="flex justify-start mt-6">
                    <button
                      type="button"
                      onClick={addResponse}
                      className="group flex items-center space-x-2 rounded-md border border-red-300 bg-white py-2 px-4 text-sm font-medium text-red-600 shadow-sm hover:bg-red-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2  ease-in duration-100"
                    >
                      <PlusIcon className="h-5 w-5 text-red-600 group-hover:text-white ease-in duration-100" />
                      <p>Añadir respuesta</p>
                    </button>
                  </div>
                </div>
              )}
              <div className="pt-6">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={(e) => modalRef.current.hideModal()}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    disabled={loadingQuestion}
                    onClick={addQuestion}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {currentQuestion != 0
                      ? loadingQuestion
                        ? "Guardando pregunta..."
                        : "Editar"
                      : loadingQuestion
                      ? "Creando pregunta..."
                      : "Crear"}
                  </button>
                </div>
              </div>
            </div>
          </BigModalTemplate>
          <BigModalTemplate ref={modalSectionRef}>
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={(e) => modalSectionRef.current.hideModal()}
              >
                <span className="sr-only">Cerrar</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="divide-y">
              <div>
                <div className="mb-8">
                  <label
                    htmlFor="subsection-title"
                    className="block text-xs text-gray-400"
                  >
                    TÍTULO DEL APARTADO
                  </label>
                  <div className="mt-1">
                    <input
                      type="text"
                      name="subsection-title"
                      id="subsection-title"
                      value={subsectionTitle}
                      onChange={(e) => setSubsectionTitle(e.target.value)}
                      placeholder="Título del apartado"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 text-xl font-medium"
                    />
                  </div>
                </div>
              </div>
              <div className="pt-6">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={(e) => modalSectionRef.current.hideModal()}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={addSubsection}
                    disabled={loadingSubsection}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {loadingSubsection ? 'Guardando' : 'Guardar'}
                  </button>
                </div>
              </div>
            </div>
          </BigModalTemplate>
          <BigModalTemplate ref={modalSectionDeleteRef}>
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={(e) => modalSectionDeleteRef.current.hideModal()}
              >
                <span className="sr-only">Cerrar</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="">
              <div>
                <div className="mb-8">
                  <p
                    className="block text-xl font-medium"
                  >
                    ¿Deseas eliminar el apartado {subsectionTitle}?
                  </p>
                </div>
              </div>
              <div className="pt-6">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={(e) => modalSectionDeleteRef.current.hideModal()}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={inactivateSubsection}
                    disabled={loadingSubsection}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {loadingSubsection ? 'Eliminando' : 'Eliminar'}
                  </button>
                </div>
              </div>
            </div>
          </BigModalTemplate>
          <BigModalTemplate ref={modalQuestionDeleteRef}>
            <div className="flex justify-end">
              <button
                type="button"
                className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={(e) => modalQuestionDeleteRef.current.hideModal()}
              >
                <span className="sr-only">Cerrar</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <div className="">
              <div>
                <div className="mb-8">
                  <p
                    className="block text-xl font-medium"
                  >
                    ¿Deseas eliminar la pregunta {questionTitle}?
                  </p>
                </div>
              </div>
              <div className="pt-6">
                <div className="flex justify-end">
                  <button
                    type="button"
                    onClick={(e) => modalQuestionDeleteRef.current.hideModal()}
                    className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    Cancelar
                  </button>
                  <button
                    type="button"
                    onClick={deleteQuestion}
                    disabled={loadingQuestion}
                    className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  >
                    {loadingQuestion ? 'Eliminando' : 'Eliminar'}
                  </button>
                </div>
              </div>
            </div>
          </BigModalTemplate>
        </div>
      )}
    </>
  );
};

export default EditFormat;
