export const objectivesTypes = [
  {
    id: "1",
    label: "Carga de objetivos anteriores y próximos",
  },
  {
    id: "2",
    label: "Carga de objetivos próximos",
  },
  {
    id: "3",
    label: "Objetivos de ventas",
  },
];

export const actionPlanTypes = [
  {
    id: "1",
    label: "Incluye acciones de capacitación",
  },
  {
    id: "2",
    label: "Incluye acciones de capacitación para operaciones",
  },
  {
    id: "3",
    label: "Sin acciones de capacitación",
  },
];
