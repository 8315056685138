import { useEffect, useRef, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import API from "../../services/API";
import logo from "./../../assets/img/brand/logo.svg";
import backwall from "./../../assets/img/brand/BACK_WALL_WELCOME.png";
import BACK_WALL_1S from "./../../assets/img/brand/BACK_WALL_1S.png";
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  PlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import Notification from "../../components/notifications/Notification";
import CommonModalTemplate from "../../components/modals/CommonModalTemplate";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import { default as WDatePicker } from "react-date-picker/dist/entry.nostyle";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import PlusFolder from "../../components/vectors/PlusFolder";
import AssessmentNewSimpleObjective from "../../components/cards/AssessmentNewSimpleObjective";

export default function UpdateFeedback() {
  const [assessment, setAssessment] = useState([]);
  const [answers, setAnswers] = useState({});
  const [dateFeedback, setDateFeedback] = useState(new Date());
  const [objectives, setObjectives] = useState([]);
  const [salesAnswers, setSalesAnswers] = useState({});
  const [boss, setBoss] = useState({});
  const [plans, setPlans] = useState([]);
  const simpleObjective = useRef();
  const deleteNextObjetiveRef = useRef();
  const [selectedNextObjective, setSelectedNextObjective] = useState(0);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [dateCreate, setDateCreate] = useState(new Date());
  const [loading, setLoading] = useState(true);
  const [loadingDevelopment, setLoadingDevelopment] = useState(false);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [assessmentSent, setAssessmentSent] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const [nextObjectives, setNextObjectives] = useState([]);
  const [nextParentObjectives, setNextParentObjectives] = useState([]);
  const modalRefEnd = useRef();
  const notificationRef = useRef();
  const selfAssessmentRef = useRef();
  const pathParams = useParams();
  const navigate = useNavigate();
  const { user, token } = useSelector((state) => state.authentication);

  useEffect(() => {
    const getUserAssessment = (id) => {
      const params = { user_assessment: id };
      API.get(`/assessments`, {
        headers: { Authorization: token },
        params,
      })
        .then(({ status, data }) => {
          let assess = data[0];
          if (!assess.evaluacion_usuario_id) {
            navigate("/t3b/home", { replace: true });
          }
          if (status === 200) {
            setAssessment(assess);
          } else if (status == 204) {
            setError("El formato no tiene información");
            notificationRef.current.showNotification();
          } else {
            setError(
              "Ha ocurrido un error al buscar la información del formato"
            );
            notificationRef.current.showNotification();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            } else {
              setError(error.response.data);
            }
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar el formato"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar el formato"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    if (!assessment.evaluacion_usuario_id) {
      getUserAssessment(pathParams.assessmentId);
    }
  }, [pathParams.assessmentId]);

  useEffect(() => {
    const fetchBoss = (users) => {
      const options = { headers: { Authorization: token } };
      API.get("/users/"+user.jefe_inmediato_id, options)
        .then(({ data, status }) => {
          if (status === 200) {
            setBoss(data);
          } else {
            setBoss({});
          }
        })
        .catch((error) => {
          setBoss({});
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    fetchBoss();
  }, []);

  useEffect(() => {
    let headers = { Authorization: token };

    const getNextObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          face: true
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives`,
          { headers, params }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getNextParentObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          tipo_objetivo_id:
            assessment.numero_empleado === user.numero_empleado ? 1 : 2,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives-parent`,
          { headers, params }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextParentObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });

    const getAssessmentInfo = () => {
      Promise.all([getNextObjectives(), getNextParentObjectives()])
        .then((data) => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError(error.message);
          notificationRef.current.showNotification();
        });
    };
    if (assessment.evaluacion_usuario_id) {
      getAssessmentInfo();
    }
  }, [assessment]);

  const endAssessment = () => {
    let body = {
      revision_terminada: true,
      estatus_cierre_id: 2
    };
    setLoadingEnd(true);
    API.patch(`/assessments/${assessment.evaluacion_usuario_id}`, body, { headers: { Authorization: token } })
    .then(({ status })=>{
      setLoadingEnd(false);
      if(status===200){
        sendEmail();
        navigate("/t3b/home", { replace: true });
      }
    })
    .catch( error =>{
      setLoadingEnd(false);
      setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
    });
  };

  const sendEmail = () => {
    let body = {
      templateId: "d-02c0ee430ca24299adccd25bf968f306",
      groupId: 17817,
      personalizations: [
        {
          from: {
            email: "notificaciones@integrarh3b.com",
            name: "Evaluación de desempeño",
          },
          to: [
            {
              email: boss?.email,
              name: boss?.nombre_completo,
            },
          ],
          dynamicTemplateData: {
            receive: boss?.nombre_completo,
            colaborador: assessment?.nombre_completo
          },
        },
      ],
    };
    API.post("/integra/email/template", body, {
      headers: { Authorization: token },
    })
      .then((response) => {
        if (response) {
          const { status } = response;
          if (status != 200) {

          }
        }
      })
      .catch((error) => {

      });
  };

  const clickSendAssessment = () => {
    selfAssessmentRef.current.showModal();
  };

  const selectNextObjectiveToDelete = (objectiveId) => {
    setSelectedNextObjective(objectiveId);
    deleteNextObjetiveRef.current.showModal();
  };

  const selectNextObjectiveToEdit = (objectiveId, titulo, descripcion, fecha) => {
    setSelectedNextObjective(objectiveId);
    setTitle(titulo);
    setDescription(descripcion);
    setDateCreate(new Date(fecha));
    simpleObjective.current.showModal();
  };

  const addObjective = () => {
    let body = {
      type: "objetivo",
      usuario: assessment.numero_empleado,
      assessment_type: user.numero_empleado == assessment.numero_empleado ? 1 : 2,
      item: {
        titulo: title,
        descripcion: description,
        fecha_entrega: dateCreate,
        is_active: true
      }
    }
    if(selectedNextObjective!=0){
      body.item.objetivo_proximo_id = selectedNextObjective;
    }
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, { headers })
    .then((response) => {
      setLoadingDevelopment(false);
      if(response.status == 200){
        let newObjectives = [...nextObjectives];
        if(selectedNextObjective!=0){
          let oldItem = newObjectives.find(o => o.objetivo_proximo_id == selectedNextObjective);
          if(oldItem){
            oldItem.titulo = title;
            oldItem.descripcion = description;
            oldItem.fecha_entrega = dateCreate;
          }
        } else {
          let newItem = {
            objetivo_proximo_id: response.data.objetivo_proximo_id,
            estatus_objetivo_id: 1,
            titulo: title,
            descripcion: description,
            fecha_entrega: dateCreate
          }
          newObjectives.push(newItem);
        }
        setNextObjectives(newObjectives)
        setTitle("");
        setDescription("");
        setDateCreate(new Date());
        simpleObjective.current.hideModal();
      } else {
        alert("Hubo un error a crear el objeto");
      }
    })
    .catch((error) => {
      setLoadingDevelopment(false);
      setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
    })
  };

  const deleteNextObjective = () => {
    let body = {
      type: "objetivo",
      usuario: assessment.numero_empleado,
      assessment_type: user.numero_empleado == assessment.numero_empleado ? 1 : 2,
      item: {
        titulo: title,
        descripcion: description,
        fecha_entrega: dateCreate,
        is_active: false
      }
    }
    if(selectedNextObjective!=0){
      body.item.objetivo_proximo_id = selectedNextObjective;
    }
    setLoadingDevelopment(true);
    let headers = { Authorization: token };
    API.post(`/assessments/${assessment.evaluacion_usuario_id}/object`, body, { headers })
    .then((response) => {
      setLoadingDevelopment(false);
      if(response.status == 200){
        let newObjectives = [...nextObjectives];
        if(selectedNextObjective!=0){
          let oldItem = newObjectives.find(o => o.objetivo_proximo_id == selectedNextObjective);
          if(oldItem){
            oldItem.titulo = title;
            oldItem.descripcion = description;
            oldItem.fecha_entrega = dateCreate;
            oldItem.is_active = false;
          }
        } else {
          let newItem = {
            objetivo_proximo_id: response.data.objetivo_proximo_id,
            estatus_objetivo_id: 1,
            titulo: title,
            descripcion: description,
            fecha_entrega: dateCreate
          }
          newObjectives.push(newItem);
        }
        setNextObjectives(newObjectives)
        setTitle("");
        setDescription("");
        setDateCreate(new Date());
        deleteNextObjetiveRef.current.hideModal();
      } else {
        alert("Hubo un error al eliminar el objeto");
      }
    })
    .catch((error) => {
      setLoadingDevelopment(false);
      setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
    })
  };

  const newNextObjective = () => {
    setSelectedNextObjective(0);
    setTitle("");
    setDescription("");
    setDateCreate(new Date());
    simpleObjective.current.showModal();
  };


  return (
    <>
      {loading ? (
        <div className="min-h-screen w-full h-full flex items-center justify-center bg-white">
          <video
            className="w-96 h-96 flex flex-1"
            alt={"Tiendas 3B"}
            muted
            loop
            autoPlay
          >
            <source
              src={"https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"}
              type="video/mp4"
            />
            Sin soporte de video MP4
          </video>
        </div>
      ) : (
        <>
          {!assessmentSent ? (
            <main className="relative min-w-screen flex-1 bg-zinc-100 overflow-y-auto custom-scrollbar">
              <div className="flex-1 flex flex-col justify-start absolute top-0 w-full z-10 h-full">
                <div className="mb-2 shadow-lg border-gray-300 relative w-full bg-white">
                  <p className="relative tracking-widest text-3xl md:text-5xl text-red-500 text-center py-12 flex flex-col font-bold uppercase z-10">
                    PRÓXIMOS OBJETIVOS
                  </p>
                </div>
                <div className="flex-1">
                  <div className="mt-8 flex flex-col items-center justify-center py-2 px-2 md:py-8 md:px-8 max-w-7xl mx-auto">
                    <>
                      <div className="w-full">
                        <div className="w-full">
                          {assessment.numero_empleado ===
                            user.numero_empleado && (
                            <p className="text-lg font-bold">
                              En esta sección deberás actualizar los objetivos que platicaste en la sesión de retroalimentación con tu jefe inmediato
                            </p>
                          )}
                          <div className="w-full flex justify-end">
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                              <button
                                type="button"
                                onClick={newNextObjective}
                                className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                              >
                                <PlusIcon className="h-6 w-6 mr-1" />
                                Agregar objetivo
                              </button>
                            </div>
                          </div>
                          {/* <div className="w-full flex justify-end">
                            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                              <button
                                type="button"
                                onClick={newNextObjective}
                                className="flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:w-auto"
                              >
                                <PlusIcon className="h-6 w-6 mr-1" />
                                Agregar objetivo
                              </button>
                            </div>
                          </div> */}
                          <div className="mt-8 flex flex-col space-y-4">
                            {nextObjectives.length > 0 ? (
                              nextObjectives.map((objective) => (
                                <AssessmentNewSimpleObjective
                                  key={`key-new-simple-objective-${objective.objetivo_proximo_id}`}
                                  id={objective.objetivo_proximo_id}
                                  active={objective.is_active}
                                  status={objective.estatus_objetivo_id}
                                  title={objective.titulo}
                                  description={objective.descripcion}
                                  dueDate={new Date(objective.fecha_entrega+"T00:00:00")}
                                  selectNextObjectiveToDelete={
                                    selectNextObjectiveToDelete
                                  }
                                  selectNextObjectiveToEdit={
                                    selectNextObjectiveToEdit
                                  }
                                />
                              ))
                            ) : (
                              <div className="text-center mt-3">
                                <PlusFolder />
                                <h3 className="mt-2 text-sm font-medium text-gray-900">
                                  Sin nuevos objetivos
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                  Da click en el botón "Agregar objetivo" y crea
                                  un nuevo objetivo para el siguiente periodo.
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                </div>
                <div className="p-8 border-t border-gray-300 flex justify-end space-x-2 bg-white">
                  <button
                    className="relative inline-flex items-center rounded-md border border-red-700 bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:z-20"
                    onClick={(e) => clickSendAssessment()}
                  >
                    <span className="sr-only">Enviar</span>
                    Enviar
                  </button>
                </div>
              </div>
            </main>
          ) : (
            <div className="w-full h-screen flex items-center justify-center bg-white">
              <img
                src={backwall}
                alt={"T3B"}
                className="absolute w-full h-full z-0 object-cover rounded-lg left-0 top-0"
              />
              <div className="relative items-center flex flex-col z-10 p-10">
                <img
                  src={logo}
                  alt={"T3B"}
                  className="w-40 h-40 -mt-10 mb-5 object-contain rounded-lg"
                />
                <p className="text-3xl font-light text-center mb-2">
                  La actualización ha sido enviada.
                </p>
                <p className="text-3xl font-light text-center mb-2">
                  ¡Muchas gracias por tu participación en la evaluación de
                  desempeño!
                </p>
                <p className="text-xl font-light text-center mb-2">
                  En 10 segundos serás redireccionado a la pantalla de inicio
                </p>
              </div>
            </div>
          )}
          <Notification
            title={errorTitle}
            message={error}
            type="danger"
            ref={notificationRef}
          />
        </>
      )}
      <CommonModalTemplate ref={modalRefEnd}>
        {loadingEnd ? (
          <div className="w-full h-full flex items-center justify-center bg-white">
            <video
              className="w-48 h-48 flex flex-1"
              alt={"Tiendas 3B"}
              muted
              loop
              autoPlay
            >
              <source
                src={
                  "https://dfh9lj2viqrbd.cloudfront.net/splash/IRHLoading.mp4"
                }
                type="video/mp4"
              />
              Sin soporte de video MP4
            </video>
          </div>
        ) : (
          <>
            <img
              src={backwall}
              alt={"T3B"}
              className="absolute w-full h-full z-0 object-cover rounded-lg left-0 top-0"
            />
            <div className="relative items-center flex flex-col z-10">
              <p className="text-3xl font-medium text-center mb-2">
                Muchas gracias, has respondido satisfatoriamente el formato de
                evaluación de desempeño
              </p>
            </div>
          </>
        )}
      </CommonModalTemplate>
      <CommonModalTemplate ref={selfAssessmentRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon
              className="h-6 w-6 text-red-600"
              aria-hidden="true"
            />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">
              Enviar objetivos
            </p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Deseas enviar la actualización? Una vez enviada no podrás editar
                los objetivos
              </p>
              <p className="text-sm text-gray-500 mt-2">
                Tu jefe inmediato aún podrá actualizar aquellos que no hayas eliminado
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            disabled={loadingEnd}
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={endAssessment}
          >
            {loadingEnd ? "Enviando" : "Enviar"}
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => selfAssessmentRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
      <BigModalTemplate ref={simpleObjective}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">{selectedNextObjective != 0 ? "Editar" : "Nuevo"} objetivo</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => simpleObjective.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              TÍTULO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Título del objetivo..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              ENTREGABLE
            </label>
            <div className="mt-1">
              <input
                id="description"
                name="description"
                type="text"
                placeholder="Entregable del objetivo..."
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mb-2">
            <label
              htmlFor="question-description"
              className="block text-xs text-red-500"
            >
              FECHA COMPROMISO
            </label>
            <div className="">
              <WDatePicker
                onChange={setDateCreate}
                value={dateCreate}
                minDate={new Date(assessment.minima_fecha_objetivos)}
                maxDate={new Date(assessment.maximo_fecha_objetivos)}
                calendarIcon={<CalendarIcon className="h-5 w-5 text-black" />}
                clearIcon={null}
                format="dd/MM/y"
                prevLabel={<ChevronLeftIcon className="h-5 w-5 text-white" />}
                nextLabel={<ChevronRightIcon className="h-5 w-5 text-white" />}
              />
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => simpleObjective.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingDevelopment}
                onClick={addObjective}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {
                  selectedNextObjective != 0
                  ? loadingDevelopment ? "Editando objetivo..." : "Editar"
                  : loadingDevelopment ? "Creando objetivo..." : "Crear"
                }
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <CommonModalTemplate ref={deleteNextObjetiveRef}>
        <div className="sm:flex sm:items-start">
          <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
            <p className="text-lg font-medium leading-6 text-gray-900">Eliminar objetivo</p>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                ¿Estás seguro de querer eliminar este objetivo? Una vez eliminado, no podrás deshacer esta acción
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
            onClick={deleteNextObjective}
          >
            Eliminar
          </button>
          <button
            type="button"
            className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
            onClick={() => deleteNextObjetiveRef.current.hideModal()}
          >
            Cancelar
          </button>
        </div>
      </CommonModalTemplate>
    </>
  );
}
