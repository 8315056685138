import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";

const GlobalResult = ({ period, isBoss, region, area }) => {
  const {user, token} = useSelector(state => state.authentication);
  const [global, setGlobal] = useState({});
  const [loadingGlobal, setLoadingGlobal] = useState(true);

  useEffect(() => {
    if (period) {
      fetchGlobalResults()
    }
  }, [period, region, area]);

  const fetchGlobalResults = useCallback(()=>{
    let params = { period };
      if (region != 0) {
        params.region = region;
      }
      if (area != "0") {
        params.area = area;
      }
      setLoadingGlobal(true);
      const headers = { Authorization: token }
      API.get(`/results/global`, { params, headers })
        .then(({ status, data }) => {
          setLoadingGlobal(false);
          if (status === 200) {
            setGlobal(data);
          } else {
            setGlobal({});
          }
        })
        .catch((error) => {
          setLoadingGlobal(false);
          setGlobal({});
        });
  },[period, region, area, token])
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-2">
        <dt className="truncate text-sm font-medium text-gray-500">
          Promedio Global
        </dt>
        {
          loadingGlobal
          ? <InfinityLoader legend={false} className="h-14 w-14"/>
          : <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {roundTwoDecimals(isBoss ? global.calificacion_global_evaluacion : global.calificacion_global_autoevaluacion)}
          </dd>
        }
      </div>
    </>
  );
};

GlobalResult.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number,
  area: PropTypes.string,
};

GlobalResult.defaultProps = {
  period: null,
  isBoss: true,
  region: 0,
  area: "0",
};

export default GlobalResult;
