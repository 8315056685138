import { useCallback } from "react";
import { useState } from "react";
import { useReactToPrint } from "react-to-print";
import { useRef } from "react";
import { sortByOrder } from "../../constants/functions";
import logo from "./../../assets/img/brand/logo.svg";
import { PrinterIcon } from "@heroicons/react/24/outline";

const BlankPrint = ({ name, objectivesType, sections }) => {
  let printComponent = useRef();

  const [printType, setPrintType] = useState(1);

  const reactToPrintContent = useCallback(() => {
    return printComponent.current;
  }, [printComponent.current]);

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `${printType < 2 ? "Autoevaluacion_" : "Evaluacion_"}${
      name
    }`,
    removeAfterPrint: true,
    copyStyles: true,
    pageStyle: "{ size: auto; margin: 0mm; }",
  });

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center px-2 sm:px-4 md:px-8 py-8">
        <div className="w-full max-w-5xl mt-8 flex justify-end">
          <div className={`grid grid-cols-2 gap-4`}>
            <select
              id="location"
              name="location"
              className="col-span-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              value={printType}
              onChange={(e) => setPrintType(e.target.value)}
            >
              <option value={1}>Autoevaluación</option>
              <option value={2}>Evaluación</option>
            </select>
            <button
              type="button"
              onClick={handlePrint}
              className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              Imprimir
              <PrinterIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
        <div className="bg-white max-w-5xl mt-4 shadow border w-full">
          <div ref={printComponent}>
            <div className="m-6 sm:m-8 md:m-10 lg:m-16 print:m-0 print:sm:m-0 print:md:m-0 print:lg:m-0">
              <div className="w-full flex justify-center">
                <img src={logo} alt="" className="h-24 w-24" />
              </div>
              <p className="text-center text-lg font-medium text-red-500">
                Periodo
              </p>
              <p className="text-center text-xl font-bold text-gray-900">
                {name}
              </p>
              <div className="grid grid-cols-1 sm:grid-cols-2 my-6">
                <div className="grid grid-cols-1">
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">NOMBRE: </span>
                      NOMBRE DEL COLABORADOR
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">PUESTO: </span>
                      PUESTO DEL COLABORADOR
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">REGIÓN: </span>
                      REGIÓN DEL COLABORADOR
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">ÁREA: </span>ÁREA
                      DEL COLABORADOR
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">
                        EVALUADOR:{" "}
                      </span>
                      NOMBRE DEL EVALUADOR
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">
                        FECHA DE REVISIÓN:{" "}
                      </span>
                      00-00-0000
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">
                        ¿AUTOEVALUACIÓN FINALIZADA?:{" "}
                      </span>
                      NO
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-gray-900 text-sm print:text-xs mb-1">
                      <span className="text-red-500 font-bold">
                        ¿EVALUACIÓN FINALIZADA?:{" "}
                      </span>
                      NO
                    </p>
                  </div>
                </div>
                <div className="flex mb-6">
                  <div className="flex flex-col items-start">
                    {Number(objectivesType) === 3 && (
                      <>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Objetivos cumplidos:{" "}
                          </span>
                          100 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Competencias cumplidas:{" "}
                          </span>
                          100 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Cultura cumplidas:{" "}
                          </span>
                          100 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Global:{" "}
                          </span>
                          100 %
                        </p>
                      </>
                    )}
                    {Number(objectivesType) === 1 && (
                      <>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Objetivos logrados:{" "}
                          </span>
                          100 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Excede requerimiento:{" "}
                          </span>
                          33.33 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Cumple requerimiento:{" "}
                          </span>
                          33.33 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Insatisfactorio:{" "}
                          </span>
                          33.33 %
                        </p>
                        <p className="text-gray-900 text-sm print:text-xs mb-1">
                          <span className="text-red-500 font-bold">
                            % Global:{" "}
                          </span>
                          100 %
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-full flex-col items-start mt-2">
                {(printType == 1
                  ? [...sections]
                  : [...sections].filter((s) => Number(s.tipo_evaluacion) != 2)
                )
                  .sort(sortByOrder)
                  .map((section) => (
                    <div key={`section-${section.seccion_id}`} className="pb-3">
                      <p className="pb-2 text-left text-xl font-extrabold text-gray-900">
                        {section.title}
                      </p>
                      {section.type === 1 &&
                        section.subsections
                          .sort(sortByOrder)
                          .map((subsection) => (
                            <div
                              className="pb-2"
                              key={`subsection-${subsection.apartado_id}`}
                            >
                              <p className="text-left font-bold text-red-600 mb-1">
                                {subsection.title == "Comentarios" &&
                                printType > 1
                                  ? subsection.title + " jefe inmediato"
                                  : subsection.title}
                              </p>
                              <ul className="list-disc px-5 space-y-1">
                                {subsection.questions
                                  .sort(sortByOrder)
                                  .map((question, index) => (
                                    <li
                                      key={`question-${question.pregunta_id}`}
                                    >
                                      <p className="text-sm print:text-xs font-medium break-before-all">
                                        {printType == 1
                                          ? question.title_auto
                                          : subsection.title == "Comentarios"
                                          ? ""
                                          : question.title}
                                      </p>
                                      {
                                        [5].includes(question.type) && <ul className="list-disc px-5 space-y-1">
                                          {question.answers
                                            .sort(sortByOrder)
                                            .map((answer, index) => (
                                              <li
                                                key={`answer-${answer.respuesta_id}`}
                                              >
                                                <p className="text-gray-500 text-xs print:text-xs break-before-all">
                                                  {answer.title}
                                                </p>
                                              </li>
                                            ))}
                                        </ul>
                                      }
                                    </li>
                                  ))}
                              </ul>
                            </div>
                          ))}
                      {[34].includes(section.type) && (
                        <div className="m-0 overflow-x-auto">
                          <table className="min-w-full divide-y divide-gray-300 border mb-2">
                            <thead className="bg-gray-50">
                              <tr className="divide-x divide-gray-200">
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Categoría
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Grupo
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Línea
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Comprador
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Descripción
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Unidad
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Tipo
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-xs font-semibold text-gray-900 print:text-[10px]"
                                >
                                  Fecha de entrega
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr className="divide-x divide-gray-200">
                                <td className="p-2 text-xs font-medium text-gray-900 print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-left print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px]">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-xs text-gray-900 text-center print:text-[10px] whitespace-nowrap">
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                      {[4].includes(section.type) && (
                        <div className="m-0 overflow-x-auto my-6">
                          <table className="min-w-full divide-y divide-gray-300 border mb-2">
                            <thead className="bg-gray-50">
                              <tr className="divide-x divide-gray-200">
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 print:text-xs"
                                >
                                  Objetivo
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                                >
                                  Esperado
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                                >
                                  Real
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                                >
                                  Resultado
                                </th>
                                <th
                                  scope="col"
                                  className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                                >
                                  Comentarios
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr className="divide-x divide-gray-200">
                                <td className="p-2 text-sm font-medium text-gray-900 print:text-xs">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">
                                  &nbsp;
                                </td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">
                                  &nbsp;
                                </td>
                                <td
                                  className={`whitespace-nowrap p-2 text-sm text-center font-medium print:text-xs`}
                                >
                                  &nbsp;
                                </td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">
                                  &nbsp;
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
              <div className="w-full flex-col items-start mt-24">
                <div className="grid grid-cols-2 gap-10">
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-center text-sm text-gray-900 font-bold border-t">
                      NOMBRE COLABORADOR
                    </p>
                    <p className="text-center text-xs text-gray-900">
                      PUESTO COLABORADOS
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-center text-sm text-gray-900 font-bold border-t">
                      NOMBRE EVALUADOR
                    </p>
                    <p className="text-center text-xs text-gray-900">
                      PUESTO EVALUADOR
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlankPrint;
