import { ArrowPathRoundedSquareIcon, CheckCircleIcon, EyeIcon, PencilSquareIcon, XCircleIcon } from "@heroicons/react/24/solid";
import PropTypes from "prop-types";
import { useRef } from "react";
import { Link } from "react-router-dom";
import { classNames } from "../../constants/functions";
import { months_short_es } from "../../utils/date_utils";
import ObjectiveCompleteModal from "../modals/ObjectiveCompleteModal";
import ObjectiveProgressModal from "../modals/ObjectiveProgressModal";

const HomeObjective = ({ id, status, title, titleParent, description, dueDate, startDate, periodStatus, self, superior, endSelfAssessment, endSuperiorAssessment, loadObjectivesAgain, objectiveValidated }) => {
  const progressRef = useRef();
  const completeRef = useRef();

  const getObjectiveStatusClassName = (status_id) => {
    let className = "";
    if(status_id < 4){
      className = "bg-red-100 text-red-800";
    } else {
      className = "bg-blue-100 text-blue-800";
    }
    return className;
  }

  const getObjectiveStatusLabel = (status_id) => {
    let className = "";
    if(status_id < 4){
      className = "No alcanzado";
    } else {
      className = "Alcanzado";
    }
    return className;
  }

  const getObjectiveDatePercentage = () => {
    let percentage = "";
    let beginDate = new Date(startDate);
    let endDate = new Date(dueDate);

    if (new Date() > endDate) {
      percentage = 100;
    } else {
      const diffTimeTotal = Math.abs(endDate - beginDate);
      const diffDaysTotal = Math.ceil(diffTimeTotal / (1000 * 60 * 60 * 24));

      const diffTimePartial = Math.abs(endDate - new Date());
      const diffDaysPartial = Math.ceil(
        diffTimePartial / (1000 * 60 * 60 * 24)
      );

      const percentageNumber = (diffDaysPartial / diffDaysTotal) * 100;

      percentage = 100 - percentageNumber;
    }

    return percentage;
  };

  const getObjectiveDatePercentageLabel = () => {
    let percentage = "";
    let endDate = new Date(dueDate);

    if(status < 3){
      if (new Date() > endDate) {
        percentage = <span className="text-red-600 italic">No se cumplió el objetivo a tiempo</span>;
      } else {
        const diffTimePartial = Math.abs(endDate - new Date());
        const diffDaysPartial = Math.ceil(
          diffTimePartial / (1000 * 60 * 60 * 24)
        );

        if (diffDaysPartial > 30) {
          percentage = `Resta${Math.floor(diffDaysPartial / 30) != 1 ? "n" : ""} ${Math.floor(diffDaysPartial / 30)} mes${
            Math.floor(diffDaysPartial / 30) != 1 ? "es" : ""
          } para completar este objetivo`;
        } else {
          percentage = `Resta${diffDaysPartial != 1 ? "n" : ""} ${diffDaysPartial} día${
            diffDaysPartial != 1 ? "s" : ""
          } para completar este objetivo`;
        }
      }
    }

    return percentage;
  };

  const getObjectiveCompletionDateLock = () => {
    let notLocked = true;
    let endDate = new Date(dueDate);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(9999);

    if(status < 3){
      if (new Date() > endDate) {
        notLocked = false;
      }
      if (objectiveValidated) {
        notLocked = true;
      }
    }
    return notLocked;
  };

  const getObjectiveValidationDateLock = () => {
    let notLocked = true;
    let endDate = new Date(dueDate);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);
    endDate.setMilliseconds(999);

    if (new Date() > endDate && objectiveValidated) {
      notLocked = false;
    }

    return notLocked;
  };

  const getObjectiveDatePercentageClassName = () => {
    let className = "";
    let percentage = getObjectiveDatePercentage();
    if (percentage >= 85) {
      className = "bg-red-500";
    } else if (percentage > 60 && percentage < 85) {
      className = "bg-yellow-500";
    } else {
      className = "bg-green-500";
    }
    return className;
  };

  const watchProgress = () => {
    progressRef.current.showModal(id);
  };

  return (
    <div
      key={`key-objetivo-${id}`}
      className={classNames("relative bg-white px-6 py-3 flex")}
    >
      <div className="flex-1 flex flex-col">
        {periodStatus != 2 && <div className="flex justify-end">
          <span
            className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 text-xs font-medium ${getObjectiveStatusClassName(
              status
            )}`}
          >
            {getObjectiveStatusLabel(status)}
          </span>
        </div>}
        {titleParent && <h3 className="text-lg font-bold underline focus:outline-none">{titleParent}</h3>}
        <h3 className="text-lg font-medium focus:outline-none">{title}</h3>
        <p className="mt-1 text-sm text-gray-500 flex-1">{description}</p>
        <p className="mt-1 mb-2 text-sm text-gray-900 font-normal">
          Fecha de entrega:{" "}
          <span className="underline">
            {new Date(dueDate).getDate()} de {months_short_es[new Date(dueDate).getMonth()]}. del {new Date(dueDate).getFullYear()}
          </span>
        </p>
        {periodStatus == 2 && (
          <div className="flex flex-wrap space-x-2 items-center">
            <p className="m-0 font-medium text-sm mb-1">
              Objetivo del siguiente periodo
            </p>
          </div>
        )}
        {periodStatus == 1 && status < 3 && (
          <div className="flex flex-wrap space-x-2 items-center">
            {getObjectiveDatePercentage() < 100 && <div className="h-2 flex-1 rounded-full bg-gray-300">
              <div
                className={`h-2 rounded-full ${getObjectiveDatePercentageClassName()}`}
                role="progressbar"
                style={{ width: `${getObjectiveDatePercentage()}%` }}
                aria-valuenow={getObjectiveDatePercentage()}
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>}
            <p className="m-0 font-medium text-sm mb-1">
              {getObjectiveDatePercentageLabel()}
            </p>
          </div>
        )}
        <div className="flex text-sm font-medium mt-2 flex-wrap">
          {(self || superior) && (periodStatus == 2 || (!endSelfAssessment && status < 3)) && getObjectiveCompletionDateLock() && <Link
            to={`/t3b/objectives/${id}/progress`}
            className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
          >
            <PencilSquareIcon className="h-5 w-5 mr-1"/>
            Registrar progreso
          </Link>}
          {status > 1 && <div className="">
            <button
              onClick={watchProgress}
              type="button"
              className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
            >
              <EyeIcon className="h-5 w-5 mr-1"/>
              Ver progreso
            </button>
          </div>}
          {(self && status == 2) && getObjectiveCompletionDateLock() && <button
            onClick={e => completeRef.current.showModal(id)}
            className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
          >
            <CheckCircleIcon className="h-5 w-5 mr-1"/>
            Alcanzar objetivo
          </button>}
          {getObjectiveValidationDateLock() && !endSuperiorAssessment && status == 3 && superior && <Link
            to={`/t3b/objectives/${id}/reopen`}
            className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
          >
            <ArrowPathRoundedSquareIcon className="h-5 w-5 mr-1"/>
            Reabrir objetivo
          </Link>}
          {getObjectiveValidationDateLock() && periodStatus != 2 && !endSuperiorAssessment && status == 3 && superior && <Link
            to={`/t3b/objectives/${id}/validate`}
            className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
          >
            <CheckCircleIcon className="h-5 w-5 mr-1"/>
            Validar objetivo
          </Link>}
          {getObjectiveValidationDateLock() && periodStatus == 2 && status == 3 && superior && <Link
            to={`/t3b/objectives/${id}/validate`}
            className="text-blue-600 hover:underline hover:text-blue-800 flex items-center mr-6 mb-2"
          >
            <CheckCircleIcon className="h-5 w-5 mr-1"/>
            Validar objetivo
          </Link>}
        </div>
      </div>
      <ObjectiveProgressModal ref={progressRef} />
      <ObjectiveCompleteModal ref={completeRef} loadObjectivesAgain={loadObjectivesAgain}/>
    </div>
  );
};

HomeObjective.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  periodStatus: PropTypes.number,
  title: PropTypes.string,
  titleParent: PropTypes.string,
  dueDate: PropTypes.string,
  startDate: PropTypes.string,
  description: PropTypes.string,
  superior: PropTypes.bool,
  self: PropTypes.bool,
  endSelfAssessment: PropTypes.bool,
  loadObjectivesAgain: PropTypes.func,
  endSuperiorAssessment: PropTypes.bool,
  objectiveValidated: PropTypes.bool
};

HomeObjective.defaultProps = {
  id: 1,
  status: 1,
  periodStatus: 1,
  superior: false,
  self: false,
  titleParent: null,
  endSelfAssessment: false,
  endSuperiorAssessment: false,
  objectiveValidated: false,
  loadObjectivesAgain: () => {},
  title: "Default title",
  dueDate: "2023-01-01",
  startDate: "2023-01-01",
  description: "Default description",
};

export default HomeObjective;
