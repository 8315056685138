import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import face from "./../../assets/img/faces/avatar.png";

const HomeEmployee = ({id, image, name, position, objectivesCount, objectivesToValidateCount, salesObjectivesCount, salesObjectivesValidCount, nextObjectivesCount}) => {
  return (
    <li key={`key-employee-${id}`} className="py-4">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <img className="h-8 w-8 rounded-full object-cover" src={image ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${image}` : face} alt="" />
        </div>
        <div className="min-w-0 flex-1">
          <p className="truncate text-sm font-medium text-gray-900">{name}</p>
          <p className="truncate text-sm text-gray-500">{position}</p>
        </div>
        <div>
          <Link
            to={`/t3b/employees/${id}`}
            className="inline-flex items-center rounded-full border border-gray-300 bg-white px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 shadow-sm hover:bg-gray-50 hover:shadow-lg"
          >
            Objetivos
          </Link>
        </div>
      </div>
      <div className="flex items-center space-x-4 mt-1">
        <div className="w-8 "></div>
        <div className="min-w-0 flex-1">
          {
            objectivesCount > 0 && <p className="text-sm text-gray-900">{objectivesCount} objetivos en el periodo</p>
          }
          {
            nextObjectivesCount > 0 && <p className="text-sm text-gray-900">{nextObjectivesCount} objetivos para el siguiente periodo</p>
          }
          {
            salesObjectivesCount > 0 &&  <Link
                  to={`/t3b/employees/${id}`}
                  className="text-sm font-medium text-red-600 underline hover:text-red-800"
                >
                  {salesObjectivesValidCount} objetivo{salesObjectivesValidCount != 1 ? "s" : ""} cumplido{salesObjectivesValidCount != 1 ? "s" : ""} de {salesObjectivesCount}
                </Link>
          }
          {
            objectivesCount < 1 && salesObjectivesCount < 1 && nextObjectivesCount < 0 && <p className="text-sm text-gray-900">Sin objetivos en el periodo</p>
          }
          {
            objectivesToValidateCount > 0
            ? <Link
              to={`/t3b/employees/${id}`}
              className="text-sm font-medium text-red-600 underline hover:text-red-800"
            >
              {objectivesToValidateCount} objetivo{objectivesToValidateCount != 1 ? "s" : ""} por validar
            </Link>
            : <></>
          }
        </div>
      </div>
    </li>
  )
};

HomeEmployee.propTypes = {
  id: PropTypes.number,
  image: PropTypes.string,
  name: PropTypes.string,
  position: PropTypes.string,
  objectivesCount: PropTypes.number,
  nextObjectivesCount: PropTypes.number,
  objectivesToValidateCount: PropTypes.number,
  salesObjectivesCount: PropTypes.number,
  salesObjectivesValidCount: PropTypes.number,
};

HomeEmployee.defaultProps = {
  id: 1,
  image: null,
  name: "Default name",
  position: "Default position",
  objectivesCount: 0,
  nextObjectivesCount: 0,
  objectivesToValidateCount: 0,
  salesObjectivesCount: 0,
  salesObjectivesValidCount: 0
};

export default HomeEmployee;