import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundPercentage, roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";
import {
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
} from "recharts";

const COLORSOBJFILL = ["#16a34a66", "#dc262666"];
const COLORSOBJSTROKE = ["#16a34a", "#dc2626"];

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, value }) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);


  return (
    <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {value}
    </text>
  );
};

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center pt-2 items-center space-x-6 flex-wrap">
      {
        payload.map((entry, index) => {
          const { payload } = entry;
          return (
          <li key={`item-${index}`} className="flex items-center">
            <div className="h-4 w-4 mr-2" style={{backgroundColor: payload.fill, border: 1, borderStyle: "solid", borderColor: payload.fill}}></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        )})
      }
    </ul>
  );
}

const PieChartObjectives = ({ period, isBoss, region, area }) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [objectives, setObjectives] = useState({});
  const [loadingObjectives, setLoadingObjectives] = useState(true);

  useEffect(() => {
    if (period) {
      fetchPieChartObjectivess();
    }
  }, [period, region, area]);

  const fetchPieChartObjectivess = useCallback(() => {
    let params = { period };
    if (region != 0) {
      params.region = region;
    }
    if (area != "0") {
      params.area = area;
    }
    setLoadingObjectives(true);
    const headers = { Authorization: token };
    API.get(`/results/objectives`, { params, headers })
      .then(({ status, data }) => {
        setLoadingObjectives(false);
        if (status === 200) {
          let dataObj = data[0] || {};
          setObjectives(dataObj);
        } else {
          setObjectives({});
        }
      })
      .catch((error) => {
        setLoadingObjectives(false);
        setObjectives({});
      });
  }, [period, region, area, token]);

  const getObjectivesData = (objectivesType) => {
    let cumplidos = 0;
    let noCumplidos = 0;
    let labelObj = "porcentaje_objetivos";
    if (objectivesType === "VENTAS") {
      labelObj = "porcentaje_objetivos_ventas";
    }
    cumplidos = roundPercentage(Number(objectives[labelObj]));
    noCumplidos = roundPercentage(1 - Number(objectives[labelObj]));
    let result = [
      { name: "Cumplidos", value: cumplidos },
      { name: "No cumplidos", value: noCumplidos },
    ];
    return result;
  };
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-3 h-96">
        <dt className="truncate text-sm font-medium text-gray-500">
          Objetivos SMART
        </dt>
        {loadingObjectives ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="h-full">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  nameKey="name"
                  dataKey="value"
                  data={getObjectivesData("SMART")}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="80%"
                  fill="#E31B22"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {getObjectivesData("SMART").map((entry, index) => (
                    <Cell
                      key={`key-objective-smart-estatus-cell-${index}`}
                      fill={COLORSOBJFILL[index]}
                      stroke={COLORSOBJSTROKE[index]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
              </PieChart>
            </ResponsiveContainer>
          </dd>
        )}
      </div>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-3 h-96">
        <dt className="truncate text-sm font-medium text-gray-500">
          Objetivos Ventas
        </dt>
        {loadingObjectives ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="h-full">
            <ResponsiveContainer width="100%" height="100%">
              <PieChart width="100%" height="100%">
                <Pie
                  nameKey="name"
                  dataKey="value"
                  data={getObjectivesData("VENTAS")}
                  cx="50%"
                  cy="50%"
                  innerRadius="50%"
                  outerRadius="80%"
                  fill="#E31B22"
                  labelLine={false}
                  label={renderCustomizedLabel}
                >
                  {getObjectivesData("VENTAS").map((entry, index) => (
                    <Cell
                      key={`key-objective-ventas-estatus-cell-${index}`}
                      fill={COLORSOBJFILL[index]}
                      stroke={COLORSOBJSTROKE[index]}
                    />
                  ))}
                </Pie>
                <Tooltip />
                <Legend content={renderLegend} wrapperStyle={{display: "flex"}}/>
              </PieChart>
            </ResponsiveContainer>
          </dd>
        )}
      </div>
    </>
  );
};

PieChartObjectives.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number,
  area: PropTypes.string,
};

PieChartObjectives.defaultProps = {
  period: null,
  isBoss: true,
  region: 0,
  area: "0",
};

export default PieChartObjectives;
