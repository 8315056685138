import { Fragment, useEffect } from 'react'
import { Menu, Transition } from '@headlessui/react'
import {
  ChartPieIcon,
  ChevronDownIcon,
  ClipboardDocumentListIcon,
  CloudArrowUpIcon,
  DocumentIcon,
  PencilSquareIcon,
  PresentationChartLineIcon,
  UsersIcon
} from '@heroicons/react/24/outline'
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { logout } from '../redux/reducers/auth'
import { useDispatch, useSelector } from 'react-redux'

const subNavigationSuperAdmin = [
  {
    name: 'Usuarios',
    description: 'Registra nuevos usuarios, y edita su información, estatus o roles en el sistema.',
    href: '/t3b/admin/users',
    icon: UsersIcon,
    current: true,
  },
  // {
  //   name: 'Tiendas',
  //   description: 'Consulta, actualiza o da de alta nuevos registros en el catálogo de tiendas.',
  //   href: '/t3b/admin/profitcenters',
  //   icon: BuildingStorefrontIcon,
  //   current: false,
  // },
  // {
  //   name: 'Cambios',
  //   description: 'Registra los cambios de centros de costos de los Gerentes de Tienda.',
  //   href: '/t3b/admin/pfchanges',
  //   icon: ArrowPathRoundedSquareIcon,
  //   current: false,
  // },
  // {
  //   name: 'COVS',
  //   description: 'Carga los objetivos de tiendas, distritos y zonas.',
  //   href: '/t3b/admin/covs',
  //   icon: CloudArrowUpIcon,
  //   current: false,
  // },
  {
    name: 'Reportes',
    description: 'Consulta los reportes de objetivos, evaluaciones y competencias de tu región.',
    href: '/t3b/admin/reports',
    icon: ChartPieIcon,
    current: false,
  },
  {
    name: 'Evaluaciones',
    description: 'Consulta las evaluaciones asignadas al personal de tu región.',
    href: '/t3b/admin/periods',
    icon: ClipboardDocumentListIcon,
    current: false,
  },
  {
    name: 'Objetivos',
    description: 'Consulta los objetivos del personal de tu región y su progreso.',
    href: '/t3b/admin/objectives',
    icon: PencilSquareIcon,
    current: false,
  },
  {
    name: 'Avance de objetivos',
    description: 'Consulta los avances de los objetivos por mes.',
    href: '/t3b/admin/progress',
    icon: PresentationChartLineIcon,
    current: false,
  },
  {
    name: 'Formatos',
    description: 'Edita los formatos de evaluación.',
    href: '/t3b/admin/forms',
    icon: DocumentIcon,
    current: false,
  },
]

const subNavigationSimpleAdmin = [
  {
    name: 'Usuarios',
    description: 'Registra nuevos usuarios, y edita su información, estatus o roles en el sistema.',
    href: '/t3b/admin/users',
    icon: UsersIcon,
    current: true,
  },
  {
    name: 'Reportes',
    description: 'Consulta los reportes de objetivos, evaluaciones y competencias de tu región.',
    href: '/t3b/admin/reports',
    icon: ChartPieIcon,
    current: false,
  },
  {
    name: 'Evaluaciones',
    description: 'Consulta las evaluaciones asignadas al personal de tu región.',
    href: '/t3b/admin/periods',
    icon: ClipboardDocumentListIcon,
    current: false,
  },
  {
    name: 'Avance de objetivos',
    description: 'Consulta los avances de los objetivos por mes.',
    href: '/t3b/admin/progress',
    icon: PresentationChartLineIcon,
    current: false,
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const activeClassName = "bg-red-200 bg-opacity-50";
const inactiveClassName = "hover:bg-red-50 hover:bg-opacity-50";

export default function AdminLayout() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {user} = useSelector(state => state.authentication);

  useEffect(() => {
    if (!user) {
      dispatch(logout("Inicia sesión para ingresar al portal"));
      navigate("/", { state: { from: location } });
    } else {
      let superAdministrador = user?.certificados.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;
      let administradorRegional = user?.certificados.filter(c => c.cat_permiso_id == 7 && c.estatus == 1)?.length > 0;

      if (!superAdministrador && !administradorRegional) {
        navigate("/t3b/home");
      }
    }
  }, [user]);

  let superAdministrador = user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length > 0;

  return (
    <>
      <main className='flex flex-1 flex-col h-full'>
        <div className="flex flex-1 flex-col h-full">
          {/* Breadcrumb */}
          <nav aria-label="Breadcrumb" className="border-b border-blue-gray-200 bg-white xl:hidden">
            <div className="py-3 px-4 sm:px-6 lg:px-8 w-full">
              <Menu as="div" className="w-full relative inline-block text-left">
                <Menu.Button className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                  {subNavigationSuperAdmin.find(n => n.href === location.pathname)?.name}
                  <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                </Menu.Button>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {(superAdministrador ? [...subNavigationSuperAdmin] : [...subNavigationSimpleAdmin]).map((item) => (
                        <Menu.Item key={`key-menu-item-1-${item.name}`}>
                          {({ active }) => (
                            <Link
                              as="li"
                              to={item.href}
                              className={classNames(
                                location.pathname.includes(item.href) ? activeClassName : inactiveClassName,
                                'flex p-3 border-b border-blue-gray-200'
                              )}
                            >
                              <item.icon className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-gray-400" aria-hidden="true" />
                              <div className="ml-3 text-sm">
                                <p className="font-medium text-blue-gray-900">{item.name}</p>
                              </div>
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </nav>

          <div className="flex flex-1 h-full">
            {/* Secondary sidebar */}
            <aside
              aria-label="Barra lateral"
              className="hidden w-96 flex-shrink-0 border-r border-blue-gray-200 xl:block -mt-16 bg-white"
            >
              <div className="flex max-h-screen pt-16 sticky top-0">
                <nav className="block overflow-y-auto custom-scrollbar">
                  <ul>
                    {(superAdministrador ? [...subNavigationSuperAdmin] : [...subNavigationSimpleAdmin]).map((item) => (
                      <Link
                        as="li"
                        key={`key-menu-item-2-${item.name}`}
                        to={item.href}
                        className={classNames(
                          location.pathname.includes(item.href) ? activeClassName : inactiveClassName,
                          'flex p-6 border-b border-blue-gray-200'
                        )}
                      >
                        <item.icon className="-mt-0.5 h-6 w-6 flex-shrink-0 text-blue-gray-400" aria-hidden="true" />
                        <div className="ml-3 text-sm">
                          <p className="font-medium text-blue-gray-900">{item.name}</p>
                          <p className="mt-1 text-blue-gray-500">{item.description}</p>
                        </div>
                      </Link>
                    ))}
                  </ul>
                </nav>
              </div>
            </aside>

            {/* Main content */}
            <Outlet />
          </div>
        </div>
      </main>
    </>
  )
}
