import { useEffect, useRef } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { sortByOrder } from "../../constants/functions";
import logo from "./../../assets/img/brand/logo.svg";
import API from "../../services/API";
import API2 from "../../services/API2";
import { CheckCircleIcon, CheckIcon, XCircleIcon } from "@heroicons/react/20/solid";
import Notification from "../../components/notifications/Notification";

const Feedback = () => {
  const navigate = useNavigate();
  const pathParams = useParams();
  const { assessmentId } = pathParams;
  const { user, token } = useSelector((state) => state.authentication);

  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();
  const [assessment, setAssessment] = useState({});
  const [global, setGlobal] = useState({});
  const [isLoading, setLoading] = useState(true);
  const [loadingEnd, setLoadingEnd] = useState(false);
  const [sections, setSections] = useState([]);
  const [nextObjectives, setNextObjectives] = useState([]);
  const [nextParentObjectives, setNextParentObjectives] = useState([]);
  const [pastObjectives, setPastObjectives] = useState([]);
  const [salesAnswers, setSalesAnswers] = useState([]);
  const [plans, setPlans] = useState([]);
  const [answers, setAnswers] = useState({});
  const [results, setResults] = useState({});
  const [assessmentUser, setAssessmentUser] = useState({});
  const [nextProjects, setNextProjects] = useState([]);
  const [pastProjects, setPastProjects] = useState([]);

  useEffect(() => {
    const params = { user_assessment: assessmentId, report: true };
    API.get(`/assessments`, { params, headers: { Authorization: token } })
      .then(({ status, data }) => {
        let assess = data[0];
        if (!assess.evaluacion_usuario_id) {
          navigate("/assessments");
        }
        if (status === 200) {
          setAssessment(assess);
        }
      })
      .catch((error) => {});
    API.get(`/results/global`, { params, headers: { Authorization: token } })
      .then(({ status, data }) => {
        if (status === 200) {
          setGlobal(data[0]);
        } else {
          setGlobal({});
        }
      })
      .catch((error) => {
        setGlobal({});
      });
  }, [assessmentId]);

  useEffect(() => {
    const getResults = () =>
      new Promise((resolve, reject) => {
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/results`,
          { headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setResults(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getUser = () =>
      new Promise((resolve, reject) => {
        return API.get("/users/"+assessment.numero_empleado, {
          headers: { Authorization: token },
        })
          .then(({ status, data }) => {
            if (status === 200) {
              setAssessmentUser(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getPlans = () =>
      new Promise((resolve, reject) => {
        if (assessment.tipo_plan === 3) {
          return resolve();
        }
        const params = {
          users: assessment.numero_empleado,
          period: assessment.evaluacion_usuario_id,
        };
        return API.get(`/plans`, { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              setPlans(
                data.filter(
                  (a) =>
                    a.evaluacion_registrado_id ===
                    assessment.evaluacion_usuario_id
                )
              );
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getSalesObjectiveAnswers = () =>
      new Promise((resolve, reject) => {
        if (assessment.tipo_objetivos !== 3) {
          return resolve();
        }
        const params = { print: true };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/salesobjectives`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let newSalesAnswers = {};
              for (let answer of data) {
                newSalesAnswers[answer.objetivo_venta_id] = { ...answer };
              }
              setSalesAnswers(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getAnswers = () =>
      new Promise((resolve, reject) => {
        let params = { face: true };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/answers`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let newAnswers = {};
              for (let answer of data) {
                if(!newAnswers[answer.pregunta_id]){
                  newAnswers[answer.pregunta_id] = {}
                }
                newAnswers[answer.pregunta_id][answer.evaluacion_tipo_id] = { ...answer }
              }
              setAnswers(newAnswers);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getObjectivesData = () =>
      new Promise((resolve, reject) => {
        let params = {
          users: assessment.numero_empleado,
          period: assessment.evaluacion_usuario_id,
        };
        return API.get(`/objectives`, {
          params,
          headers: { Authorization: token },
        })
          .then(({ status, data }) => {
            if (status === 200) {
              setPastObjectives(
                [...data].filter(
                  (o) =>
                    o.evaluacion_calificado_id ===
                    assessment.evaluacion_usuario_id
                )
              );
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          evaluacion_usuario_id: assessment.evaluacion_usuario_id,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextParentObjectives = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        let params = {
          evaluacion_usuario_id: assessment.evaluacion_usuario_id,
        };
        return API.get(
          `/assessments/${assessment.evaluacion_usuario_id}/next-objectives-parent`,
          { params, headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              setNextParentObjectives(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getAssessedProjects = () =>
      new Promise((resolve, reject) => {
        let params = {
          projects: true,
          assess_period: assessment.evaluacion_usuario_id,
        };
        return API.get(`/objectives`, { params, headers: { Authorization: token } })
          .then(({ status, data }) => {
            if (status === 200) {
              setPastProjects(data);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const getNextProjects = () =>
      new Promise((resolve, reject) => {
        if (assessment.estatus_evaluacion_id === 3) {
          return resolve();
        }
        return API2.get(
          `/edd/evaluacion-usuario/${assessment.evaluacion_usuario_id}/next-projects`,
          { headers: { Authorization: token } }
        )
          .then(({ status, data }) => {
            if (status === 200) {
              let response = data
              setNextProjects(response);
            }
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    const filterSections = (section) => {
      if (assessment.numero_empleado === user.numero_empleado) {
        return section.type_assessment !== 3;
      }
      if (user.colaboradores.includes(assessment.numero_empleado)) {
        return section.type_assessment !== 2;
      }
      return true;
    };
    if (assessment.evaluacion_usuario_id) {
      Promise.all([
        API.get(`/forms/${assessment.formato_id}/content`, {
          headers: { Authorization: token },
        })
          .then(({ status, data }) => {
            if (status === 200) {
              setSections(data.formato_json.filter(filterSections));
            }
          })
          .catch((error) => {}),
        getObjectivesData(),
        getAnswers(),
        getUser(),
        getPlans(),
        getSalesObjectiveAnswers(),
        getNextObjectives(),
        getNextParentObjectives(),
        getResults(),
        getAssessedProjects(),
        getNextProjects(),
      ])
        .then((data) => {
          setLoading(false);
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }, [user, assessment]);

  const getObjectiveStatus = (obj) => {
    let status = "";
    switch (obj) {
      case 4:
        status = "Validado";
        break;
      default:
        status = "No Validado";
        break;
    }
    return status;
  };

  const roundPercentage = (number) => {
    let num = number * 100;
    return Math.round(num * 100) / 100;
  };

  const getLabelAnswers = (question, type) => {
    let label = "";
    let maxOption = question.answers.find((a) => a.is_max);
    console.log(question);
    console.log(type);
    if (answers[question.pregunta_id][type]?.valor_pregunta < maxOption?.value) {
      label = "No cumple requerimiento. ";
    } else if (answers[question.pregunta_id][type]?.valor_pregunta > maxOption?.value) {
      label = "Excede requerimiento. ";
    } else {
      label = "Cumple requerimiento. ";
    }
    return label;
  };

  const getGlobalGrade = (type) => {
    let grade =
      type >= 2
        ? global.calificacion_global_evaluacion
        : global.calificacion_global_autoevaluacion;
    return Math.round(grade * 100) / 100;
  };

  const getGlobalGradeNoOp = (type) => {
    let grade =
      type >= 2
        ? global.calificacion_global_evaluacion
        : global.calificacion_global_autoevaluacion;
    return Math.round(grade * 100) / 100;
  };

  const checkForRound = (value) => {
    if (value.match(/^-?\d+$/) || value.match(/^\d+\.\d+$/)) {
      const formatter = new Intl.NumberFormat("en-US", {
        style: "decimal",
        maximumFractionDigits: 2,
      });
      return formatter.format(value);
    } else if (value.match(/^\d+\.\d+$/)) {
      return value;
    } else {
      return value;
    }
  };

  const endFaceToFace = () => {
    let body = {
      revision_terminada: true,
      estatus_cierre_id: 1
    };
    if(assessment.tipo_objetivos==3){
      body.estatus_evaluacion_id = 4;
    }
    setLoadingEnd(true);
    API.patch(`/assessments/${assessmentId}`, body, { headers: { Authorization: token } })
    .then(({ status })=>{
      setLoadingEnd(false);
      if(status===200){
        if(assessment.tipo_objetivos!=3 && assessment.tipo_objetivos!=4){
          sendEmail();
        }
        navigate("/t3b/home", { replace: true });
      }
    })
    .catch( error =>{
      setLoadingEnd(false);
      setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
    });
  };

  const sendEmail = () => {
    let body = {
      templateId: "d-1df143db7f2145ec91fe8ec24cf02cf0",
      groupId: 17817,
      personalizations: [
        {
          from: {
            email: "notificaciones@integrarh3b.com",
            name: "Evaluación de desempeño",
          },
          to: [
            {
              email: assessment?.email,
              name: assessment?.nombre_completo,
            },
          ],
          dynamicTemplateData: {
            receive: assessment?.nombre_completo
          },
        },
      ],
    };
    API.post("/integra/email/template", body, {
      headers: { Authorization: token },
    })
      .then((response) => {
        if (response) {
          const { status } = response;
          if (status != 200) {

          }
        }
      })
      .catch((error) => {

      });
  };

  return (
    <>
      <div className="w-full flex flex-col justify-center items-center px-2 sm:px-4 md:px-8 py-8">
        <div className="w-full max-w-5xl mt-8 flex justify-end">
          <div
            className={`grid grid-cols-1`}
          >
            {assessment.evaluacion_usuario_id && <button
              type="button"
              onClick={endFaceToFace}
              disabled={loadingEnd}
              className="col-span-1 flex items-center justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
            >
              {loadingEnd ? "Enviando respuesta..." :"Terminar revisión"}
              <CheckIcon className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
            </button>}
          </div>
        </div>
        <div className="bg-white max-w-5xl mt-4 shadow border w-full">
          <div>
            <div className="m-6 sm:m-8 md:m-10 lg:m-16 print:m-0 print:sm:m-0 print:md:m-0 print:lg:m-0">
              <div className="w-full flex justify-center">
                <img src={logo} alt="" className="h-24 w-24" />
              </div>
              <p className="text-center text-lg font-medium text-red-500">
                {assessment.periodo_nombre}
              </p>
              <p className="text-center text-xl font-bold text-gray-900">
                {assessment.titulo}
              </p>
              <div className="grid grid-cols-2 my-6">
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">NOMBRE: </span>
                    {assessment.nombre_completo}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">PUESTO: </span>
                    {assessment.puesto}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">REGIÓN: </span>
                    {assessmentUser.region}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">ÁREA: </span>
                    {assessmentUser.area}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">EVALUADOR: </span>
                    {assessment.nombre_completo_evaluador}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">
                      FECHA DE REVISIÓN:{" "}
                    </span>
                    {assessment.fecha_revision}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">
                      ¿AUTOEVALUACIÓN FINALIZADA?:{" "}
                    </span>
                    {assessment.autoevaluacion_terminada ? "SÍ" : "NO"}
                  </p>
                </div>
                <div className="col-span-2 sm:col-span-1">
                  <p className="text-gray-900 text-sm print:text-xs mb-1">
                    <span className="text-red-500 font-bold">
                      ¿EVALUACIÓN FINALIZADA?:{" "}
                    </span>
                    {assessment.evaluacion_terminada ? "SÍ" : "NO"}
                  </p>
                </div>
              </div>
              <div className="flex mb-6">
                <div className="flex flex-col items-start">
                  {Number(assessment.tipo_objetivos) === 3 && (
                    <>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Objetivos cumplidos:{" "}
                        </span>
                        {roundPercentage(
                          results?.cumplidos / results?.objetivos_ventas
                        )}{" "}
                        %
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Competencias cumplidas:{" "}
                        </span>
                        <>
                          {roundPercentage(
                            Number(
                              results.secciones_valores?.find(
                                (s) =>
                                  s.seccion_titulo ===
                                  "Competencias del Puesto"
                              ).cumple_evaluacion
                            ) /
                              (Number(
                                results.secciones_valores?.find(
                                  (s) =>
                                    s.seccion_titulo ===
                                    "Competencias del Puesto"
                                ).cumple_evaluacion
                              ) +
                                Number(
                                  results.secciones_valores?.find(
                                    (s) =>
                                      s.seccion_titulo ===
                                      "Competencias del Puesto"
                                  ).excede_evaluacion
                                ) +
                                Number(
                                  results.secciones_valores?.find(
                                    (s) =>
                                      s.seccion_titulo ===
                                      "Competencias del Puesto"
                                  ).insatisfactorio_evaluacion
                                ))
                          )}{" "}
                          %
                        </>
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Cultura cumplidas:{" "}
                        </span>
                        <>
                          {roundPercentage(
                            Number(
                              results.secciones_valores?.find(
                                (s) => s.seccion_titulo === "Cultura 3B"
                              ).cumple_evaluacion
                            ) /
                              (Number(
                                results.secciones_valores?.find(
                                  (s) => s.seccion_titulo === "Cultura 3B"
                                ).cumple_evaluacion
                              ) +
                                Number(
                                  results.secciones_valores?.find(
                                    (s) => s.seccion_titulo === "Cultura 3B"
                                  ).excede_evaluacion
                                ) +
                                Number(
                                  results.secciones_valores?.find(
                                    (s) => s.seccion_titulo === "Cultura 3B"
                                  ).insatisfactorio_evaluacion
                                ))
                          )}{" "}
                          %
                        </>
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Global:{" "}
                        </span>
                        {getGlobalGrade(Number(2))} %
                      </p>
                    </>
                  )}
                  {Number(assessment.tipo_objetivos) === 1 && (
                    <>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Objetivos logrados:{" "}
                        </span>
                        {results.objetivos > 0
                          ? roundPercentage(
                              results.validados / results.objetivos
                            )
                          : "N/A"}{" "}
                        %
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Excede requerimiento:{" "}
                        </span>
                        <>
                          {roundPercentage(
                            Number(results.excede_evaluacion) /
                              (Number(results.cumple_evaluacion) +
                                Number(results.excede_evaluacion) +
                                Number(results.insatisfactorio_evaluacion))
                          )}{" "}
                          %
                        </>
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Cumple requerimiento:{" "}
                        </span>
                        <>
                          {roundPercentage(
                            Number(results.cumple_evaluacion) /
                              (Number(results.cumple_evaluacion) +
                                Number(results.excede_evaluacion) +
                                Number(results.insatisfactorio_evaluacion))
                          )}{" "}
                          %
                        </>
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Insatisfactorio:{" "}
                        </span>
                        <>
                          {roundPercentage(
                            Number(results.insatisfactorio_evaluacion) /
                              (Number(results.cumple_evaluacion) +
                                Number(results.excede_evaluacion) +
                                Number(results.insatisfactorio_evaluacion))
                          )}{" "}
                          %
                        </>
                      </p>
                      <p className="text-gray-900 text-sm print:text-xs mb-1">
                        <span className="text-red-500 font-bold">
                          % Global:{" "}
                        </span>
                        {getGlobalGradeNoOp(Number(2))} %
                      </p>
                    </>
                  )}
                </div>
              </div>
              <div className="w-full flex-col items-start mt-2">
                {[...sections].sort(sortByOrder).map((section) => (
                  <div key={`section-${section.seccion_id}`} className="pb-3">
                    <p className="pb-2 text-left text-xl font-extrabold text-gray-900">
                      {section.title}
                    </p>
                    {section.type === 1 &&
                      section.subsections
                        .sort(sortByOrder)
                        .map((subsection) => (
                          <div
                            className="pb-2"
                            key={`subsection-${subsection.apartado_id}`}
                          >
                            <p className="text-left font-bold text-red-600 mb-1">
                              {subsection.title}
                            </p>
                            <ul className="list-disc px-5 space-y-1">
                              {subsection.questions
                                .sort(sortByOrder)
                                .map((question, index) => (
                                  <li key={`question-${question.pregunta_id}`}>
                                    <p className="text-sm print:text-xs font-medium">
                                      {question.title}
                                    </p>
                                    {
                                      {
                                        1: (
                                          <p className="text-sm print:text-xs font-light">
                                            {answers[question.pregunta_id] && (
                                              <>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo_evaluador}</span>: {answers[question.pregunta_id][2]?.respuesta_texto}
                                                </p>
                                                <p className="">
                                                <span className="underline">{assessment.nombre_completo}</span>: {answers[question.pregunta_id][1]?.respuesta_texto}
                                                </p>
                                              </>
                                            )}
                                          </p>
                                        ),
                                        2: (
                                          <p className="text-sm print:text-xs font-light">
                                            {answers[question.pregunta_id] && (
                                              <>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo_evaluador}</span>:
                                                  {
                                                    question.answers.find(
                                                      (a) =>
                                                        a.value ===
                                                        answers[
                                                          question.pregunta_id
                                                        ][2]?.valor_pregunta
                                                    )?.title
                                                  }
                                                </p>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo}</span>:
                                                  {
                                                      question.answers.find(
                                                        (a) =>
                                                          a.value ===
                                                          answers[
                                                            question.pregunta_id
                                                          ][1]?.valor_pregunta
                                                      )?.title
                                                    }
                                                </p>
                                              </>
                                            )}
                                          </p>
                                        ),
                                        3: (
                                          <p className="text-sm print:text-xs font-light">
                                            {answers[question.pregunta_id] && (
                                              <>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo_evaluador}</span>:
                                                  {answers[question.pregunta_id][2]?.respuesta_switch ? (
                                                    <span className="text-green-600 font-medium">
                                                      Sí
                                                    </span>
                                                  ) : (
                                                    <span className="text-red-600 font-medium">
                                                      No
                                                    </span>
                                                  )}
                                                  .
                                                  {` ${
                                                    answers[question.pregunta_id][2]?.respuesta_texto
                                                  }`}
                                                </p>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo}</span>:
                                                  {answers[question.pregunta_id][1]?.respuesta_switch ? (
                                                    <span className="text-green-600 font-medium">
                                                      Sí
                                                    </span>
                                                  ) : (
                                                    <span className="text-red-600 font-medium">
                                                      No
                                                    </span>
                                                  )}
                                                  .
                                                  {` ${
                                                    answers[question.pregunta_id][1]?.respuesta_texto
                                                  }`}
                                                </p>
                                              </>
                                            )}
                                          </p>
                                        ),
                                        4: (
                                          <p className="text-sm print:text-xs font-light">
                                            {answers[question.pregunta_id] &&
                                              answers[question.pregunta_id]
                                                .respuesta_etiquetas && (
                                                <span>
                                                  {answers[
                                                    question.pregunta_id
                                                  ].respuesta_etiquetas.join()}
                                                </span>
                                              )}
                                          </p>
                                        ),
                                        5: (
                                          <p className="text-sm print:text-xs font-light">
                                            {answers[question.pregunta_id] && (
                                              <>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo_evaluador}</span>:
                                                  <b>
                                                    {getLabelAnswers(question,2)}
                                                  </b>
                                                  {
                                                    question.answers.find(
                                                      (a) =>
                                                        a.value ===
                                                        answers[
                                                          question.pregunta_id
                                                        ][2]?.valor_pregunta
                                                    )?.title
                                                  }
                                                </p>
                                                <p className="">
                                                  <span className="underline">{assessment.nombre_completo}</span>:
                                                  <b>
                                                    {getLabelAnswers(question,1)}
                                                  </b>
                                                  {
                                                      question.answers.find(
                                                        (a) =>
                                                          a.value ===
                                                          answers[
                                                            question.pregunta_id
                                                          ][1]?.valor_pregunta
                                                      )?.title
                                                    }
                                                </p>
                                              </>
                                            )}
                                          </p>
                                        ),
                                      }[question.type]
                                    }
                                  </li>
                                ))}
                            </ul>
                          </div>
                        ))}
                    {[2, 99].includes(section.type) &&
                      pastObjectives.map((objective) => (
                        <div
                          key={`key-objective-${objective.objetivo_id}`}
                          className="mb-4"
                        >
                          <div>
                            <p className="font-semibold text-gray-900 print:text-sm">
                              {objective.titulo}
                            </p>
                            <p className="font-regular text-gray-600 text-sm underline mb-1">
                              Fecha compromiso: {objective.fecha_entrega}
                            </p>
                            <p className="font-regular text-gray-800 text-sm">
                              {objective.descripcion}
                            </p>
                            <p
                              className={`text-sm font-semibold flex items-center ${
                                objective.estatus_objetivo_id == 4
                                  ? "text-green-600"
                                  : "text-red-600"
                              }`}
                            >
                              {objective.estatus_objetivo_id == 4 ? (
                                <CheckCircleIcon className="h-5 w-5 mr-1" />
                              ) : (
                                <XCircleIcon className="h-5 w-5 mr-1" />
                              )}
                              {getObjectiveStatus(
                                objective.estatus_objetivo_id
                              )}
                            </p>
                          </div>
                        </div>
                      ))}
                    {/* {[3].includes(section.type) &&
                      nextObjectives.map((objective, nextIndex) => (
                        <div
                          key={`next-objective-${nextIndex}`}
                          className="mb-4"
                        >
                          <div>
                            {objective.tipo_objetivo_id === 2 ? (
                              <p className="font-medium text-gray-500 text-sm">
                                Propuesto por{" "}
                                {assessment.nombre_completo_evaluador}
                              </p>
                            ) : (
                              <p className="font-medium text-gray-500 text-sm">
                                Propuesto por {assessment.nombre_completo}
                              </p>
                            )}
                            <p className="font-semibold text-gray-900 print:text-sm">
                              {objective.titulo}
                            </p>
                            <p className="font-regular text-gray-600 text-sm underline mb-1">
                              Fecha compromiso: {objective.fecha_entrega}
                            </p>
                            <p className="font-regular text-gray-800 text-sm">
                              {objective.descripcion}
                            </p>
                          </div>
                        </div>
                      ))} */}
                    {[3, 33].includes(section.type) &&
                      nextObjectives.map((objective, nextIndex) => (
                        <div
                          key={`next-objective-${nextIndex}`}
                          className="mb-4"
                        >
                          <div>
                            {objective.tipo_objetivo_id === 2 ? (
                              <p className="font-medium text-gray-500 text-sm">
                                Propuesto por{" "}
                                {assessment.nombre_completo_evaluador}
                              </p>
                            ) : (
                              <p className="font-medium text-gray-500 text-sm">
                                Propuesto por {assessment.nombre_completo}
                              </p>
                            )}
                            <p className="font-semibold text-gray-900 print:text-sm">
                              {objective.titulo}
                            </p>
                            <p className="font-regular text-gray-600 text-sm underline mb-1">
                              Fecha compromiso: {objective.fecha_entrega}
                            </p>
                            <p className="font-regular text-gray-800 text-sm">
                              {objective.descripcion}
                            </p>
                          </div>
                        </div>
                      ))}
                    {section.type === 4 && (
                      <div className="m-0 overflow-x-auto">
                        <table className="min-w-full divide-y divide-gray-300 border mb-2">
                          <thead className="bg-gray-50">
                            <tr className="divide-x divide-gray-200">
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 print:text-xs"
                              >
                                Objetivo
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                              >
                                Esperado
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                              >
                                Real
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center text-sm font-semibold text-gray-900 print:text-xs"
                              >
                                Resultado
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-left text-sm font-semibold text-gray-900 print:text-xs"
                              >
                                Comentarios
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {salesAnswers.map((sale) => (
                              <tr
                                key={`key-sale-objective-${sale.objetivo_venta_id}`}
                                className="divide-x divide-gray-200"
                              >
                                <td className="p-2 text-sm font-medium text-gray-900 print:text-xs">
                                  {sale.titulo}
                                </td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">
                                  {checkForRound(sale.valor_esperado)}
                                </td>
                                <td className="p-2 text-sm text-gray-900 text-center print:text-xs">
                                  {checkForRound(sale.valor_real)}
                                </td>
                                <td
                                  className={`whitespace-nowrap p-2 text-sm text-center font-medium print:text-xs ${
                                    sale.cumplido === "Cumplido"
                                      ? "text-green-600"
                                      : "text-red-600"
                                  }`}
                                >
                                  {sale.cumplido}
                                </td>
                                <td className="p-2 text-sm text-gray-900 sm:pr-6 print:text-xs">
                                  <p className="">
                                    {assessment.nombre_completo}: {sale.comentarios_autoevaluacion}
                                  </p>
                                  <p className="">
                                    {assessment.nombre_completo_evaluador}: {sale.comentarios}
                                  </p>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    )}
                    {[5,6].includes(section.type) && (
                      <ul className="list-disc px-5 space-y-1">
                        {plans.filter((a) => a.is_active).map((action, actionIdx) => (
                          <li
                            key={`key-development-${action.accion_id}`}
                          >
                            <p className="text-left font-bold text-xs text-gray-900">ACCIÓN {actionIdx + 1}</p>
                            {
                              Number(action.tipo_plan) === 1 ? (
                                <p className="font-medium text-gray-500 text-sm">
                                  Propuesto por {assessment.nombre_completo_evaluador}
                                </p>
                              ) : (
                                <p className="font-medium text-gray-500 text-sm">
                                  Propuesto por {assessment.nombre_completo}
                                </p>
                              )
                            }
                            <p className="font-regular text-gray-800 text-sm">{action.descripcion}</p>
                            <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {action.fecha_entrega}</p>
                          </li>
                        ))}
                      </ul>
                    )}
                    {[8].includes(section.type) && <table className="w-full break-inside-avoid">
                      <tbody>
                        {nextProjects.map((objective, nextIndex) => (
                          <tr key={`next-project-${nextIndex}`} className="border border-gray-400 break-inside-avoid">
                            <td className="p-4 break-inside-avoid">
                              <p className="text-left font-bold text-red-500">PROYECTO {nextIndex + 1}</p>
                              <p className="font-semibold text-gray-900 print:text-sm">{objective.titulo}</p>
                              <p className="font-regular text-red-500 text-sm"><span className="font-medium underline">Valor:</span>&nbsp;{objective.valor}%</p>
                              <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {objective.semestre}{objective.semestre==1 ? "er" : "do"} Semestre del 2024</p>
                              <p className="text-left font-bold text-xs text-gray-900 underline">RESULTADOS CLAVE</p>
                              <ul className="list-disc pl-4 text-xs text-gray-900">
                                  {(objective.krs.map((kr)=>(
                                      <li>{kr.titulo}</li>
                                  )))}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>}
                    {[9].includes(section.type) && <table className="w-full break-inside-avoid">
                      <tbody>
                        {pastProjects.map((objective, pastIndex) => (
                          <tr key={`past-project-${pastIndex}`} className="border border-gray-400 break-inside-avoid">
                            <td className="p-4 break-inside-avoid">
                              <p className="text-left font-bold text-red-500">PROYECTO {pastIndex + 1}</p>
                              <p className="font-semibold text-gray-900 print:text-sm">{objective.titulo}</p>
                              <div className="flex space-x-4">
                                <p className="font-regular text-red-500 text-sm"><span className="font-medium underline">Valor:</span>&nbsp;{objective.valor}%</p>
                                <p className="font-regular text-red-500 text-sm"><span className="font-medium underline">Logrado:</span>&nbsp;{objective.logrado}%</p>
                              </div>
                              <p className="font-regular text-gray-600 text-sm underline mb-1">Fecha límite de cumplimiento: {objective.semestre}{objective.semestre==1 ? "er" : "do"} Semestre del 2023</p>
                              <p className="text-left font-bold text-xs text-gray-900 underline">RESULTADOS CLAVE</p>
                              <ul className="list-disc pl-4 text-xs text-gray-900">
                                  {(objective.krs.map((kr)=>(
                                      <li>{kr.titulo}</li>
                                  )))}
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>}
                  </div>
                ))}
              </div>
              <div className="w-full flex-col items-start mt-24">
                <div className="grid grid-cols-2 gap-10">
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-center text-sm text-gray-900 font-bold border-t">
                      {assessment.nombre_completo}
                    </p>
                    <p className="text-center text-xs text-gray-900">
                      {assessment.puesto}
                    </p>
                  </div>
                  <div className="col-span-2 sm:col-span-1">
                    <p className="text-center text-sm text-gray-900 font-bold border-t">
                      {assessment.nombre_completo_evaluador}
                    </p>
                    <p className="text-center text-xs text-gray-900">
                      {assessment.puesto_evaluador}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Notification
          title={errorTitle}
          message={error}
          type="danger"
          ref={notificationRef}
        />
    </>
  );
};

export default Feedback;
