import { useEffect, useRef, useState } from "react";
import Notification from "../../components/notifications/Notification";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { getDateLabel, months_db_es } from "../../utils/date_utils";
import GlobalObjectives from "../../components/dashboard/GlobalObjectives";
import BarChartObjectivesAchieved from "../../components/dashboard/BarChartObjectivesAchieved";
import { base64StringToBlob } from "blob-util";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import ObjectivesReports from "../../components/dashboard/ObjectivesReports";

export default function Progress() {
  const { user, token } = useSelector((state) => state.authentication);
  const [yearsOptions, setYearsOptions] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [loadingReportIntegral, setLoadingReportIntegral] = useState(false);
  const [loadingReportAvances, setLoadingReportAvances] = useState(false);
  const [loadingReportPDF, setLoadingReportPDF] = useState(false);
  const [period, setPeriod] = useState(null);
  const [regions, setRegions] = useState([]);
  const [region, setRegion] = useState(0);
  const [areas, setAreas] = useState([]);
  const [area, setArea] = useState("0");
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();

  useEffect(() => {
    if(user?.certificados?.filter(c => c.cat_permiso_id == 6 && c.estatus == 1)?.length < 1){
      setRegion(user.region_id);
    }
    let today = new Date();
    let yearNow = today.getFullYear();
    let yearIterator = yearNow;
    let years = [];
    while (yearIterator >= 2018) {
      years.push(yearIterator);
      yearIterator -= 1;
    }
    setYearsOptions(years);
    setSelectedYear(years[0]);
    const options = { headers: { Authorization: token } };
    API.get("/regions", options).then(({ data, status }) => {
      if (status === 200) {
        setRegions(
          data.map((f) => {
            f.id = f.region_id;
            f.label = f.region_nombre;
            return f;
          })
        );
      }
    });
    API.get("/areas", options).then(({ data, status }) => {
      if (status === 200) {
        setAreas(
          data.map((f) => {
            f.id = f.area_id;
            f.label = f.area_nombre;
            return f;
          })
        );
      }
    });
  }, []);

  const downloadFileIntegral = () => {
    let params = {
      periodo: selectedYear,
      area: area,
      region: region,
    };
    const headers = { Authorization: token };
    setLoadingReportIntegral(true);
    API.get("/objectives/results/file", {
      params,
      headers,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingReportIntegral(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `Objetivos_${selectedYear}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportIntegral(false);
      });
  };

  const downloadFileAvances = () => {
    let params = {
      year: selectedYear,
      month: selectedMonth,
      area: area,
      region: region,
    };
    const headers = { Authorization: token };
    setLoadingReportAvances(true);
    API.get("/objectives/results/avances", {
      params,
      headers,
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingReportAvances(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", `Avances_${selectedYear}_${selectedMonth==0 ? "TLM" : months_db_es[selectedMonth]}.xlsx`);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportAvances(false);
      });
  };

  const downloadFilePDF = () => {
    let params = {
      periodo: selectedYear,
      region_id: region
    };
    const headers = { Authorization: token };
    setLoadingReportPDF(true);
    API.get("/objectives/results/pdf", {
      params,
      headers,
      responseType:
        "application/pdf",
    })
      .then((response) => {
        setLoadingReportPDF(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/pdf"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `ProgresoObjetivos_${selectedYear}_${regions.find((r) => r.region_id == region)?.region_nombre}.pdf`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingReportPDF(false);
      });
  };

  const superAdministrador =
    user?.certificados?.filter((c) => c.cat_permiso_id == 6 && c.estatus == 1)
      ?.length > 0;

  return (
    <>
      <div className="flex-1 max-w-full">
        <main className="py-8">
          <div className="px-4 sm:px-6 lg:px-8">
            <div>
              <>
                <section aria-labelledby="profile-overview-title">
                  <div className="overflow-hidden rounded-lg bg-white shadow">
                    <h2 className="sr-only" id="profile-overview-title">
                      Reportes de administración
                    </h2>
                    <div className="border-t border-gray-200 bg-white px-6 py-5 text-center text-sm font-medium">
                      <nav
                        className="flex flex-col items-center justify-center"
                        aria-label="Progress"
                      >
                        <select
                          id="periods"
                          name="periods"
                          value={selectedYear}
                          className="rounded-md border-gray-300 py-1 pl-3 pr-10 text-xl font-bold uppercase focus:border-indigo-500 focus:outline-none focus:ring-indigo-500"
                          onChange={(e) => setSelectedYear(e.target.value)}
                        >
                          {yearsOptions.map((p) => (
                            <option key={`key-option-year-${p}`} value={p}>
                              {p}
                            </option>
                          ))}
                        </select>
                        <p className="mt-1 text font-light">
                          <span className="font-bold text-gray-500">
                            Del {getDateLabel(new Date(selectedYear, 0, 1))} al{" "}
                            {getDateLabel(new Date(selectedYear, 11, 31))}
                          </span>
                        </p>
                      </nav>
                    </div>
                  </div>
                </section>
                <section className="my-4 w-full grid grid-cols-1 sm:grid-cols-4 gap-4">
                  <div className="mb-4 col-span-1 sm:col-span-2">
                    <label
                      htmlFor="question-title"
                      className="block text-xs text-red-500"
                    >
                      REGION
                    </label>
                    <div className="mt-1">
                      <div>
                        {superAdministrador ? (
                          <select
                            id="location"
                            name="location"
                            value={region}
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                            onChange={(e) => setRegion(Number(e.target.value))}
                          >
                            <option value={0}>TODAS LAS REGIONES</option>
                            {regions.map((r) => (
                              <option
                                key={`key-option-region-${r.region_id}`}
                                value={r.region_id}
                              >
                                {r.region_nombre}
                              </option>
                            ))}
                          </select>
                        ) : (
                          <p>{user.region}</p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mb-4 col-span-1 sm:col-span-2">
                    <label
                      htmlFor="question-title"
                      className="block text-xs text-red-500"
                    >
                      AREA
                    </label>
                    <div className="mt-1">
                      <div>
                        <select
                          id="location"
                          name="location"
                          value={area}
                          className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                          onChange={(e) => setArea(e.target.value)}
                        >
                          <option value={0}>TODAS LAS AREAS</option>
                          {areas.map((r) => (
                            <option
                              key={`key-option-area-${r.area_id}`}
                              value={r.area_codigo}
                            >
                              {r.area_nombre}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </section>
                <section className="my-4 w-full grid grid-cols-1 sm:grid-cols-8 gap-4">
                  <button
                    type="button"
                    onClick={downloadFileIntegral}
                    disabled={loadingReportIntegral}
                    className="col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 text-sm font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  >
                    <ArrowDownTrayIcon
                      className={`h-5 w-5 mr-1 ${
                        loadingReportIntegral ? "animate-bounce" : ""
                      }`}
                    />
                    {loadingReportIntegral
                      ? "Descargando archivo"
                      : "Descargar Registros"}
                  </button>
                  <button
                    type="button"
                    onClick={downloadFilePDF}
                    disabled={loadingReportPDF || region == 0}
                    className="col-span-2 inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 text-sm font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <ArrowDownTrayIcon
                      className={`h-5 w-5 mr-1 ${
                        loadingReportPDF ? "animate-bounce" : ""
                      }`}
                    />
                    {loadingReportPDF
                      ? "Descargando archivo"
                      : "Descargar PDF"}
                  </button>
                  <span className="col-span-2 inline-flex rounded-md shadow-sm">
                    <button
                      type="button"
                      onClick={downloadFileAvances}
                      disabled={loadingReportAvances}
                      className="inline-flex w-full items-center justify-center rounded-l-md border border-transparent bg-blue-100 px-4 py-2 text-sm font-medium text-blue-700 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    >
                      {loadingReportAvances
                        ? "Descargando"
                        : "Avances"}
                    </button>
                    <select
                      id="message-type"
                      name="message-type"
                      value={selectedMonth}
                      onChange={e => setSelectedMonth(e.target.value)}
                      className="-ml-px block rounded-l-none rounded-r-md border-0 bg-white py-1.5 pl-3 pr-9 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                    >
                      <option value={0}>Todos los meses</option>
                      <option value={1}>Enero</option>
                      <option value={2}>Febrero</option>
                      <option value={3}>Marzo</option>
                      <option value={4}>Abril</option>
                      <option value={5}>Mayo</option>
                      <option value={6}>Junio</option>
                      <option value={7}>Julio</option>
                      <option value={8}>Agosto</option>
                      <option value={9}>Septiembre</option>
                      <option value={10}>Octubre</option>
                      <option value={11}>Noviembre</option>
                      <option value={12}>Diciembre</option>
                    </select>
                  </span>
                </section>
                {superAdministrador && <ObjectivesReports period={selectedYear}/>}
                <>
                  <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
                    <GlobalObjectives
                      period={selectedYear}
                      region={region}
                      area={area}
                    />
                    <BarChartObjectivesAchieved
                      period={selectedYear}
                      region={region}
                      area={area}
                    />
                  </dl>
                </>
              </>
            </div>
          </div>
        </main>
      </div>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </>
  );
}
