export const profit_centers = [
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "26",
    tienda: "LaDalia",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "67",
    tienda: "Molinito",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "82",
    tienda: "Zomeyucan",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "232",
    tienda: "SantiagoAhuizotla",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "277",
    tienda: "Ahuizotla",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "305",
    tienda: "Zomeyucan2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "145",
    gd: "Jose Luis Zavala Sanchez",
    clave_tienda: "1795",
    tienda: "5Ahuizotla2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "157",
    gd: "Ariel Rodriguez Otero",
    clave_tienda: "36",
    tienda: "Belisario",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "157",
    gd: "Ariel Rodriguez Otero",
    clave_tienda: "164",
    tienda: "JimenezCantu",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "157",
    gd: "Ariel Rodriguez Otero",
    clave_tienda: "229",
    tienda: "SanJosedelJaral",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "157",
    gd: "Ariel Rodriguez Otero",
    clave_tienda: "387",
    tienda: "Huizache",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "157",
    gd: "Ariel Rodriguez Otero",
    clave_tienda: "863",
    tienda: "Fresnos",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "157",
    gd: "Ariel Rodriguez Otero",
    clave_tienda: "1746",
    tienda: "6UAMAtizapan",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "297",
    gd: "Nayeli  Adriana Guerrero Castillo",
    clave_tienda: "253",
    tienda: "LaQuebrada2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "297",
    gd: "Nayeli  Adriana Guerrero Castillo",
    clave_tienda: "526",
    tienda: "LaBlanca",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "297",
    gd: "Nayeli  Adriana Guerrero Castillo",
    clave_tienda: "977",
    tienda: "Toltecas",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "297",
    gd: "Nayeli  Adriana Guerrero Castillo",
    clave_tienda: "1437",
    tienda: "7TlalnepantlaCent",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "297",
    gd: "Nayeli  Adriana Guerrero Castillo",
    clave_tienda: "1447",
    tienda: "7Tequexquinahuac2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "308",
    gd: "Eugenio Garcia Silva",
    clave_tienda: "567",
    tienda: "Caracoles2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "308",
    gd: "Eugenio Garcia Silva",
    clave_tienda: "855",
    tienda: "CuautepecBarrioBa",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "308",
    gd: "Eugenio Garcia Silva",
    clave_tienda: "1712",
    tienda: "2GuadalupeVictori",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "308",
    gd: "Eugenio Garcia Silva",
    clave_tienda: "1747",
    tienda: "7Caracoles3",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "382",
    gd: "Ana Gabriela Armeida Cortes",
    clave_tienda: "6",
    tienda: "Chalma",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "382",
    gd: "Ana Gabriela Armeida Cortes",
    clave_tienda: "94",
    tienda: "Progreso",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "382",
    gd: "Ana Gabriela Armeida Cortes",
    clave_tienda: "157",
    tienda: "Ixtacala",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "382",
    gd: "Ana Gabriela Armeida Cortes",
    clave_tienda: "734",
    tienda: "SanLucasPatoni",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "382",
    gd: "Ana Gabriela Armeida Cortes",
    clave_tienda: "1207",
    tienda: "7Tenayuca",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "382",
    gd: "Ana Gabriela Armeida Cortes",
    clave_tienda: "1474",
    tienda: "4PiramideDeTeotih",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "494",
    gd: "Diana Berenice Garcia Munoz",
    clave_tienda: "64",
    tienda: "SanLorenzo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "494",
    gd: "Diana Berenice Garcia Munoz",
    clave_tienda: "269",
    tienda: "SanLorenzo2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "494",
    gd: "Diana Berenice Garcia Munoz",
    clave_tienda: "472",
    tienda: "FelipeAngeles",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "494",
    gd: "Diana Berenice Garcia Munoz",
    clave_tienda: "1185",
    tienda: "5Calacoaya",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "494",
    gd: "Diana Berenice Garcia Munoz",
    clave_tienda: "1534",
    tienda: "4MercedAtizapan",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "494",
    gd: "Diana Berenice Garcia Munoz",
    clave_tienda: "1650",
    tienda: "0SanJuanBosco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "495",
    gd: "Mirna Janeth Islas Aparicio",
    clave_tienda: "55",
    tienda: "SanJuanico",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "495",
    gd: "Mirna Janeth Islas Aparicio",
    clave_tienda: "135",
    tienda: "SanJuanico2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "495",
    gd: "Mirna Janeth Islas Aparicio",
    clave_tienda: "1191",
    tienda: "1CalzadaMisterios",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "495",
    gd: "Mirna Janeth Islas Aparicio",
    clave_tienda: "1345",
    tienda: "5LaVilla",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "495",
    gd: "Mirna Janeth Islas Aparicio",
    clave_tienda: "1711",
    tienda: "1LindavistaVallej",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "509",
    gd: "Rosa Isela Perez Cordero",
    clave_tienda: "87",
    tienda: "LaHiguera",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "509",
    gd: "Rosa Isela Perez Cordero",
    clave_tienda: "587",
    tienda: "LasTorres",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "509",
    gd: "Rosa Isela Perez Cordero",
    clave_tienda: "719",
    tienda: "Durango",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "509",
    gd: "Rosa Isela Perez Cordero",
    clave_tienda: "1316",
    tienda: "6AtizapanCentro",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "509",
    gd: "Rosa Isela Perez Cordero",
    clave_tienda: "1561",
    tienda: "1CalzadaSanMateo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "509",
    gd: "Rosa Isela Perez Cordero",
    clave_tienda: "1894",
    tienda: "4PrimeroDeSeptiem",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "58",
    gd: "Yanin V. Esquivel Avila",
    clave_tienda: "3",
    tienda: "Charco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "58",
    gd: "Yanin V. Esquivel Avila",
    clave_tienda: "10",
    tienda: "Cuautepec",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "58",
    gd: "Yanin V. Esquivel Avila",
    clave_tienda: "121",
    tienda: "Forestal",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "58",
    gd: "Yanin V. Esquivel Avila",
    clave_tienda: "142",
    tienda: "Cuautepec2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "58",
    gd: "Yanin V. Esquivel Avila",
    clave_tienda: "187",
    tienda: "JuventinoRosas",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "85",
    gz: "Camilo Andres Alvarado Torres",
    clave_distrito: "58",
    gd: "Yanin V. Esquivel Avila",
    clave_tienda: "590",
    tienda: "LaBrecha",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "246",
    gd: "Nataly Mendoza Melo",
    clave_tienda: "270",
    tienda: "Yancuitlalpan",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "246",
    gd: "Nataly Mendoza Melo",
    clave_tienda: "368",
    tienda: "SanFernando",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "246",
    gd: "Nataly Mendoza Melo",
    clave_tienda: "594",
    tienda: "SanMiguelXochiman",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "246",
    gd: "Nataly Mendoza Melo",
    clave_tienda: "806",
    tienda: "Huixquilucan",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "246",
    gd: "Nataly Mendoza Melo",
    clave_tienda: "1544",
    tienda: "4SanJoseDelJaral2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "246",
    gd: "Nataly Mendoza Melo",
    clave_tienda: "1876",
    tienda: "6Xochimanga2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "256",
    gd: "Juana Perez Aniceto",
    clave_tienda: "88",
    tienda: "Totolinga",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "256",
    gd: "Juana Perez Aniceto",
    clave_tienda: "252",
    tienda: "LomaColorada",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "256",
    gd: "Juana Perez Aniceto",
    clave_tienda: "288",
    tienda: "Totolinga2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "256",
    gd: "Juana Perez Aniceto",
    clave_tienda: "312",
    tienda: "LasHuertas2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "256",
    gd: "Juana Perez Aniceto",
    clave_tienda: "920",
    tienda: "LasHuertas3",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "256",
    gd: "Juana Perez Aniceto",
    clave_tienda: "1904",
    tienda: "4SanLuisTlatilco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "324",
    gd: "Miguel Angel Francisco Sanchez",
    clave_tienda: "17",
    tienda: "Mazapil",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "324",
    gd: "Miguel Angel Francisco Sanchez",
    clave_tienda: "54",
    tienda: "Mazapil2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "324",
    gd: "Miguel Angel Francisco Sanchez",
    clave_tienda: "189",
    tienda: "Chamizal",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "324",
    gd: "Miguel Angel Francisco Sanchez",
    clave_tienda: "201",
    tienda: "Carrusel",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "324",
    gd: "Miguel Angel Francisco Sanchez",
    clave_tienda: "455",
    tienda: "ElCadete",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "390",
    gd: "Rocio Ramos Ruvalcaba",
    clave_tienda: "45",
    tienda: "Santiago",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "390",
    gd: "Rocio Ramos Ruvalcaba",
    clave_tienda: "531",
    tienda: "FederalBurocratic",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "390",
    gd: "Rocio Ramos Ruvalcaba",
    clave_tienda: "1181",
    tienda: "1Country",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "390",
    gd: "Rocio Ramos Ruvalcaba",
    clave_tienda: "1335",
    tienda: "5PaloSolo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "447",
    gd: "Marco Antonio Julian Luis",
    clave_tienda: "192",
    tienda: "PradesdeSanMateo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "447",
    gd: "Marco Antonio Julian Luis",
    clave_tienda: "333",
    tienda: "ElTejocote",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "447",
    gd: "Marco Antonio Julian Luis",
    clave_tienda: "399",
    tienda: "Penitas",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "447",
    gd: "Marco Antonio Julian Luis",
    clave_tienda: "450",
    tienda: "MargaritaMaza",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "447",
    gd: "Marco Antonio Julian Luis",
    clave_tienda: "810",
    tienda: "SanMateoNopala",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "447",
    gd: "Marco Antonio Julian Luis",
    clave_tienda: "1482",
    tienda: "2AvDelRio",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "464",
    gd: "Leonardo Alavez Ignacio",
    clave_tienda: "265",
    tienda: "ElCoyote",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "464",
    gd: "Leonardo Alavez Ignacio",
    clave_tienda: "413",
    tienda: "LosCuartos",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "464",
    gd: "Leonardo Alavez Ignacio",
    clave_tienda: "414",
    tienda: "LosArcos",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "464",
    gd: "Leonardo Alavez Ignacio",
    clave_tienda: "425",
    tienda: "LaMancha",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "464",
    gd: "Leonardo Alavez Ignacio",
    clave_tienda: "764",
    tienda: "LosArcos2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "529",
    gd: "Beatriz Gomez Vasquez",
    clave_tienda: "671",
    tienda: "SanLucasTepetlaca",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "529",
    gd: "Beatriz Gomez Vasquez",
    clave_tienda: "1179",
    tienda: "9SantaMonica",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "529",
    gd: "Beatriz Gomez Vasquez",
    clave_tienda: "1401",
    tienda: "1AmpliacionXalpa",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "529",
    gd: "Beatriz Gomez Vasquez",
    clave_tienda: "1664",
    tienda: "4ValleDeSantaMoni",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "529",
    gd: "Beatriz Gomez Vasquez",
    clave_tienda: "1703",
    tienda: "3CETRAM4Caminos",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "530",
    gd: "Daniela Santos Chavez",
    clave_tienda: "33",
    tienda: "Guadalupe",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "530",
    gd: "Daniela Santos Chavez",
    clave_tienda: "295",
    tienda: "Chamapa",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "530",
    gd: "Daniela Santos Chavez",
    clave_tienda: "443",
    tienda: "Guadalupe2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "530",
    gd: "Daniela Santos Chavez",
    clave_tienda: "841",
    tienda: "Chamapa2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "530",
    gd: "Daniela Santos Chavez",
    clave_tienda: "1388",
    tienda: "8LomasDeSanAgusti",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "32",
    gz: "Jesus G. Hernandez Aguilar",
    clave_distrito: "530",
    gd: "Daniela Santos Chavez",
    clave_tienda: "1696",
    tienda: "6NaucalpanCentro",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "24",
    tienda: "LagoGarda",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "44",
    tienda: "Tacuba",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "66",
    tienda: "MartiresRioBlanco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "132",
    tienda: "MarMediterraneo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "674",
    tienda: "Claveria",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "1117",
    tienda: "7Tacuba2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "207",
    gd: "Carlos Alberto Romero Chavez",
    clave_tienda: "1152",
    tienda: "2Tacuba3",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "71",
    tienda: "Pensil",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "191",
    tienda: "NuevaSantaMaria",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "575",
    tienda: "Cedros",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "629",
    tienda: "Pensil2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "776",
    tienda: "NvaSantaMaria2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "892",
    tienda: "AvenidaGranjas",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "273",
    gd: "Gerardo Alonso Castella",
    clave_tienda: "1842",
    tienda: "2SantaBarbaraAzca",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "352",
    gd: "Yasmin Anaid Pichardo Martinez",
    clave_tienda: "1",
    tienda: "Prohogar",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "352",
    gd: "Yasmin Anaid Pichardo Martinez",
    clave_tienda: "91",
    tienda: "Anahuac",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "352",
    gd: "Yasmin Anaid Pichardo Martinez",
    clave_tienda: "151",
    tienda: "Anahuac2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "352",
    gd: "Yasmin Anaid Pichardo Martinez",
    clave_tienda: "573",
    tienda: "LeonCavallo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "352",
    gd: "Yasmin Anaid Pichardo Martinez",
    clave_tienda: "979",
    tienda: "Esterlinas",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "352",
    gd: "Yasmin Anaid Pichardo Martinez",
    clave_tienda: "1145",
    tienda: "5Prohogar2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "448",
    gd: "Jessica Valadez Montes de Oca",
    clave_tienda: "576",
    tienda: "Oriente91",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "448",
    gd: "Jessica Valadez Montes de Oca",
    clave_tienda: "781",
    tienda: "SanJuanTlihuaca",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "448",
    gd: "Jessica Valadez Montes de Oca",
    clave_tienda: "1271",
    tienda: "1ElTenayo",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "448",
    gd: "Jessica Valadez Montes de Oca",
    clave_tienda: "1298",
    tienda: "8Azcapotzalco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "448",
    gd: "Jessica Valadez Montes de Oca",
    clave_tienda: "1336",
    tienda: "6CalzCamarones",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "448",
    gd: "Jessica Valadez Montes de Oca",
    clave_tienda: "1757",
    tienda: "7AzcapotzalcoCent",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "468",
    gd: "Alejandro Perez Carreno",
    clave_tienda: "15",
    tienda: "RioBlanco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "468",
    gd: "Alejandro Perez Carreno",
    clave_tienda: "76",
    tienda: "ZonaEscolar",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "468",
    gd: "Alejandro Perez Carreno",
    clave_tienda: "108",
    tienda: "NvaTenochtitlan",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "468",
    gd: "Alejandro Perez Carreno",
    clave_tienda: "328",
    tienda: "JorgeNegrete",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "468",
    gd: "Alejandro Perez Carreno",
    clave_tienda: "338",
    tienda: "ZonaEscolar2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "23",
    tienda: "Zacatenco",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "114",
    tienda: "LaPresa",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "238",
    tienda: "Ticoman",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "314",
    tienda: "Caracoles",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "588",
    tienda: "Ticoman2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "784",
    tienda: "Cardenales",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
  {
    region_id: 1002,
    region: "Barrientos",
    clave_zona: "92",
    gz: "Juan M. Cuadros Munoz",
    clave_distrito: "54",
    gd: "Karla B. Balino Segundo",
    clave_tienda: "1526",
    tienda: "6LaPresa2",
    almacen: "1002Barrientos",
    estado: "Abierta",
  },
];

export const timeOptionsAssessments = [
	{
		id: 1,
		label: 'Mayor a'
	},
	{
		id: 2,
		label: 'Menor a'
	}
]

export const timeLapseOptions = [
	{
		id: 1,
		label: 'Meses'
	}
]