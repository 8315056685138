import React from 'react';
import { Tree, TreeNode } from 'react-organizational-chart';
import OrganizationCard from '../cards/OrganizationCard';
const RecursiveTreeNode = ({ colaboradores }) => {
  return (
    <>
      {
        colaboradores.map((colaborador) => (
          colaborador.tieneColaboradores
          ? <TreeNode key={colaborador.numero_empleado} className={`li-${colaborador.numero_empleado}`} label={<OrganizationCard user={{...colaborador}} />}>
              <RecursiveTreeNode tieneColaboradores={colaborador.tieneColaboradores} colaboradores={[...colaborador.colaboradores]} />
            </TreeNode>
          : <TreeNode key={colaborador.numero_empleado} className={`li-${colaborador.numero_empleado}`} label={<OrganizationCard className="last" user={{...colaborador}} />} />
        ))
      }
    </>
  )
}

const OrganizationSection = ({ data }) => (
  <Tree label={<OrganizationCard user={data} className='parent-node'/>}>
    {data.tieneColaboradores && <RecursiveTreeNode colaboradores={[...data.colaboradores]} />}
  </Tree>
);

OrganizationSection.defaultProps = {
  data: {}
}


export default OrganizationSection;