import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import { useState } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "../../constants/functions";
import { getDateLabel, getDateTimeLabel, months_es } from "../../utils/date_utils";
import CommonModalTemplate from "../modals/CommonModalTemplate";

const HomeEmployeeAssessment = ({ id, status, title, name, startDate, endDate, autoevaluacion, evaluacion, feedback, feedbackDate, feedbackEnded,evaluacion_iniciada, onlySelf, onlySuperior, self  }) => {
  let cannotDoAssessment = useRef();
  let [assessmentErrorTitle, setAssessmentErrorTitle] = useState("");
  let [assessmentErrorMessage, setAssessmentErrorMessage] = useState("");
  let navigate = useNavigate();

  const getAssessmentStatusRowClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "bg-red-100";
        break;
      case 2:
        if(!evaluacion_iniciada){
          className = "bg-red-100";
        } else {
          className = "bg-yellow-100";
        }
        break;
      case 3:
        className = "bg-orange-100";
        break;
      case 4:
        className = "bg-green-100";
        break;
      default:
        className = "bg-red-100";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabelClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "text-red-800";
        break;
      case 2:
        if(!evaluacion_iniciada){
          className = "text-red-800";
        } else {
          className = "text-yellow-800";
        }
        break;
      case 3:
        className = "text-orange-800";
        break;
      case 4:
        className = "text-green-800";
        break;
      default:
        className = "text-red-800";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabel = (status_id, evaluacion_iniciada) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "No iniciada";
        break;
      case 2:
        if(!evaluacion_iniciada){
          className = "No iniciada";
        } else {
          className = "En proceso";
        }
        break;
      case 3:
        className = "Proceso COV";
        break;
      case 4:
        className = "Completada";
        break;
      default:
        className = "No iniciada";
        break;
    }
    return className;
  };

  const startAssessment = () => {
    let now = new Date();
    if(now < startDate || now > endDate){
      setAssessmentErrorTitle("No puedes responder el formato");
      let message = "El formato sólo se encuentra disponible del " + startDate.getDate() + " de " + months_es[startDate.getMonth()] + " al " + endDate.getDate() + " de " + months_es[endDate.getMonth()];
      setAssessmentErrorMessage(message);
      return cannotDoAssessment.current.showModal();
    }
    return navigate(`/t3b/assessments/${id}/answer`);
  }

  const startAssessmentFeedback = () => {
    let now = new Date();

    let lastFeedbackDate = new Date(endDate);
    lastFeedbackDate.setDate(lastFeedbackDate.getDate() - 1);
    lastFeedbackDate.setHours(19, 0, 0);

    let startOfDayLimit = new Date();
    let endOfDayLimit = new Date();
    startOfDayLimit.setHours(8, 0, 0);
    endOfDayLimit.setHours(19, 0, 0);

    if(now > lastFeedbackDate){
      setAssessmentErrorTitle("No puedes realizar el cara a cara");
      let message = "El cara a cara sólo se encontraba disponible hasta el " + lastFeedbackDate.getDate() + " de " + months_es[lastFeedbackDate.getMonth()] + " a las 7:00pm";
      setAssessmentErrorMessage(message);
      return cannotDoAssessment.current.showModal();
    }

    if(now < startOfDayLimit || now > endOfDayLimit){
      setAssessmentErrorTitle("No puedes realizar el cara a cara");
      let message = "El cara a cara sólo se encuentra disponible de las 8:00 am a las 7:00pm ";
      setAssessmentErrorMessage(message);
      return cannotDoAssessment.current.showModal();
    }

    return navigate(`/t3b/assessments/${id}/feedback`);
  }

  return (
    <div
      className={classNames("relative mb-4 pt-4")}
      key={`key-employee-assessment-${id}`}
    >
      <div className="relative mx-auto">
        <div>
          <div className="flex justify-end">
            <span
              className={`inline-block flex-shrink-0 text-xs rounded-full px-2 py-0.5 font-medium ${getAssessmentStatusLabelClassName(
                status, evaluacion_iniciada
              )} ${getAssessmentStatusRowClassName(
                status, evaluacion_iniciada
              )}`}
            >
              {getAssessmentStatusLabel(status, evaluacion_iniciada)}
            </span>
          </div>
          <p className="mt-1 font-medium tracking-tight text-red-500 text-sm">
            {name}
          </p>
          <p className="font-semibold tracking-tight text-black text-sm">
            {title}
          </p>
          <p className="text-gray-500 font-medium text-sm">
            Disponible del{" "}
            <span className="text-red-500">
              {startDate.getDate()}
            </span>{" "}
            de {months_es[startDate.getMonth()]} al{" "}
            <span className="text-red-500">
              {endDate.getDate()}
            </span>{" "}
            de {months_es[endDate.getMonth()]}
          </p>
          {status == 3 && <p className="text-sm text-red-600 font-medium">
            No se puede responder el formato porque aún no se han cargado los objetivos de venta
          </p>}
          {evaluacion && status == 2 && <p className="text-gray-500 text-xs">
            Fecha de la sesión de retroalimentación: {getDateTimeLabel(feedbackDate)}
          </p>}
          <div className="mt-2 grid grid-cols-2 sm:flex sm:space-x-2 gap-2">
            {!onlySelf && status == 1 &&
              <button
                type="button"
                onClick={startAssessment}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-1 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Responder
              </button>
            }
            {status == 2 && !evaluacion_iniciada &&
              <button
                type="button"
                onClick={startAssessment}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-1 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Responder
              </button>
            }
            {status == 2 && evaluacion_iniciada && !evaluacion &&
              <button
                type="button"
                onClick={startAssessment}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-4 py-1 font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
              >
                Continuar
              </button>
            }
            {status == 4 && <Link to={`/t3b/assessments/${id}/print/3`}>
              <button
                type="button"
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-1 font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                Imprimir
              </button>
            </Link>}
            {autoevaluacion && evaluacion && feedback && !feedbackEnded && status == 2 && new Date() >= feedbackDate &&
              <button
                type="button"
                onClick={startAssessmentFeedback}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-1 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
              >
                Cara a cara
              </button>
            }
            {evaluacion && status == 2 && <Link to={`/t3b/assessments/${id}/print/2`}>
              <button
                type="button"
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-1 font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
              >
                Imprimir
              </button>
            </Link>}
          </div>
        </div>
      </div>
      <CommonModalTemplate ref={cannotDoAssessment}>
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {assessmentErrorTitle}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {assessmentErrorMessage}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            onClick={() => cannotDoAssessment.current.hideModal()}
          >
            Entendido
          </button>
        </div>
      </CommonModalTemplate>
    </div>
  );
};

HomeEmployeeAssessment.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  name: PropTypes.string,
  title: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  feedbackDate: PropTypes.instanceOf(Date),
  autoevaluacion: PropTypes.bool,
  evaluacion: PropTypes.bool,
  feedback: PropTypes.bool,
  feedbackEnded: PropTypes.bool,
};

HomeEmployeeAssessment.defaultProps = {
  id: 1,
  status: 1,
  name: "Default name",
  title: "Default title",
  startDate: new Date(),
  endDate: new Date(),
  feedbackDate: new Date(),
  autoevaluacion: false,
  evaluacion: false,
  feedback: false,
  feedbackEnded: false,
  onlySelf: false,
};

export default HomeEmployeeAssessment;
