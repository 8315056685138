import loader from "./../../assets/img/brand/loader.svg";
import PropTypes from "prop-types";

const InfinityLoader = ({ legend, className }) => {
  return (
    <div className="flex flex-col items-center justify-center">
      <img src={loader} alt="cargando..." className={className}/>
      {legend && <p className="text-t3b text-xl animate-ping">Cargando...</p>}
    </div>
  );
};

InfinityLoader.propTypes = {
  legend: PropTypes.bool,
  status: PropTypes.number,
  periodStatus: PropTypes.number,
  title: PropTypes.string,
  dueDate: PropTypes.string,
  description: PropTypes.string,
};

InfinityLoader.defaultProps = {
  legend: true,
  className: "h-40 w-40"
};

export default InfinityLoader;
