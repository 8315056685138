import { useEffect, useRef, useState } from "react";
import { CalendarDaysIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
import { Link, useParams } from "react-router-dom";
import API from "../../services/API";
import { useSelector } from "react-redux";
import Notification from "../../components/notifications/Notification";
import {
  DocumentMinusIcon,
  DocumentPlusIcon,
} from "@heroicons/react/24/outline";
import { ArrowDownTrayIcon, PlusIcon, UsersIcon } from "@heroicons/react/24/solid";
import InfinityLoader from "../../components/loaders/InfinityLoader";
import { base64StringToBlob } from "blob-util";
import BigModalTemplate from "../../components/modals/BigModalTemplate";
import AdminAssessmentAction from "../../components/dropdowns/AdminAssessmentAction";

export default function PeriodFormats() {
  const { user, token } = useSelector((state) => state.authentication);
  const [assessments, setAssessments] = useState([]);
  const [assessmentsUsers, setAssessmentsUsers] = useState([]);
  const [forms, setForms] = useState([]);
  const [form, setForm] = useState(0);
  const [period, setPeriod] = useState({});
  const [findAssessments, setFindAssessments] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);
  const [loadingCovs, setLoadingCovs] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [loadingAssessments, setLoadingAssessments] = useState(false);
  const pathParams = useParams();
  const [error, setError] = useState("Ocurrió un error");
  const [search, setSearch] = useState("");
  const [title, setTitle] = useState("");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();
  const newFormatRef = useRef();

  useEffect(() => {
    const fetchFormats = () => {
      API.get("/forms", { headers: { Authorization: token } }).then(({ data, status }) => {
        if (status === 200) {
          setForms(
            data.map((f) => {
              f.id = f.formato_id;
              f.label = f.titulo;
              return f;
            })
          );
        }
      });
    };
    const fetchPeriod = () => {
      API.get("/periods", {
        params: { periodo_id: pathParams.periodId },
        headers: { Authorization: token },
      })
        .then(({ data, status }) => {
          if (status === 200) {
            setPeriod(data);
          }
        })
        .catch((error) => {
          setPeriod({});
          setErrorTitle("Error al buscar");
          setError("Ocurrió un error al buscar los periodos");
          return notificationRef.current.showNotification();
        });
    };
    const fetchAssessments = () => {
      setLoadingInfo(true);
      API.get(`/periods/${pathParams.periodId}/assessments`, {
        headers: { Authorization: token },
      })
        .then(({ data, status }) => {
          setLoadingInfo(false);
          if (status === 200) {
            setAssessments(data);
          }
        })
        .catch((error) => {
          setLoadingInfo(false);
        });
    };
    fetchPeriod();
    fetchFormats();
    fetchAssessments();
  }, []);

  const patchPeriod = () => {
    setLoadingCovs(true);
    let body = {
      cov_abierto: !period.cov_abierto,
      titulo: period.titulo,
    };
    API.patch(`/periods/${pathParams.periodId}`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setLoadingCovs(false);
        let newPeriod = { ...period };
        newPeriod.cov_abierto = body.cov_abierto;
        setPeriod(newPeriod);
      })
      .catch((error) => {
        setLoadingCovs(false);
        setErrorTitle("Error al editas");
        setError("Ocurrió un error al editar el estatus cov del periodo");
        return notificationRef.current.showNotification();
      });
  };

  const downloadFile = (e) => {
    e.preventDefault();
    setLoadingFile(true);
    let params = {
      periodo: pathParams.periodId,
      evaluacion: 0,
      region: user.region_id,
    };
    API.get("/assessments/filedownload", {
      params,
      headers: { Authorization: token },
      responseType:
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    })
      .then((response) => {
        setLoadingFile(false);
        const link = document.createElement("a");
        const blob = base64StringToBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute(
          "download",
          `EvaluacionesPeriodo_${period.titulo}.xlsx`
        );
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        setLoadingFile(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los usuarios"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los usuarios"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const fetchUsersAssessments = () => {
    setLoadingAssessments(true);
    let params = {
      find: search,
      period: pathParams.periodId
    };
    API.get(`/assessments`, {
      headers: { Authorization: token },
      params
    })
      .then(({ data, status }) => {
        setLoadingAssessments(false);
        if (status === 200) {
          setAssessmentsUsers(data);
        } else {
          setAssessmentsUsers([]);
        }
      })
      .catch((error) => {
        setAssessmentsUsers([]);
        setLoadingAssessments(false);
      });
  };

  const fetchAssessments = () => {
    setLoadingInfo(true);
    API.get(`/periods/${pathParams.periodId}/assessments`, {
      headers: { Authorization: token },
    })
      .then(({ data, status }) => {
        setLoadingInfo(false);
        if (status === 200) {
          setAssessments(data);
        }
      })
      .catch((error) => {
        setLoadingInfo(false);
      });
  };

  const postAssessment = () => {
    if(title==""){
        setError(
          "Escribe un título del formato en el periodo"
        );
        return notificationRef.current.showNotification();
    }
    if(form==0){
      setError(
        "Selecciona un formato"
      );
      return notificationRef.current.showNotification();
    }
    setLoadingSave(true);
    let body = {
        titulo: title,
        formato_id: form,
        periodo_id: pathParams.periodId
    }
    API.post(`/periods/${pathParams.periodId}/assessments`, body, { headers: { Authorization: token }, })
    .then( result => {
        setLoadingSave(false);
        fetchAssessments();
        setTitle("");
        setForm(0);
        newFormatRef.current.hideModal()
    })
    .catch((error) => {
      setLoadingFile(false);
      if (error.response) {
        if (error.response.status === 401) {
          setError("La sesión ha caducado");
        } else {
          setError(error.response.data);
        }
      } else if (error.request) {
        setError(
          "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los usuarios"
        );
      } else {
        setError(
          "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los usuarios"
        );
      }
      notificationRef.current.showNotification();
    });
  }

  const findUsers = (e) => {
    e.preventDefault();
    setFindAssessments(true);
    fetchUsersAssessments();
  }

  const getIndividualAssessmentStatusLabel = (iniciada, finalizada) => {
    let label = (finalizada ? 'finalizada' : (iniciada ? 'en proceso' : 'no iniciada'))
    return label;
  }

  const getFeedbackStatusLabel = (agendada, terminada, cierre) => {
    let label = "";
    if(!agendada){
      label = "";
    } else {
      if(!terminada){
        label = "Cara a Cara";
      } else {
        if(cierre == 3){
          label = "Terminada";
        } else if(cierre == 2){
          label = "Validando";
        } else {
          label = "Actualizando";
        }
      }
    }
    return label;
  }

  const getGlobalAssessmentStatusLabel = (status_id) => {
    let label = ""
    switch(status_id){
        case 1:
            label = "No iniciada";
            break;
        case 2:
            label = "En proceso";
            break;
        case 3:
            label = "Proceso COV";
            break;
        case 4:
            label = "Cerrada";
            break;
        default:
            label = "No iniciada";
            break;
    }
    return label;
  }

  const getAssessmentStatusRowClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "bg-red-100";
        break;
      case 2:
        className = "bg-yellow-100";
        break;
      case 3:
        className = "bg-orange-100";
        break;
      case 4:
        className = "bg-green-100";
        break;
      default:
        className = "bg-red-100";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabelClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "text-red-800";
        break;
      case 2:
        className = "text-yellow-800";
        break;
      case 3:
        className = "text-orange-800";
        break;
      case 4:
        className = "text-green-800";
        break;
      default:
        className = "text-red-800";
        break;
    }
    return className;
  };

  const onCloseAssessment = (id) => {
    let newAssessments = [...assessmentsUsers];
    let indexOne = newAssessments.findIndex(a => a.evaluacion_usuario_id==id);
    if(indexOne >= 0){
      newAssessments[indexOne].estatus_evaluacion_id = 4;
    }
    setAssessmentsUsers(newAssessments);
  }

  const onDeleteAssessment = (id) => {
    let newAssessments = [...assessmentsUsers];
    let indexOne = newAssessments.findIndex(a => a.evaluacion_usuario_id==id);
    if(indexOne >= 0){
      newAssessments.splice(indexOne, 1);
    }
    setAssessmentsUsers(newAssessments);
  }

  const onEndAssessment = (id, type, value) => {
    let newAssessments = [...assessmentsUsers];
    let indexOne = newAssessments.findIndex(a => a.evaluacion_usuario_id==id);
    let field = "autoevaluacion_terminada"
    if(type == 2){
      field = "evaluacion_terminada"
    }
    if(indexOne >= 0){
      newAssessments[indexOne][field] = value;
    }
    setAssessmentsUsers(newAssessments);
  }

  let superAdministrador =
    user?.certificados?.filter((c) => c.cat_permiso_id == 6 && c.estatus == 1)
      ?.length > 0;

  return (
    <div className="min-w-0 w-full">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <div className="flex flex-col justify-start pb-6">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">
                  Evaluaciones del{" "}
                  {assessments.length > 0 && assessments[0].periodo_nombre}
                </h1>
                <p className="mt-4 text-sm text-gray-700">
                  Asigna nuevos formatos de evaluación al periodo y consulta las
                  evaluaciones de cada uno.
                </p>
              </div>
              <div className="sm:flex-auto mt-4"></div>
            </div>
            {superAdministrador && (
              <div className="flex flex-col sm:flex-row space-x-0 space-y-3 sm:space-x-3 sm:space-y-0 justify-start sm:justify-end mb-4">
                {period.periodo_id && (
                  <button
                    type="button"
                    className="inline-flex items-center rounded-md border border-transparent bg-red-200 px-6 py-3 text-base font-medium text-red-700 hover:bg-red-300 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={patchPeriod}
                    disabled={loadingCovs}
                  >
                    {period.cov_abierto ? (
                      <DocumentMinusIcon className="h-5 w-5 mr-1" />
                    ) : (
                      <DocumentPlusIcon className="h-5 w-5 mr-1" />
                    )}
                    {loadingCovs
                      ? "Guardando cambios"
                      : period.cov_abierto
                      ? "Cerrar COVS"
                      : "Abrir COVS"}
                  </button>
                )}
                <button
                  type="button"
                  onClick={downloadFile}
                  disabled={loadingFile}
                  className="inline-flex items-center rounded-md border border-transparent bg-indigo-200 px-6 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  // onClick={e =>  editAssessmentRef.current.showModal()}
                >
                  <ArrowDownTrayIcon
                    className={`h-5 w-5 mr-1 ${
                      loadingFile ? "animate-bounce" : ""
                    }`}
                  />
                  {loadingFile ? "Descargando archivo" : "Descargar"}
                </button>
                <button
                  type="button"
                  className="inline-flex items-center rounded-md border border-transparent bg-green-200 px-6 py-3 text-base font-medium text-green-700 hover:bg-green-300 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
                  onClick={(e) => newFormatRef.current.showModal()}
                >
                  <PlusIcon className="h-5 w-5 mr-1" />
                  Agregar evaluación
                </button>
              </div>
            )}
          </div>
          <form className="mb-6" onSubmit={findUsers}>
            <label htmlFor="usersearch" className="block text-sm font-medium text-gray-700">
              Buscar evaluación
            </label>
            <div className="mt-1 flex rounded-md shadow-sm mb-4">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <UsersIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </div>
                <input
                  type="text"
                  name="usersearch"
                  id="usersearch"
                  value={search}
                  onChange={e => setSearch(e.target.value)}
                  className="block w-full rounded-md border-gray-300 pl-10 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder="Nombre o apellidos"
                />
              </div>
            </div>
          </form>
          {search != "" && findAssessments && <div className="mt-8 mb-8 flex flex-col">
            {
              loadingAssessments
              ? <>
                <InfinityLoader />
              </>
              :
              <div className="-my-2 -mx-4 overflow-x-auto lg:overflow-visible sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                  <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="bg-gray-50">
                        <tr>
                          <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                            Nombre
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Estatus individual
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Proceso
                          </th>
                          <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                            Estatus global
                          </th>
                          <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Edit</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="divide-y divide-gray-200 bg-white">
                        {
                          assessmentsUsers.length < 1 &&
                          <tr>
                            <td className="py-4 pl-4 pr-3 text-sm sm:pl-6 text-red-500 font-medium text-center" colSpan={5}>
                              No hay evaluaciones con el filtro seleccionado
                            </td>
                          </tr>
                        }
                        {assessmentsUsers.map((assessment) => (
                          <tr key={`key-row-${assessment.evaluacion_usuario_id}`}>
                            <td className="py-4 pl-4 pr-3 text-sm sm:pl-6">
                              <div className="flex items-center">
                                <div className="ml-4">
                                  <div className="font-medium text-gray-900">{assessment.nombre_completo}</div>
                                  <div className="text-gray-500">{assessment.puesto}</div>
                                </div>
                              </div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="text-gray-500">Autoevaluación {getIndividualAssessmentStatusLabel(assessment.autoevaluacion_iniciada, assessment.autoevaluacion_terminada)}</div>
                              <div className="text-gray-500">Evaluación  {getIndividualAssessmentStatusLabel(assessment.evaluacion_iniciada, assessment.evaluacion_terminada)}</div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <div className="text-gray-500">{getFeedbackStatusLabel(assessment.revision_agendada, assessment.revision_terminada, assessment.estatus_cierre_id)}</div>
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 ${getAssessmentStatusRowClassName(assessment.estatus_evaluacion_id)} ${getAssessmentStatusLabelClassName(assessment.estatus_evaluacion_id)}`}>
                                {getGlobalAssessmentStatusLabel(assessment.estatus_evaluacion_id)}
                              </span>
                            </td>
                            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <AdminAssessmentAction
                                id={assessment.evaluacion_usuario_id}
                                employeeId={assessment.numero_empleado}
                                status={assessment.estatus_evaluacion_id}
                                evaluacion={assessment.evaluacion_terminada}
                                autoevaluacion={assessment.autoevaluacion_terminada}
                                deleteCallback={onDeleteAssessment}
                                endCallback={onEndAssessment}
                                closeCallback={onCloseAssessment}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            }
          </div>}
          {loadingInfo ? (
            <>
              <InfinityLoader />
            </>
          ) : (
            <div className="flex flex-col">
              <div className="-my-2 -mx-4 overflow-hidden sm:-mx-0 lg:-mx-0  bg-white shadow sm:rounded-md">
                <ul className="divide-y divide-gray-200">
                  {assessments.map((period) => (
                    <li key={`key-periodo-formato-${period.evaluacion_id}`}>
                      <Link
                        to={`/t3b/admin/periods/${period.periodo_id}/assessments/${period.evaluacion_id}`}
                        className="block hover:bg-gray-50"
                      >
                        <div className="flex items-center px-4 py-4 sm:px-6">
                          <div className="flex min-w-0 flex-1 items-center">
                            <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4">
                              <div>
                                <p className="truncate text-sm font-medium text-indigo-600">
                                  {period.titulo}
                                </p>
                                <p className="hidden md:flex mt-2 items-center text-sm text-gray-500">
                                  <CalendarDaysIcon
                                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  <span className="truncate">
                                    Del {period.fecha_inicio} al{" "}
                                    {period.fecha_fin}
                                  </span>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div>
                            <ChevronRightIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                        </div>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          )}
        </div>
      </main>
      <BigModalTemplate ref={newFormatRef}>
        <div className="flex justify-between items-top mb-8">
          <p className="text-3xl font-medium">Agregar formato al periodo</p>
          <button
            type="button"
            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
            onClick={(e) => newFormatRef.current.hideModal()}
          >
            <span className="sr-only">Cerrar</span>
            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="">
          <div className="mb-4">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              TÍTULO
            </label>
            <div className="mt-1">
              <input
                id="title"
                name="title"
                type="text"
                placeholder="Título del formato en el periodo..."
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="mb-4 col-span-1 sm:col-span-2">
            <label
              htmlFor="question-title"
              className="block text-xs text-red-500"
            >
              FORMATO
            </label>
            <div className="mt-1">
              <div>
                <select
                  id="location"
                  name="location"
                  value={form}
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-red-500 focus:outline-none focus:ring-red-500 sm:text-sm"
                  onChange={(e) => setForm(e.target.value)}
                >
                  <option
                    value={0}
                    disabled
                  >
                    Selecciona un formato
                  </option>
                  {forms.map((r) => (
                    <option
                      key={`key-option-area-${r.formato_id}`}
                      value={r.formato_id}
                    >
                      {r.titulo}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="pt-6">
            <div className="flex justify-end">
              <button
                type="button"
                onClick={(e) => newFormatRef.current.hideModal()}
                className="rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
              >
                Cancelar
              </button>
              <button
                type="button"
                disabled={loadingSave}
                onClick={postAssessment}
                className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2"
              >
                {loadingSave ? "AgregandoEvaluacion" : "Agregar"}
              </button>
            </div>
          </div>
        </div>
      </BigModalTemplate>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </div>
  );
}
