import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import html2canvas from "html2canvas";
import API from "../../services/API";
import OrganizationSection from "../../components/sections/OrganizationSection";
import { useSelector } from "react-redux";
import { ArrowDownTrayIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";

const Organization = () => {
  const [data, setData] = useState({ data: {} });
  const [loadingFile, setLoadingFile] = useState(false);
  const {user,token} = useSelector(state => state.authentication)
  let pathParams = useParams();
  let containerRef = useRef();

  useEffect(() => {
    const options = {
      headers: {
        Authorization: token
      }
    }
    API.get(`/users/${pathParams.employeeId}/organization`, options).then(({ data, status }) => {
      if (status === 200) {
        setData(data);
      } else {
        setData({});
      }
    });
  }, []);

  const exportOrg = () => {
    const input = document.getElementById(`emp-${pathParams.employeeId}`).parentNode;
    setLoadingFile(true);
    html2canvas(input, {
      useCORS: true,
      logging: false,
      backgroundColor: null,
      windowWidth: containerRef.current.scrollWidth,
      windowHeight: containerRef.current.scrollHeight,
      width: containerRef.current.scrollWidth,
      height: containerRef.current.scrollHeight
    }).then((canvas) => {
      setLoadingFile(false);
      var image = canvas.toDataURL("image/png", 1.0);
      var fileName = "organigrama.png";
      saveAs(image, fileName);
    });
  };

  const saveAs = (blob, fileName) => {
    var elem = window.document.createElement("a");
    elem.href = blob;
    elem.download = fileName;
    elem.style = "display:none;";
    (document.body || document.documentElement).appendChild(elem);
    if (typeof elem.click === "function") {
      elem.click();
    } else {
      elem.target = "_blank";
      elem.dispatchEvent(
        new MouseEvent("click", {
          view: window,
          bubbles: true,
          cancelable: true,
        })
      );
    }
    URL.revokeObjectURL(elem.href);
    elem.remove();
  };

  return (
    <div className="flex-1 flex flex-col">
      <div className="flex flex-1 mt-1 overflow-auto" ref={containerRef}>
        <div className="flex relative w-full">
          <button
            type="button"
            onClick={exportOrg}
            disabled={loadingFile}
            className="fixed z-50 bottom-6 right-6 inline-flex items-center rounded-md border border-transparent bg-indigo-200 px-6 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-300 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <ArrowDownTrayIcon className={`h-5 w-5 mr-1 ${loadingFile ? "animate-bounce" : ""}`} />
            {loadingFile ? "Descargando organigrama" : "Descargar"}
          </button>
          <div className="absolute bottom-4 top-4 h-full w-full">
            <OrganizationSection data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Organization;
