import {
  useState,
  forwardRef,
  useRef,
  useImperativeHandle,
  useEffect,
} from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import BigModalTemplate from "./BigModalTemplate";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import Notification from "../notifications/Notification";
import { useNavigate } from "react-router-dom";
var fileDownload = require("js-file-download");

const ObjectiveCompleteModal = forwardRef((props, ref) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [progress, setProgress] = useState([]);
  let navigate = useNavigate();
  const [objective, setObjective] = useState(null);
  const [loadingObjective, setLoadingObjective] = useState(true);
  const [boss, setBoss] = useState({});
  const [loadingSave, setLoadingSave] = useState(false);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const refModal = useRef();
  const notificationRef = useRef();

  useImperativeHandle(ref, () => ({
    showModal(objectiveId) {
      fetchObjectiveInfo(objectiveId);
      refModal.current.showModal();
    },
    hideModal() {
      refModal.current.hideModal();
    },
  }));

  const fetchObjectiveInfo = (objectiveId) => {
    setLoadingObjective(true);
    API.get(`/objectives/${objectiveId}`, {
      headers: { Authorization: token },
    })
      .then((result) => {
        setLoadingObjective(false);
        if (result.status == 200) {
          setObjective(result.data);
        } else {
          setObjective(null);
          setErrorTitle("Error");
          setError("Error al buscar la información del objetivo");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setErrorTitle("Error");
        setLoadingObjective(false);
        setObjective(null);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };
  useEffect(() => {
    const fetchBoss = (users) => {
      if(!user.jefe_inmediato_id) return
      const options = { headers: { Authorization: token } };
      API.get("/users/"+user.jefe_inmediato_id, options)
        .then(({ data, status }) => {
          if (status === 200) {
            setBoss(data[0]);
          } else {
            setBoss({});
          }
        })
        .catch((error) => {
          setBoss({});
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };
    fetchBoss();
  }, []);

  const saveProgress = async (progr_res) => {
    if (progress == "") {
      setError("Ingresa el comentario de cierre");
      return notificationRef.current.showNotification();
    }
    setLoadingSave(true);
    let body = {
      descripcion: progress,
      resultado_objetivo_id: progr_res,
      numero_empleado: user.numero_empleado,
      fecha_captura: new Date(),
      evidencias: [],
    };
    let evidencias = [];
    body.evidencias = evidencias;
    API.post(`/objectives/${objective.objetivo_id}/newprogressv2`, body, {
      headers: { Authorization: token },
    })
      .then((result) => {
        if (result.status == 201) {
          sendEmailEnd();
          setLoadingSave(false);
          refModal.current.hideModal();
          props.loadObjectivesAgain();
        } else {
          setObjective(null);
          setError("Error al registrar la información del objetivo");
          notificationRef.current.showNotification();
        }
      })
      .catch((error) => {
        setLoadingSave(false);
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          }
          setError(error.response.data);
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al registrar la información del objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al registrar la información del objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  const sendEmailEnd = () => {
    let body = {
      templateId: "d-5300f44a427344ca92b3b66947916f28",
      groupId: 17817,
      personalizations: [
        {
          from: {
            email: "notificaciones@evaluaciondedesempeno3b.com",
            name: "Evaluación de desempeño",
          },
          to: [
            {
              email: boss?.email,
              name: boss?.nombre_completo,
            },
          ],
          dynamicTemplateData: {
            receive: boss?.nombre_completo,
            colaborador: user?.nombre,
            objetivo: objective?.titulo,
          },
        },
      ],
    };
    API.post("/integra/email/template", body, {
      headers: { Authorization: token },
    })
      .then((response) => {
        if (response) {
          const { status } = response;
          if (status != 200) {
            setError(
              "Ocurrió un error al notificar al jefe inmediato de la completitud de su objetivo"
            );
            notificationRef.current.showNotification();
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status === 401) {
            setError("La sesión ha caducado");
          } else {
            setError(error.response.data);
          }
        } else if (error.request) {
          setError(
            "La petición fue realizada, pero no hubo respuesta por parte del servidor al notificar al jefe inmediato de la completitud de su objetivo"
          );
        } else {
          setError(
            "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al notificar al jefe inmediato de la completitud de su objetivo"
          );
        }
        notificationRef.current.showNotification();
      });
  };

  return (
    <>
      <BigModalTemplate ref={refModal}>
        <div className="sm:flex sm:items-start">
          <div className="mt-3 text-center sm:mt-0 sm:text-left w-full">
            <p className="text-lg font-medium leading-6 text-gray-900">
            ¿Consideras que el objetivo fue alcanzado al 100% con los avances registrados?
            </p>
            <div>
              <div className="mt-6 space-y-2">
                <div className="">
                  {/* <label
                    htmlFor="about"
                    className="block text-sm text-gray-500"
                  >
                    Comentario de cierre
                  </label> */}
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      placeholder="Comentario de cierre"
                      rows={3}
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-red-500 focus:ring-red-500 sm:text-sm"
                      value={progress}
                      onChange={(e) => setProgress(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                Recuerda que tu jefe inmediato debe validarlo para que el objetivo cambie a "Alcanzado".
              </p>
            </div>
          </div>
        </div>
        {!loadingObjective && (
          <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              disabled={loadingSave}
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
              onClick={(e) => saveProgress(3)}
            >
              {loadingSave ? "Cargando progreso" : "Alcanzar"}
            </button>
            {!loadingSave && (
              <button
                type="button"
                className="mt-3 inline-flex w-full justify-center rounded-md border border-red-300 bg-red-600 text-white px-4 py-2 text-base font-medium  shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:w-auto sm:text-sm"
                disabled={loadingSave}
                onClick={() => refModal.current.hideModal()}
              >
                {loadingSave ? "Cargando progreso" : "Cancelar"}
              </button>
            )}
          </div>
        )}
      </BigModalTemplate>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </>
  );
});

export default ObjectiveCompleteModal;
