import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";

const ActionPlans = ({ period, region, area }) => {
  const {user, token} = useSelector(state => state.authentication);
  const [plans, setPlans] = useState({});
  const [loadingPlans, setLoadingPlans] = useState(true);

  useEffect(() => {
    if (period) {
      fetchActionPlanss()
    }
  }, [period, region, area]);

  const fetchActionPlanss = useCallback(()=>{
    let params = { period };
      if (region != 0) {
        params.region = region;
      }
      if (area != "0") {
        params.area = area;
      }
      setLoadingPlans(true);
      const headers = { Authorization: token }
      API.get(`/results/plans`, { params, headers })
        .then(({ status, data }) => {
          setLoadingPlans(false);
          if (status === 200) {
            setPlans(data);
          } else {
            setPlans({});
          }
        })
        .catch((error) => {
          setLoadingPlans(false);
          setPlans({});
        });
  },[period, region, area, token])
  return (
    <>
      <div className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-2">
        <dt className="truncate text-sm font-medium text-gray-500">
          Planes de acción
        </dt>
        {
          loadingPlans
          ? <InfinityLoader legend={false} className="h-14 w-14"/>
          : <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
            {plans.conteo}
          </dd>
        }
      </div>
    </>
  );
};

ActionPlans.propTypes = {
  period: PropTypes.number,
  region: PropTypes.number,
  area: PropTypes.string,
};

ActionPlans.defaultProps = {
  period: null,
  region: 0,
  area: "0",
};

export default ActionPlans;
