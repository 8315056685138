import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import PropTypes from "prop-types";
import { useState } from "react";
import { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { classNames } from "../../constants/functions";
import { months_es } from "../../utils/date_utils";
import CommonModalTemplate from "../modals/CommonModalTemplate";

const HomeAssessment = ({
  id,
  status,
  title,
  startDate,
  endDate,
  superior,
  self,
  autoevaluacion,
  evaluacion,
  feedback,
  feedbackEnded,
  feedbackDate,
  onlySuperior,
  onlySelf,
  nextPeriodObjectives
}) => {
  let cannotDoAssessment = useRef();
  let [assessmentErrorTitle, setAssessmentErrorTitle] = useState("");
  let [assessmentErrorMessage, setAssessmentErrorMessage] = useState("");
  let navigate = useNavigate();

  const getAssessmentStatusRowClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "bg-red-100";
        break;
      case 2:
        className = "bg-yellow-100";
        break;
      case 3:
        className = "bg-orange-100";
        break;
      case 4:
        className = "bg-green-100";
        break;
      default:
        className = "bg-red-100";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabelClassName = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "text-red-800";
        break;
      case 2:
        className = "text-yellow-800";
        break;
      case 3:
        className = "text-orange-800";
        break;
      case 4:
        className = "text-green-800";
        break;
      default:
        className = "text-red-800";
        break;
    }
    return className;
  };

  const getAssessmentStatusLabel = (status_id) => {
    let className = "";
    switch (status_id) {
      case 1:
        className = "No iniciada";
        break;
      case 2:
        className = "En proceso";
        break;
      case 3:
        className = "Proceso COV";
        break;
      case 4:
        className = "Completada";
        break;
      default:
        className = "No iniciada";
        break;
    }
    return className;
  };

  const startAssessment = () => {
    let now = new Date();
    if(now < startDate || now > endDate){
      setAssessmentErrorTitle("No puedes responder el formato");
      let message = "El formato sólo se encuentra disponible del " + startDate.getDate() + " de " + months_es[startDate.getMonth()] + " al " + endDate.getDate() + " de " + months_es[endDate.getMonth()];
      setAssessmentErrorMessage(message);
      return cannotDoAssessment.current.showModal();
    }
    return navigate(`/t3b/assessments/${id}/answer`);
  }

  const startAssessmentFeedback = () => {
    let now = new Date();

    let lastFeedbackDate = new Date(endDate);
    lastFeedbackDate.setDate(lastFeedbackDate.getDate() - 1);
    lastFeedbackDate.setHours(19, 0, 0);

    let startOfDayLimit = new Date();
    let endOfDayLimit = new Date();
    startOfDayLimit.setHours(8, 0, 0);
    endOfDayLimit.setHours(19, 0, 0);

    if(now > lastFeedbackDate){
      setAssessmentErrorTitle("No puedes realizar el cara a cara");
      let message = "El cara a cara sólo se encontraba disponible hasta el " + lastFeedbackDate.getDate() + " de " + months_es[lastFeedbackDate.getMonth()] + " a las 7:00pm";
      setAssessmentErrorMessage(message);
      return cannotDoAssessment.current.showModal();
    }

    if(now < startOfDayLimit || now > endOfDayLimit){
      setAssessmentErrorTitle("No puedes realizar el cara a cara");
      let message = "El cara a cara sólo se encuentra disponible de las 8:00 am a las 7:00pm ";
      setAssessmentErrorMessage(message);
      return cannotDoAssessment.current.showModal();
    }

    return navigate(`/t3b/assessments/${id}/feedback`);
  }

  return (
    <div
      className={classNames("relative bg-white rounded-lg shadow")}
      key={`key-assessment-${id}`}
    >
      <div className="relative mx-auto max-w-7xl p-4 sm:p-6 lg:p-6">
        <div>
          <div className="flex justify-end">
            <span
              className={`inline-block flex-shrink-0 rounded-full px-2 py-0.5 font-medium ${getAssessmentStatusLabelClassName(
                status
              )} ${getAssessmentStatusRowClassName(status)}`}
            >
              {getAssessmentStatusLabel(status)}
            </span>
          </div>
          <p className="mt-1 text-xl font-semibold tracking-tight text-black sm:text-2xl">
            {title}
          </p>
          <p className="sm:text-lg text-gray-500 font-medium">
            Disponible del{" "}
            <span className="text-red-500">{startDate.getDate()}</span> de{" "}
            {months_es[startDate.getMonth()]} al{" "}
            <span className="text-red-500">{endDate.getDate()}</span> de{" "}
            {months_es[endDate.getMonth()]}
          </p>
          {onlySuperior && self && status != 4 && <p className="text-sm text-red-600 font-medium">
            Este formato debe ser respondido por tu jefe inmediato
          </p>}
          {status == 3 && (
            <p className="text-sm text-red-600 font-medium">
              No se puede responder el formato porque aún no se han cargado los
              objetivos de venta
            </p>
          )}
          <div className="mt-2 grid grid-cols-2 sm:flex sm:space-x-2 gap-2">
            {
              status == 1 && onlySuperior && superior &&
              <button
                type="button"
                onClick={startAssessment}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Responder
              </button>
            }
            {
              status == 1 && self && onlySelf &&
              <button
                type="button"
                onClick={startAssessment}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Responder
              </button>
            }
            {
              status == 1 && !onlySuperior && !onlySelf &&
              <button
                type="button"
                onClick={startAssessment}
                className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-red-100 px-4 py-2 font-medium text-red-700 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
              >
                Responder
              </button>
            }
            {self
              ? status == 2 &&
                !autoevaluacion && (
                  <button
                    type="button"
                    onClick={startAssessment}
                    className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                  >
                    {superior
                      ? evaluacion
                        ? "Actualizar"
                        : "Continuar"
                      : autoevaluacion
                      ? "Actualizar"
                      : "Continuar"}
                  </button>
                )
              : status == 2 &&
                !evaluacion && (
                  <button
                    type="button"
                    onClick={startAssessment}
                    className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 sm:text-sm"
                  >
                    {superior
                      ? evaluacion
                        ? "Actualizar"
                        : "Continuar"
                      : autoevaluacion
                      ? "Actualizar"
                      : "Continuar"}
                  </button>
                )}
            {status == 4 && (
              <Link to={`/t3b/assessments/${id}/print/3`}>
                <button
                  type="button"
                  className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                >
                  Imprimir
                </button>
              </Link>
            )}
            {autoevaluacion &&
              status == 2 &&
              self && (
                <Link to={`/t3b/assessments/${id}/print/1`}>
                  <button
                    type="button"
                    className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Imprimir
                  </button>
                </Link>
              )}
            {evaluacion &&
              status == 2 &&
              superior && (
                <Link to={`/t3b/assessments/${id}/print/2`}>
                  <button
                    type="button"
                    className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-4 py-2 font-medium text-indigo-700 hover:bg-indigo-200 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                  >
                    Imprimir
                  </button>
                </Link>
              )}
            {superior &&
              autoevaluacion &&
              evaluacion &&
              feedback &&
              !feedbackEnded &&
              status == 2 &&
              new Date() >= feedbackDate && (
                <button
                  type="button"
                  onClick={startAssessmentFeedback}
                  className="col-span-1 sm:inline-flex items-center justify-center rounded-md border border-transparent bg-green-100 px-4 py-2 font-medium text-green-700 hover:bg-green-200 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 sm:text-sm"
                >
                  Cara a cara
                </button>
              )}
          </div>
        </div>
      </div>
      <CommonModalTemplate ref={cannotDoAssessment}>
        <div>
          <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
            <ExclamationTriangleIcon className="h-6 w-6 text-red-600" aria-hidden="true" />
          </div>
          <div className="mt-3 text-center sm:mt-5">
            <h3
              as="h3"
              className="text-lg font-medium leading-6 text-gray-900"
            >
              {assessmentErrorTitle}
            </h3>
            <div className="mt-2">
              <p className="text-sm text-gray-500">
                {assessmentErrorMessage}
              </p>
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mt-6">
          <button
            type="button"
            className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
            onClick={() => cannotDoAssessment.current.hideModal()}
          >
            Entendido
          </button>
        </div>
      </CommonModalTemplate>
    </div>
  );
};

HomeAssessment.propTypes = {
  id: PropTypes.number,
  status: PropTypes.number,
  title: PropTypes.string,
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  superior: PropTypes.bool,
  self: PropTypes.bool,
  autoevaluacion: PropTypes.bool,
  evaluacion: PropTypes.bool,
  feedback: PropTypes.bool,
  feedbackEnded: PropTypes.bool,
};

HomeAssessment.defaultProps = {
  id: 1,
  status: 1,
  title: "Default title",
  startDate: new Date(),
  endDate: new Date(),
  superior: false,
  self: false,
  autoevaluacion: false,
  evaluacion: false,
  feedback: false,
  feedbackEnded: false,
  feedbackDate: new Date(),
  onlySuperior: false,
  onlySelf: false,
  nextPeriodObjectives: false
};

export default HomeAssessment;
