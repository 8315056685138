export const roundTwoDecimals = (origin) => {
  let num = Number(origin);
  let rounded = Math.round((num + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const checkForRound = (value) => {
  if (value?.match(/^-?\d+$/) || value?.match(/^\d+\.\d+$/)) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "decimal",
      maximumFractionDigits: 2,
    });
    return formatter.format(value);
  } else if (value?.match(/^\d+\.\d+$/)) {
    return value;
  } else {
    return value;
  }
};

export const roundPercentage = (number) => {
	let num = number * 100;
	return Math.round(num * 100) / 100;
};