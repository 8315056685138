import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import API from '../../services/API'
import { parseJwt } from '../../utils/jwt'
var Buffer = require('buffer').Buffer;


let userLS = localStorage.getItem("user");
let userJSON = null;
let tokenJSON = null;
if (userLS===undefined || userLS==="undefined" || userLS === null){
  userLS = null;
  localStorage.removeItem('user');
  API.defaults.headers.common['Authorization'] = userLS;
} else {
  userJSON = JSON.parse(userLS);
  tokenJSON = userJSON.token;
}

const userInitialState = {
  status: "idle",
  user: (userLS ? userJSON : null),
  token: (userLS ? tokenJSON : null),
  error: null
};

export const fetchUser = createAsyncThunk(
  'users/fetch',
  async ({username, password}, { rejectWithValue }) => {
    const buf = Buffer.from(`${username}:${password}`);
    const headers = {
      Authorization: `Basic ${buf.toString('base64')}`
    }
    try {
      const response = await API.post('/login_v2', { proceso: 1 }, { headers })
      localStorage.setItem("user", JSON.stringify(response.data));
      API.defaults.headers.common['Authorization'] = response.data.token;
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return rejectWithValue("No está autorizado para entrar a esta página");
        } else {
          return rejectWithValue(error.response.data);
        }
      } else if (error.request) {
        return rejectWithValue("La petición fue realizada, pero no hubo respuesta por parte del servidor");
      } else {
        return rejectWithValue("Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet");
      }
    }
  }
)

export const refreshUser = createAsyncThunk(
  'users/refreshtoken',
  async ({token}, { rejectWithValue }) => {
    const headers = {
      Authorization: token
    }
    try {
      const response = await API.post('/refreshtoken_v2', { proceso: 1 }, { headers })
      localStorage.setItem("user", JSON.stringify(response.data));
      API.defaults.headers.common['Authorization'] = response.data.token;
      return response.data;
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          return rejectWithValue("La sesión ha caducado");
        } else {
          return rejectWithValue(error.response.data);
        }
      } else if (error.request) {
        return rejectWithValue("La petición fue realizada, pero no hubo respuesta por parte del servidor");
      } else {
        return rejectWithValue("Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet");
      }
    }
  }
)

export const logout = createAction('users/logout', function prepare(action) {
  localStorage.removeItem('user');
  return {
    status: "idle",
    user: null,
    token: null,
    error: action,
  }
})

export const counterSlice = createSlice({
  name: 'authentication',
  initialState: userInitialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(logout, (state, action) => {
        return state = action
      })
      .addCase(fetchUser.pending, (state) => {
        return state = {
          status: "loading",
          user: {},
          token: "",
          error: null,
        }
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        return state = {
          status: "idle",
          user: action.payload,
          token: action.payload.token,
          error: null,
        }
      })
      .addCase(fetchUser.rejected, (state, action) => {
        return state = {
          status: "idle",
          user: null,
          token: null,
          error: action.payload,
        }
      })
      .addCase(refreshUser.pending, (state) => {
        return state = {
          status: "loading",
          user: state.user,
          token: state.token,
          error: null,
        }
      })
      .addCase(refreshUser.fulfilled, (state, action) => {
        return state = {
          status: "idle",
          user: action.payload,
          token: action.payload.token,
          error: null,
        }
      })
      .addCase(refreshUser.rejected, (state, action) => {
        return state = {
          status: "idle",
          user: null,
          token: null,
          error: action.payload,
        }
      })
  }
})

export default counterSlice.reducer