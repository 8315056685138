import { Link } from "react-router-dom";
import banner from "./../../assets/img/brand/BANNER_COV.jpg";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/20/solid";
import { useDispatch, useSelector } from "react-redux";
import Notification from "../notifications/Notification";
import { useRef, useState } from "react";
import API from "../../services/API";
import { base64StringToBlob } from 'blob-util';
import { logout } from "../../redux/reducers/auth";

const HomeShopping = () => {
  const {user, token} = useSelector(state=>state.authentication);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  const notificationRef = useRef();
  const [loadingFile, setLoadingFile] = useState(false);
  const dispatch = useDispatch();
  const downloadShoppingFile = () => {
    let params = {
      numero_empleado: user.numero_empleado,
      calificar: "NO",
      tipo: 1
    }
    setLoadingFile(true);
    API.get('/objectives/shopping/current',{ headers: { Authorization: token } ,params, responseType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
    .then((response) => {
      setLoadingFile(false);
        const link = document.createElement('a');
        const blob = base64StringToBlob(response.data, 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', `ObjetivosAreaActuales.xlsx`);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    })
    .catch((error) => {
      setLoadingFile(false);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(logout("Inicie sesión nuevamente"));
        } else {
          setError(error.response.data);
        }
      } else if (error.request) {
        setError(
          "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar los objetivos del área"
        );
      } else {
        setError(
          "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar los objetivos del área"
        );
      }
      notificationRef.current.showNotification();
    })
  }
  return (
    <>
      <div className="relative bg-white shadow rounded-lg">
        <div className="relative mx-auto px-4 py-6 sm:px-6 lg:px-8 flex flex-wrap items-center justify-between">
          <div className="">
            <p className="text-xl font-semibold tracking-tight text-gray-900 sm:text-2xl">
              Objetivos del área
            </p>
            <p className="text-lg text-gray-500">
              Descarga los objetivos del área.
            </p>
          </div>
          <div className="mt-4">
            <div className="inline-flex rounded-md shadow">
              <button onClick={downloadShoppingFile} className="inline-flex items-center justify-center rounded-md border border-transparent bg-red-600 px-5 py-3 text-base font-medium text-white hover:bg-red-700">
                {loadingFile ? 'Descargando archivo' : 'Descargar objetivos'}
                <ArrowTopRightOnSquareIcon
                  className="-mr-1 ml-3 h-5 w-5 text-white"
                  aria-hidden="true"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
      <Notification
        title={errorTitle}
        message={error}
        type="danger"
        ref={notificationRef}
      />
    </>
  );
};

HomeShopping.propTypes = { };

HomeShopping.defaultProps = { };

export default HomeShopping;
