import { Fragment, useState } from 'react'
import { Dialog, Disclosure, Menu, Popover, Transition } from '@headlessui/react'
import { BuildingStorefrontIcon, DocumentArrowDownIcon, DocumentArrowUpIcon, XMarkIcon } from '@heroicons/react/24/outline'
import {
  ChevronDownIcon,
} from '@heroicons/react/20/solid'
import { profit_centers } from '../../constants/profitcenters';
const sortOptions = [
  { name: 'Nombre', href: '#', current: true },
  { name: 'Zona', href: '#', current: false },
  { name: 'Distrito', href: '#', current: false },
]
const filters = [
  {
    id: 'category',
    name: 'Zona',
    options: [
      { value: 'new-arrivals', label: 'Camilo Andres Alvarado Torres', checked: false },
      { value: 'tees', label: 'Jesus G. Hernandez Aguilar', checked: false },
      { value: 'objects', label: 'Juan M. Cuadros Munoz', checked: true },
    ],
  },
  {
    id: 'color',
    name: 'Distrito',
    options: [
      { value: 'white', label: 'Jose Luis Zavala Sanchez', checked: false },
      { value: 'beige', label: 'Ariel Rodriguez Otero', checked: false },
      { value: 'blue', label: 'Nayeli  Adriana Guerrero Castillo', checked: false },
      { value: 'yel', label: 'Eugenio Garcia Silva', checked: false },
      { value: 'rose', label: 'Ana Gabriela Armeida Cortes', checked: false },
    ],
  },
  {
    id: 'sizes',
    name: 'Estado',
    options: [
      { value: 's', label: 'Abierta', checked: false },
      { value: 'm', label: 'Por abrir', checked: false }
    ],
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ProfitCenters() {
  const [open, setOpen] = useState(false)
  return (
    <div className="min-w-0">
      <main className="py-8">
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col">
            <Transition.Root show={open} as={Fragment}>
              <Dialog as="div" className="relative z-40 sm:hidden" onClose={setOpen}>
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                      <div className="flex items-center justify-between px-4">
                        <h2 className="text-lg font-medium text-gray-900">Filtros</h2>
                        <button
                          type="button"
                          className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close menu</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>

                      {/* Filters */}
                      <form className="mt-4">
                        {filters.map((section) => (
                          <Disclosure as="div" key={section.name} className="border-t border-gray-200 px-4 py-6">
                            {({ open }) => (
                              <>
                                <h3 className="-mx-2 -my-3 flow-root">
                                  <Disclosure.Button className="flex w-full items-center justify-between bg-white px-2 py-3 text-sm text-gray-400">
                                    <span className="font-medium text-gray-900">{section.name}</span>
                                    <span className="ml-6 flex items-center">
                                      <ChevronDownIcon
                                        className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-5 w-5 transform')}
                                        aria-hidden="true"
                                      />
                                    </span>
                                  </Disclosure.Button>
                                </h3>
                                <Disclosure.Panel className="pt-6">
                                  <div className="space-y-6">
                                    {section.options.map((option, optionIdx) => (
                                      <div key={option.value} className="flex items-center">
                                        <input
                                          id={`filter-mobile-${section.id}-${optionIdx}`}
                                          name={`${section.id}[]`}
                                          defaultValue={option.value}
                                          type="checkbox"
                                          defaultChecked={option.checked}
                                          className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                        />
                                        <label
                                          htmlFor={`filter-mobile-${section.id}-${optionIdx}`}
                                          className="ml-3 text-sm text-gray-500"
                                        >
                                          {option.label}
                                        </label>
                                      </div>
                                    ))}
                                  </div>
                                </Disclosure.Panel>
                              </>
                            )}
                          </Disclosure>
                        ))}
                      </form>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            <div className="flex sm:flex md:flex lg:flex flex-col sm:flex-col md:flex-row lg:flex-row items-center sm:items-center md:items-center lg:items-center justify-start sm:justify-start md:justify-between lg:justify-between pb-16">
              <div className="sm:flex-auto">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">Tiendas</h1>
                <p className="mt-4 text-sm text-gray-700">
                  Catálogo de tiendas. Da de alta nuevas tiendas, edita tiendas existentes, descarga el catálogo o haz una carga masiva con el layout de tiendas.
                </p>
              </div>
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-auto">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    Acciones
                    <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <BuildingStorefrontIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Nueva tienda
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                    <div className="py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <DocumentArrowDownIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Descargar catálogo
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="/#"
                            className={classNames(
                              active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                              'group flex items-center px-4 py-2 text-sm'
                            )}
                          >
                            <DocumentArrowUpIcon className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500" aria-hidden="true" />
                            Cargar layout
                          </a>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
              </div>
            </div>

            {/* Filters */}
            <section aria-labelledby="filter-heading" className='-my-2 -mx-4 flex-1 sm:-mx-6 lg:-mx-8'>
              <h2 id="filter-heading" className="sr-only">
                Filtros
              </h2>

              <div className="">
                <div className="mx-auto flex max-w-7xl items-center justify-between px-4 sm:px-6 lg:px-8">
                  <Menu as="div" className="relative inline-block text-left">
                    <div>
                      <Menu.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                        Ordenar por
                        <ChevronDownIcon
                          className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                          aria-hidden="true"
                        />
                      </Menu.Button>
                    </div>

                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-white shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {sortOptions.map((option) => (
                            <Menu.Item key={option.name}>
                              {({ active }) => (
                                <a
                                  href={option.href}
                                  className={classNames(
                                    option.current ? 'font-medium text-gray-900' : 'text-gray-500',
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm'
                                  )}
                                >
                                  {option.name}
                                </a>
                              )}
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </Transition>
                  </Menu>

                  <button
                    type="button"
                    className="inline-block text-sm font-medium text-gray-700 hover:text-gray-900 sm:hidden"
                    onClick={() => setOpen(true)}
                  >
                    Filtros
                  </button>

                  <div className="hidden sm:block">
                    <div className="flow-root">
                      <Popover.Group className="-mx-4 flex items-center divide-x divide-gray-200">
                        {filters.map((section, sectionIdx) => (
                          <Popover key={section.name} className="relative inline-block px-4 text-left">
                            <Popover.Button className="group inline-flex justify-center text-sm font-medium text-gray-700 hover:text-gray-900">
                              <span>{section.name}</span>
                              {sectionIdx === 0 ? (
                                <span className="ml-1.5 rounded bg-gray-200 py-0.5 px-1.5 text-xs font-semibold tabular-nums text-gray-700">
                                  1
                                </span>
                              ) : null}
                              <ChevronDownIcon
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                aria-hidden="true"
                              />
                            </Popover.Button>

                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Popover.Panel className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white p-4 shadow-2xl ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <form className="space-y-4">
                                  {section.options.map((option, optionIdx) => (
                                    <div key={option.value} className="flex items-center">
                                      <input
                                        id={`filter-${section.id}-${optionIdx}`}
                                        name={`${section.id}[]`}
                                        defaultValue={option.value}
                                        type="checkbox"
                                        defaultChecked={option.checked}
                                        className="h-4 w-4 rounded border-gray-300 text-red-600 focus:ring-red-500"
                                      />
                                      <label
                                        htmlFor={`filter-${section.id}-${optionIdx}`}
                                        className="ml-3 whitespace-nowrap pr-6 text-sm font-medium text-gray-900"
                                      >
                                        {option.label}
                                      </label>
                                    </div>
                                  ))}
                                </form>
                              </Popover.Panel>
                            </Transition>
                          </Popover>
                        ))}
                      </Popover.Group>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr className="divide-x divide-gray-200">
                        <th colSpan="2"  scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Tienda
                        </th>
                        <th colSpan="2" scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Región
                        </th>
                        <th colSpan="2"  scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Zona
                        </th>
                        <th colSpan="2"  scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Distrito
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Almacén
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-center text-sm font-semibold text-gray-900">
                          Estado
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6 lg:pr-8">
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {profit_centers.map((person) => (
                        <tr key={person.clave_tienda} className="divide-x divide-gray-200">
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 lg:pl-8 font-medium">{person.clave_tienda}</td>
                          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm text-gray-900 sm:pl-6 lg:pl-8 font-medium">{person.tienda}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            {person.region_id}
                          </td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.region}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.clave_zona}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.gz}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.clave_distrito}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.gd}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{person.almacen}</td>
                          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                            <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
                              {person.estado}
                            </span>
                          </td>
                          <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6 lg:pr-8">
                            <a href="/#" className="text-indigo-600 hover:text-indigo-900">
                              Editar<span className="sr-only">, {person.name}</span>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}
