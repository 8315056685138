import { useEffect, useState, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import face from "./../../assets/img/faces/avatar.png";
import PlusFolder from "../../components/vectors/PlusFolder";
import HistoryObjective from "../../components/cards/HistoryObjective";
import HistoryAssessment from "../../components/cards/HistoryAssessment";
import HistoryEmployeesPalette from "../../components/commandpalettes/HistoryEmployeesPalette";
import HistoryPeriodsPalette from "../../components/commandpalettes/HistoryPeriodsPalette";
import { getDateLabel } from "../../utils/date_utils";
import Notification from "../../components/notifications/Notification";

export default function History() {
  const { user, token } = useSelector((state) => state.authentication);
  const [currentUser, setCurrentUser] = useState({
    numero_empleado: user?.numero_empleado,
    nombre_completo: user?.nombre,
    puesto: user?.puesto,
    imagen_perfil: user?.imagen_perfil,
  });
  const [period, setPeriod] = useState(null);
  const [periods, setPeriods] = useState([]);
  const [objectives, setObjectives] = useState([]);
  const [assessments, setAssessments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState("Hubo un error con el servicio");
  const [errorTitle, setErrorTitle] = useState("Error");
  let employeesPaletteRef = useRef();
  let periodsPaletteRef = useRef();
  let notificationRef = useRef();

  useEffect(() => {
    if (user) {
      const options = { headers: { Authorization: token } };
      API.get("/periods", options)
        .then(({ data, status }) => {
          if (status === 200) {
            let periodsData = [...data];
            periodsData.shift();
            let periodo_id = periodsData[0].periodo_id;
            setPeriods(periodsData);
            setPeriod(periodsData[0]);
            fetchInfo(currentUser?.numero_empleado, periodo_id);
          }
        })
        .catch((error) => {
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    }
  }, [user]);

  useEffect(() => {
    const fetchEmployees = (users) => {
      const params = { users };
      const options = { params, headers: { Authorization: token } };
      API.get("/usersearch", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setEmployees(data);
          } else {
            setEmployees([]);
          }
        })
        .catch((error) => {
          setEmployees([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    };

    if (user) {
      let users = "";
      if (user?.colaboradores?.length > 0) {
        let usersToSearch = [user.numero_empleado, ...user.colaboradores]
        fetchEmployees(usersToSearch.join());
      }
    }
  }, [user]);

  const fetchObjectives = useCallback(
    (users, period, setter) => {
      const params = { users, hist_period: period };
      const options = { params, headers: { Authorization: token } };
      return API.get("/objectives", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setter(data);
          } else {
            setter([]);
          }
        })
        .catch((error) => {
          setter([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    },
    [token]
  );

  const fetchAssessments = useCallback(
    (users, period, setter) => {
      const params = { users, period };
      const options = { params, headers: { Authorization: token } };
      return API.get("/assessments", options)
        .then(({ data, status }) => {
          if (status === 200) {
            setter(data);
          } else {
            setter([]);
          }
        })
        .catch((error) => {
          setter([]);
          setErrorTitle("Error");
          if (error.response) {
            if (error.response.status === 401) {
              setError("La sesión ha caducado");
            }
            setError(error.response.data);
          } else if (error.request) {
            setError(
              "La petición fue realizada, pero no hubo respuesta por parte del servidor al descargar la información del objetivo"
            );
          } else {
            setError(
              "Ocurrió un error al realizar la petición hacia el servidor, revise su conexión a internet al descargar la información del objetivo"
            );
          }
          notificationRef.current.showNotification();
        });
    },
    [token]
  );

  const fetchInfo = useCallback((numero_empleado, periodo_id) => {
    fetchObjectives(numero_empleado, periodo_id, setObjectives);
    fetchAssessments(numero_empleado, periodo_id, setAssessments);
  }, []);

  const onChangeEmployee = (employee) => {
    setCurrentUser(employee);
    fetchInfo(employee?.numero_empleado, period.periodo_id);
    employeesPaletteRef.current.hidePalette();
  };

  const onChangeOption = (value) => {
    setPeriod(value);
    fetchInfo(currentUser?.numero_empleado, value.periodo_id);
    periodsPaletteRef.current.hidePalette();
  };

  return (
    <>
      <div className="min-h-full">
        <main className="py-8">
          <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:max-w-7xl lg:px-8 space-y-6">
            <h1 className="sr-only">Perfil de usuario</h1>
            <section aria-labelledby="profile-overview-title">
              <div className="overflow-hidden rounded-lg bg-white shadow">
                <h2 className="sr-only" id="profile-overview-title">
                  Perfil de usuario
                </h2>
                <div className="bg-white p-6">
                  <div className="sm:flex sm:items-center sm:justify-between">
                    <div className="sm:flex sm:space-x-5">
                      <div className="flex-shrink-0">
                        <img
                          className="mx-auto h-16 w-16 rounded-full object-cover"
                          src={
                            currentUser.imagen_perfil
                              ? `https://dfh9lj2viqrbd.cloudfront.net/profile/${currentUser.imagen_perfil}`
                              : face
                          }
                          alt=""
                        />
                      </div>
                      <div className="mt-4 text-center sm:mt-0 sm:pt-1 sm:text-left">
                        <p className="text-xl font-bold text-gray-900 sm:text-2xl">
                          {currentUser?.nombre_completo}
                        </p>
                        <p className="text-sm font-medium text-red-600">
                          {currentUser?.puesto}
                        </p>
                      </div>
                    </div>
                    <div className="mt-5 flex justify-center sm:mt-0 space-x-4">
                      {user?.colaboradores?.length > 0 && <button
                        onClick={(e) =>
                          employeesPaletteRef.current.showPalette()
                        }
                        className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                      >
                        Cambiar colaborador
                      </button>}
                      <button
                        onClick={(e) => periodsPaletteRef.current.showPalette()}
                        className="flex items-center justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                      >
                        Cambiar periodo
                      </button>
                    </div>
                  </div>
                </div>
                <div className="border-t border-gray-200 bg-gray-50 px-6 py-5 text-center text-sm font-medium">
                  <nav
                    className="flex flex-col items-center justify-center"
                    aria-label="Progress"
                  >
                    <p className="text-xl font-light">
                      <span className="font-bold uppercase">
                        {period?.titulo}
                      </span>
                    </p>
                    <p className="text font-light">
                      <span className="font-bold text-gray-500">
                        Del {getDateLabel(period?.fecha_inicio+"T00:00:00")} al{" "}
                        {getDateLabel(period?.fecha_fin+"T00:00:00")}
                      </span>
                    </p>
                  </nav>
                </div>
              </div>
            </section>
            {assessments.length > 0 && (
              <section aria-labelledby="evaluaciones" className="mb-8">
                <h2 className="text-lg font-medium text-gray-500 mb-2">
                  Evaluaciones
                </h2>
                <h2 className="sr-only" id="evaluaciones">
                  Evaluación del periodo
                </h2>
                {assessments.map((assessment) => (
                  <HistoryAssessment
                    key={`key-assessment-row-${assessment.evaluacion_usuario_id}`}
                    id={assessment.evaluacion_usuario_id}
                    status={assessment.estatus_evaluacion_id}
                    title={
                      user?.numero_empleado == assessment.numero_empleado
                        ? assessment.titulo_autoevaluacion
                        : assessment.titulo
                    }
                    startDate={new Date(assessment.fecha_inicio + "T00:00:00")}
                    endDate={new Date(assessment.fecha_fin + "T00:00:00")}
                  />
                ))}
              </section>
            )}
            <section aria-labelledby="objetivos">
              <h2 className="text-lg font-medium text-gray-500">Objetivos</h2>
              {objectives.length > 0 ? (
                <div className="mt-3 divide-y divide-gray-200 overflow-hidden rounded-lg bg-gray-200 shadow sm:grid sm:grid-cols-2 sm:gap-px sm:divide-y-0">
                  <h2 className="sr-only" id="objetivos">
                    Objetivos
                  </h2>
                  {objectives.map((objective) => (
                    <HistoryObjective
                      key={`key-objetive-row-${objective.objetivo_id}`}
                      id={objective.objetivo_id}
                      status={objective.estatus_objetivo_id}
                      title={objective.titulo}
                      titleParent={objective.titulo_padre}
                      description={objective.descripcion}
                      dueDate={objective.fecha_entrega + "T00:00:00"}
                    />
                  ))}
                </div>
              ) : (
                <div className="text-center mt-3">
                  <PlusFolder />
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    Sin objetivos
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    El colaborador no tiene objetivos del periodo.
                  </p>
                </div>
              )}
            </section>
          </div>
        </main>
      </div>
      <HistoryEmployeesPalette
        people={[...employees]}
        ref={employeesPaletteRef}
        onChangeOption={onChangeEmployee}
      />
      <HistoryPeriodsPalette
        people={[...periods]}
        ref={periodsPaletteRef}
        onChangeOption={onChangeOption}
      />
      <Notification
            title={errorTitle}
            message={error}
            type="danger"
            ref={notificationRef}
          />
    </>
  );
}
