import PropTypes from "prop-types";
import { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import API from "../../services/API";
import { roundTwoDecimals } from "../../utils/math_utils";
import InfinityLoader from "../loaders/InfinityLoader";
import {
  ResponsiveContainer,
  Tooltip,
  Legend,
  BarChart,
  XAxis,
  YAxis,
  Bar,
} from "recharts";
import PlusFolder from "../vectors/PlusFolder";

const renderLegend = (props) => {
  const { payload } = props;

  return (
    <ul className="w-full flex flex-1 justify-center items-center space-x-6 flex-wrap">
      {payload.map((entry, index) => {
        const { payload } = entry;
        return (
          <li key={`item-${index}`} className="flex items-center">
            <div
              className="h-4 w-4 mr-2"
              style={{
                backgroundColor: payload.fill,
                border: 1,
                borderStyle: "solid",
                borderColor: payload.fill,
              }}
            ></div>
            <p className="text-sm">{entry.value}</p>
          </li>
        );
      })}
    </ul>
  );
};

const BarChartCulture = ({ period, isBoss, region, area, form }) => {
  const { user, token } = useSelector((state) => state.authentication);
  const [competences, setCompetences] = useState([]);
  const [loadingCompetences, setLoadingCompetences] = useState(true);

  useEffect(() => {
    if (period && form) {
      fetchBarChartCulture();
    }
  }, [period, region, area, form]);

  const fetchBarChartCulture = useCallback(() => {
    let params = { format: form ,period };
    if (region !== 0) {
      params.region = region;
    }
    if (area !== "0") {
      params.area = area;
    }
    setLoadingCompetences(true);
    const headers = { Authorization: token };
    API.get(`/results/culture`, { params, headers })
      .then(({ status, data }) => {
        setLoadingCompetences(false);
        if (status === 200) {
          let dataObj = data || [];
          setCompetences(dataObj);
        } else {
          setCompetences([]);
        }
      })
      .catch((error) => {
        setLoadingCompetences(false);
        setCompetences([]);
      });
  }, [period, region, area, token, form]);

  const getCompetences = () => {
    let data = [];
    let labelC = "porcentaje_cumple_autoevaluacion";
    let labelI = "porcentaje_insatisfactorio_autoevaluacion";
    let labelE = "porcentaje_excede_autoevaluacion";
    if (isBoss) {
      labelC = "porcentaje_cumple_evaluacion";
      labelI = "porcentaje_insatisfactorio_evaluacion";
      labelE = "porcentaje_excede_evaluacion";
    }
    competences.forEach((c) => {
      let apartado = {
        name: c.apartado_titulo,
        Cumple: roundTwoDecimals(c[labelC]),
        Insatisfactorio: roundTwoDecimals(c[labelI]),
        Excede: roundTwoDecimals(c[labelE]),
      };
      data.push(apartado);
    });
    return data;
  };

  return (
    <>
      <div className="overflow-hidden flex flex-col rounded-lg bg-white px-4 py-5 shadow sm:p-6 col-span-1 sm:col-span-6 h-96">
        <dt className="w-full text-center text-lg font-medium text-gray-500">
          Cultura
        </dt>
        {loadingCompetences ? (
          <InfinityLoader legend={false} className="h-60 w-full" />
        ) : (
          <dd className="flex-1">
            {!loadingCompetences && competences.length < 1
              ? <div className="flex flex-col h-full items-center justify-center mt-3">
                <PlusFolder />
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  No hay evaluaciones finalizadas
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Se debe tener al menos una evaluación finalizada con los filtros seleccionados para realizar el cálculo.
                </p>
              </div>
              :<ResponsiveContainer width="100%" height="100%">
              <BarChart
                margin={{ top: 15 }}
                data={getCompetences()}
              >
                <Tooltip />
                <Legend
                  content={renderLegend}
                  wrapperStyle={{ display: "flex" }}
                />
                <XAxis dataKey="name" />
                <YAxis type="number" />
                <Bar
                  dataKey="Cumple"
                  stackId="a"
                  fill="#16a34a66"
                  stroke="#16a34a"
                ></Bar>
                <Bar
                  dataKey="Insatisfactorio"
                  stackId="a"
                  fill="#dc262666"
                  stroke="#dc2626"
                />
                <Bar
                  dataKey="Excede"
                  stackId="a"
                  fill="#2563eb66"
                  stroke="#2563eb"
                />
              </BarChart>
            </ResponsiveContainer>
            }
          </dd>
        )}
      </div>
    </>
  );
};

BarChartCulture.propTypes = {
  period: PropTypes.number,
  isBoss: PropTypes.bool,
  region: PropTypes.number,
  area: PropTypes.string,
};

BarChartCulture.defaultProps = {
  period: null,
  isBoss: true,
  region: 0,
  area: "0",
};

export default BarChartCulture;
